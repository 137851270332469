import type { DeliveryDetailsDTO } from 'API';
import type { Reducer } from 'redux';
import type { loginRequestSuccess, userDetailsRefresh } from 'store/user/actions';
import { UserActionTypes } from 'store/user/types';
import { setTokens } from 'utils';
import { action } from 'typesafe-actions';
import type { MetaActions } from '../../../../store/meta/store';

export interface DeliveryDetailsState {
    details?: DeliveryDetailsDTO;
}

export const initialState: DeliveryDetailsState = {};

enum DeliveryDetailsActionTypes {
    SET_DELIVERY_DETAILS = '@@patient/UPDATE_DELIVERY_DETAILS',
}

export function setDeliveryDetails(deliveryDetails: DeliveryDetailsDTO) {
    return action(DeliveryDetailsActionTypes.SET_DELIVERY_DETAILS, deliveryDetails);
}

export type DeliveryDetailsActions =
    | ReturnType<typeof setDeliveryDetails>
    | ReturnType<typeof loginRequestSuccess>
    | ReturnType<typeof userDetailsRefresh>;

export const deliveryDetailsReducer: Reducer<DeliveryDetailsState, DeliveryDetailsActions | MetaActions> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case DeliveryDetailsActionTypes.SET_DELIVERY_DETAILS: {
            return { ...state, details: action.payload };
        }
        case UserActionTypes.LOGIN_REQUEST_SUCCESS: {
            setTokens(action.payload);
            return { ...state, details: action.payload.userDetails.client?.deliveryDetails };
        }
        case UserActionTypes.USER_DETAILS_REFRESH: {
            return { ...state, details: action.payload.client?.deliveryDetails };
        }
        default: {
            return state;
        }
    }
};
