import type {
    GroceryListAvailableDeliveryWindowsResponseDTO,
    GroceryListAvailableVendorsResponseDTO,
    GroceryListResponseDTO,
    InitiateGroceryListOfflineShoppingRequestDTO,
    ShopGroceryOrderOfflineRequestDTO,
    UpdateGroceryListAllowSubstitutionsRequestDTO,
    UpdateGroceryListClientCartCostDetailsRequestDTO,
    UpdateGroceryListRequestDTO,
    UpdateGroceryVendorPreferenceRequestDTO,
    UpdatePreferredDeliveryWindowsRequestDTO,
} from 'API';
import type { AxiosResponse } from 'axios';
import type { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import { clientAPI, groceryListAPI } from 'utils';

export const groceryQueryKeys = {
    activeGroceryList: (clientId: string) => ['getActiveGroceryList', clientId] as const,
};

export const useActiveGroceryList = (
    clientId: string,
    options?: Omit<UseQueryOptions<GroceryListResponseDTO>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        groceryQueryKeys.activeGroceryList(clientId),
        () => clientAPI.getActiveGroceryList(clientId).then((r) => r.data),
        options
    );

export const useGetGroceryListById = (
    groceryListId: string,
    options?: Omit<UseQueryOptions<GroceryListResponseDTO>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        ['getGroceryListById', groceryListId],
        () => groceryListAPI.getGroceryList(groceryListId).then((r) => r.data),
        options
    );

interface SubmitGroceryListUpdateArgs {
    id: string;
    requestDTO: UpdateGroceryListRequestDTO;
}

export const useUpdateGroceryList = (
    options?: UseMutationOptions<AxiosResponse<GroceryListResponseDTO>, unknown, SubmitGroceryListUpdateArgs>
) =>
    useMutation(
        (args: SubmitGroceryListUpdateArgs) => {
            return groceryListAPI.updateGroceryList(args.id, args.requestDTO);
        },
        { ...options }
    );

interface UpdateGroceryListClientCartCostDetailsArgs {
    id: string;
    requestDTO: UpdateGroceryListClientCartCostDetailsRequestDTO;
}

export const useUpdateGroceryListClientCartCostDetails = (
    options?: UseMutationOptions<
        AxiosResponse<GroceryListResponseDTO>,
        unknown,
        UpdateGroceryListClientCartCostDetailsArgs
    >
) =>
    useMutation(
        (args: UpdateGroceryListClientCartCostDetailsArgs) => {
            return groceryListAPI.updateGroceryListClientCartCostDetails(args.id, args.requestDTO);
        },
        { ...options }
    );

interface PlaceGroceryListOrderArgs {
    groceryListId: string;
}

export const useOrderGroceryList = (options?: UseMutationOptions<void, unknown, PlaceGroceryListOrderArgs>) =>
    useMutation(
        async (args: PlaceGroceryListOrderArgs) => {
            await groceryListAPI.placeGroceryListOrder(args.groceryListId);
        },
        { ...options }
    );

interface ShopOrderOfflineArgs {
    groceryListId: string;
    request?: ShopGroceryOrderOfflineRequestDTO;
}

export const useShopGroceryListOffline = (options?: UseMutationOptions<void, unknown, ShopOrderOfflineArgs>) =>
    useMutation(
        async (args: ShopOrderOfflineArgs) => {
            await groceryListAPI.shopOrderOffline(args.groceryListId, args.request);
        },
        { ...options }
    );
interface InitiateGroceryListOfflineShopping {
    groceryListId: string;
    request: InitiateGroceryListOfflineShoppingRequestDTO;
}

export const useInitiateGroceryListOfflineShopping = (
    options?: UseMutationOptions<void, unknown, InitiateGroceryListOfflineShopping>
) =>
    useMutation(
        async (args: InitiateGroceryListOfflineShopping) => {
            await groceryListAPI.initiateGroceryListOfflineShopping(args.groceryListId, args.request);
        },
        { ...options }
    );

interface AbortGroceryListOfflineShopping {
    groceryListId: string;
}

export const useAbortGroceryListOfflineShopping = (
    options?: UseMutationOptions<void, unknown, AbortGroceryListOfflineShopping>
) =>
    useMutation(
        async (args: AbortGroceryListOfflineShopping) => {
            await groceryListAPI.abortGroceryListOfflineShopping(args.groceryListId);
        },
        { ...options }
    );

interface UpdatePreferredDeliveryWindowsArgs {
    groceryListId: string;
    request: UpdatePreferredDeliveryWindowsRequestDTO;
}

export const useUpdatePreferredDeliveryWindows = (
    options?: UseMutationOptions<AxiosResponse<GroceryListResponseDTO>, unknown, UpdatePreferredDeliveryWindowsArgs>
) =>
    useMutation(
        (args: UpdatePreferredDeliveryWindowsArgs) => {
            return groceryListAPI.updatePreferredDeliveryWindows(args.groceryListId, args.request);
        },
        { ...options }
    );

export const availableVendorsQueryKeys = (groceryListId: string) => ['getAvailableVendors', groceryListId] as const;

export const useGetAvailableVendors = (
    groceryListId: string,
    options?: Omit<UseQueryOptions<GroceryListAvailableVendorsResponseDTO>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        availableVendorsQueryKeys(groceryListId),
        () => groceryListAPI.getAvailableVendors(groceryListId).then((r) => r.data),
        options
    );

export const availableDeliveryWindowsQueryKeys = (groceryListId: string) =>
    ['getAvailableDeliveryWindows', groceryListId] as const;

export const useGetAvailableDeliveryWindows = (
    groceryListId: string,
    options?: Omit<UseQueryOptions<GroceryListAvailableDeliveryWindowsResponseDTO>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        availableDeliveryWindowsQueryKeys(groceryListId),
        () => groceryListAPI.getAvailableDeliveryWindows(groceryListId).then((r) => r.data),
        options
    );
interface UpdateVendorPreferenceArgs {
    groceryListId: string;
    request: UpdateGroceryVendorPreferenceRequestDTO;
    selfCheckoutUrl?: string;
}

export const useUpdateVendorPreference = (
    options?: UseMutationOptions<AxiosResponse<GroceryListResponseDTO>, unknown, UpdateVendorPreferenceArgs>
) =>
    useMutation(
        (args: UpdateVendorPreferenceArgs) => {
            return groceryListAPI.updateVendorPreference(args.groceryListId, args.request);
        },
        { ...options }
    );

interface UpdateAllowSubstitutionsArgs {
    groceryListId: string;
    allowSubstitutions: boolean;
}

export const useUpdateAllowSubstitutions = (
    options?: UseMutationOptions<
        AxiosResponse<GroceryListResponseDTO>,
        unknown,
        UpdateGroceryListAllowSubstitutionsRequestDTO
    >
) =>
    useMutation(
        (args: UpdateAllowSubstitutionsArgs) => {
            return groceryListAPI.updateAllowSubstitutions(args.groceryListId, {
                allowSubstitutions: args.allowSubstitutions,
            });
        },
        { ...options }
    );
