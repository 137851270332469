/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CostRangeDTO } from './cost-range-dto';
// May contain unused imports in some cases
// @ts-ignore
import { DietModuleNameDTO } from './diet-module-name-dto';
// May contain unused imports in some cases
// @ts-ignore
import { MealTypeDTO } from './meal-type-dto';
// May contain unused imports in some cases
// @ts-ignore
import { RecipeStateDTO } from './recipe-state-dto';

/**
 * 
 * @export
 * @interface SearchRecipesRequestDTO
 */
export interface SearchRecipesRequestDTO {
    /**
     * 
     * @type {RecipeStateDTO}
     * @memberof SearchRecipesRequestDTO
     */
    'recipeState': RecipeStateDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchRecipesRequestDTO
     */
    'recipeTemplateComponentIds'?: Array<string>;
    /**
     * 
     * @type {Array<DietModuleNameDTO>}
     * @memberof SearchRecipesRequestDTO
     */
    'eligibleDietModules'?: Array<DietModuleNameDTO>;
    /**
     * Empty or null will not filter on the basis of cuisine
     * @type {Array<string>}
     * @memberof SearchRecipesRequestDTO
     */
    'cuisines'?: Array<string>;
    /**
     * Empty or null will not filter on the basis of meal type
     * @type {Array<MealTypeDTO>}
     * @memberof SearchRecipesRequestDTO
     */
    'meals'?: Array<MealTypeDTO>;
    /**
     * 
     * @type {string}
     * @memberof SearchRecipesRequestDTO
     */
    'recipeNameQuery'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRecipesRequestDTO
     */
    'ingredientNameQuery'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchRecipesRequestDTO
     */
    'servingCount'?: number;
    /**
     * 
     * @type {CostRangeDTO}
     * @memberof SearchRecipesRequestDTO
     */
    'useCostCentsPerServingRange'?: CostRangeDTO;
    /**
     * 
     * @type {CostRangeDTO}
     * @memberof SearchRecipesRequestDTO
     */
    'purchaseCostCentsPerServingRange'?: CostRangeDTO;
    /**
     * 
     * @type {CostRangeDTO}
     * @memberof SearchRecipesRequestDTO
     */
    'useCostCentsExcludingPantryPerServingRange'?: CostRangeDTO;
    /**
     * 
     * @type {CostRangeDTO}
     * @memberof SearchRecipesRequestDTO
     */
    'purchaseCostCentsExcludingPantryPerServingRange'?: CostRangeDTO;
    /**
     * If true, will filter out modular recipes. Defaults to false.
     * @type {boolean}
     * @memberof SearchRecipesRequestDTO
     */
    'filterOutModularRecipes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchRecipesRequestDTO
     */
    'sortBy'?: SearchRecipesRequestDTOSortByEnum;
    /**
     * Defaults to ASC
     * @type {string}
     * @memberof SearchRecipesRequestDTO
     */
    'sortDir'?: SearchRecipesRequestDTOSortDirEnum;
    /**
     * defaults to 100
     * @type {number}
     * @memberof SearchRecipesRequestDTO
     */
    'limit'?: number;
    /**
     * defaults to 0
     * @type {number}
     * @memberof SearchRecipesRequestDTO
     */
    'offset'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchRecipesRequestDTOSortByEnum {
    RECIPE_NAME = 'RECIPE_NAME',
    INVALID = 'INVALID'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchRecipesRequestDTOSortDirEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}

