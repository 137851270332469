/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddPreparedMealFeedbackRequestDTO } from '../models';
// @ts-ignore
import { CreateFoodOrderCartRequestDTO } from '../models';
// @ts-ignore
import { FoodOrderCartResponseDTO } from '../models';
// @ts-ignore
import { FoodOrderResponseDTO } from '../models';
// @ts-ignore
import { FoodOrdersResponseDTO } from '../models';
// @ts-ignore
import { GetPreparedMealFeedbackResponseDTO } from '../models';
// @ts-ignore
import { MarkFoodOrderDeliveredRequestDTO } from '../models';
// @ts-ignore
import { MarkFoodOrderLineItemAsNotConsumedRequestDTO } from '../models';
// @ts-ignore
import { OrderPaymentsDTO } from '../models';
// @ts-ignore
import { UpdateFoodOrderCartRequestDTO } from '../models';
// @ts-ignore
import { UpdateFoodOrderEstimatedDeliveryRequestDTO } from '../models';
/**
 * FoodOrderApi - axios parameter creator
 * @export
 */
export const FoodOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add feedback for a prepared meal from food order
         * @param {string} id Food Order ID
         * @param {AddPreparedMealFeedbackRequestDTO} addPreparedMealFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPreparedMealFeedback: async (id: string, addPreparedMealFeedbackRequest: AddPreparedMealFeedbackRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addPreparedMealFeedback', 'id', id)
            // verify required parameter 'addPreparedMealFeedbackRequest' is not null or undefined
            assertParamExists('addPreparedMealFeedback', 'addPreparedMealFeedbackRequest', addPreparedMealFeedbackRequest)
            const localVarPath = `/food_orders/{id}/prepared-meal-feedback`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPreparedMealFeedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelFoodOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelFoodOrder', 'id', id)
            const localVarPath = `/food_orders/{id}/cancel-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Claim a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimFoodOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('claimFoodOrder', 'id', id)
            const localVarPath = `/food_orders/{id}/claim`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a cart for a food order with a certain vendor 
         * @param {CreateFoodOrderCartRequestDTO} createFoodOrderCartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFoodOrderCart: async (createFoodOrderCartRequest: CreateFoodOrderCartRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFoodOrderCartRequest' is not null or undefined
            assertParamExists('createFoodOrderCart', 'createFoodOrderCartRequest', createFoodOrderCartRequest)
            const localVarPath = `/food_orders/create-cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFoodOrderCartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get food order by ID.
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoodOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFoodOrder', 'id', id)
            const localVarPath = `/food_orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get food order cart by ID.
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoodOrderCart: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFoodOrderCart', 'id', id)
            const localVarPath = `/food_orders/{id}/cart`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payments for a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoodOrderPayments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFoodOrderPayments', 'id', id)
            const localVarPath = `/food_orders/{id}/payments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get food orders that satisfy query paramaters.
         * @param {Array<'ORDER_CLAIMED' | 'ORDER_PLACED' | 'ORDER_DELIVERED'>} states Food Order states requested.
         * @param {string} [clientId] Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoodOrders: async (states: Array<'ORDER_CLAIMED' | 'ORDER_PLACED' | 'ORDER_DELIVERED'>, clientId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'states' is not null or undefined
            assertParamExists('getFoodOrders', 'states', states)
            const localVarPath = `/food_orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get prepared meal feedback
         * @param {string} clientId Client ID
         * @param {Array<string>} preparedMealIds Prepared Meal IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreparedMealFeedback: async (clientId: string, preparedMealIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getPreparedMealFeedback', 'clientId', clientId)
            // verify required parameter 'preparedMealIds' is not null or undefined
            assertParamExists('getPreparedMealFeedback', 'preparedMealIds', preparedMealIds)
            const localVarPath = `/food_orders/prepared-meal-feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (preparedMealIds) {
                localVarQueryParameter['preparedMealIds'] = preparedMealIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a food order delivered
         * @param {string} id Food Order ID
         * @param {MarkFoodOrderDeliveredRequestDTO} markFoodOrderDeliveredRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFoodOrderDelivered: async (id: string, markFoodOrderDeliveredRequest: MarkFoodOrderDeliveredRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markFoodOrderDelivered', 'id', id)
            // verify required parameter 'markFoodOrderDeliveredRequest' is not null or undefined
            assertParamExists('markFoodOrderDelivered', 'markFoodOrderDeliveredRequest', markFoodOrderDeliveredRequest)
            const localVarPath = `/food_orders/{id}/mark-delivered`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markFoodOrderDeliveredRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a food order line item as not consumed
         * @param {string} id Food Order ID
         * @param {MarkFoodOrderLineItemAsNotConsumedRequestDTO} markFoodOrderLineItemAsNotConsumedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFoodOrderLineItemAsNotConsumed: async (id: string, markFoodOrderLineItemAsNotConsumedRequest: MarkFoodOrderLineItemAsNotConsumedRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markFoodOrderLineItemAsNotConsumed', 'id', id)
            // verify required parameter 'markFoodOrderLineItemAsNotConsumedRequest' is not null or undefined
            assertParamExists('markFoodOrderLineItemAsNotConsumed', 'markFoodOrderLineItemAsNotConsumedRequest', markFoodOrderLineItemAsNotConsumedRequest)
            const localVarPath = `/food_orders/{id}/mark-line-item-as-not-consumed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markFoodOrderLineItemAsNotConsumedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a food order as sent to vendor
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFoodOrderSentToVendor: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markFoodOrderSentToVendor', 'id', id)
            const localVarPath = `/food_orders/{id}/mark-sent-to-vendor`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Place a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeFoodOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('placeFoodOrder', 'id', id)
            const localVarPath = `/food_orders/{id}/place-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update food order cart
         * @param {string} id Food Order ID
         * @param {UpdateFoodOrderCartRequestDTO} updateFoodOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFoodOrderCart: async (id: string, updateFoodOrderRequest: UpdateFoodOrderCartRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFoodOrderCart', 'id', id)
            // verify required parameter 'updateFoodOrderRequest' is not null or undefined
            assertParamExists('updateFoodOrderCart', 'updateFoodOrderRequest', updateFoodOrderRequest)
            const localVarPath = `/food_orders/{id}/cart`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFoodOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update food order estimated delivery
         * @param {string} id Food Order ID
         * @param {UpdateFoodOrderEstimatedDeliveryRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFoodOrderEstimatedDelivery: async (id: string, request: UpdateFoodOrderEstimatedDeliveryRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFoodOrderEstimatedDelivery', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateFoodOrderEstimatedDelivery', 'request', request)
            const localVarPath = `/food_orders/{id}/update-estimated-delivery`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FoodOrderApi - functional programming interface
 * @export
 */
export const FoodOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FoodOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add feedback for a prepared meal from food order
         * @param {string} id Food Order ID
         * @param {AddPreparedMealFeedbackRequestDTO} addPreparedMealFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPreparedMealFeedback(id: string, addPreparedMealFeedbackRequest: AddPreparedMealFeedbackRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPreparedMealFeedback(id, addPreparedMealFeedbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelFoodOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelFoodOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Claim a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimFoodOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimFoodOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a cart for a food order with a certain vendor 
         * @param {CreateFoodOrderCartRequestDTO} createFoodOrderCartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFoodOrderCart(createFoodOrderCartRequest: CreateFoodOrderCartRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodOrderCartResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFoodOrderCart(createFoodOrderCartRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get food order by ID.
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFoodOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFoodOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get food order cart by ID.
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFoodOrderCart(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodOrderCartResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFoodOrderCart(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get payments for a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFoodOrderPayments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPaymentsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFoodOrderPayments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get food orders that satisfy query paramaters.
         * @param {Array<'ORDER_CLAIMED' | 'ORDER_PLACED' | 'ORDER_DELIVERED'>} states Food Order states requested.
         * @param {string} [clientId] Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFoodOrders(states: Array<'ORDER_CLAIMED' | 'ORDER_PLACED' | 'ORDER_DELIVERED'>, clientId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodOrdersResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFoodOrders(states, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get prepared meal feedback
         * @param {string} clientId Client ID
         * @param {Array<string>} preparedMealIds Prepared Meal IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreparedMealFeedback(clientId: string, preparedMealIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPreparedMealFeedbackResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreparedMealFeedback(clientId, preparedMealIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a food order delivered
         * @param {string} id Food Order ID
         * @param {MarkFoodOrderDeliveredRequestDTO} markFoodOrderDeliveredRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markFoodOrderDelivered(id: string, markFoodOrderDeliveredRequest: MarkFoodOrderDeliveredRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markFoodOrderDelivered(id, markFoodOrderDeliveredRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a food order line item as not consumed
         * @param {string} id Food Order ID
         * @param {MarkFoodOrderLineItemAsNotConsumedRequestDTO} markFoodOrderLineItemAsNotConsumedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markFoodOrderLineItemAsNotConsumed(id: string, markFoodOrderLineItemAsNotConsumedRequest: MarkFoodOrderLineItemAsNotConsumedRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markFoodOrderLineItemAsNotConsumed(id, markFoodOrderLineItemAsNotConsumedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a food order as sent to vendor
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markFoodOrderSentToVendor(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markFoodOrderSentToVendor(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Place a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placeFoodOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placeFoodOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update food order cart
         * @param {string} id Food Order ID
         * @param {UpdateFoodOrderCartRequestDTO} updateFoodOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFoodOrderCart(id: string, updateFoodOrderRequest: UpdateFoodOrderCartRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodOrderCartResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFoodOrderCart(id, updateFoodOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update food order estimated delivery
         * @param {string} id Food Order ID
         * @param {UpdateFoodOrderEstimatedDeliveryRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFoodOrderEstimatedDelivery(id: string, request: UpdateFoodOrderEstimatedDeliveryRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFoodOrderEstimatedDelivery(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FoodOrderApi - factory interface
 * @export
 */
export const FoodOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FoodOrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Add feedback for a prepared meal from food order
         * @param {string} id Food Order ID
         * @param {AddPreparedMealFeedbackRequestDTO} addPreparedMealFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPreparedMealFeedback(id: string, addPreparedMealFeedbackRequest: AddPreparedMealFeedbackRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addPreparedMealFeedback(id, addPreparedMealFeedbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelFoodOrder(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelFoodOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Claim a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimFoodOrder(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.claimFoodOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a cart for a food order with a certain vendor 
         * @param {CreateFoodOrderCartRequestDTO} createFoodOrderCartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFoodOrderCart(createFoodOrderCartRequest: CreateFoodOrderCartRequestDTO, options?: any): AxiosPromise<FoodOrderCartResponseDTO> {
            return localVarFp.createFoodOrderCart(createFoodOrderCartRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get food order by ID.
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoodOrder(id: string, options?: any): AxiosPromise<FoodOrderResponseDTO> {
            return localVarFp.getFoodOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get food order cart by ID.
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoodOrderCart(id: string, options?: any): AxiosPromise<FoodOrderCartResponseDTO> {
            return localVarFp.getFoodOrderCart(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payments for a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoodOrderPayments(id: string, options?: any): AxiosPromise<OrderPaymentsDTO> {
            return localVarFp.getFoodOrderPayments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get food orders that satisfy query paramaters.
         * @param {Array<'ORDER_CLAIMED' | 'ORDER_PLACED' | 'ORDER_DELIVERED'>} states Food Order states requested.
         * @param {string} [clientId] Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoodOrders(states: Array<'ORDER_CLAIMED' | 'ORDER_PLACED' | 'ORDER_DELIVERED'>, clientId?: string, options?: any): AxiosPromise<FoodOrdersResponseDTO> {
            return localVarFp.getFoodOrders(states, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get prepared meal feedback
         * @param {string} clientId Client ID
         * @param {Array<string>} preparedMealIds Prepared Meal IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreparedMealFeedback(clientId: string, preparedMealIds: Array<string>, options?: any): AxiosPromise<GetPreparedMealFeedbackResponseDTO> {
            return localVarFp.getPreparedMealFeedback(clientId, preparedMealIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a food order delivered
         * @param {string} id Food Order ID
         * @param {MarkFoodOrderDeliveredRequestDTO} markFoodOrderDeliveredRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFoodOrderDelivered(id: string, markFoodOrderDeliveredRequest: MarkFoodOrderDeliveredRequestDTO, options?: any): AxiosPromise<FoodOrderResponseDTO> {
            return localVarFp.markFoodOrderDelivered(id, markFoodOrderDeliveredRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a food order line item as not consumed
         * @param {string} id Food Order ID
         * @param {MarkFoodOrderLineItemAsNotConsumedRequestDTO} markFoodOrderLineItemAsNotConsumedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFoodOrderLineItemAsNotConsumed(id: string, markFoodOrderLineItemAsNotConsumedRequest: MarkFoodOrderLineItemAsNotConsumedRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.markFoodOrderLineItemAsNotConsumed(id, markFoodOrderLineItemAsNotConsumedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a food order as sent to vendor
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFoodOrderSentToVendor(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.markFoodOrderSentToVendor(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Place a food order
         * @param {string} id Food Order ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeFoodOrder(id: string, options?: any): AxiosPromise<FoodOrderResponseDTO> {
            return localVarFp.placeFoodOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update food order cart
         * @param {string} id Food Order ID
         * @param {UpdateFoodOrderCartRequestDTO} updateFoodOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFoodOrderCart(id: string, updateFoodOrderRequest: UpdateFoodOrderCartRequestDTO, options?: any): AxiosPromise<FoodOrderCartResponseDTO> {
            return localVarFp.updateFoodOrderCart(id, updateFoodOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update food order estimated delivery
         * @param {string} id Food Order ID
         * @param {UpdateFoodOrderEstimatedDeliveryRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFoodOrderEstimatedDelivery(id: string, request: UpdateFoodOrderEstimatedDeliveryRequestDTO, options?: any): AxiosPromise<FoodOrderResponseDTO> {
            return localVarFp.updateFoodOrderEstimatedDelivery(id, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FoodOrderApi - object-oriented interface
 * @export
 * @class FoodOrderApi
 * @extends {BaseAPI}
 */
export class FoodOrderApi extends BaseAPI {
    /**
     * 
     * @summary Add feedback for a prepared meal from food order
     * @param {string} id Food Order ID
     * @param {AddPreparedMealFeedbackRequestDTO} addPreparedMealFeedbackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public addPreparedMealFeedback(id: string, addPreparedMealFeedbackRequest: AddPreparedMealFeedbackRequestDTO, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).addPreparedMealFeedback(id, addPreparedMealFeedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a food order
     * @param {string} id Food Order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public cancelFoodOrder(id: string, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).cancelFoodOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Claim a food order
     * @param {string} id Food Order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public claimFoodOrder(id: string, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).claimFoodOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a cart for a food order with a certain vendor 
     * @param {CreateFoodOrderCartRequestDTO} createFoodOrderCartRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public createFoodOrderCart(createFoodOrderCartRequest: CreateFoodOrderCartRequestDTO, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).createFoodOrderCart(createFoodOrderCartRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get food order by ID.
     * @param {string} id Food Order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public getFoodOrder(id: string, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).getFoodOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get food order cart by ID.
     * @param {string} id Food Order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public getFoodOrderCart(id: string, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).getFoodOrderCart(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payments for a food order
     * @param {string} id Food Order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public getFoodOrderPayments(id: string, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).getFoodOrderPayments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get food orders that satisfy query paramaters.
     * @param {Array<'ORDER_CLAIMED' | 'ORDER_PLACED' | 'ORDER_DELIVERED'>} states Food Order states requested.
     * @param {string} [clientId] Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public getFoodOrders(states: Array<'ORDER_CLAIMED' | 'ORDER_PLACED' | 'ORDER_DELIVERED'>, clientId?: string, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).getFoodOrders(states, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get prepared meal feedback
     * @param {string} clientId Client ID
     * @param {Array<string>} preparedMealIds Prepared Meal IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public getPreparedMealFeedback(clientId: string, preparedMealIds: Array<string>, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).getPreparedMealFeedback(clientId, preparedMealIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a food order delivered
     * @param {string} id Food Order ID
     * @param {MarkFoodOrderDeliveredRequestDTO} markFoodOrderDeliveredRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public markFoodOrderDelivered(id: string, markFoodOrderDeliveredRequest: MarkFoodOrderDeliveredRequestDTO, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).markFoodOrderDelivered(id, markFoodOrderDeliveredRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a food order line item as not consumed
     * @param {string} id Food Order ID
     * @param {MarkFoodOrderLineItemAsNotConsumedRequestDTO} markFoodOrderLineItemAsNotConsumedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public markFoodOrderLineItemAsNotConsumed(id: string, markFoodOrderLineItemAsNotConsumedRequest: MarkFoodOrderLineItemAsNotConsumedRequestDTO, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).markFoodOrderLineItemAsNotConsumed(id, markFoodOrderLineItemAsNotConsumedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a food order as sent to vendor
     * @param {string} id Food Order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public markFoodOrderSentToVendor(id: string, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).markFoodOrderSentToVendor(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Place a food order
     * @param {string} id Food Order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public placeFoodOrder(id: string, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).placeFoodOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update food order cart
     * @param {string} id Food Order ID
     * @param {UpdateFoodOrderCartRequestDTO} updateFoodOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public updateFoodOrderCart(id: string, updateFoodOrderRequest: UpdateFoodOrderCartRequestDTO, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).updateFoodOrderCart(id, updateFoodOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update food order estimated delivery
     * @param {string} id Food Order ID
     * @param {UpdateFoodOrderEstimatedDeliveryRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodOrderApi
     */
    public updateFoodOrderEstimatedDelivery(id: string, request: UpdateFoodOrderEstimatedDeliveryRequestDTO, options?: AxiosRequestConfig) {
        return FoodOrderApiFp(this.configuration).updateFoodOrderEstimatedDelivery(id, request, options).then((request) => request(this.axios, this.basePath));
    }
}
