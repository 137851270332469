import clsx from 'clsx';

type iconProps = {
    src: string;
    alt: string;
    ariaLabel?: string;
    width?: number;
};

export type Props = {
    icon?: iconProps;
    text?: string;
    className?: string;
};

const Chip = ({ icon, text, className }: Props): JSX.Element => {
    return (
        <div
            className={clsx('border-standard-oat inline-flex items-center rounded-[100px] border px-2 py-1', className)}
        >
            {icon && (
                <img src={icon.src} alt={icon.alt} aria-label={icon.ariaLabel} className="smIcon relative bottom-0" />
            )}
            <label className={clsx('overlineLight', icon && 'ml-2')}>{text}</label>
        </div>
    );
};

export default Chip;
