import NewVersionBanner from 'components/NewVersionBanner';
import NotFoundPage from 'components/NotFoundPage/NotFoundPage';
import OpenNativeLink from 'components/OpenNativeLink';
import WithHelmet from 'components/WithHelmet';
import { LoadingScreen } from 'galley';
import type React from 'react';
import { Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import {
    ADMIN_APP_HOME,
    CARE_APP_HOME,
    GATEWAY_APP_HOME,
    LOGIN_ROUTE,
    PATIENT_APP_HOME,
    PATIENT_SIGN_UP_META_TITLE,
    PATIENT_SIGN_UP_ROUTE,
    PROVIDER_APP_HOME,
} from 'utils';
import { lazyWithRetry } from './lazyWithRetry';

const AdminRouter = lazyWithRetry(() => import('../apps/admin/Routes'), 'admin');
const CareRouter = lazyWithRetry(() => import('../apps/care/Routes'), 'care');
const GatewayRouter = lazyWithRetry(() => import('../apps/gateway/Routes'), 'gateway');
const PatientRouter = lazyWithRetry(() => import('../apps/patient/routes'), 'patient');
const ProviderRouter = lazyWithRetry(() => import('../apps/provider/Routes'), 'provider');
// it is important for sign-up to be fast, so split it out
const SignUp = lazyWithRetry(() => import('../apps/patient/pages/SignUp/SignUp'), 'signup');

const AllRoutes: React.FC = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" errorElement={<NotFoundPage />}>
                <Route path="/" element={<Navigate to={LOGIN_ROUTE} />} />
                <Route path={'/open-native-link/'} element={<OpenNativeLink />} />
                <Route path="/" element={<NewVersionBanner />}>
                    <Route
                        path={`${ADMIN_APP_HOME}/*`}
                        element={
                            <Suspense fallback={<LoadingScreen testId="suspense-fallback-ADMIN-router" />}>
                                <AdminRouter />
                            </Suspense>
                        }
                    />
                    <Route
                        path={`${CARE_APP_HOME}/*`}
                        element={
                            <Suspense fallback={<LoadingScreen testId="suspense-fallback-CARE-router" />}>
                                <CareRouter />
                            </Suspense>
                        }
                    />
                    <Route
                        path={`${GATEWAY_APP_HOME}/*`}
                        element={
                            <Suspense fallback={<LoadingScreen />}>
                                <GatewayRouter />
                            </Suspense>
                        }
                    />
                    <Route
                        path={`${PROVIDER_APP_HOME}/*`}
                        element={
                            <Suspense fallback={<LoadingScreen testId="suspense-fallback-PROVIDER-router" />}>
                                <ProviderRouter />
                            </Suspense>
                        }
                    />
                    <Route
                        path={PATIENT_SIGN_UP_ROUTE}
                        element={
                            <WithHelmet title={PATIENT_SIGN_UP_META_TITLE}>
                                <Suspense fallback={<LoadingScreen />}>
                                    <SignUp />
                                </Suspense>
                            </WithHelmet>
                        }
                    />
                    <Route
                        path={`${PATIENT_APP_HOME}/*`}
                        element={
                            <Suspense fallback={<LoadingScreen testId="suspense-fallback-CLIENT-router" />}>
                                <PatientRouter />
                            </Suspense>
                        }
                    />
                </Route>
            </Route>
        )
    );

    return <RouterProvider router={router} />;
};

export default AllRoutes;
