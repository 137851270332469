import type { InsurancePolicyResponseDTO } from 'API';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { insurancePolicyApi } from '../index';

export const useActiveInsurancePolicy = (
    clientId: string,
    options?: Omit<UseQueryOptions<InsurancePolicyResponseDTO | undefined>, 'queryFn' | 'queryKey'>
) =>
    useQuery(
        ['activeInsurancePolicy', clientId],
        async () => {
            const res = (await insurancePolicyApi.getInsurancePolicies(clientId)).data;
            return res.activePolicyId ? res.policies.find((policy) => policy.id === res.activePolicyId) : undefined;
        },
        options
    );
