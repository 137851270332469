import { ZIP_CODE_REGEX } from 'utils/validation';
import * as Yup from 'yup';

export type PaymentFormValues = {
    fullName: string;
    zipCode: string;
    cardNumber: { isValid: boolean };
    cardExpiry: { isValid: boolean };
    cardCvc: { isValid: boolean };
};

export const initialValues: PaymentFormValues = {
    fullName: '',
    zipCode: '',
    cardNumber: { isValid: false },
    cardExpiry: { isValid: false },
    cardCvc: { isValid: false },
};

const stripeFieldValidationSchema = Yup.object().shape({
    isValid: Yup.bool().test((v) => v === true),
});

export const validationSchema = Yup.object().shape({
    fullName: Yup.string().trim().required(),
    zipCode: Yup.string().matches(ZIP_CODE_REGEX).required(),
    cardNumber: stripeFieldValidationSchema,
    cardExpiry: stripeFieldValidationSchema,
    cardCvc: stripeFieldValidationSchema,
});

export const stripeInputOptions = {
    classes: {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        base: 'input-field bg-white placeholder:text-greyscale-inactive disabled:text-greyscale-inactive',
        // eslint-disable-next-line lingui/no-unlocalized-strings
        focus: '!border-2 !px-[15px] !py-[11px]',
        invalid: '!border-alert-red2',
    },
    style: {
        base: {
            // eslint-disable-next-line lingui/no-unlocalized-strings
            fontFamily: 'GT America',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
        },
        invalid: {
            color: '#000',
        },
    },
};
