export enum BannerColors {
    GREEN = 'border-traffic-lightGreen bg-alert-lightGreen text-traffic-green',
    GREEN_BLACK_TEXT = 'border-traffic-lightGreen bg-alert-lightGreen text-black',
    YELLOW = 'border-traffic-yellow bg-alert-lightYellow',
    RED = 'border-traffic-red bg-alert-lightRed text-traffic-red',
    BLUE = 'bg-lowBlue-1 text-highBlue-3 border-0',
    WHEAT = 'border-wheat-medium bg-wheat-light/30 text-wheat-dark',
    SAGE = 'border-sage-medium bg-sage-light/30 text-sage-dark',
    WHITE = 'border-standard-barley bg-white text-black',
}
