const PERSISTED_ITEMS_KEY = 'persisted';

export const getPersistedItems = (): { [key: string]: unknown } => {
    return JSON.parse(localStorage.getItem(PERSISTED_ITEMS_KEY) || '{}');
};

export const getPersistedItem = <T>(key: string): T | undefined => {
    return getPersistedItems()[key] as T | undefined;
};

export const setPersistedItems = (items: { [key: string]: unknown }) => {
    localStorage.setItem(PERSISTED_ITEMS_KEY, JSON.stringify(items));
};

export const setPersistedItem = (key: string, value: unknown): void => {
    setPersistedItems({
        ...getPersistedItems(),
        [key]: value,
    });
};
