import { useField } from 'formik';
import { forwardRef } from 'react';
import { trackBlur } from 'utils/tracking/events';
import type { Props } from './Input';
import Input from './Input';

const InputWithFormik = forwardRef(({ type, name, value, ...props }: Props, ref: React.Ref<HTMLInputElement>) => {
    const [field, meta] = useField({
        name: name || '',
        type: type,
        value: value,
    });
    const isInvalid = !!meta.error && meta.touched;
    return (
        <Input
            {...props}
            type={type}
            label={props.label}
            isInvalid={isInvalid}
            {...field}
            ref={ref}
            onBlur={(e) => {
                field.onBlur(e);
                trackBlur({
                    label: field.name,
                    error: meta.error,
                });
            }}
        />
    );
});

// eslint-disable-next-line lingui/no-unlocalized-strings
InputWithFormik.displayName = 'InputWithFormik';

export default InputWithFormik;
