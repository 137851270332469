import { Trans } from '@lingui/macro';
import clsx from 'clsx';

export type Props = { size?: 16 | 24 | 32; color?: 'primary' | 'secondary'; className?: string };

const Spinner = ({ size = 16, color = 'primary', className }: Props): JSX.Element => {
    return (
        <div
            className={clsx(
                'inline-block animate-spin rounded-full border-2 border-solid border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
                size === 16 && 'h-4 w-4',
                size === 24 && 'h-6 w-6',
                size === 32 && 'h-8 w-8',
                color === 'primary' && 'text-highBlue-1 border-highBlue-1',
                color === 'secondary' && 'border-white text-white',
                className
            )}
            role="progressbar"
        >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                <Trans>Loading...</Trans>
            </span>
        </div>
    );
};

export default Spinner;
