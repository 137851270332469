/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum DietDTO {
    DASH = 'DASH',
    DASH_1500 = 'DASH_1500',
    DIABETES_FRIENDLY = 'DIABETES_FRIENDLY',
    DIALYSIS_KIDNEY_FRIENDLY = 'DIALYSIS_KIDNEY_FRIENDLY',
    GENERAL_HEALTHFUL = 'GENERAL_HEALTHFUL',
    GLUTEN_FREE = 'GLUTEN_FREE',
    HIGH_FIBER = 'HIGH_FIBER',
    KIDNEY_FRIENDLY = 'KIDNEY_FRIENDLY',
    MEDITERRANEAN = 'MEDITERRANEAN',
    PREGNANCY_FRIENDLY = 'PREGNANCY_FRIENDLY'
};

