import clsx from 'clsx';
import { Button } from 'galley';
import type { Props as ButtonProps } from '../Button/Button';

export type Props = React.PropsWithChildren<
    {
        variant?: keyof typeof STYLES;
    } & ButtonProps
>;

const BASE = {
    plain: clsx(
        'shadow-pill flex h-9 w-9 cursor-pointer items-center justify-center rounded-full duration-200 ease-in'
    ),
};

const enum STYLES {
    primary,
    secondary,
}

const IconButton = ({ variant, ...props }: Props): JSX.Element => {
    const className = clsx(variant && BASE.plain, props.className);

    return <Button {...props} variant={variant} className={className} asIcon />;
};

export default IconButton;
