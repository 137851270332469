import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import type { OptionWithLabel } from 'galley';
import isEmpty from 'lodash/isEmpty';
import { Suggestion } from './Suggestion';

export type Props = {
    filteredSuggestions: OptionWithLabel[];
    fallbackSuggestions?: OptionWithLabel[];
    className?: string;
    activeSuggestion: number;
    onSelect: (suggestion: OptionWithLabel) => void;
    normalize?: boolean;
};

const Suggestions = ({
    filteredSuggestions,
    fallbackSuggestions,
    className,
    activeSuggestion,
    onSelect,
    normalize,
}: Props): JSX.Element => (
    <div
        aria-label={t`suggestions`}
        className={clsx(
            'border-standard-barley bodyMdLight shadow-a-2 absolute z-50 mx-auto max-h-36 w-full overflow-y-scroll rounded-md border bg-white py-4 text-left',
            className
        )}
    >
        {isEmpty(filteredSuggestions) && isEmpty(fallbackSuggestions) ? (
            <p className="px-4">
                <Trans>No Option Available</Trans>
            </p>
        ) : (
            <>
                <ul>
                    {filteredSuggestions?.map((suggestion, index) => (
                        <Suggestion
                            activeSuggestion={activeSuggestion}
                            index={index}
                            key={index}
                            normalize={normalize}
                            onSelect={onSelect}
                            suggestion={suggestion}
                        />
                    ))}
                    {fallbackSuggestions && <li className="border-standard-oat mx-4 border-b outline-1" />}
                    {fallbackSuggestions?.map((suggestion, index) => {
                        const curIndex = filteredSuggestions.length + index;
                        return (
                            <Suggestion
                                activeSuggestion={activeSuggestion}
                                index={curIndex}
                                key={curIndex}
                                normalize={normalize}
                                onSelect={onSelect}
                                suggestion={suggestion}
                            />
                        );
                    })}
                </ul>
            </>
        )}
    </div>
);

export default Suggestions;
