import { t } from '@lingui/macro';
import clsx from 'clsx';
import { MinusStrokeIcon, PlusStrokeIcon, TrashStrokeIcon } from 'galley/Icons';
import { trackClick } from 'utils';
import { PrimaryStepInput } from './Primary';
import { SecondaryStepInput } from './Secondary';
import { TertiaryStepInput } from './Tertiary';

export type Props = {
    variant: 'primary' | 'secondary' | 'tertiary';
    value: number;
    onIncrement: () => void | React.MouseEventHandler<HTMLButtonElement>;
    onDecrement: () => void | React.MouseEventHandler<HTMLButtonElement>;
    isDisabled?: boolean;
    isLoading?: boolean;
    formatValue?: (value: number) => string;
    className?: string;
    maxValue?: number;
    minValue?: number;
};

export type VariantProps = Omit<Props, 'variant'>;

export const onClickHandler = (callback: () => void) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        trackClick({ label: (e.target as HTMLElement).ariaLabel ?? 'step input clicked' });
        callback();
    };
};
const iconClassName = clsx('mdIcon stroke-[3] transition group-active:scale-75');

export const PlusIcon = (): JSX.Element => {
    return <PlusStrokeIcon aria-label={t`increment`} className={iconClassName} />;
};

export const MinusIcon = (): JSX.Element => {
    return <MinusStrokeIcon aria-label={t`decrement`} className={iconClassName} />;
};

export const TrashIcon = (): JSX.Element => {
    return <TrashStrokeIcon aria-label={t`remove`} className={iconClassName} />;
};

const StepInput = (props: Props): JSX.Element => {
    const { variant } = props;
    if (variant === 'primary') return <PrimaryStepInput {...props} />;
    if (variant === 'secondary') return <SecondaryStepInput {...props} />;
    return <TertiaryStepInput {...props} />;
};

export default StepInput;
