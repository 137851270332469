/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConversationResponseDTO } from '../models';
// @ts-ignore
import { ConversationsResponseDTO } from '../models';
// @ts-ignore
import { MessagesResponseDTO } from '../models';
// @ts-ignore
import { MessagingLinkRequestDTO } from '../models';
// @ts-ignore
import { RemoteFileDTO } from '../models';
// @ts-ignore
import { SendMessageRequestDTO } from '../models';
/**
 * MessagingApi - axios parameter creator
 * @export
 */
export const MessagingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get attachment by id.
         * @param {string} attachmentId Attachment ID
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachment: async (attachmentId: string, clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('getAttachment', 'attachmentId', attachmentId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getAttachment', 'clientId', clientId)
            const localVarPath = `/messaging/attachments/{attachmentId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get conversation by id.
         * @param {string} conversationId 
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation: async (conversationId: string, clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getConversation', 'conversationId', conversationId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getConversation', 'clientId', clientId)
            const localVarPath = `/messaging/conversations/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get conversations previews for client.
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getConversations', 'clientId', clientId)
            const localVarPath = `/messaging/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get messages for conversation.
         * @param {string} clientId Client ID
         * @param {string} conversationId Conversation ID from Healthie
         * @param {number} [offset] Message offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (clientId: string, conversationId: string, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getMessages', 'clientId', clientId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getMessages', 'conversationId', conversationId)
            const localVarPath = `/messaging/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['conversationId'] = conversationId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link client to external message service user id
         * @param {MessagingLinkRequestDTO} sendMessageRequest Request to link client to external messaging service user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkClientToMessagingUser: async (sendMessageRequest: MessagingLinkRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMessageRequest' is not null or undefined
            assertParamExists('linkClientToMessagingUser', 'sendMessageRequest', sendMessageRequest)
            const localVarPath = `/messaging/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send message
         * @param {string} conversationId 
         * @param {string} clientId Client ID
         * @param {SendMessageRequestDTO} sendMessageRequest Request to send a message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage: async (conversationId: string, clientId: string, sendMessageRequest: SendMessageRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('sendMessage', 'conversationId', conversationId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('sendMessage', 'clientId', clientId)
            // verify required parameter 'sendMessageRequest' is not null or undefined
            assertParamExists('sendMessage', 'sendMessageRequest', sendMessageRequest)
            const localVarPath = `/messaging/conversations/{conversationId}/send-message`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagingApi - functional programming interface
 * @export
 */
export const MessagingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get attachment by id.
         * @param {string} attachmentId Attachment ID
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttachment(attachmentId: string, clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteFileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachment(attachmentId, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get conversation by id.
         * @param {string} conversationId 
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversation(conversationId: string, clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversation(conversationId, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get conversations previews for client.
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversations(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversations(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get messages for conversation.
         * @param {string} clientId Client ID
         * @param {string} conversationId Conversation ID from Healthie
         * @param {number} [offset] Message offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(clientId: string, conversationId: string, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(clientId, conversationId, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Link client to external message service user id
         * @param {MessagingLinkRequestDTO} sendMessageRequest Request to link client to external messaging service user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkClientToMessagingUser(sendMessageRequest: MessagingLinkRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkClientToMessagingUser(sendMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send message
         * @param {string} conversationId 
         * @param {string} clientId Client ID
         * @param {SendMessageRequestDTO} sendMessageRequest Request to send a message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessage(conversationId: string, clientId: string, sendMessageRequest: SendMessageRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessage(conversationId, clientId, sendMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagingApi - factory interface
 * @export
 */
export const MessagingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get attachment by id.
         * @param {string} attachmentId Attachment ID
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachment(attachmentId: string, clientId: string, options?: any): AxiosPromise<RemoteFileDTO> {
            return localVarFp.getAttachment(attachmentId, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get conversation by id.
         * @param {string} conversationId 
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation(conversationId: string, clientId: string, options?: any): AxiosPromise<ConversationResponseDTO> {
            return localVarFp.getConversation(conversationId, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get conversations previews for client.
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations(clientId: string, options?: any): AxiosPromise<ConversationsResponseDTO> {
            return localVarFp.getConversations(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get messages for conversation.
         * @param {string} clientId Client ID
         * @param {string} conversationId Conversation ID from Healthie
         * @param {number} [offset] Message offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(clientId: string, conversationId: string, offset?: number, options?: any): AxiosPromise<MessagesResponseDTO> {
            return localVarFp.getMessages(clientId, conversationId, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link client to external message service user id
         * @param {MessagingLinkRequestDTO} sendMessageRequest Request to link client to external messaging service user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkClientToMessagingUser(sendMessageRequest: MessagingLinkRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.linkClientToMessagingUser(sendMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send message
         * @param {string} conversationId 
         * @param {string} clientId Client ID
         * @param {SendMessageRequestDTO} sendMessageRequest Request to send a message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(conversationId: string, clientId: string, sendMessageRequest: SendMessageRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.sendMessage(conversationId, clientId, sendMessageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessagingApi - object-oriented interface
 * @export
 * @class MessagingApi
 * @extends {BaseAPI}
 */
export class MessagingApi extends BaseAPI {
    /**
     * 
     * @summary Get attachment by id.
     * @param {string} attachmentId Attachment ID
     * @param {string} clientId Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getAttachment(attachmentId: string, clientId: string, options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).getAttachment(attachmentId, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get conversation by id.
     * @param {string} conversationId 
     * @param {string} clientId Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getConversation(conversationId: string, clientId: string, options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).getConversation(conversationId, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get conversations previews for client.
     * @param {string} clientId Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getConversations(clientId: string, options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).getConversations(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get messages for conversation.
     * @param {string} clientId Client ID
     * @param {string} conversationId Conversation ID from Healthie
     * @param {number} [offset] Message offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getMessages(clientId: string, conversationId: string, offset?: number, options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).getMessages(clientId, conversationId, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link client to external message service user id
     * @param {MessagingLinkRequestDTO} sendMessageRequest Request to link client to external messaging service user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public linkClientToMessagingUser(sendMessageRequest: MessagingLinkRequestDTO, options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).linkClientToMessagingUser(sendMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send message
     * @param {string} conversationId 
     * @param {string} clientId Client ID
     * @param {SendMessageRequestDTO} sendMessageRequest Request to send a message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public sendMessage(conversationId: string, clientId: string, sendMessageRequest: SendMessageRequestDTO, options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).sendMessage(conversationId, clientId, sendMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
