/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum LessonModuleFeedbackReasonDTO {
    DID_NOT_UNDERSTAND_CONTENT = 'DID_NOT_UNDERSTAND_CONTENT',
    ALREADY_KNEW_CONTENT = 'ALREADY_KNEW_CONTENT',
    OTHER = 'OTHER'
};

