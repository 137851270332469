import clsx from 'clsx';
import { Button, Spinner } from 'galley';
import type { VariantProps } from './StepInput';
import { MinusIcon, onClickHandler, PlusIcon, TrashIcon } from './StepInput';

export const SecondaryStepInput = ({
    onIncrement,
    onDecrement,
    value,
    formatValue,
    isDisabled = false,
    isLoading = false,
    maxValue,
    minValue = 1,
    className,
}: VariantProps): JSX.Element => {
    const isExpanded = value > 0;
    return (
        <div
            data-dd-privacy="allow"
            className={clsx(
                'rounded-round shadow-a-1 flex h-[42px] w-fit flex-row items-center justify-center gap-2 border text-white transition-all duration-500',
                isExpanded && 'border-highBlue-3 bg-highBlue-3',
                !isExpanded && 'bg-highBlue-1 border-highBlue-1',
                className
            )}
            role="presentation"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {isExpanded && (
                <>
                    <Button
                        className="md:hover:bg-highBlue-2 active:bg-highBlue-2 rounded-l-round active:border-highBlue-2 group h-10 flex-none p-3"
                        onClick={onClickHandler(onDecrement)}
                        disabled={isDisabled || !isExpanded}
                    >
                        {value > minValue ? (
                            <MinusIcon />
                        ) : value === minValue ? (
                            <TrashIcon />
                        ) : (
                            <div className="mdIcon" />
                        )}
                    </Button>
                    <div className="flex w-16 flex-1 items-center justify-center text-center">
                        {isLoading ? (
                            <Spinner color="secondary" />
                        ) : (
                            <span className="bodyMdMedium">{formatValue ? formatValue(value) : value}</span>
                        )}
                    </div>
                </>
            )}

            {(maxValue && value < maxValue) || maxValue === undefined || value === 0 ? (
                <Button
                    className={clsx(
                        'md:hover:bg-highBlue-2 active:bg-highBlue-2 group flex h-10 border border-none p-3',
                        isExpanded ? 'rounded-r-round' : 'rounded-round'
                    )}
                    onClick={onClickHandler(onIncrement)}
                    disabled={isDisabled}
                >
                    {isLoading && !isExpanded ? <Spinner color="secondary" /> : <PlusIcon />}
                </Button>
            ) : (
                <Button
                    className={clsx(
                        'group flex h-10 border border-none p-3',
                        isExpanded ? 'rounded-r-round' : 'rounded-round'
                    )}
                    disabled={true}
                >
                    <div className="mdIcon" />
                </Button>
            )}
        </div>
    );
};
