import type { StatusType } from '@datadog/browser-logs';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import type { AxiosError } from 'axios';
import axios from 'axios';
import {
    DATADOG_CLIENT_TOKEN,
    DATADOG_LOGGER_ENV,
    DATADOG_RUM_APPLICATION_ID,
    DATADOG_RUM_CLIENT_TOKEN,
    ENABLE_CONSOLE_LOGGING,
    ENABLE_DD_SESSION_REPLAY,
    ENABLE_NATIVE_APP_LOG_FORWARDING,
    LOGGER_LEVEL,
    SEASON_API_BASE_URL,
    SEASON_APP_VERSION,
} from 'utils/constants/env';
import { NativeAppOutboundMessageTypes } from './native-app-bridge/messageTypes';
import { isRunningInsideSeasonNativeApp, postMessageToNativeApp } from './native-app-bridge/utils';

const ENABLE_DD_LOGGING = Boolean(DATADOG_CLIENT_TOKEN && DATADOG_LOGGER_ENV);
const ENABLE_DD_RUM = Boolean(DATADOG_RUM_APPLICATION_ID && DATADOG_RUM_CLIENT_TOKEN && DATADOG_LOGGER_ENV);

/**
 * A wrapper class to include a unique datadog fingerprint
 * that will be used to group errors.
 * If a fingerprint is not passed to the constructor, the error message will be used instead
 */
interface DatadogError extends Error {
    dd_fingerprint: string;
}

const messagesToIgnore = {
    /* eslint-disable lingui/no-unlocalized-strings */
    includes: [
        'AxiosError',
        'Request failed with status code 401',
        'Request failed with status code 403',
        '[LaunchDarkly]',
        'Failed to load Stripe.js',
        'Bad HTTP status: 0',
        'Script error',
        'network error (Error)',
        'app.launchdarkly.com',
        'api.perfalytics.com',
        'events.launchdarkly.com',
        'Cannot load google oauth script',
        'algolia.net',
        'algolianet.com',
        'ChunkLoadError',
        'Network Error',
    ],
    startsWith: ['AxiosError:'],
    /* eslint-enable lingui/no-unlocalized-strings */
};

if (ENABLE_DD_LOGGING) {
    datadogLogs.init({
        clientToken: DATADOG_CLIENT_TOKEN,
        service: 'season-app',
        env: DATADOG_LOGGER_ENV,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        version: SEASON_APP_VERSION,
        beforeSend: (event) => {
            // discard a LogsEvent of status 'error' or 'warn' if its message is in our messagesToIgnore map
            return !(
                (event.status === 'error' || event.status === 'warn') &&
                (messagesToIgnore.includes.some((it) => event.message.includes(it)) ||
                    messagesToIgnore.startsWith.some((it) => event.message.startsWith(it)))
            );
        },
    });
    datadogLogs.logger.setLevel((LOGGER_LEVEL === 'all' ? 'debug' : LOGGER_LEVEL) as StatusType);
}

if (ENABLE_DD_RUM) {
    datadogRum.init({
        applicationId: DATADOG_RUM_APPLICATION_ID as string,
        clientToken: DATADOG_RUM_CLIENT_TOKEN as string,
        site: 'datadoghq.com',
        service: 'season-app',
        env: DATADOG_LOGGER_ENV,
        version: SEASON_APP_VERSION,
        sessionSampleRate: 100,
        trackUserInteractions: true,
        allowedTracingUrls: [SEASON_API_BASE_URL],
        sessionReplaySampleRate: ENABLE_DD_SESSION_REPLAY ? 100 : 0,
        defaultPrivacyLevel: 'mask', // will mask all text, input, images, etc.
        beforeSend: (event) => {
            // discard a RumEvent of type 'error' if its message is in our messagesToIgnore map
            return !(
                event.type === 'error' &&
                (messagesToIgnore.includes.some((it) => event.error.message.includes(it)) ||
                    messagesToIgnore.startsWith.some((it) => event.error.message.startsWith(it)))
            );
        },
    });

    ENABLE_DD_SESSION_REPLAY && datadogRum.startSessionReplayRecording();
}

const globalContext: Map<string, unknown> = new Map();

const log = (level: 'info' | 'debug' | 'warn' | 'error', msg: string, context?: object, error?: Error) => {
    if (ENABLE_CONSOLE_LOGGING) {
        // eslint-disable-next-line no-console
        console[level](msg, { ...Object.fromEntries(globalContext), ...context }, error);
    }

    /*
     * for debugging purposes, we forward all logs to the native app if we are currently
     * running as a webview within the app
     */
    if (isRunningInsideSeasonNativeApp && ENABLE_NATIVE_APP_LOG_FORWARDING) {
        try {
            postMessageToNativeApp(NativeAppOutboundMessageTypes.LOG_EVENT, {
                data: { level, msg, inner: { ...Object.fromEntries(globalContext), ...context }, error },
            });
        } catch {
            //try to log a minimal message without context
            postMessageToNativeApp(NativeAppOutboundMessageTypes.LOG_EVENT, {
                data: { level, msg, error },
            });
        }
    }

    if (context) {
        // if we have a context and any of the members of the context object are an AxiosError, only use the
        // message from the AxiosError object. This only works for top level AxiosError context properties.
        const contextKeys = Object.keys(context);
        contextKeys.forEach((contextKey) => {
            const key = contextKey as keyof typeof context;
            const isAxiosError = axios.isAxiosError(context[key]);
            context[key] = isAxiosError
                ? ({
                      message: (context[key] as AxiosError).message,
                      status: (context[key] as AxiosError).response?.status,
                  } as never)
                : context[key];
        });
    }

    datadogLogs.logger[level](msg, context);
    if (level === 'error') {
        const rumError = error ?? new Error(msg);
        (rumError as DatadogError).dd_fingerprint = rumError.message;
        datadogRum.addError(rumError, context);
    }
};

interface LogHelper {
    debug: (msg: string, context?: object) => void;
    info: (msg: string, context?: object) => void;
    warn: (msg: string, context?: object) => void;
    // error messages will also emit a RUM error
    error: (msg: string, context?: object, error?: Error) => void;
    addGlobalContext: (key: string, value: unknown) => void;
}

const logger: LogHelper = {
    debug: (msg: string, context?: object) => log('debug', msg, context),
    info: (msg: string, context?: object) => log('info', msg, context),
    warn: (msg: string, context?: object) => log('warn', msg, context),
    error: (msg: string, context?: object, error?: Error) => log('error', msg, context, error),

    addGlobalContext: (key: string, value: unknown) => {
        if (ENABLE_DD_LOGGING) {
            datadogLogs.setGlobalContextProperty(key, value);
        }
        if (ENABLE_DD_RUM) {
            datadogRum.setGlobalContextProperty(key, value);
        }
        globalContext.set(key, value);
    },
};
export default logger;
