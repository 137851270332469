import clsx from 'clsx';
import Button from 'galley/Button';
import { ChevronDownStrokeIcon } from 'galley/Icons';
import type { Props as CheckboxSelectorProps } from 'galley/Input/Checkbox/CheckboxSelectorGroup/CheckboxSelectorGroup';
import CheckboxSelectorGroup from 'galley/Input/Checkbox/CheckboxSelectorGroup/CheckboxSelectorGroup';
import { useEffect, useRef, useState } from 'react';

export type Props = {
    name: string;
    isInvalid?: boolean;
    className?: string;
} & CheckboxSelectorProps;

const MultiselectDropdown = ({
    name,
    isInvalid = false,
    groupLabel,
    options,
    selectedValues,
    className,
    onValueUpdate,
}: Props): JSX.Element => {
    const menu = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const closeOpenMenus = ({ target }: MouseEvent) => {
        if (menu.current && isOpen && !menu.current.contains(target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeOpenMenus);
    });
    return (
        <div className={clsx('relative flex flex-col', className)} ref={menu}>
            <label className="bodyMdLight mb-1" htmlFor={groupLabel}>
                {groupLabel}
            </label>
            <div className="relative">
                <Button
                    id={groupLabel}
                    onClick={() => setIsOpen((prevState) => !prevState)}
                    className={clsx(
                        'input-field select group relative flex !h-[46px] w-full overflow-hidden rounded bg-white p-2 text-left',
                        isInvalid ? '!border-alert-red2' : '!border-standard-barley'
                    )}
                >
                    <p className="text-ellipses h-5 w-full overflow-hidden text-ellipsis break-words">
                        {options
                            .filter((opt) => selectedValues.includes(opt.value))
                            .map((item) => item.label)
                            .join(', ')}
                    </p>
                    <ChevronDownStrokeIcon
                        className={clsx('smIcon absolute right-2 top-1/3 w-3/12', isOpen && 'rotate-180')}
                    />
                </Button>
                <div
                    className={clsx(
                        'shadow-a-1 absolute top-[46px] z-50 max-h-80 w-full overflow-scroll bg-white p-3',
                        isOpen ? 'visible' : 'hidden'
                    )}
                >
                    <CheckboxSelectorGroup
                        name={name}
                        options={options}
                        selectedValues={selectedValues}
                        onValueUpdate={onValueUpdate}
                        // eslint-disable-next-line lingui/no-unlocalized-strings
                        classNames={{ label: 'hover:bg-lowBlue-1 !text-base', checked: 'bg-lowBlue-1' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default MultiselectDropdown;
