/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum CuisineDTO {
    AFRICAN = 'AFRICAN',
    AMERICAN = 'AMERICAN',
    ASIAN = 'ASIAN',
    CARIBBEAN = 'CARIBBEAN',
    CHINESE = 'CHINESE',
    FILIPINO = 'FILIPINO',
    FRENCH = 'FRENCH',
    GERMAN = 'GERMAN',
    GREEK = 'GREEK',
    INDIAN = 'INDIAN',
    ITALIAN = 'ITALIAN',
    LATIN_AMERICAN = 'LATIN_AMERICAN',
    MIDDLE_EASTERN = 'MIDDLE_EASTERN',
    NORTH_AFRICAN = 'NORTH_AFRICAN',
    SCANDINAVIAN = 'SCANDINAVIAN',
    TAIWANESE = 'TAIWANESE'
};

