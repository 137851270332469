import { t, Trans } from '@lingui/macro';
import { Button } from 'galley';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { alertError } from 'store/alerts/actions';
import { loginRequestSuccess, signOut } from 'store/user/actions';
import { selectUserData } from 'store/user/selectors';
import { authAPI } from 'utils';

const UserImpersonationMessage = (): JSX.Element => {
    const dispatch = useDispatch();
    const userData = useSelector(selectUserData);
    const { mutate: impersonationLogout } = useMutation(() => authAPI.impersonationLogout().then((r) => r.data), {
        onSuccess: (res) => {
            // dispatch login success with the token response (switch back to admin session)
            dispatch(loginRequestSuccess(res));
        },
        onError: () => {
            dispatch(alertError(t`Error switching back to Admin session; logging out.`));
            dispatch(signOut());
        },
    });

    return (
        <Button className="bg-alert-red w-full px-3 py-1" onClick={() => impersonationLogout()}>
            <Trans>
                <span className="bodySmLight text-white">You are a Season Admin acting on behalf of </span>
                <span className="bodySmMedium text-white">{`${userData.firstName} ${userData.lastName}. `}</span>
                <span className="bodySmLight text-white">Click here to exit.</span>
            </Trans>
        </Button>
    );
};

export default UserImpersonationMessage;
