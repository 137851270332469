/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum IngredientUnitDTO {
    CAN_10 = 'CAN_10',
    CAN_300 = 'CAN_300',
    CAN_303 = 'CAN_303',
    CAN_401 = 'CAN_401',
    CAN_404 = 'CAN_404',
    APPETIZER = 'APPETIZER',
    AS_ENTERED = 'AS_ENTERED',
    BAG = 'BAG',
    BOTTLE = 'BOTTLE',
    BOWL = 'BOWL',
    BOX = 'BOX',
    CAN = 'CAN',
    CAPLET = 'CAPLET',
    CAPSULE = 'CAPSULE',
    CONTAINER = 'CONTAINER',
    CUBE = 'CUBE',
    CUBIC_INCH = 'CUBIC_INCH',
    CUP = 'CUP',
    DROP = 'DROP',
    DRY_SERVING = 'DRY_SERVING',
    EACH = 'EACH',
    ENTREE = 'ENTREE',
    EXTRA_LARGE = 'EXTRA_LARGE',
    FAMILY = 'FAMILY',
    FLUID_OUNCE = 'FLUID_OUNCE',
    GALLON = 'GALLON',
    GRAM = 'GRAM',
    INDIVIDUAL = 'INDIVIDUAL',
    INDIVIDUAL_BAG = 'INDIVIDUAL_BAG',
    INDIVIDUAL_CARTON = 'INDIVIDUAL_CARTON',
    INDIVIDUAL_CUP = 'INDIVIDUAL_CUP',
    INDIVIDUAL_PACKAGE = 'INDIVIDUAL_PACKAGE',
    INDIVIDUAL_PACKET = 'INDIVIDUAL_PACKET',
    INTAKE = 'INTAKE',
    ITEM = 'ITEM',
    JAR = 'JAR',
    JUMBO = 'JUMBO',
    KIDS = 'KIDS',
    KILOGRAM = 'KILOGRAM',
    LARGE = 'LARGE',
    LITER = 'LITER',
    MEAL = 'MEAL',
    MEDIUM = 'MEDIUM',
    MICROGRAM = 'MICROGRAM',
    MILLIGRAM = 'MILLIGRAM',
    MILLILITER = 'MILLILITER',
    MINI = 'MINI',
    ORDER = 'ORDER',
    OUNCE = 'OUNCE',
    PACKAGE = 'PACKAGE',
    PACKET = 'PACKET',
    PAT = 'PAT',
    PIECE = 'PIECE',
    PINT = 'PINT',
    PORTION_CUP = 'PORTION_CUP',
    POUCH = 'POUCH',
    POUND = 'POUND',
    QUART = 'QUART',
    REGULAR = 'REGULAR',
    SCOOP = 'SCOOP',
    SECOND_SPRAY = 'SECOND_SPRAY',
    SERVING = 'SERVING',
    SHEET = 'SHEET',
    SIDE = 'SIDE',
    SLICE = 'SLICE',
    SMALL = 'SMALL',
    STICK = 'STICK',
    TABLESPOON = 'TABLESPOON',
    TABLET = 'TABLET',
    TEASPOON = 'TEASPOON',
    THICK_SLICE = 'THICK_SLICE',
    THIN_SLICE = 'THIN_SLICE',
    TOPPING = 'TOPPING',
    WHOLE = 'WHOLE'
};

