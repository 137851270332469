import { Trans } from '@lingui/macro';
import logo from 'assets/images/logo512.png';
import clsx from 'clsx';

export type Props = {
    testId?: string;
    blur?: boolean;
};

const LoadingScreen = ({ testId, blur }: Props): JSX.Element => {
    return (
        <div
            className={clsx(
                'fixed left-0 top-0 flex h-screen w-screen flex-col items-center justify-center',
                blur && 'backdrop-blur'
            )}
            role="progressbar"
            data-test-id={testId}
        >
            <div className="z-50">
                <img src={logo} alt="logo" className="h-24 w-24 animate-pulse" />
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    <Trans>Loading...</Trans>
                </span>
            </div>
        </div>
    );
};

export default LoadingScreen;
