import clsx from 'clsx';
import { SearchStrokeIcon } from 'galley';
import type { InputHTMLAttributes } from 'react';
import InputField from './components/InputField';

export interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    className?: string;
    onChange?: (input: string) => void;
    onClear?: () => void;
    onSearch?: () => void;
}

const SearchField = ({ className, onChange, onClear, value, onSearch, ...rest }: Props): JSX.Element => {
    const searchIconProps = {
        ...(onSearch && {
            'aria-label': 'search',
            role: 'button',
            onClick: onSearch,
        }),
        className: clsx(
            'fill-greyscale-inactive peer-focus:fill-greyscale-offBlack absolute top-0 h-full',
            value ? 'right-10' : 'right-4'
        ),
    };

    const icon = () => {
        return <>{onSearch && <SearchStrokeIcon {...searchIconProps} />}</>;
    };

    return (
        <InputField
            className={className}
            value={value}
            onChange={onChange}
            type="search"
            onClear={onClear}
            icon={icon()}
            {...rest}
        />
    );
};

export default SearchField;
