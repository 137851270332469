import { GATEWAY_APP_HOME, LOGIN_PATH, TWO_FACTOR_PATH } from 'utils';

const FORGOT_PASSWORD = 'reset-password/';

const PATHS = {
    ACCOUNT_CREATION: 'create-account/',
    FORGOT_PASSWORD: FORGOT_PASSWORD,
    HOME: GATEWAY_APP_HOME,
    LOGIN: LOGIN_PATH,
    RESET_PASSWORD: `${FORGOT_PASSWORD}:token`,
    INACTIVE_ACCOUNT: 'inactive-account/',
    SAML: 'saml/',
    TWO_FACTOR_AUTH: TWO_FACTOR_PATH,
};

const ROUTES = Object.entries(PATHS).reduce(
    (acc, [key, path]) => ({
        ...acc,
        [key]: GATEWAY_APP_HOME + path,
    }),
    {} as typeof PATHS
);

export { PATHS, ROUTES };
