import clsx from 'clsx';
import { Spinner } from 'galley';
import { Link } from 'react-router-dom';
import { trackClick } from 'utils/tracking/events';
import type { ButtonTargetProps } from './button-utils';
import { getLabelFromChildren, shouldRenderAsAnchor, shouldRenderAsLink } from './button-utils';

export type Props = React.PropsWithChildren<
    {
        dataTestId?: string;
        variant?: keyof typeof VARIANT_STYLES;
        loading?: boolean;
        sticky?: boolean;
        small?: boolean;
        asIcon?: boolean;
        disabled?: boolean;
    } & ButtonTargetProps
>;

export const STYLES = {
    plain: clsx('bodyLgMedium shadow-button box-border rounded-full pb-2 pt-1.5 md:h-10'),
    primaryColors: clsx(
        'bg-highBlue-1 border-highBlue-1 hover:border-highBlue-3 hover:bg-highBlue-3 active:bg-highBlue-2 active:border-highBlue-2 focus:border-highBlue-3 border-2 text-white'
    ),
    secondaryColors: clsx(
        'text-highBlue-3 bg-mediumBlue-1 border-mediumBlue-1 hover:bg-mediumBlue-2 hover:border-mediumBlue-2 active:bg-mediumBlue-3 focus:border-highBlue-2 border-2'
    ),
    label: clsx('flex items-center justify-center px-4 md:w-fit'),
    stroke: clsx(
        'border-highBlue-1 text-highBlue-1 hover:bg-lowBlue-1 active:border-highBlue-2 active:text-highBlue-2 focus:border-highBlue-3 focus:text-highBlue-3 disabled:!border-greyscale-inactive border-2 disabled:!bg-transparent'
    ),
    sticky: clsx(
        'fixed bottom-0 left-0 mx-auto w-full rounded-none pb-[19px] pt-[17px] md:sticky md:w-max md:rounded-full md:pb-2 md:pt-1.5'
    ),
    small: clsx('bodyMdMedium py-1 md:h-[30px]'),
    disabled: clsx('disabled:bg-greyscale-20 disabled:text-greyscale-inactive disabled:border-greyscale-20'),
};

export const VARIANT_STYLES = {
    primary: STYLES.primaryColors,
    secondary: STYLES.secondaryColors,
    outline: STYLES.stroke,
};

const Button = ({ dataTestId, variant, loading, sticky, small, asIcon, ...props }: Props): JSX.Element => {
    const className = clsx(
        'appearance-none',
        !asIcon && variant && [STYLES.plain, STYLES.label],
        variant && VARIANT_STYLES[variant],
        sticky && STYLES.sticky,
        small && STYLES.small,
        variant && props.disabled && STYLES.disabled,
        props.className
    );

    const label = props['aria-label'] ?? getLabelFromChildren(props.children);
    const trackLink = (link: string) => {
        trackClick({ label, link });
    };

    if (shouldRenderAsAnchor(props)) {
        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-has-content, jsx-a11y/no-static-element-interactions
            <a
                data-test-id={dataTestId}
                {...props}
                className={className}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                    props?.onClick && props.onClick(e);
                    trackLink(props.href as string);
                }}
            />
        );
    }

    if (shouldRenderAsLink(props)) {
        return (
            <Link
                data-test-id={dataTestId}
                {...props}
                className={className}
                onClick={() => trackLink(props.to as string)}
            />
        );
    }

    if (loading) {
        props.children = <Spinner color={variant ? 'secondary' : 'primary'} />;
    }

    return (
        <button
            {...props}
            type={props.type ?? 'button'}
            data-test-id={dataTestId}
            onClick={(e) => {
                e.stopPropagation();
                trackClick({ label: label ?? (e.target as HTMLElement).textContent });
                props.onClick && props.onClick(e);
            }}
            className={className}
        />
    );
};

export default Button;
