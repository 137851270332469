import type {
    BaseRecipeResponseDTO,
    ClientFavoriteRecipesResponseDTO,
    ClientRecipeSearchFiltersDTO,
    RecipeCostResponseDTO,
    RecipeShovelersResponseDTO,
} from 'API';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { selectClientData } from 'store/user/selectors';
import { clientAPI, logger, recipeAPI } from 'utils';

enum TIME_IN_MS {
    THIRTY_SECONDS = 1000 * 30,
    ONE_MIN = THIRTY_SECONDS * 2,
    FIVE_MIN = ONE_MIN * 5,
}

export const useGetRecipes = (
    recipeIds: string[],
    options?: Omit<UseQueryOptions<BaseRecipeResponseDTO[]>, 'queryFn' | 'queryKey'>
) =>
    useQuery(
        ['getRecipes', recipeIds],
        async () => {
            try {
                const { data } = await recipeAPI.getRecipes(recipeIds);
                return data.recipes;
            } catch (error) {
                if (error) {
                    logger.error('error when calling getRecipes API', error ? (error as object) : {});
                }
                return [];
            }
        },
        options
    );

export const useGetActiveRecipeShovelers = (
    clientId: string,
    shovelerStrategyNames?: string[],
    excludeCostData?: boolean,
    options?:
        | Omit<
              UseQueryOptions<
                  RecipeShovelersResponseDTO | undefined,
                  unknown,
                  RecipeShovelersResponseDTO | undefined,
                  string[]
              >,
              'queryKey' | 'queryFn'
          >
        | undefined
) =>
    useQuery(
        ['shovelers', clientId, ...(shovelerStrategyNames ?? []), JSON.stringify(useSelector(selectClientData))],
        async () => {
            const { data } = await recipeAPI.getActiveRecipeShovelers(
                clientId,
                excludeCostData ?? false,
                shovelerStrategyNames
            );
            if (data) {
                return data;
            }
        },
        {
            staleTime: TIME_IN_MS.FIVE_MIN,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            ...options,
        }
    );

export const useGetRecipeSearchFilters = (
    clientId: string,
    options?: Omit<UseQueryOptions<ClientRecipeSearchFiltersDTO>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        ['getRecipeSearchFilters', clientId],
        () => clientAPI.getRecipeSearchFilters(clientId).then((r) => r.data),
        options
    );

export const useGetFavoriteRecipes = (
    clientId: string,
    options?: Omit<UseQueryOptions<ClientFavoriteRecipesResponseDTO>, 'queryFn' | 'queryKey'>
) => useQuery(['favoriteRecipes', clientId], () => clientAPI.getFavoriteRecipes(clientId).then((r) => r.data), options);

export const useGetRecipesCost = (
    clientId: string,
    recipeIds: string[],
    options?: Omit<
        UseQueryOptions<
            Map<string, number | undefined> | undefined,
            unknown,
            Map<string, number | undefined> | undefined,
            string[]
        >,
        'queryKey' | 'queryFn'
    >
) =>
    useQuery(
        ['recipeCosts', clientId, ...recipeIds, JSON.stringify(useSelector(selectClientData))],
        async () => {
            if (!recipeIds || recipeIds.length === 0) return;

            const { data } = await recipeAPI.getRecipesCostForClient({ clientId, recipeIds });

            if (data) {
                return data.recipes.reduce(
                    (acc: Map<string, number | undefined>, val: RecipeCostResponseDTO) =>
                        acc.set(val.recipeId, val.estimatedCost.useCostCentsPerServing),
                    new Map<string, number | undefined>()
                );
            }
        },
        {
            staleTime: TIME_IN_MS.FIVE_MIN,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            ...options,
        }
    );
