/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateRecipeTemplateRequestDTO } from '../models';
// @ts-ignore
import { RecipeTemplateResponseDTO } from '../models';
// @ts-ignore
import { RecipeTemplatesResponseDTO } from '../models';
/**
 * RecipeTemplateApi - axios parameter creator
 * @export
 */
export const RecipeTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate a RecipeTemplate to enable recipes to be generated with this template
         * @param {string} id The RecipeTemplate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateRecipeTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activateRecipeTemplate', 'id', id)
            const localVarPath = `/recipe_templates/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new RecipeTemplate
         * @param {CreateRecipeTemplateRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipeTemplate: async (request: CreateRecipeTemplateRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createRecipeTemplate', 'request', request)
            const localVarPath = `/recipe_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate a RecipeTemplate and deprecate all recipes that have been created using it
         * @param {string} id The RecipeTemplate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateRecipeTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateRecipeTemplate', 'id', id)
            const localVarPath = `/recipe_templates/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get buildable recipe templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildableRecipeTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recipe_templates/buildable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get RecipeTemplate
         * @param {string} id The RecipeTemplate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecipeTemplate', 'id', id)
            const localVarPath = `/recipe_templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get RecipeTemplates
         * @param {boolean} [isBuildable] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeTemplates: async (isBuildable?: boolean, isActive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recipe_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isBuildable !== undefined) {
                localVarQueryParameter['isBuildable'] = isBuildable;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecipeTemplateApi - functional programming interface
 * @export
 */
export const RecipeTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecipeTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate a RecipeTemplate to enable recipes to be generated with this template
         * @param {string} id The RecipeTemplate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateRecipeTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateRecipeTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new RecipeTemplate
         * @param {CreateRecipeTemplateRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecipeTemplate(request: CreateRecipeTemplateRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeTemplateResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecipeTemplate(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate a RecipeTemplate and deprecate all recipes that have been created using it
         * @param {string} id The RecipeTemplate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateRecipeTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateRecipeTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get buildable recipe templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuildableRecipeTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeTemplatesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuildableRecipeTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get RecipeTemplate
         * @param {string} id The RecipeTemplate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeTemplateResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get RecipeTemplates
         * @param {boolean} [isBuildable] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeTemplates(isBuildable?: boolean, isActive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeTemplatesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeTemplates(isBuildable, isActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecipeTemplateApi - factory interface
 * @export
 */
export const RecipeTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecipeTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate a RecipeTemplate to enable recipes to be generated with this template
         * @param {string} id The RecipeTemplate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateRecipeTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.activateRecipeTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new RecipeTemplate
         * @param {CreateRecipeTemplateRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipeTemplate(request: CreateRecipeTemplateRequestDTO, options?: any): AxiosPromise<RecipeTemplateResponseDTO> {
            return localVarFp.createRecipeTemplate(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate a RecipeTemplate and deprecate all recipes that have been created using it
         * @param {string} id The RecipeTemplate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateRecipeTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateRecipeTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get buildable recipe templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildableRecipeTemplates(options?: any): AxiosPromise<RecipeTemplatesResponseDTO> {
            return localVarFp.getBuildableRecipeTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get RecipeTemplate
         * @param {string} id The RecipeTemplate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeTemplate(id: string, options?: any): AxiosPromise<RecipeTemplateResponseDTO> {
            return localVarFp.getRecipeTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get RecipeTemplates
         * @param {boolean} [isBuildable] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeTemplates(isBuildable?: boolean, isActive?: boolean, options?: any): AxiosPromise<RecipeTemplatesResponseDTO> {
            return localVarFp.getRecipeTemplates(isBuildable, isActive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecipeTemplateApi - object-oriented interface
 * @export
 * @class RecipeTemplateApi
 * @extends {BaseAPI}
 */
export class RecipeTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Activate a RecipeTemplate to enable recipes to be generated with this template
     * @param {string} id The RecipeTemplate ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeTemplateApi
     */
    public activateRecipeTemplate(id: string, options?: AxiosRequestConfig) {
        return RecipeTemplateApiFp(this.configuration).activateRecipeTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new RecipeTemplate
     * @param {CreateRecipeTemplateRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeTemplateApi
     */
    public createRecipeTemplate(request: CreateRecipeTemplateRequestDTO, options?: AxiosRequestConfig) {
        return RecipeTemplateApiFp(this.configuration).createRecipeTemplate(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate a RecipeTemplate and deprecate all recipes that have been created using it
     * @param {string} id The RecipeTemplate ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeTemplateApi
     */
    public deactivateRecipeTemplate(id: string, options?: AxiosRequestConfig) {
        return RecipeTemplateApiFp(this.configuration).deactivateRecipeTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get buildable recipe templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeTemplateApi
     */
    public getBuildableRecipeTemplates(options?: AxiosRequestConfig) {
        return RecipeTemplateApiFp(this.configuration).getBuildableRecipeTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get RecipeTemplate
     * @param {string} id The RecipeTemplate ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeTemplateApi
     */
    public getRecipeTemplate(id: string, options?: AxiosRequestConfig) {
        return RecipeTemplateApiFp(this.configuration).getRecipeTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get RecipeTemplates
     * @param {boolean} [isBuildable] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeTemplateApi
     */
    public getRecipeTemplates(isBuildable?: boolean, isActive?: boolean, options?: AxiosRequestConfig) {
        return RecipeTemplateApiFp(this.configuration).getRecipeTemplates(isBuildable, isActive, options).then((request) => request(this.axios, this.basePath));
    }
}
