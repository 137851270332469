import {
    AccountApi,
    AdminApi,
    AppointmentApi,
    AuthApi,
    AutocompleteApi,
    BenefitApi,
    ChartNoteApi,
    ClientApi,
    ClientIntakeApi,
    CmsApi,
    Configuration,
    EducationProgramApi,
    EducationProgramProgressApi,
    FoodOrderApi,
    GroceryListApi,
    IngredientApi,
    InsuranceCarrierApi,
    InsurancePolicyApi,
    MealApi,
    MessagingApi,
    PracticeApi,
    PreparedMealApi,
    ProviderApi,
    RecipeApi,
    RecipeTemplateApi,
    RecipeTemplateComponentApi,
    ReferralApi,
    SsoApi,
    SubscriptionPackageApi,
    WalletApi,
} from 'API';
import { SEASON_API_BASE_URL, SEASON_EXTERNAL_API_BASE_URL } from '../constants/env';

export const accountAPI = new AccountApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const adminAPI = new AdminApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const appointmentAPI = new AppointmentApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const authAPI = new AuthApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const authAPIExternal = new AuthApi(new Configuration({ basePath: SEASON_EXTERNAL_API_BASE_URL }));
export const autocompleteApi = new AutocompleteApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const benefitApi = new BenefitApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const chartNoteAPI = new ChartNoteApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const clientAPI = new ClientApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const clientIntakeAPI = new ClientIntakeApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const cmsAPI = new CmsApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const educationProgramProgressAPI = new EducationProgramProgressApi(
    new Configuration({ basePath: SEASON_API_BASE_URL })
);
export const educationProgramAPI = new EducationProgramApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const foodOrderApi = new FoodOrderApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const groceryListAPI = new GroceryListApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const ingredientAPI = new IngredientApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const insuranceCarrierApi = new InsuranceCarrierApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const insurancePolicyApi = new InsurancePolicyApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const mealsAPI = new MealApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const messagingAPI = new MessagingApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const practiceAPI = new PracticeApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const preparedMealApi = new PreparedMealApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const providerAPI = new ProviderApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const recipeAPI = new RecipeApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const recipeTemplateAPI = new RecipeTemplateApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const recipeTemplateComponentAPI = new RecipeTemplateComponentApi(
    new Configuration({ basePath: SEASON_API_BASE_URL })
);
export const referralAPI = new ReferralApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const ssoAPI = new SsoApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const subscriptionPackagesApi = new SubscriptionPackageApi(new Configuration({ basePath: SEASON_API_BASE_URL }));
export const walletsApi = new WalletApi(new Configuration({ basePath: SEASON_API_BASE_URL }));

export * from './queries/account';
export * from './queries/autocomplete';
export * from './queries/billing';
export * from './queries/client';
export * from './queries/insuranceCarrier';
export * from './queries/insurancePolicy';
export * from './queries/intake';
export * from './queries/provider';
