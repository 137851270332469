/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum IngredientDietModuleTagDTO {
    ADDED_SUGAR = 'ADDED_SUGAR',
    DAIRY_OR_ALTERNATIVE = 'DAIRY_OR_ALTERNATIVE',
    FRESH_POTATO = 'FRESH_POTATO',
    FRUIT_JUICE_OR_DRIED_FRUIT = 'FRUIT_JUICE_OR_DRIED_FRUIT',
    GLUTEN_FREE = 'GLUTEN_FREE',
    GRAIN = 'GRAIN',
    HIGH_SATURATED_FAT = 'HIGH_SATURATED_FAT',
    LOW_SODIUM_PROCESSED_MEAT = 'LOW_SODIUM_PROCESSED_MEAT',
    LOW_FAT_DAIRY_OR_ALTERNATIVE = 'LOW_FAT_DAIRY_OR_ALTERNATIVE',
    NOT_KIDNEY_FRIENDLY = 'NOT_KIDNEY_FRIENDLY',
    NOT_PREGNANCY_FRIENDLY = 'NOT_PREGNANCY_FRIENDLY',
    OIL = 'OIL',
    OLIVE_OIL = 'OLIVE_OIL',
    PLANT_BASED_PROTEIN = 'PLANT_BASED_PROTEIN',
    POTATO = 'POTATO',
    PROCESSED_MEAT = 'PROCESSED_MEAT',
    PROTEIN = 'PROTEIN',
    RED_MEAT = 'RED_MEAT',
    WHOLE_GRAIN = 'WHOLE_GRAIN'
};

