export enum NativeAppOutboundMessageTypes {
    LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE',
    LOG_EVENT = 'LOG_EVENT',
    LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED',
    UNSUPPORTED_USER_TYPE = 'UNSUPPORTED_USER_TYPE',
    OPEN_PRINT_DIALOG = 'OPEN_PRINT_DIALOG',
    LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED',
}

export enum NativeAppInboundMessageTypes {
    RECEIVED_GOOGLE_TOKEN = 'RECEIVED_GOOGLE_TOKEN',
}
