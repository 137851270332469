import clsx from 'clsx';
import Alert from 'galley/Alert';
import { connect, useDispatch } from 'react-redux';
import type { ApplicationState } from 'store';
import { closeAlert } from 'store/alerts/actions';
import type { Alert as AlertType } from 'store/alerts/types';

interface GlobalAlertSystemProps {
    openAlert: boolean;
    alert: AlertType;
}

const GlobalAlertSystem = ({ openAlert, alert }: GlobalAlertSystemProps): JSX.Element => {
    const { alertType, alertMessage } = alert;
    const dispatch = useDispatch();
    return (
        <div
            className={clsx(
                'fixed bottom-20 left-1/2 z-[400] w-max max-w-full -translate-x-1/2 md:bottom-3',
                !openAlert && 'hidden'
            )}
        >
            <Alert variant={alertType} message={alertMessage} onCloseClick={() => dispatch(closeAlert())} />
        </div>
    );
};

const mapDispatchToProps = {
    closeAlertAction: closeAlert,
};

const mapStateToProps = ({ alert }: ApplicationState): GlobalAlertSystemProps => alert;

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAlertSystem);
