/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppointmentAvailabilityDatesResponseDTO } from '../models';
// @ts-ignore
import { AppointmentAvailabilityTimesResponseDTO } from '../models';
// @ts-ignore
import { AppointmentDurationDTO } from '../models';
// @ts-ignore
import { AppointmentRecordingPartResponseDTO } from '../models';
// @ts-ignore
import { AppointmentResponseDTO } from '../models';
// @ts-ignore
import { AppointmentSchedulingDetailsDTO } from '../models';
// @ts-ignore
import { AppointmentsResponseDTO } from '../models';
// @ts-ignore
import { ProviderAppointmentResponseDTO } from '../models';
// @ts-ignore
import { ProviderAppointmentSchedulingDetailsDTO } from '../models';
// @ts-ignore
import { ProviderAppointmentsResponseDTO } from '../models';
// @ts-ignore
import { RescheduleAppointmentRequestDTO } from '../models';
// @ts-ignore
import { ScheduleAppointmentAsProviderRequestDTO } from '../models';
// @ts-ignore
import { ScheduleAppointmentRequestDTO } from '../models';
// @ts-ignore
import { SearchAppointmentsResponseDTO } from '../models';
// @ts-ignore
import { TelehealthMeetingAndAccessDetailsResponseDTO } from '../models';
/**
 * AppointmentApi - axios parameter creator
 * @export
 */
export const AppointmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Appointment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelAppointment', 'id', id)
            const localVarPath = `/appointments/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Appointment as Provider
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointmentAsProvider: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelAppointmentAsProvider', 'id', id)
            const localVarPath = `/appointments/{id}/cancel-as-provider`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Charge appointment attendance policy violation for non-COVERED appointments
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargeAppointmentAttendancePolicyViolation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chargeAppointmentAttendancePolicyViolation', 'id', id)
            const localVarPath = `/appointments/{id}/charge-attendance-policy-violation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete an appointment recording
         * @param {string} id The id of the appointment to stop recording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeAppointmentRecording: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('completeAppointmentRecording', 'id', id)
            const localVarPath = `/appointments/{id}/complete-recording`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Excuse Appointment Attendance Policy Violation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excuseAppointmentAttendancePolicyViolation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('excuseAppointmentAttendancePolicyViolation', 'id', id)
            const localVarPath = `/appointments/{id}/excuse-attendance-policy-violation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get appointment
         * @param {string} id The appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAppointment', 'id', id)
            const localVarPath = `/appointments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get appointment durations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentDurations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/appointments/durations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information relevant to scheduling appointment for client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentSchedulingDetailsForClient: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getAppointmentSchedulingDetailsForClient', 'clientId', clientId)
            const localVarPath = `/appointments/scheduling-details-for-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointments
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointments: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getAppointments', 'clientId', clientId)
            const localVarPath = `/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dates with available appointment times for the specified month and appointment type for a given client
         * @param {string} month A year-month
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} clientId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentDatesForClient: async (month: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', clientId: string, providerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getAvailableAppointmentDatesForClient', 'month', month)
            // verify required parameter 'appointmentType' is not null or undefined
            assertParamExists('getAvailableAppointmentDatesForClient', 'appointmentType', appointmentType)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getAvailableAppointmentDatesForClient', 'clientId', clientId)
            const localVarPath = `/appointments/available-dates-for-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (appointmentType !== undefined) {
                localVarQueryParameter['appointmentType'] = appointmentType;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dates with available appointment times for the specified month for a client intake that does not yet have an initial consultation 
         * @param {string} month A year-month
         * @param {string} clientIntakeId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentDatesForClientIntake: async (month: string, clientIntakeId: string, providerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getAvailableAppointmentDatesForClientIntake', 'month', month)
            // verify required parameter 'clientIntakeId' is not null or undefined
            assertParamExists('getAvailableAppointmentDatesForClientIntake', 'clientIntakeId', clientIntakeId)
            const localVarPath = `/appointments/available-dates-for-client-intake`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (clientIntakeId !== undefined) {
                localVarQueryParameter['clientIntakeId'] = clientIntakeId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dates with available appointment times for the specified month and appointment type for a given provider
         * @param {string} month A year-month
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentDatesForProvider: async (month: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', providerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getAvailableAppointmentDatesForProvider', 'month', month)
            // verify required parameter 'appointmentType' is not null or undefined
            assertParamExists('getAvailableAppointmentDatesForProvider', 'appointmentType', appointmentType)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getAvailableAppointmentDatesForProvider', 'providerId', providerId)
            const localVarPath = `/appointments/available-dates-for-provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (appointmentType !== undefined) {
                localVarQueryParameter['appointmentType'] = appointmentType;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available appointment times for the specified date and appointment type for a given client
         * @param {string} date 
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} clientId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentTimesForClient: async (date: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', clientId: string, providerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAvailableAppointmentTimesForClient', 'date', date)
            // verify required parameter 'appointmentType' is not null or undefined
            assertParamExists('getAvailableAppointmentTimesForClient', 'appointmentType', appointmentType)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getAvailableAppointmentTimesForClient', 'clientId', clientId)
            const localVarPath = `/appointments/available-times-for-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (appointmentType !== undefined) {
                localVarQueryParameter['appointmentType'] = appointmentType;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available appointment times for the specified date for a client intake that does not yet have an initial consultation 
         * @param {string} date 
         * @param {string} clientIntakeId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentTimesForClientIntake: async (date: string, clientIntakeId: string, providerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAvailableAppointmentTimesForClientIntake', 'date', date)
            // verify required parameter 'clientIntakeId' is not null or undefined
            assertParamExists('getAvailableAppointmentTimesForClientIntake', 'clientIntakeId', clientIntakeId)
            const localVarPath = `/appointments/available-times-for-client-intake`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (clientIntakeId !== undefined) {
                localVarQueryParameter['clientIntakeId'] = clientIntakeId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available appointment times for the specified date and appointment type for a given provider
         * @param {string} date 
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentTimesForProvider: async (date: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', providerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAvailableAppointmentTimesForProvider', 'date', date)
            // verify required parameter 'appointmentType' is not null or undefined
            assertParamExists('getAvailableAppointmentTimesForProvider', 'appointmentType', appointmentType)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getAvailableAppointmentTimesForProvider', 'providerId', providerId)
            const localVarPath = `/appointments/available-times-for-provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (appointmentType !== undefined) {
                localVarQueryParameter['appointmentType'] = appointmentType;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get appointment for provider
         * @param {string} id The appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAppointment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProviderAppointment', 'id', id)
            const localVarPath = `/appointments/{id}/provider`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information relevant to scheduling appointment for client of a provider
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAppointmentSchedulingDetailsForClient: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getProviderAppointmentSchedulingDetailsForClient', 'clientId', clientId)
            const localVarPath = `/appointments/provider-scheduling-details-for-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointments For Provider
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAppointments: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getProviderAppointments', 'clientId', clientId)
            const localVarPath = `/appointments/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the details required to join the telehealth meeting including any required access token for the client. When requesting this endpoint for an appointment that does not yet have a telehealth meeting created, this endpoint will create that meeting. If two simultaneous calls are made to this endpoint by different attendees of and appointment, a 409 conflict will be sent. If the client does not have access to this appointment then the caller will receive a 403 error. 
         * @param {string} id The Appointment ID for which the client wants to join the telehealth meeting.
         * @param {boolean} isRecordingConsentGranted If the patient consents to recording, and recording is permitted in their location and the configured telehealth meeting implementation for the appointment supports it, then the meeting will be recorded. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinAppointmentTelehealthMeetingAsClient: async (id: string, isRecordingConsentGranted: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('joinAppointmentTelehealthMeetingAsClient', 'id', id)
            // verify required parameter 'isRecordingConsentGranted' is not null or undefined
            assertParamExists('joinAppointmentTelehealthMeetingAsClient', 'isRecordingConsentGranted', isRecordingConsentGranted)
            const localVarPath = `/appointments/{id}/join-telehealth-meeting-as-client`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isRecordingConsentGranted !== undefined) {
                localVarQueryParameter['isRecordingConsentGranted'] = isRecordingConsentGranted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the details required to join the telehealth meeting including any required access token for the provider. When requesting this endpoint for an appointment that does not yet have a telehealth meeting created, this endpoint will create that meeting. If two simultaneous calls are made to this endpoint by different attendees of and appointment, a 409 conflict will be sent. If the provider does not have access to this appointment then the caller will receive a 403 error. 
         * @param {string} id The Appointment ID for which the client wants to join the telehealth meeting.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinAppointmentTelehealthMeetingAsProvider: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('joinAppointmentTelehealthMeetingAsProvider', 'id', id)
            const localVarPath = `/appointments/{id}/join-telehealth-meeting-as-provider`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark Appointment Attended
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAppointmentAttended: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markAppointmentAttended', 'id', id)
            const localVarPath = `/appointments/{id}/mark-attended`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark Appointment Missed
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAppointmentMissed: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markAppointmentMissed', 'id', id)
            const localVarPath = `/appointments/{id}/mark-missed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reschedule Appointment
         * @param {string} id 
         * @param {RescheduleAppointmentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescheduleAppointment: async (id: string, request: RescheduleAppointmentRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rescheduleAppointment', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('rescheduleAppointment', 'request', request)
            const localVarPath = `/appointments/{id}/reschedule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reschedule Appointment as Provider
         * @param {string} id 
         * @param {RescheduleAppointmentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescheduleAppointmentAsProvider: async (id: string, request: RescheduleAppointmentRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rescheduleAppointmentAsProvider', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('rescheduleAppointmentAsProvider', 'request', request)
            const localVarPath = `/appointments/{id}/reschedule-as-provider`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedule Appointment
         * @param {ScheduleAppointmentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAppointment: async (request: ScheduleAppointmentRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('scheduleAppointment', 'request', request)
            const localVarPath = `/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedule Appointment as Provider
         * @param {ScheduleAppointmentAsProviderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAppointmentAsProvider: async (request: ScheduleAppointmentAsProviderRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('scheduleAppointmentAsProvider', 'request', request)
            const localVarPath = `/appointments/schedule-as-provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search appointments
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {string} [clientIntakeId] Optionally filter to a certain client intake
         * @param {string} [providerId] Optionally filter to a certain provider
         * @param {Array<'SCHEDULED' | 'RESCHEDULED' | 'CANCELED' | 'ATTENDED' | 'MISSED'>} [appointmentStates] 
         * @param {Array<'ELIGIBLE_FOR_CHARGE' | 'EXCUSED' | 'CHARGED'>} [appointmentAttendancePolicyViolationStates] 
         * @param {Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>} [appointmentTypes] 
         * @param {Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>} [appointmentPaymentTypes] 
         * @param {string} [dateTimeMin] Optionally provide a minimum date time
         * @param {string} [dateTimeMax] Optionally provide a maximum date time
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {'DATE_TIME'} [sortBy] Sort results. Defaults to DATE_TIME.
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAppointments: async (clientId?: string, clientIntakeId?: string, providerId?: string, appointmentStates?: Array<'SCHEDULED' | 'RESCHEDULED' | 'CANCELED' | 'ATTENDED' | 'MISSED'>, appointmentAttendancePolicyViolationStates?: Array<'ELIGIBLE_FOR_CHARGE' | 'EXCUSED' | 'CHARGED'>, appointmentTypes?: Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>, appointmentPaymentTypes?: Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>, dateTimeMin?: string, dateTimeMax?: string, limit?: number, offset?: number, sortBy?: 'DATE_TIME', sortDir?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/appointments/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (clientIntakeId !== undefined) {
                localVarQueryParameter['clientIntakeId'] = clientIntakeId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (appointmentStates) {
                localVarQueryParameter['appointmentStates'] = appointmentStates;
            }

            if (appointmentAttendancePolicyViolationStates) {
                localVarQueryParameter['appointmentAttendancePolicyViolationStates'] = appointmentAttendancePolicyViolationStates;
            }

            if (appointmentTypes) {
                localVarQueryParameter['appointmentTypes'] = appointmentTypes;
            }

            if (appointmentPaymentTypes) {
                localVarQueryParameter['appointmentPaymentTypes'] = appointmentPaymentTypes;
            }

            if (dateTimeMin !== undefined) {
                localVarQueryParameter['dateTimeMin'] = (dateTimeMin as any instanceof Date) ?
                    (dateTimeMin as any).toISOString() :
                    dateTimeMin;
            }

            if (dateTimeMax !== undefined) {
                localVarQueryParameter['dateTimeMax'] = (dateTimeMax as any instanceof Date) ?
                    (dateTimeMax as any).toISOString() :
                    dateTimeMax;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets up storage for an in progress appointment recording part
         * @param {string} id The id of the appointment to start recording
         * @param {number} partNumber The sequential part number of the current upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupAppointmentRecordingPart: async (id: string, partNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setupAppointmentRecordingPart', 'id', id)
            // verify required parameter 'partNumber' is not null or undefined
            assertParamExists('setupAppointmentRecordingPart', 'partNumber', partNumber)
            const localVarPath = `/appointments/{id}/setup-recording-part`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (partNumber !== undefined) {
                localVarQueryParameter['partNumber'] = partNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentApi - functional programming interface
 * @export
 */
export const AppointmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Appointment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAppointment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel Appointment as Provider
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAppointmentAsProvider(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAppointmentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAppointmentAsProvider(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Charge appointment attendance policy violation for non-COVERED appointments
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chargeAppointmentAttendancePolicyViolation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chargeAppointmentAttendancePolicyViolation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete an appointment recording
         * @param {string} id The id of the appointment to stop recording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeAppointmentRecording(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeAppointmentRecording(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Excuse Appointment Attendance Policy Violation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excuseAppointmentAttendancePolicyViolation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excuseAppointmentAttendancePolicyViolation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get appointment
         * @param {string} id The appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get appointment durations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentDurations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentDurationDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentDurations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get information relevant to scheduling appointment for client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentSchedulingDetailsForClient(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentSchedulingDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentSchedulingDetailsForClient(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Appointments
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointments(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointments(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dates with available appointment times for the specified month and appointment type for a given client
         * @param {string} month A year-month
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} clientId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableAppointmentDatesForClient(month: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', clientId: string, providerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentAvailabilityDatesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableAppointmentDatesForClient(month, appointmentType, clientId, providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dates with available appointment times for the specified month for a client intake that does not yet have an initial consultation 
         * @param {string} month A year-month
         * @param {string} clientIntakeId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableAppointmentDatesForClientIntake(month: string, clientIntakeId: string, providerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentAvailabilityDatesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableAppointmentDatesForClientIntake(month, clientIntakeId, providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dates with available appointment times for the specified month and appointment type for a given provider
         * @param {string} month A year-month
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableAppointmentDatesForProvider(month: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', providerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentAvailabilityDatesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableAppointmentDatesForProvider(month, appointmentType, providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available appointment times for the specified date and appointment type for a given client
         * @param {string} date 
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} clientId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableAppointmentTimesForClient(date: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', clientId: string, providerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentAvailabilityTimesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableAppointmentTimesForClient(date, appointmentType, clientId, providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available appointment times for the specified date for a client intake that does not yet have an initial consultation 
         * @param {string} date 
         * @param {string} clientIntakeId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableAppointmentTimesForClientIntake(date: string, clientIntakeId: string, providerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentAvailabilityTimesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableAppointmentTimesForClientIntake(date, clientIntakeId, providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available appointment times for the specified date and appointment type for a given provider
         * @param {string} date 
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableAppointmentTimesForProvider(date: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', providerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentAvailabilityTimesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableAppointmentTimesForProvider(date, appointmentType, providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get appointment for provider
         * @param {string} id The appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAppointment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAppointmentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get information relevant to scheduling appointment for client of a provider
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAppointmentSchedulingDetailsForClient(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAppointmentSchedulingDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAppointmentSchedulingDetailsForClient(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Appointments For Provider
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAppointments(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAppointmentsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAppointments(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the details required to join the telehealth meeting including any required access token for the client. When requesting this endpoint for an appointment that does not yet have a telehealth meeting created, this endpoint will create that meeting. If two simultaneous calls are made to this endpoint by different attendees of and appointment, a 409 conflict will be sent. If the client does not have access to this appointment then the caller will receive a 403 error. 
         * @param {string} id The Appointment ID for which the client wants to join the telehealth meeting.
         * @param {boolean} isRecordingConsentGranted If the patient consents to recording, and recording is permitted in their location and the configured telehealth meeting implementation for the appointment supports it, then the meeting will be recorded. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinAppointmentTelehealthMeetingAsClient(id: string, isRecordingConsentGranted: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelehealthMeetingAndAccessDetailsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinAppointmentTelehealthMeetingAsClient(id, isRecordingConsentGranted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the details required to join the telehealth meeting including any required access token for the provider. When requesting this endpoint for an appointment that does not yet have a telehealth meeting created, this endpoint will create that meeting. If two simultaneous calls are made to this endpoint by different attendees of and appointment, a 409 conflict will be sent. If the provider does not have access to this appointment then the caller will receive a 403 error. 
         * @param {string} id The Appointment ID for which the client wants to join the telehealth meeting.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinAppointmentTelehealthMeetingAsProvider(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelehealthMeetingAndAccessDetailsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinAppointmentTelehealthMeetingAsProvider(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark Appointment Attended
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAppointmentAttended(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAppointmentAttended(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark Appointment Missed
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAppointmentMissed(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAppointmentMissed(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reschedule Appointment
         * @param {string} id 
         * @param {RescheduleAppointmentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rescheduleAppointment(id: string, request: RescheduleAppointmentRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rescheduleAppointment(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reschedule Appointment as Provider
         * @param {string} id 
         * @param {RescheduleAppointmentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rescheduleAppointmentAsProvider(id: string, request: RescheduleAppointmentRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAppointmentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rescheduleAppointmentAsProvider(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedule Appointment
         * @param {ScheduleAppointmentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleAppointment(request: ScheduleAppointmentRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleAppointment(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedule Appointment as Provider
         * @param {ScheduleAppointmentAsProviderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleAppointmentAsProvider(request: ScheduleAppointmentAsProviderRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAppointmentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleAppointmentAsProvider(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search appointments
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {string} [clientIntakeId] Optionally filter to a certain client intake
         * @param {string} [providerId] Optionally filter to a certain provider
         * @param {Array<'SCHEDULED' | 'RESCHEDULED' | 'CANCELED' | 'ATTENDED' | 'MISSED'>} [appointmentStates] 
         * @param {Array<'ELIGIBLE_FOR_CHARGE' | 'EXCUSED' | 'CHARGED'>} [appointmentAttendancePolicyViolationStates] 
         * @param {Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>} [appointmentTypes] 
         * @param {Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>} [appointmentPaymentTypes] 
         * @param {string} [dateTimeMin] Optionally provide a minimum date time
         * @param {string} [dateTimeMax] Optionally provide a maximum date time
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {'DATE_TIME'} [sortBy] Sort results. Defaults to DATE_TIME.
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAppointments(clientId?: string, clientIntakeId?: string, providerId?: string, appointmentStates?: Array<'SCHEDULED' | 'RESCHEDULED' | 'CANCELED' | 'ATTENDED' | 'MISSED'>, appointmentAttendancePolicyViolationStates?: Array<'ELIGIBLE_FOR_CHARGE' | 'EXCUSED' | 'CHARGED'>, appointmentTypes?: Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>, appointmentPaymentTypes?: Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>, dateTimeMin?: string, dateTimeMax?: string, limit?: number, offset?: number, sortBy?: 'DATE_TIME', sortDir?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAppointmentsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAppointments(clientId, clientIntakeId, providerId, appointmentStates, appointmentAttendancePolicyViolationStates, appointmentTypes, appointmentPaymentTypes, dateTimeMin, dateTimeMax, limit, offset, sortBy, sortDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets up storage for an in progress appointment recording part
         * @param {string} id The id of the appointment to start recording
         * @param {number} partNumber The sequential part number of the current upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setupAppointmentRecordingPart(id: string, partNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentRecordingPartResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setupAppointmentRecordingPart(id, partNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppointmentApi - factory interface
 * @export
 */
export const AppointmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Appointment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointment(id: string, options?: any): AxiosPromise<AppointmentResponseDTO> {
            return localVarFp.cancelAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Appointment as Provider
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointmentAsProvider(id: string, options?: any): AxiosPromise<ProviderAppointmentResponseDTO> {
            return localVarFp.cancelAppointmentAsProvider(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Charge appointment attendance policy violation for non-COVERED appointments
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargeAppointmentAttendancePolicyViolation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.chargeAppointmentAttendancePolicyViolation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete an appointment recording
         * @param {string} id The id of the appointment to stop recording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeAppointmentRecording(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.completeAppointmentRecording(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Excuse Appointment Attendance Policy Violation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excuseAppointmentAttendancePolicyViolation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.excuseAppointmentAttendancePolicyViolation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get appointment
         * @param {string} id The appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment(id: string, options?: any): AxiosPromise<AppointmentResponseDTO> {
            return localVarFp.getAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get appointment durations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentDurations(options?: any): AxiosPromise<Array<AppointmentDurationDTO>> {
            return localVarFp.getAppointmentDurations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information relevant to scheduling appointment for client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentSchedulingDetailsForClient(clientId: string, options?: any): AxiosPromise<AppointmentSchedulingDetailsDTO> {
            return localVarFp.getAppointmentSchedulingDetailsForClient(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointments
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointments(clientId: string, options?: any): AxiosPromise<AppointmentsResponseDTO> {
            return localVarFp.getAppointments(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dates with available appointment times for the specified month and appointment type for a given client
         * @param {string} month A year-month
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} clientId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentDatesForClient(month: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', clientId: string, providerId?: string, options?: any): AxiosPromise<AppointmentAvailabilityDatesResponseDTO> {
            return localVarFp.getAvailableAppointmentDatesForClient(month, appointmentType, clientId, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dates with available appointment times for the specified month for a client intake that does not yet have an initial consultation 
         * @param {string} month A year-month
         * @param {string} clientIntakeId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentDatesForClientIntake(month: string, clientIntakeId: string, providerId?: string, options?: any): AxiosPromise<AppointmentAvailabilityDatesResponseDTO> {
            return localVarFp.getAvailableAppointmentDatesForClientIntake(month, clientIntakeId, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dates with available appointment times for the specified month and appointment type for a given provider
         * @param {string} month A year-month
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentDatesForProvider(month: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', providerId: string, options?: any): AxiosPromise<AppointmentAvailabilityDatesResponseDTO> {
            return localVarFp.getAvailableAppointmentDatesForProvider(month, appointmentType, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available appointment times for the specified date and appointment type for a given client
         * @param {string} date 
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} clientId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentTimesForClient(date: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', clientId: string, providerId?: string, options?: any): AxiosPromise<AppointmentAvailabilityTimesResponseDTO> {
            return localVarFp.getAvailableAppointmentTimesForClient(date, appointmentType, clientId, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available appointment times for the specified date for a client intake that does not yet have an initial consultation 
         * @param {string} date 
         * @param {string} clientIntakeId 
         * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentTimesForClientIntake(date: string, clientIntakeId: string, providerId?: string, options?: any): AxiosPromise<AppointmentAvailabilityTimesResponseDTO> {
            return localVarFp.getAvailableAppointmentTimesForClientIntake(date, clientIntakeId, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available appointment times for the specified date and appointment type for a given provider
         * @param {string} date 
         * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableAppointmentTimesForProvider(date: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', providerId: string, options?: any): AxiosPromise<AppointmentAvailabilityTimesResponseDTO> {
            return localVarFp.getAvailableAppointmentTimesForProvider(date, appointmentType, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get appointment for provider
         * @param {string} id The appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAppointment(id: string, options?: any): AxiosPromise<ProviderAppointmentResponseDTO> {
            return localVarFp.getProviderAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information relevant to scheduling appointment for client of a provider
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAppointmentSchedulingDetailsForClient(clientId: string, options?: any): AxiosPromise<ProviderAppointmentSchedulingDetailsDTO> {
            return localVarFp.getProviderAppointmentSchedulingDetailsForClient(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointments For Provider
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAppointments(clientId: string, options?: any): AxiosPromise<ProviderAppointmentsResponseDTO> {
            return localVarFp.getProviderAppointments(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the details required to join the telehealth meeting including any required access token for the client. When requesting this endpoint for an appointment that does not yet have a telehealth meeting created, this endpoint will create that meeting. If two simultaneous calls are made to this endpoint by different attendees of and appointment, a 409 conflict will be sent. If the client does not have access to this appointment then the caller will receive a 403 error. 
         * @param {string} id The Appointment ID for which the client wants to join the telehealth meeting.
         * @param {boolean} isRecordingConsentGranted If the patient consents to recording, and recording is permitted in their location and the configured telehealth meeting implementation for the appointment supports it, then the meeting will be recorded. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinAppointmentTelehealthMeetingAsClient(id: string, isRecordingConsentGranted: boolean, options?: any): AxiosPromise<TelehealthMeetingAndAccessDetailsResponseDTO> {
            return localVarFp.joinAppointmentTelehealthMeetingAsClient(id, isRecordingConsentGranted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the details required to join the telehealth meeting including any required access token for the provider. When requesting this endpoint for an appointment that does not yet have a telehealth meeting created, this endpoint will create that meeting. If two simultaneous calls are made to this endpoint by different attendees of and appointment, a 409 conflict will be sent. If the provider does not have access to this appointment then the caller will receive a 403 error. 
         * @param {string} id The Appointment ID for which the client wants to join the telehealth meeting.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinAppointmentTelehealthMeetingAsProvider(id: string, options?: any): AxiosPromise<TelehealthMeetingAndAccessDetailsResponseDTO> {
            return localVarFp.joinAppointmentTelehealthMeetingAsProvider(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark Appointment Attended
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAppointmentAttended(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.markAppointmentAttended(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark Appointment Missed
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAppointmentMissed(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.markAppointmentMissed(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reschedule Appointment
         * @param {string} id 
         * @param {RescheduleAppointmentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescheduleAppointment(id: string, request: RescheduleAppointmentRequestDTO, options?: any): AxiosPromise<AppointmentResponseDTO> {
            return localVarFp.rescheduleAppointment(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reschedule Appointment as Provider
         * @param {string} id 
         * @param {RescheduleAppointmentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescheduleAppointmentAsProvider(id: string, request: RescheduleAppointmentRequestDTO, options?: any): AxiosPromise<ProviderAppointmentResponseDTO> {
            return localVarFp.rescheduleAppointmentAsProvider(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedule Appointment
         * @param {ScheduleAppointmentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAppointment(request: ScheduleAppointmentRequestDTO, options?: any): AxiosPromise<AppointmentResponseDTO> {
            return localVarFp.scheduleAppointment(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedule Appointment as Provider
         * @param {ScheduleAppointmentAsProviderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAppointmentAsProvider(request: ScheduleAppointmentAsProviderRequestDTO, options?: any): AxiosPromise<ProviderAppointmentResponseDTO> {
            return localVarFp.scheduleAppointmentAsProvider(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search appointments
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {string} [clientIntakeId] Optionally filter to a certain client intake
         * @param {string} [providerId] Optionally filter to a certain provider
         * @param {Array<'SCHEDULED' | 'RESCHEDULED' | 'CANCELED' | 'ATTENDED' | 'MISSED'>} [appointmentStates] 
         * @param {Array<'ELIGIBLE_FOR_CHARGE' | 'EXCUSED' | 'CHARGED'>} [appointmentAttendancePolicyViolationStates] 
         * @param {Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>} [appointmentTypes] 
         * @param {Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>} [appointmentPaymentTypes] 
         * @param {string} [dateTimeMin] Optionally provide a minimum date time
         * @param {string} [dateTimeMax] Optionally provide a maximum date time
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {'DATE_TIME'} [sortBy] Sort results. Defaults to DATE_TIME.
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAppointments(clientId?: string, clientIntakeId?: string, providerId?: string, appointmentStates?: Array<'SCHEDULED' | 'RESCHEDULED' | 'CANCELED' | 'ATTENDED' | 'MISSED'>, appointmentAttendancePolicyViolationStates?: Array<'ELIGIBLE_FOR_CHARGE' | 'EXCUSED' | 'CHARGED'>, appointmentTypes?: Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>, appointmentPaymentTypes?: Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>, dateTimeMin?: string, dateTimeMax?: string, limit?: number, offset?: number, sortBy?: 'DATE_TIME', sortDir?: 'ASC' | 'DESC', options?: any): AxiosPromise<SearchAppointmentsResponseDTO> {
            return localVarFp.searchAppointments(clientId, clientIntakeId, providerId, appointmentStates, appointmentAttendancePolicyViolationStates, appointmentTypes, appointmentPaymentTypes, dateTimeMin, dateTimeMax, limit, offset, sortBy, sortDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets up storage for an in progress appointment recording part
         * @param {string} id The id of the appointment to start recording
         * @param {number} partNumber The sequential part number of the current upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupAppointmentRecordingPart(id: string, partNumber: number, options?: any): AxiosPromise<AppointmentRecordingPartResponseDTO> {
            return localVarFp.setupAppointmentRecordingPart(id, partNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentApi - object-oriented interface
 * @export
 * @class AppointmentApi
 * @extends {BaseAPI}
 */
export class AppointmentApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Appointment
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public cancelAppointment(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).cancelAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Appointment as Provider
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public cancelAppointmentAsProvider(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).cancelAppointmentAsProvider(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Charge appointment attendance policy violation for non-COVERED appointments
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public chargeAppointmentAttendancePolicyViolation(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).chargeAppointmentAttendancePolicyViolation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete an appointment recording
     * @param {string} id The id of the appointment to stop recording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public completeAppointmentRecording(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).completeAppointmentRecording(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Excuse Appointment Attendance Policy Violation
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public excuseAppointmentAttendancePolicyViolation(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).excuseAppointmentAttendancePolicyViolation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get appointment
     * @param {string} id The appointment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointment(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get appointment durations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentDurations(options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentDurations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information relevant to scheduling appointment for client
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentSchedulingDetailsForClient(clientId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentSchedulingDetailsForClient(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointments
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointments(clientId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointments(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dates with available appointment times for the specified month and appointment type for a given client
     * @param {string} month A year-month
     * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
     * @param {string} clientId 
     * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAvailableAppointmentDatesForClient(month: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', clientId: string, providerId?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAvailableAppointmentDatesForClient(month, appointmentType, clientId, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dates with available appointment times for the specified month for a client intake that does not yet have an initial consultation 
     * @param {string} month A year-month
     * @param {string} clientIntakeId 
     * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAvailableAppointmentDatesForClientIntake(month: string, clientIntakeId: string, providerId?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAvailableAppointmentDatesForClientIntake(month, clientIntakeId, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dates with available appointment times for the specified month and appointment type for a given provider
     * @param {string} month A year-month
     * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAvailableAppointmentDatesForProvider(month: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', providerId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAvailableAppointmentDatesForProvider(month, appointmentType, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available appointment times for the specified date and appointment type for a given client
     * @param {string} date 
     * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
     * @param {string} clientId 
     * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAvailableAppointmentTimesForClient(date: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', clientId: string, providerId?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAvailableAppointmentTimesForClient(date, appointmentType, clientId, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available appointment times for the specified date for a client intake that does not yet have an initial consultation 
     * @param {string} date 
     * @param {string} clientIntakeId 
     * @param {string} [providerId] Optionally filter availability for the given providerId. If not present, availability returned for all providers in aggregate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAvailableAppointmentTimesForClientIntake(date: string, clientIntakeId: string, providerId?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAvailableAppointmentTimesForClientIntake(date, clientIntakeId, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available appointment times for the specified date and appointment type for a given provider
     * @param {string} date 
     * @param {'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'} appointmentType 
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAvailableAppointmentTimesForProvider(date: string, appointmentType: 'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP', providerId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAvailableAppointmentTimesForProvider(date, appointmentType, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get appointment for provider
     * @param {string} id The appointment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getProviderAppointment(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getProviderAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information relevant to scheduling appointment for client of a provider
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getProviderAppointmentSchedulingDetailsForClient(clientId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getProviderAppointmentSchedulingDetailsForClient(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointments For Provider
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getProviderAppointments(clientId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getProviderAppointments(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the details required to join the telehealth meeting including any required access token for the client. When requesting this endpoint for an appointment that does not yet have a telehealth meeting created, this endpoint will create that meeting. If two simultaneous calls are made to this endpoint by different attendees of and appointment, a 409 conflict will be sent. If the client does not have access to this appointment then the caller will receive a 403 error. 
     * @param {string} id The Appointment ID for which the client wants to join the telehealth meeting.
     * @param {boolean} isRecordingConsentGranted If the patient consents to recording, and recording is permitted in their location and the configured telehealth meeting implementation for the appointment supports it, then the meeting will be recorded. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public joinAppointmentTelehealthMeetingAsClient(id: string, isRecordingConsentGranted: boolean, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).joinAppointmentTelehealthMeetingAsClient(id, isRecordingConsentGranted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the details required to join the telehealth meeting including any required access token for the provider. When requesting this endpoint for an appointment that does not yet have a telehealth meeting created, this endpoint will create that meeting. If two simultaneous calls are made to this endpoint by different attendees of and appointment, a 409 conflict will be sent. If the provider does not have access to this appointment then the caller will receive a 403 error. 
     * @param {string} id The Appointment ID for which the client wants to join the telehealth meeting.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public joinAppointmentTelehealthMeetingAsProvider(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).joinAppointmentTelehealthMeetingAsProvider(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark Appointment Attended
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public markAppointmentAttended(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).markAppointmentAttended(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark Appointment Missed
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public markAppointmentMissed(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).markAppointmentMissed(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reschedule Appointment
     * @param {string} id 
     * @param {RescheduleAppointmentRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public rescheduleAppointment(id: string, request: RescheduleAppointmentRequestDTO, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).rescheduleAppointment(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reschedule Appointment as Provider
     * @param {string} id 
     * @param {RescheduleAppointmentRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public rescheduleAppointmentAsProvider(id: string, request: RescheduleAppointmentRequestDTO, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).rescheduleAppointmentAsProvider(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedule Appointment
     * @param {ScheduleAppointmentRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public scheduleAppointment(request: ScheduleAppointmentRequestDTO, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).scheduleAppointment(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedule Appointment as Provider
     * @param {ScheduleAppointmentAsProviderRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public scheduleAppointmentAsProvider(request: ScheduleAppointmentAsProviderRequestDTO, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).scheduleAppointmentAsProvider(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search appointments
     * @param {string} [clientId] Optionally filter to a certain client
     * @param {string} [clientIntakeId] Optionally filter to a certain client intake
     * @param {string} [providerId] Optionally filter to a certain provider
     * @param {Array<'SCHEDULED' | 'RESCHEDULED' | 'CANCELED' | 'ATTENDED' | 'MISSED'>} [appointmentStates] 
     * @param {Array<'ELIGIBLE_FOR_CHARGE' | 'EXCUSED' | 'CHARGED'>} [appointmentAttendancePolicyViolationStates] 
     * @param {Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>} [appointmentTypes] 
     * @param {Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>} [appointmentPaymentTypes] 
     * @param {string} [dateTimeMin] Optionally provide a minimum date time
     * @param {string} [dateTimeMax] Optionally provide a maximum date time
     * @param {number} [limit] Limit query for pagination. Defaults to 100
     * @param {number} [offset] Offset query for pagination. Defaults to 0.
     * @param {'DATE_TIME'} [sortBy] Sort results. Defaults to DATE_TIME.
     * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public searchAppointments(clientId?: string, clientIntakeId?: string, providerId?: string, appointmentStates?: Array<'SCHEDULED' | 'RESCHEDULED' | 'CANCELED' | 'ATTENDED' | 'MISSED'>, appointmentAttendancePolicyViolationStates?: Array<'ELIGIBLE_FOR_CHARGE' | 'EXCUSED' | 'CHARGED'>, appointmentTypes?: Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>, appointmentPaymentTypes?: Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>, dateTimeMin?: string, dateTimeMax?: string, limit?: number, offset?: number, sortBy?: 'DATE_TIME', sortDir?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).searchAppointments(clientId, clientIntakeId, providerId, appointmentStates, appointmentAttendancePolicyViolationStates, appointmentTypes, appointmentPaymentTypes, dateTimeMin, dateTimeMax, limit, offset, sortBy, sortDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets up storage for an in progress appointment recording part
     * @param {string} id The id of the appointment to start recording
     * @param {number} partNumber The sequential part number of the current upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public setupAppointmentRecordingPart(id: string, partNumber: number, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).setupAppointmentRecordingPart(id, partNumber, options).then((request) => request(this.axios, this.basePath));
    }
}
