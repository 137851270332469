/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { t } from '@lingui/macro';
import type { ErrorDTO, TokenResponseDTO } from 'API';
import { ErrorDTOReasonEnum } from 'API';
import { getLoginErrorMessages } from 'apps/gateway/pages/Auth/LoginPage/constant';
import { ROUTES } from 'apps/gateway/utils/routeConstants';
import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import { all, call, debounce, fork, put, takeLatest } from 'redux-saga/effects';
import { REFRESH_TOKEN_ACCESSOR, authAPI, ssoAPI } from 'utils';
import logger from 'utils/logger';
import { alertError } from '../alerts/actions';
import type { ssoLoginRequest } from './actions';
import { loginRequestSuccessPendingMfa } from './actions';
import { loginRequestError, loginRequestSuccess } from './actions';
import { UserActionTypes } from './types';

const alertUnknownError = () => alertError(t`An unknown error occurred.`);

export function* handleSsoLogin(action: ReturnType<typeof ssoLoginRequest>) {
    try {
        logger.debug('handleSsoLogin called');
        const res: AxiosResponse<TokenResponseDTO> =
            action.payload.type === 'google'
                ? yield call([ssoAPI, ssoAPI.googleLogin], action.payload.request)
                : yield call([ssoAPI, ssoAPI.appleLogin], action.payload.request);
        yield put(loginRequestSuccess(res.data));
    } catch (err) {
        logger.debug('Got an error from sso login', { err });
        const isAxiosError = axios.isAxiosError(err);
        const errorDetails = {
            actionType: action.type,
            error: isAxiosError ? (err as AxiosError).message : err,
        };
        logger.warn('Error handling SSO login', { errorDetails });

        if (isAxiosError) {
            const axiosErr = err as AxiosError<ErrorDTO>;
            const reason = axiosErr.response?.data?.reason;

            if (reason === ErrorDTOReasonEnum.ACCOUNT_INACTIVE) {
                return (window.location.pathname = ROUTES.INACTIVE_ACCOUNT);
            } else if (reason === ErrorDTOReasonEnum.ACCOUNT_LOCKED) {
                yield put(alertError(getLoginErrorMessages().ACCOUNT_LOCKED));
            } else {
                yield put(alertError(getLoginErrorMessages().ACCOUNT_NOT_FOUND));
            }

            yield put(loginRequestError(axiosErr.stack ?? ''));
        } else {
            yield put(alertUnknownError());
            yield put(loginRequestError(t`An unknown error occurred.`));
        }
    }
}

export function* handleRefreshLogin() {
    const storedRefreshToken = localStorage.getItem(REFRESH_TOKEN_ACCESSOR);
    if (storedRefreshToken && storedRefreshToken !== '') {
        try {
            const res: AxiosResponse<TokenResponseDTO> = yield call([authAPI, authAPI.refreshToken], {
                refreshToken: storedRefreshToken,
            });
            if (res.data.userDetails.requiresMfa) {
                yield put(loginRequestSuccessPendingMfa(res.data));
            } else {
                yield put(loginRequestSuccess(res.data));
            }
        } catch (err) {
            if (err instanceof Error && err.stack) {
                yield put(loginRequestError(err.stack));
            } else {
                yield put(loginRequestError(t`An unknown error occurred.`));
            }
            yield put(alertUnknownError());
        }
    } else {
        yield put(loginRequestError(t`An unknown error occurred.`));
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchSsoLoginRequest() {
    yield takeLatest(UserActionTypes.SSO_LOGIN_REQUEST, handleSsoLogin);
}

function* watchRefreshLoginRequest() {
    yield debounce(1000, UserActionTypes.LOGIN_REFRESH, handleRefreshLogin);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* userSaga() {
    yield all([fork(watchSsoLoginRequest), fork(watchRefreshLoginRequest)]);
}

export default userSaga;
