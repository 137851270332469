/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum CancelSubscriptionReasonDTO {
    APP_ISSUES = 'APP_ISSUES',
    COST_ISSUES = 'COST_ISSUES',
    DELIVERY_ISSUES = 'DELIVERY_ISSUES',
    NO_LONGER_RELEVANT = 'NO_LONGER_RELEVANT',
    OTHER = 'OTHER'
};

