import { t } from '@lingui/macro';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import type { StripeElementChangeEvent } from '@stripe/stripe-js/types/stripe-js/elements/base';
import { Field, useFormikContext } from 'formik';
import { Banner, BannerColors, Button, Input } from 'galley';
import type { PaymentFormValues } from './constants';
import { stripeInputOptions } from './constants';
import StripeField from './StripeField';

export type Props = {
    className?: string;
    ctaText?: string;
    errorMessage?: string;
    renderCta?: boolean;
};

const PaymentSetupForm = ({ className, ctaText, errorMessage, renderCta = true }: Props) => {
    const {
        dirty,
        errors,
        handleChange,
        isSubmitting,
        isValid,
        setFieldTouched,
        setFieldValue,
        setFieldError,
        submitForm,
    } = useFormikContext<PaymentFormValues>();

    const inputProps = {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        inputClassName: 'disabled:text-greyscale-inactive disabled:bg-white',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldTouched(event.target.name, false, false);
            event.target.setCustomValidity('');
            handleChange(event);
        },
        onBlur: (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;

            if (!value) {
                event.target.setCustomValidity('');
            } else if (name in errors) {
                event.target.setCustomValidity(t`Invalid input`);
            }
        },
    };

    const stripeInputProps = {
        options: {
            ...stripeInputOptions,
            disabled: isSubmitting,
        },
        onChange: ({ elementType, complete, error }: StripeElementChangeEvent) => {
            setFieldValue(elementType, { isValid: complete });

            if (error) {
                setFieldError(elementType, error.message);
            } else if (complete) {
                setFieldError(elementType, undefined);
            }
        },
    };

    return (
        <div className={className}>
            {errorMessage && <Banner className="my-6" color={BannerColors.RED} text={errorMessage} />}
            <div className="grid grid-cols-2 gap-4">
                <Field
                    as={Input}
                    label={t`Name on card`}
                    name="fullName"
                    className="col-span-2"
                    autoComplete="cc-name"
                    disabled={isSubmitting}
                    {...inputProps}
                />
                <StripeField
                    label={t`Card number`}
                    className="col-span-2"
                    element={
                        <CardNumberElement
                            {...stripeInputProps}
                            options={{
                                ...stripeInputProps.options,
                                placeholder: '',
                                showIcon: true,
                            }}
                        />
                    }
                />
                <Field
                    as={Input}
                    type="text"
                    label={t`Billing ZIP code`}
                    name="zipCode"
                    className="col-span-2"
                    maxLength={5}
                    disabled={isSubmitting}
                    inputMode="numeric"
                    autoComplete="postal-code"
                    {...inputProps}
                />
                <StripeField
                    label={t`Expiration date`}
                    className="col-span-1"
                    element={<CardExpiryElement {...stripeInputProps} />}
                />
                <StripeField
                    label={t`CVC code`}
                    className="col-span-1"
                    element={
                        <CardCvcElement
                            {...stripeInputProps}
                            options={{
                                ...stripeInputProps.options,
                                placeholder: '',
                            }}
                        />
                    }
                />
            </div>
            {renderCta && (
                <Button
                    variant="primary"
                    className="mx-auto mb-1 mt-8 w-full md:w-fit"
                    onClick={submitForm}
                    loading={isSubmitting}
                    disabled={!dirty || !isValid || isSubmitting}
                >
                    {ctaText ?? t`Save card`}
                </Button>
            )}
        </div>
    );
};

export default PaymentSetupForm;
