import { t } from '@lingui/macro';
import { SUPPORT_EMAIL_ADDRESS } from 'utils';

export const getLoginErrorMessages = () => ({
    GENERIC: t`Oh no! Login failed. Please try again or contact support if you continue to experience issues.`,
    ACCOUNT_LOCKED: `Your account has been locked due to too many failed login attempts. You will receive an email with instructions on how to unlock your account within ten minutes. If you believe this was done in error, please reach out to ${SUPPORT_EMAIL_ADDRESS}.`,
    ACCOUNT_NOT_FOUND: t`There is no account associated with this email address. Please create an account.`,
    COOKIES_DISABLED: t`Cannot Login with Google when Cookies are Disabled. Please Enable Cookies and Retry.`,
    GOOGLE: t`Could not login with Google.`,
    APPLE: t`Could not login with Apple.`,
});
