import clsx from 'clsx';
import { XContainedFillIcon } from 'galley';

export type Props = {
    touched?: boolean;
    error?: string;
    className?: string;
};

const ErrorMessage = ({ touched, error, className }: Props): JSX.Element => (
    <div data-dd-privacy="allow" className={clsx('bodySmLight mt-1 flex h-3 w-full items-center', className)}>
        {touched && error && (
            <>
                <XContainedFillIcon className="smIcon text-alert-red2 mr-1" />
                <span>{error}</span>
            </>
        )}
    </div>
);
export default ErrorMessage;
