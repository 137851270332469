/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PracticeDashboardFiltersResponseDTO } from '../models';
// @ts-ignore
import { PracticeDashboardResponseDTO } from '../models';
// @ts-ignore
import { PracticeWithProvidersDTO } from '../models';
// @ts-ignore
import { PracticesResponseDTO } from '../models';
// @ts-ignore
import { SearchPracticeClientsResponseDTO } from '../models';
/**
 * PracticeApi - axios parameter creator
 * @export
 */
export const PracticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Practice
         * @param {string} id The Practice ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPractice', 'id', id)
            const localVarPath = `/practices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Practice\'s dashboard data
         * @param {string} id The Practice ID
         * @param {Array<string>} [providerIds] Only clients belonging one of these providers, empty or null defaults to all values
         * @param {Array<string>} [subscriptionPackageIds] Only clients with one of these subscription packages, empty or null defaults to all values
         * @param {boolean} [hasSubscription] Only clients that have a subscription, null means no filter is applied
         * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Only clients with one of these cohorts, empty or null defaults to all values
         * @param {Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY'>} [diets] Only clients with one of these diets, empty or null defaults to all values
         * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [states] Only clients with one of these current states, empty or null defaults to all values
         * @param {string} [mostRecentOrderAfter] Only clients with one most recent order after this time, null defaults to no cut off
         * @param {string} [programStartDateMin] Optionally provide a minimum program start date, null defaults to no cut off
         * @param {string} [programStartDateMax] Optionally provide a maximum program start date, null defaults to no cut off
         * @param {string} [nameFilter] Optional free text filter against client first and last name
         * @param {'CLIENT_NAME' | 'PROVIDER_NAME' | 'DIETS' | 'COHORT' | 'SUBSCRIPTION_PACKAGE_NAME' | 'MOST_RECENT_ORDER' | 'CLIENT_STATE' | 'PROGRAM_START_DATE'} [sortKey] Property to sort by, defaults to client name
         * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
         * @param {number} [limit] Number of records to include, defaults to 20
         * @param {number} [offset] Number of records to skip, defaults to 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeDashboard: async (id: string, providerIds?: Array<string>, subscriptionPackageIds?: Array<string>, hasSubscription?: boolean, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, diets?: Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY'>, states?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, mostRecentOrderAfter?: string, programStartDateMin?: string, programStartDateMax?: string, nameFilter?: string, sortKey?: 'CLIENT_NAME' | 'PROVIDER_NAME' | 'DIETS' | 'COHORT' | 'SUBSCRIPTION_PACKAGE_NAME' | 'MOST_RECENT_ORDER' | 'CLIENT_STATE' | 'PROGRAM_START_DATE', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPracticeDashboard', 'id', id)
            const localVarPath = `/practices/{id}/dashboard`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (providerIds) {
                localVarQueryParameter['providerIds'] = providerIds;
            }

            if (subscriptionPackageIds) {
                localVarQueryParameter['subscriptionPackageIds'] = subscriptionPackageIds;
            }

            if (hasSubscription !== undefined) {
                localVarQueryParameter['hasSubscription'] = hasSubscription;
            }

            if (cohorts) {
                localVarQueryParameter['cohorts'] = cohorts;
            }

            if (diets) {
                localVarQueryParameter['diets'] = diets;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }

            if (mostRecentOrderAfter !== undefined) {
                localVarQueryParameter['mostRecentOrderAfter'] = (mostRecentOrderAfter as any instanceof Date) ?
                    (mostRecentOrderAfter as any).toISOString() :
                    mostRecentOrderAfter;
            }

            if (programStartDateMin !== undefined) {
                localVarQueryParameter['programStartDateMin'] = (programStartDateMin as any instanceof Date) ?
                    (programStartDateMin as any).toISOString().substr(0,10) :
                    programStartDateMin;
            }

            if (programStartDateMax !== undefined) {
                localVarQueryParameter['programStartDateMax'] = (programStartDateMax as any instanceof Date) ?
                    (programStartDateMax as any).toISOString().substr(0,10) :
                    programStartDateMax;
            }

            if (nameFilter !== undefined) {
                localVarQueryParameter['nameFilter'] = nameFilter;
            }

            if (sortKey !== undefined) {
                localVarQueryParameter['sortKey'] = sortKey;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Practice\'s dashboard filter parameters
         * @param {string} id The Practice ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeDashboardFilters: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPracticeDashboardFilters', 'id', id)
            const localVarPath = `/practices/{id}/dashboard-filters`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get practices
         * @param {boolean} [activeOnly] Only return practices currently in this state, false, empty, or null defaults to all values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractices: async (activeOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/practices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (activeOnly !== undefined) {
                localVarQueryParameter['activeOnly'] = activeOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search clients for practice
         * @param {string} id The Practice ID
         * @param {Array<string>} [providerIds] 
         * @param {string} [nameQuery] Optional free text filter against client first and last name
         * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [currentStates] Empty or null defaults to all values
         * @param {'LAST_NAME'} [sortBy] Sort results. Defaults to LAST_NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPracticeClients: async (id: string, providerIds?: Array<string>, nameQuery?: string, currentStates?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, sortBy?: 'LAST_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('searchPracticeClients', 'id', id)
            const localVarPath = `/practices/{id}/search-clients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (providerIds) {
                localVarQueryParameter['providerIds'] = providerIds;
            }

            if (nameQuery !== undefined) {
                localVarQueryParameter['nameQuery'] = nameQuery;
            }

            if (currentStates) {
                localVarQueryParameter['currentStates'] = currentStates;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PracticeApi - functional programming interface
 * @export
 */
export const PracticeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PracticeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Practice
         * @param {string} id The Practice ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPractice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeWithProvidersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPractice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Practice\'s dashboard data
         * @param {string} id The Practice ID
         * @param {Array<string>} [providerIds] Only clients belonging one of these providers, empty or null defaults to all values
         * @param {Array<string>} [subscriptionPackageIds] Only clients with one of these subscription packages, empty or null defaults to all values
         * @param {boolean} [hasSubscription] Only clients that have a subscription, null means no filter is applied
         * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Only clients with one of these cohorts, empty or null defaults to all values
         * @param {Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY'>} [diets] Only clients with one of these diets, empty or null defaults to all values
         * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [states] Only clients with one of these current states, empty or null defaults to all values
         * @param {string} [mostRecentOrderAfter] Only clients with one most recent order after this time, null defaults to no cut off
         * @param {string} [programStartDateMin] Optionally provide a minimum program start date, null defaults to no cut off
         * @param {string} [programStartDateMax] Optionally provide a maximum program start date, null defaults to no cut off
         * @param {string} [nameFilter] Optional free text filter against client first and last name
         * @param {'CLIENT_NAME' | 'PROVIDER_NAME' | 'DIETS' | 'COHORT' | 'SUBSCRIPTION_PACKAGE_NAME' | 'MOST_RECENT_ORDER' | 'CLIENT_STATE' | 'PROGRAM_START_DATE'} [sortKey] Property to sort by, defaults to client name
         * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
         * @param {number} [limit] Number of records to include, defaults to 20
         * @param {number} [offset] Number of records to skip, defaults to 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeDashboard(id: string, providerIds?: Array<string>, subscriptionPackageIds?: Array<string>, hasSubscription?: boolean, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, diets?: Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY'>, states?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, mostRecentOrderAfter?: string, programStartDateMin?: string, programStartDateMax?: string, nameFilter?: string, sortKey?: 'CLIENT_NAME' | 'PROVIDER_NAME' | 'DIETS' | 'COHORT' | 'SUBSCRIPTION_PACKAGE_NAME' | 'MOST_RECENT_ORDER' | 'CLIENT_STATE' | 'PROGRAM_START_DATE', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeDashboardResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPracticeDashboard(id, providerIds, subscriptionPackageIds, hasSubscription, cohorts, diets, states, mostRecentOrderAfter, programStartDateMin, programStartDateMax, nameFilter, sortKey, sortDir, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Practice\'s dashboard filter parameters
         * @param {string} id The Practice ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeDashboardFilters(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeDashboardFiltersResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPracticeDashboardFilters(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get practices
         * @param {boolean} [activeOnly] Only return practices currently in this state, false, empty, or null defaults to all values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPractices(activeOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPractices(activeOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search clients for practice
         * @param {string} id The Practice ID
         * @param {Array<string>} [providerIds] 
         * @param {string} [nameQuery] Optional free text filter against client first and last name
         * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [currentStates] Empty or null defaults to all values
         * @param {'LAST_NAME'} [sortBy] Sort results. Defaults to LAST_NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPracticeClients(id: string, providerIds?: Array<string>, nameQuery?: string, currentStates?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, sortBy?: 'LAST_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchPracticeClientsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPracticeClients(id, providerIds, nameQuery, currentStates, sortBy, sortDir, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PracticeApi - factory interface
 * @export
 */
export const PracticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PracticeApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Practice
         * @param {string} id The Practice ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractice(id: string, options?: any): AxiosPromise<PracticeWithProvidersDTO> {
            return localVarFp.getPractice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Practice\'s dashboard data
         * @param {string} id The Practice ID
         * @param {Array<string>} [providerIds] Only clients belonging one of these providers, empty or null defaults to all values
         * @param {Array<string>} [subscriptionPackageIds] Only clients with one of these subscription packages, empty or null defaults to all values
         * @param {boolean} [hasSubscription] Only clients that have a subscription, null means no filter is applied
         * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Only clients with one of these cohorts, empty or null defaults to all values
         * @param {Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY'>} [diets] Only clients with one of these diets, empty or null defaults to all values
         * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [states] Only clients with one of these current states, empty or null defaults to all values
         * @param {string} [mostRecentOrderAfter] Only clients with one most recent order after this time, null defaults to no cut off
         * @param {string} [programStartDateMin] Optionally provide a minimum program start date, null defaults to no cut off
         * @param {string} [programStartDateMax] Optionally provide a maximum program start date, null defaults to no cut off
         * @param {string} [nameFilter] Optional free text filter against client first and last name
         * @param {'CLIENT_NAME' | 'PROVIDER_NAME' | 'DIETS' | 'COHORT' | 'SUBSCRIPTION_PACKAGE_NAME' | 'MOST_RECENT_ORDER' | 'CLIENT_STATE' | 'PROGRAM_START_DATE'} [sortKey] Property to sort by, defaults to client name
         * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
         * @param {number} [limit] Number of records to include, defaults to 20
         * @param {number} [offset] Number of records to skip, defaults to 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeDashboard(id: string, providerIds?: Array<string>, subscriptionPackageIds?: Array<string>, hasSubscription?: boolean, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, diets?: Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY'>, states?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, mostRecentOrderAfter?: string, programStartDateMin?: string, programStartDateMax?: string, nameFilter?: string, sortKey?: 'CLIENT_NAME' | 'PROVIDER_NAME' | 'DIETS' | 'COHORT' | 'SUBSCRIPTION_PACKAGE_NAME' | 'MOST_RECENT_ORDER' | 'CLIENT_STATE' | 'PROGRAM_START_DATE', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: any): AxiosPromise<PracticeDashboardResponseDTO> {
            return localVarFp.getPracticeDashboard(id, providerIds, subscriptionPackageIds, hasSubscription, cohorts, diets, states, mostRecentOrderAfter, programStartDateMin, programStartDateMax, nameFilter, sortKey, sortDir, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Practice\'s dashboard filter parameters
         * @param {string} id The Practice ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeDashboardFilters(id: string, options?: any): AxiosPromise<PracticeDashboardFiltersResponseDTO> {
            return localVarFp.getPracticeDashboardFilters(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get practices
         * @param {boolean} [activeOnly] Only return practices currently in this state, false, empty, or null defaults to all values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractices(activeOnly?: boolean, options?: any): AxiosPromise<PracticesResponseDTO> {
            return localVarFp.getPractices(activeOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search clients for practice
         * @param {string} id The Practice ID
         * @param {Array<string>} [providerIds] 
         * @param {string} [nameQuery] Optional free text filter against client first and last name
         * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [currentStates] Empty or null defaults to all values
         * @param {'LAST_NAME'} [sortBy] Sort results. Defaults to LAST_NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPracticeClients(id: string, providerIds?: Array<string>, nameQuery?: string, currentStates?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, sortBy?: 'LAST_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: any): AxiosPromise<SearchPracticeClientsResponseDTO> {
            return localVarFp.searchPracticeClients(id, providerIds, nameQuery, currentStates, sortBy, sortDir, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PracticeApi - object-oriented interface
 * @export
 * @class PracticeApi
 * @extends {BaseAPI}
 */
export class PracticeApi extends BaseAPI {
    /**
     * 
     * @summary Get Practice
     * @param {string} id The Practice ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public getPractice(id: string, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).getPractice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Practice\'s dashboard data
     * @param {string} id The Practice ID
     * @param {Array<string>} [providerIds] Only clients belonging one of these providers, empty or null defaults to all values
     * @param {Array<string>} [subscriptionPackageIds] Only clients with one of these subscription packages, empty or null defaults to all values
     * @param {boolean} [hasSubscription] Only clients that have a subscription, null means no filter is applied
     * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Only clients with one of these cohorts, empty or null defaults to all values
     * @param {Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY'>} [diets] Only clients with one of these diets, empty or null defaults to all values
     * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [states] Only clients with one of these current states, empty or null defaults to all values
     * @param {string} [mostRecentOrderAfter] Only clients with one most recent order after this time, null defaults to no cut off
     * @param {string} [programStartDateMin] Optionally provide a minimum program start date, null defaults to no cut off
     * @param {string} [programStartDateMax] Optionally provide a maximum program start date, null defaults to no cut off
     * @param {string} [nameFilter] Optional free text filter against client first and last name
     * @param {'CLIENT_NAME' | 'PROVIDER_NAME' | 'DIETS' | 'COHORT' | 'SUBSCRIPTION_PACKAGE_NAME' | 'MOST_RECENT_ORDER' | 'CLIENT_STATE' | 'PROGRAM_START_DATE'} [sortKey] Property to sort by, defaults to client name
     * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
     * @param {number} [limit] Number of records to include, defaults to 20
     * @param {number} [offset] Number of records to skip, defaults to 0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public getPracticeDashboard(id: string, providerIds?: Array<string>, subscriptionPackageIds?: Array<string>, hasSubscription?: boolean, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, diets?: Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY'>, states?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, mostRecentOrderAfter?: string, programStartDateMin?: string, programStartDateMax?: string, nameFilter?: string, sortKey?: 'CLIENT_NAME' | 'PROVIDER_NAME' | 'DIETS' | 'COHORT' | 'SUBSCRIPTION_PACKAGE_NAME' | 'MOST_RECENT_ORDER' | 'CLIENT_STATE' | 'PROGRAM_START_DATE', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).getPracticeDashboard(id, providerIds, subscriptionPackageIds, hasSubscription, cohorts, diets, states, mostRecentOrderAfter, programStartDateMin, programStartDateMax, nameFilter, sortKey, sortDir, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Practice\'s dashboard filter parameters
     * @param {string} id The Practice ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public getPracticeDashboardFilters(id: string, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).getPracticeDashboardFilters(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get practices
     * @param {boolean} [activeOnly] Only return practices currently in this state, false, empty, or null defaults to all values
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public getPractices(activeOnly?: boolean, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).getPractices(activeOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search clients for practice
     * @param {string} id The Practice ID
     * @param {Array<string>} [providerIds] 
     * @param {string} [nameQuery] Optional free text filter against client first and last name
     * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [currentStates] Empty or null defaults to all values
     * @param {'LAST_NAME'} [sortBy] Sort results. Defaults to LAST_NAME
     * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
     * @param {number} [limit] Limit query for pagination. Defaults to 100
     * @param {number} [offset] Offset query for pagination. Defaults to 0.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public searchPracticeClients(id: string, providerIds?: Array<string>, nameQuery?: string, currentStates?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, sortBy?: 'LAST_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).searchPracticeClients(id, providerIds, nameQuery, currentStates, sortBy, sortDir, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}
