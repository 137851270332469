import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectUserData } from 'store/user/selectors';
import UpgradeAppMessage from 'UpgradeAppMessage';
import UserImpersonationMessage from 'UserImpersonationMessage';

export type Props = {
    isNewVersionAvailable: boolean;
};

const TopBanners = ({ isNewVersionAvailable }: Props): JSX.Element => {
    const userData = useSelector(selectUserData);

    if (!(isNewVersionAvailable || userData.isImpersonation)) {
        return <></>;
    }
    return (
        <div
            className={clsx(
                userData.client && 'sticky top-14',
                userData.provider && 'sticky top-0',
                userData.admin && 'fixed top-0',
                'z-20 w-full'
            )}
        >
            {isNewVersionAvailable && <UpgradeAppMessage />}
            {userData.isImpersonation && <UserImpersonationMessage />}
        </div>
    );
};

export default TopBanners;
