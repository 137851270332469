import { t } from '@lingui/macro';
import clsx from 'clsx';
import { ArrowLeftStrokeIcon, Button, ChevronLeftStrokeIcon } from 'galley';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterContext } from '../../Routes/useRouterContext';

export type Props = {
    allowBack?: boolean;
    backTo?: string;
    title?: string;
    titleIcon?: JSX.Element;
    titleStyle?: 'serif' | 'sans-serif';
    className?: string;
    iconChevron?: boolean;
};

const BackButton = ({ allowBack, backTo, className, title, titleIcon, titleStyle = 'serif', iconChevron }: Props) => {
    const navigate = useNavigate();

    const {
        route: { from },
    } = useContext(RouterContext);

    if (allowBack === false && title)
        return (
            <div className="flex items-center gap-2">
                {titleIcon} <h2 className={'headerMdSerif inline'}>{title}</h2>
            </div>
        );

    const Icon = iconChevron ? ChevronLeftStrokeIcon : ArrowLeftStrokeIcon;
    return (
        <Button
            className={clsx('rounded-8 !flex !items-center py-4', className)}
            onClick={() => (backTo ? navigate(backTo) : from ? navigate(-1) : navigate('/'))}
        >
            <Icon className="mdIcon mr-4 flex-none" aria-label={t`back`} />
            <div className="flex items-center gap-2">
                {titleIcon}
                {title && <h2 className={clsx(titleStyle === 'serif' ? 'headerMdSerif' : 'headerMdSans')}>{title}</h2>}
            </div>
        </Button>
    );
};

export default BackButton;
