import type { Store } from 'redux';
import { configureStore as toolkitConfigureStore, type Middleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import type { ApplicationActions, ApplicationState } from '.';
import { createRootReducer, rootSaga } from '.';
import type { MetaActions } from './meta/store';

export default function configureStore(): Store<ApplicationState, ApplicationActions | MetaActions> {
    const rootReducer = createRootReducer();
    const sagaMiddleware = createSagaMiddleware();

    const store = toolkitConfigureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: false,
                immutableCheck: false,
            }).concat(sagaMiddleware as Middleware),
    });

    sagaMiddleware.run(rootSaga);
    return store;
}
