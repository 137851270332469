/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BenefitDTO } from '../models';
// @ts-ignore
import { BenefitsResponseDTO } from '../models';
// @ts-ignore
import { CreateBenefitRequestDTO } from '../models';
// @ts-ignore
import { CreateBenefitResponseDTO } from '../models';
// @ts-ignore
import { CreateBenefitsAsyncRequestDTO } from '../models';
// @ts-ignore
import { CreateBenefitsAsyncResponseDTO } from '../models';
// @ts-ignore
import { ReprocessBenefitsAsyncRequestDTO } from '../models';
// @ts-ignore
import { SearchBenefitsResponseDTO } from '../models';
// @ts-ignore
import { UpdateBenefitDeliveryDateRequestDTO } from '../models';
/**
 * BenefitApi - axios parameter creator
 * @export
 */
export const BenefitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Benefit
         * @param {CreateBenefitRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefit: async (client: CreateBenefitRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('createBenefit', 'client', client)
            const localVarPath = `/benefits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(client, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Benefits async
         * @param {CreateBenefitsAsyncRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefitsAsync: async (client: CreateBenefitsAsyncRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('createBenefitsAsync', 'client', client)
            const localVarPath = `/benefits/async`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(client, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get benefit by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBenefit', 'id', id)
            const localVarPath = `/benefits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get benefits for clientId and optional states.
         * @param {string} clientId Client ID
         * @param {Array<'CREATED' | 'CLAIMED' | 'PROCESSED' | 'CANCELED' | 'EXPIRED'>} [states] Benefit states requested, defaults to all states.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefits: async (clientId: string, states?: Array<'CREATED' | 'CLAIMED' | 'PROCESSED' | 'CANCELED' | 'EXPIRED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getBenefits', 'clientId', clientId)
            const localVarPath = `/benefits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lock benefit
         * @param {string} id The Benefit ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockBenefit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lockBenefit', 'id', id)
            const localVarPath = `/benefits/{id}/lock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reprocess benefits async
         * @param {ReprocessBenefitsAsyncRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprocessBenefitsAsync: async (request: ReprocessBenefitsAsyncRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('reprocessBenefitsAsync', 'request', request)
            const localVarPath = `/benefits/reprocess-async`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search benefits
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {Array<'CREATED' | 'LOCKED' | 'CANCELED' | 'EXPIRED'>} [benefitStates] 
         * @param {Array<'GROCERY'>} [benefitTypes] 
         * @param {Array<'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>} [groceryBundleTypes] 
         * @param {string} [pendingLockDateMin] Optionally provide a minimum lock date
         * @param {string} [pendingLockDateMax] Optionally provide a maximum lock date
         * @param {string} [deliveryDateMin] Optionally provide a minimum delivery date
         * @param {string} [deliveryDateMax] Optionally provide a maximum delivery date
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {'PENDING_LOCK_DATE' | 'DELIVERY_DATE'} [sortBy] Sort results. Defaults to PENDING_LOCK_DATE. Orders with null in the sort field will come last.
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBenefits: async (clientId?: string, benefitStates?: Array<'CREATED' | 'LOCKED' | 'CANCELED' | 'EXPIRED'>, benefitTypes?: Array<'GROCERY'>, groceryBundleTypes?: Array<'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>, pendingLockDateMin?: string, pendingLockDateMax?: string, deliveryDateMin?: string, deliveryDateMax?: string, limit?: number, offset?: number, sortBy?: 'PENDING_LOCK_DATE' | 'DELIVERY_DATE', sortDir?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/benefits/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (benefitStates) {
                localVarQueryParameter['benefitStates'] = benefitStates;
            }

            if (benefitTypes) {
                localVarQueryParameter['benefitTypes'] = benefitTypes;
            }

            if (groceryBundleTypes) {
                localVarQueryParameter['groceryBundleTypes'] = groceryBundleTypes;
            }

            if (pendingLockDateMin !== undefined) {
                localVarQueryParameter['pendingLockDateMin'] = (pendingLockDateMin as any instanceof Date) ?
                    (pendingLockDateMin as any).toISOString() :
                    pendingLockDateMin;
            }

            if (pendingLockDateMax !== undefined) {
                localVarQueryParameter['pendingLockDateMax'] = (pendingLockDateMax as any instanceof Date) ?
                    (pendingLockDateMax as any).toISOString() :
                    pendingLockDateMax;
            }

            if (deliveryDateMin !== undefined) {
                localVarQueryParameter['deliveryDateMin'] = (deliveryDateMin as any instanceof Date) ?
                    (deliveryDateMin as any).toISOString().substr(0,10) :
                    deliveryDateMin;
            }

            if (deliveryDateMax !== undefined) {
                localVarQueryParameter['deliveryDateMax'] = (deliveryDateMax as any instanceof Date) ?
                    (deliveryDateMax as any).toISOString().substr(0,10) :
                    deliveryDateMax;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the delivery date on a benefit
         * @param {string} id The Benefit ID
         * @param {UpdateBenefitDeliveryDateRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitDeliveryDate: async (id: string, request: UpdateBenefitDeliveryDateRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBenefitDeliveryDate', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateBenefitDeliveryDate', 'request', request)
            const localVarPath = `/benefits/{id}/update-delivery-date`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BenefitApi - functional programming interface
 * @export
 */
export const BenefitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BenefitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Benefit
         * @param {CreateBenefitRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBenefit(client: CreateBenefitRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBenefitResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBenefit(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Benefits async
         * @param {CreateBenefitsAsyncRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBenefitsAsync(client: CreateBenefitsAsyncRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBenefitsAsyncResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBenefitsAsync(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get benefit by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get benefits for clientId and optional states.
         * @param {string} clientId Client ID
         * @param {Array<'CREATED' | 'CLAIMED' | 'PROCESSED' | 'CANCELED' | 'EXPIRED'>} [states] Benefit states requested, defaults to all states.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefits(clientId: string, states?: Array<'CREATED' | 'CLAIMED' | 'PROCESSED' | 'CANCELED' | 'EXPIRED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefits(clientId, states, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lock benefit
         * @param {string} id The Benefit ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockBenefit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockBenefit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reprocess benefits async
         * @param {ReprocessBenefitsAsyncRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reprocessBenefitsAsync(request: ReprocessBenefitsAsyncRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reprocessBenefitsAsync(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search benefits
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {Array<'CREATED' | 'LOCKED' | 'CANCELED' | 'EXPIRED'>} [benefitStates] 
         * @param {Array<'GROCERY'>} [benefitTypes] 
         * @param {Array<'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>} [groceryBundleTypes] 
         * @param {string} [pendingLockDateMin] Optionally provide a minimum lock date
         * @param {string} [pendingLockDateMax] Optionally provide a maximum lock date
         * @param {string} [deliveryDateMin] Optionally provide a minimum delivery date
         * @param {string} [deliveryDateMax] Optionally provide a maximum delivery date
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {'PENDING_LOCK_DATE' | 'DELIVERY_DATE'} [sortBy] Sort results. Defaults to PENDING_LOCK_DATE. Orders with null in the sort field will come last.
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBenefits(clientId?: string, benefitStates?: Array<'CREATED' | 'LOCKED' | 'CANCELED' | 'EXPIRED'>, benefitTypes?: Array<'GROCERY'>, groceryBundleTypes?: Array<'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>, pendingLockDateMin?: string, pendingLockDateMax?: string, deliveryDateMin?: string, deliveryDateMax?: string, limit?: number, offset?: number, sortBy?: 'PENDING_LOCK_DATE' | 'DELIVERY_DATE', sortDir?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchBenefitsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBenefits(clientId, benefitStates, benefitTypes, groceryBundleTypes, pendingLockDateMin, pendingLockDateMax, deliveryDateMin, deliveryDateMax, limit, offset, sortBy, sortDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the delivery date on a benefit
         * @param {string} id The Benefit ID
         * @param {UpdateBenefitDeliveryDateRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenefitDeliveryDate(id: string, request: UpdateBenefitDeliveryDateRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenefitDeliveryDate(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BenefitApi - factory interface
 * @export
 */
export const BenefitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BenefitApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Benefit
         * @param {CreateBenefitRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefit(client: CreateBenefitRequestDTO, options?: any): AxiosPromise<CreateBenefitResponseDTO> {
            return localVarFp.createBenefit(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Benefits async
         * @param {CreateBenefitsAsyncRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefitsAsync(client: CreateBenefitsAsyncRequestDTO, options?: any): AxiosPromise<CreateBenefitsAsyncResponseDTO> {
            return localVarFp.createBenefitsAsync(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get benefit by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefit(id: string, options?: any): AxiosPromise<BenefitDTO> {
            return localVarFp.getBenefit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get benefits for clientId and optional states.
         * @param {string} clientId Client ID
         * @param {Array<'CREATED' | 'CLAIMED' | 'PROCESSED' | 'CANCELED' | 'EXPIRED'>} [states] Benefit states requested, defaults to all states.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefits(clientId: string, states?: Array<'CREATED' | 'CLAIMED' | 'PROCESSED' | 'CANCELED' | 'EXPIRED'>, options?: any): AxiosPromise<BenefitsResponseDTO> {
            return localVarFp.getBenefits(clientId, states, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lock benefit
         * @param {string} id The Benefit ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockBenefit(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.lockBenefit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reprocess benefits async
         * @param {ReprocessBenefitsAsyncRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprocessBenefitsAsync(request: ReprocessBenefitsAsyncRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.reprocessBenefitsAsync(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search benefits
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {Array<'CREATED' | 'LOCKED' | 'CANCELED' | 'EXPIRED'>} [benefitStates] 
         * @param {Array<'GROCERY'>} [benefitTypes] 
         * @param {Array<'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>} [groceryBundleTypes] 
         * @param {string} [pendingLockDateMin] Optionally provide a minimum lock date
         * @param {string} [pendingLockDateMax] Optionally provide a maximum lock date
         * @param {string} [deliveryDateMin] Optionally provide a minimum delivery date
         * @param {string} [deliveryDateMax] Optionally provide a maximum delivery date
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {'PENDING_LOCK_DATE' | 'DELIVERY_DATE'} [sortBy] Sort results. Defaults to PENDING_LOCK_DATE. Orders with null in the sort field will come last.
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBenefits(clientId?: string, benefitStates?: Array<'CREATED' | 'LOCKED' | 'CANCELED' | 'EXPIRED'>, benefitTypes?: Array<'GROCERY'>, groceryBundleTypes?: Array<'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>, pendingLockDateMin?: string, pendingLockDateMax?: string, deliveryDateMin?: string, deliveryDateMax?: string, limit?: number, offset?: number, sortBy?: 'PENDING_LOCK_DATE' | 'DELIVERY_DATE', sortDir?: 'ASC' | 'DESC', options?: any): AxiosPromise<SearchBenefitsResponseDTO> {
            return localVarFp.searchBenefits(clientId, benefitStates, benefitTypes, groceryBundleTypes, pendingLockDateMin, pendingLockDateMax, deliveryDateMin, deliveryDateMax, limit, offset, sortBy, sortDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the delivery date on a benefit
         * @param {string} id The Benefit ID
         * @param {UpdateBenefitDeliveryDateRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitDeliveryDate(id: string, request: UpdateBenefitDeliveryDateRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateBenefitDeliveryDate(id, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenefitApi - object-oriented interface
 * @export
 * @class BenefitApi
 * @extends {BaseAPI}
 */
export class BenefitApi extends BaseAPI {
    /**
     * 
     * @summary Create Benefit
     * @param {CreateBenefitRequestDTO} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitApi
     */
    public createBenefit(client: CreateBenefitRequestDTO, options?: AxiosRequestConfig) {
        return BenefitApiFp(this.configuration).createBenefit(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Benefits async
     * @param {CreateBenefitsAsyncRequestDTO} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitApi
     */
    public createBenefitsAsync(client: CreateBenefitsAsyncRequestDTO, options?: AxiosRequestConfig) {
        return BenefitApiFp(this.configuration).createBenefitsAsync(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get benefit by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitApi
     */
    public getBenefit(id: string, options?: AxiosRequestConfig) {
        return BenefitApiFp(this.configuration).getBenefit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get benefits for clientId and optional states.
     * @param {string} clientId Client ID
     * @param {Array<'CREATED' | 'CLAIMED' | 'PROCESSED' | 'CANCELED' | 'EXPIRED'>} [states] Benefit states requested, defaults to all states.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitApi
     */
    public getBenefits(clientId: string, states?: Array<'CREATED' | 'CLAIMED' | 'PROCESSED' | 'CANCELED' | 'EXPIRED'>, options?: AxiosRequestConfig) {
        return BenefitApiFp(this.configuration).getBenefits(clientId, states, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lock benefit
     * @param {string} id The Benefit ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitApi
     */
    public lockBenefit(id: string, options?: AxiosRequestConfig) {
        return BenefitApiFp(this.configuration).lockBenefit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reprocess benefits async
     * @param {ReprocessBenefitsAsyncRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitApi
     */
    public reprocessBenefitsAsync(request: ReprocessBenefitsAsyncRequestDTO, options?: AxiosRequestConfig) {
        return BenefitApiFp(this.configuration).reprocessBenefitsAsync(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search benefits
     * @param {string} [clientId] Optionally filter to a certain client
     * @param {Array<'CREATED' | 'LOCKED' | 'CANCELED' | 'EXPIRED'>} [benefitStates] 
     * @param {Array<'GROCERY'>} [benefitTypes] 
     * @param {Array<'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>} [groceryBundleTypes] 
     * @param {string} [pendingLockDateMin] Optionally provide a minimum lock date
     * @param {string} [pendingLockDateMax] Optionally provide a maximum lock date
     * @param {string} [deliveryDateMin] Optionally provide a minimum delivery date
     * @param {string} [deliveryDateMax] Optionally provide a maximum delivery date
     * @param {number} [limit] Limit query for pagination. Defaults to 100
     * @param {number} [offset] Offset query for pagination. Defaults to 0.
     * @param {'PENDING_LOCK_DATE' | 'DELIVERY_DATE'} [sortBy] Sort results. Defaults to PENDING_LOCK_DATE. Orders with null in the sort field will come last.
     * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitApi
     */
    public searchBenefits(clientId?: string, benefitStates?: Array<'CREATED' | 'LOCKED' | 'CANCELED' | 'EXPIRED'>, benefitTypes?: Array<'GROCERY'>, groceryBundleTypes?: Array<'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>, pendingLockDateMin?: string, pendingLockDateMax?: string, deliveryDateMin?: string, deliveryDateMax?: string, limit?: number, offset?: number, sortBy?: 'PENDING_LOCK_DATE' | 'DELIVERY_DATE', sortDir?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return BenefitApiFp(this.configuration).searchBenefits(clientId, benefitStates, benefitTypes, groceryBundleTypes, pendingLockDateMin, pendingLockDateMax, deliveryDateMin, deliveryDateMax, limit, offset, sortBy, sortDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the delivery date on a benefit
     * @param {string} id The Benefit ID
     * @param {UpdateBenefitDeliveryDateRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitApi
     */
    public updateBenefitDeliveryDate(id: string, request: UpdateBenefitDeliveryDateRequestDTO, options?: AxiosRequestConfig) {
        return BenefitApiFp(this.configuration).updateBenefitDeliveryDate(id, request, options).then((request) => request(this.axios, this.basePath));
    }
}
