import clsx from 'clsx';

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    label: string | JSX.Element;
    isInvalid?: boolean;
    labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
    id: string;
};

const ChipCheckbox = ({ id, label, labelProps, isInvalid = false, type, className, ...rest }: Props): JSX.Element => (
    <label
        htmlFor={id}
        className={clsx(
            'shadow-a-1 relative cursor-pointer rounded p-3 transition',
            {
                'hover:bg-lowBlue-1 bodySmLight bg-white': !rest.checked && !rest.disabled,
                'bg-mediumBlue-1 bodySmMedium': rest.checked && !rest.disabled,
                'bodySmLight input-disabled bg-white': rest.disabled,
            },
            className
        )}
        {...labelProps}
    >
        {label}
        <input
            id={id}
            data-dd-privacy="mask"
            data-dd-action-name={`${id} input`}
            className={clsx(
                'shadow-a-1 visible absolute left-0 top-0 z-50 rounded opacity-0 transition',
                {
                    'hover:bg-lowBlue-1 bodySmLight bg-white': !rest.checked && !rest.disabled,
                    'bg-mediumBlue-1 bodySmMedium': rest.checked && !rest.disabled,
                },
                className
            )}
            type="checkbox"
            onChange={rest.onChange}
            {...rest}
        />
    </label>
);

export default ChipCheckbox;
