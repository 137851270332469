import type { PreferenceItemDTO, UserDetailsDTO } from 'API';
import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { userDetailsRefresh } from 'store/user/actions';
import { selectUserData } from 'store/user/selectors';
import { USER_PREFERENCE_KEYS } from 'utils/constants/preferences';
import { accountAPI, authAPI } from '../';

export interface SubmitAccountPreferencesArgs {
    accountId: string;
    updatedPreferences: { [name: string]: boolean };
}

export const useUpdateUserPreferences = () => {
    const dispatch = useDispatch();
    const userData = useSelector(selectUserData);
    const currentPreferences = Object.fromEntries(userData.preferences?.map(({ name, value }) => [name, value]) ?? []);

    return useMutation<PreferenceItemDTO[], Error, SubmitAccountPreferencesArgs>(
        async ({ accountId, updatedPreferences }: SubmitAccountPreferencesArgs) => {
            const preferences: PreferenceItemDTO[] = Object.entries({
                ...currentPreferences,
                ...updatedPreferences,
            }).map(([name, value]) => ({ name, value }));

            await accountAPI.updatePreferences(accountId, preferences);
            return preferences;
        },
        {
            onSuccess: (preferences: PreferenceItemDTO[]) => {
                dispatch(userDetailsRefresh({ ...userData, preferences }));
            },
        }
    );
};

export const useSetUserHasLoggedInToMobileAppAccountPreference = () => {
    const { mutate } = useUpdateUserPreferences();

    const updatedPreferences = {
        [USER_PREFERENCE_KEYS.hasLoggedIntoMobileApp]: true,
    };

    return (accountId: string) => mutate({ accountId: accountId, updatedPreferences });
};

export const userDetailsQueryKey = 'getUserDetails';

export const useGetUserDetails = (options?: Omit<UseQueryOptions<UserDetailsDTO>, 'queryFn' | 'queryKey'>) => {
    const dispatch = useDispatch();

    return useQuery(
        [userDetailsQueryKey],
        () =>
            authAPI.getUserDetails().then((res) => {
                dispatch(userDetailsRefresh(res.data));
                return res.data;
            }),
        options
    );
};
