/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum GroceryListStateDTO {
    CREATED = 'CREATED',
    DEACTIVATED = 'DEACTIVATED',
    OFFLINE_SHOPPING_INITIATED = 'OFFLINE_SHOPPING_INITIATED',
    ORDER_SHOPPED_OFFLINE = 'ORDER_SHOPPED_OFFLINE',
    ORDER_PLACED = 'ORDER_PLACED',
    ORDER_REQUESTED = 'ORDER_REQUESTED',
    PRODUCTS_FOUND = 'PRODUCTS_FOUND',
    PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED = 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED',
    ORDER_CLAIMED = 'ORDER_CLAIMED',
    ORDER_RELEASED = 'ORDER_RELEASED',
    ORDER_SENT_TO_VENDOR = 'ORDER_SENT_TO_VENDOR',
    ORDER_SHOPPING_NOW = 'ORDER_SHOPPING_NOW',
    ORDER_SHOPPED = 'ORDER_SHOPPED',
    ORDER_DELIVERED = 'ORDER_DELIVERED',
    ORDER_CANCELED = 'ORDER_CANCELED'
};

