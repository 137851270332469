/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum IntakeFormPronounsDTO {
    HE_HIM_HIS = 'HE_HIM_HIS',
    SHE_HER_HERS = 'SHE_HER_HERS',
    THEY_THEM_THEIRS = 'THEY_THEM_THEIRS',
    OTHER_PREFER_NOT_TO_SAY = 'OTHER_PREFER_NOT_TO_SAY'
};

