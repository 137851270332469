/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ClientExternalIdTypeDTO {
    AWS_CHIME_USER_ID = 'AWS_CHIME_USER_ID',
    DATA_SERVICE_SCRAPE_ADDRESS_ID = 'DATA_SERVICE_SCRAPE_ADDRESS_ID',
    EXTERNAL_PRACTICE_ID = 'EXTERNAL_PRACTICE_ID',
    KLAVIYO_PERSON_ID = 'KLAVIYO_PERSON_ID',
    STRIPE_CUSTOMER_ID = 'STRIPE_CUSTOMER_ID',
    HEALTHIE_USER_ID = 'HEALTHIE_USER_ID',
    HEALTHIE_USER_API_KEY = 'HEALTHIE_USER_API_KEY',
    SENDBIRD_USER_ID = 'SENDBIRD_USER_ID',
    ZUS_PATIENT_ID = 'ZUS_PATIENT_ID',
    ZUS_PATIENT_HISTORY_JOB_ID = 'ZUS_PATIENT_HISTORY_JOB_ID',
    ZUS_SUBSCRIPTION_PACKAGE_ID = 'ZUS_SUBSCRIPTION_PACKAGE_ID'
};

