/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AutocompleteAddressDetailsResponseDTO } from '../models';
// @ts-ignore
import { AutocompleteAddressPredictionsResponseDTO } from '../models';
// @ts-ignore
import { AutocompleteMedicationPredictionsResponseDTO } from '../models';
// @ts-ignore
import { AutocompleteStateFromPostalCodeResponseDTO } from '../models';
/**
 * AutocompleteApi - axios parameter creator
 * @export
 */
export const AutocompleteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get address details
         * @param {string} addressId 
         * @param {string} [sessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressDetails: async (addressId: string, sessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('getAddressDetails', 'addressId', addressId)
            const localVarPath = `/autocomplete/address-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (addressId !== undefined) {
                localVarQueryParameter['addressId'] = addressId;
            }

            if (sessionToken !== undefined) {
                localVarQueryParameter['sessionToken'] = sessionToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get autocomplete address predictions
         * @param {string} input 
         * @param {string} [sessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressPredictions: async (input: string, sessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'input' is not null or undefined
            assertParamExists('getAddressPredictions', 'input', input)
            const localVarPath = `/autocomplete/predictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (input !== undefined) {
                localVarQueryParameter['input'] = input;
            }

            if (sessionToken !== undefined) {
                localVarQueryParameter['sessionToken'] = sessionToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get medications predictions
         * @param {string} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicationPredictions: async (input: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'input' is not null or undefined
            assertParamExists('getMedicationPredictions', 'input', input)
            const localVarPath = `/autocomplete/medication-predictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (input !== undefined) {
                localVarQueryParameter['input'] = input;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get state from postal code
         * @param {string} postalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateFromPostalCode: async (postalCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postalCode' is not null or undefined
            assertParamExists('getStateFromPostalCode', 'postalCode', postalCode)
            const localVarPath = `/autocomplete/state-from-postal-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutocompleteApi - functional programming interface
 * @export
 */
export const AutocompleteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AutocompleteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get address details
         * @param {string} addressId 
         * @param {string} [sessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressDetails(addressId: string, sessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompleteAddressDetailsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressDetails(addressId, sessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get autocomplete address predictions
         * @param {string} input 
         * @param {string} [sessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressPredictions(input: string, sessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompleteAddressPredictionsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressPredictions(input, sessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get medications predictions
         * @param {string} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicationPredictions(input: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompleteMedicationPredictionsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicationPredictions(input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get state from postal code
         * @param {string} postalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStateFromPostalCode(postalCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompleteStateFromPostalCodeResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStateFromPostalCode(postalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AutocompleteApi - factory interface
 * @export
 */
export const AutocompleteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AutocompleteApiFp(configuration)
    return {
        /**
         * 
         * @summary Get address details
         * @param {string} addressId 
         * @param {string} [sessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressDetails(addressId: string, sessionToken?: string, options?: any): AxiosPromise<AutocompleteAddressDetailsResponseDTO> {
            return localVarFp.getAddressDetails(addressId, sessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get autocomplete address predictions
         * @param {string} input 
         * @param {string} [sessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressPredictions(input: string, sessionToken?: string, options?: any): AxiosPromise<AutocompleteAddressPredictionsResponseDTO> {
            return localVarFp.getAddressPredictions(input, sessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get medications predictions
         * @param {string} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicationPredictions(input: string, options?: any): AxiosPromise<AutocompleteMedicationPredictionsResponseDTO> {
            return localVarFp.getMedicationPredictions(input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get state from postal code
         * @param {string} postalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateFromPostalCode(postalCode: string, options?: any): AxiosPromise<AutocompleteStateFromPostalCodeResponseDTO> {
            return localVarFp.getStateFromPostalCode(postalCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutocompleteApi - object-oriented interface
 * @export
 * @class AutocompleteApi
 * @extends {BaseAPI}
 */
export class AutocompleteApi extends BaseAPI {
    /**
     * 
     * @summary Get address details
     * @param {string} addressId 
     * @param {string} [sessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutocompleteApi
     */
    public getAddressDetails(addressId: string, sessionToken?: string, options?: AxiosRequestConfig) {
        return AutocompleteApiFp(this.configuration).getAddressDetails(addressId, sessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get autocomplete address predictions
     * @param {string} input 
     * @param {string} [sessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutocompleteApi
     */
    public getAddressPredictions(input: string, sessionToken?: string, options?: AxiosRequestConfig) {
        return AutocompleteApiFp(this.configuration).getAddressPredictions(input, sessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get medications predictions
     * @param {string} input 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutocompleteApi
     */
    public getMedicationPredictions(input: string, options?: AxiosRequestConfig) {
        return AutocompleteApiFp(this.configuration).getMedicationPredictions(input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get state from postal code
     * @param {string} postalCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutocompleteApi
     */
    public getStateFromPostalCode(postalCode: string, options?: AxiosRequestConfig) {
        return AutocompleteApiFp(this.configuration).getStateFromPostalCode(postalCode, options).then((request) => request(this.axios, this.basePath));
    }
}
