import type { UserDetailsDTO } from 'API';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import type { ApplicationState } from 'store';
import { loginRefresh as loginRefreshAction } from 'store/user/actions';
import { REFRESH_TOKEN_ACCESSOR } from 'utils';

interface PropsFromState {
    data: UserDetailsDTO;
    isSignedIn: boolean;
}
interface PropsFromDispatch {
    loginRefresh: typeof loginRefreshAction;
}

interface OwnProps {
    children: JSX.Element;
}

type AllProps = PropsFromDispatch & PropsFromState & OwnProps;

const RefreshLogin = ({ isSignedIn, loginRefresh, children }: AllProps): JSX.Element => {
    useEffect(() => {
        const getStoredRefreshToken = localStorage.getItem(REFRESH_TOKEN_ACCESSOR);
        if (!isSignedIn && getStoredRefreshToken) {
            loginRefresh();
        }
    }, [isSignedIn, loginRefresh]);

    return <>{children}</>;
};

const mapStateToProps = ({
    user,
}: ApplicationState): {
    data: UserDetailsDTO;
    isSignedIn: boolean;
} => ({
    data: user.data,
    isSignedIn: user.isSignedIn,
});

const mapDispatchToProps = {
    loginRefresh: loginRefreshAction,
};

const ConnectedRefreshLogin = connect(mapStateToProps, mapDispatchToProps)(RefreshLogin);

export default ConnectedRefreshLogin;
