// There are a small number of exceptions, we define them clearly here
// Ideally we'd just use NodeJS.ProcessEnv, but it allows arbitrary string keys as well as these
type ProvidedEnv = {
    // Provided by NodeJS
    readonly NODE_ENV: 'development' | 'production' | 'test';
    // Provided by create-react-app
    readonly PUBLIC_URL: string;
};

// All custom properties defined by season must be pefixed with REACT_APP_ to make it into the build
type SeasonCustomEnv = {
    readonly [K in `REACT_APP_${string}`]: string | undefined;
};

const checkedEnv = process.env as ProvidedEnv & SeasonCustomEnv;

export const NODE_ENV = checkedEnv.NODE_ENV;
export const PUBLIC_URL = checkedEnv.PUBLIC_URL;

export const SEASON_API_BASE_URL = checkedEnv.REACT_APP_SEASON_API_BASE_URL || 'https://api.seasonhealth.com';
// If a SEASON_EXTERNAL_API_BASE_URL was provided, use it; if not fall back to the standard SEASON_API_BASE_URL
export const SEASON_EXTERNAL_API_BASE_URL = checkedEnv.REACT_APP_SEASON_EXTERNAL_API_BASE_URL || SEASON_API_BASE_URL;
export const ASSETS_URL = checkedEnv.REACT_APP_ASSETS_URL || 'https://assets.seasonhealth.com';
export const MARKETING_PAGE_URL = 'https://seasonhealth.com';
export const APP_STORE_PAGE = 'https://seasonhealth.com/app';
export const SHARE_RECIPE_BASE_URL = checkedEnv.REACT_APP_RECIPE_SHARE_BASE_URL || 'https://share.seasonhealth.com/r';

export const SEASON_APP_VERSION = checkedEnv.REACT_APP_SEASON_APP_VERSION || new Date().getTime().toString();

export const APP_BUILD_DATE = Number(checkedEnv.REACT_APP_BUILD_DATE) || new Date().getTime();

export const USE_LOCAL_BUILD_DATE = checkedEnv.REACT_APP_USE_LOCAL_BUILD_DATE === '1';

export const REFRESH_TOKEN_ACCESSOR = `refreshToken_${new URL(SEASON_API_BASE_URL).host}`;

export const GOOGLE_CLIENT_ID = checkedEnv.REACT_APP_GOOGLE_CLIENT_ID || '';

export const ENABLE_CONSOLE_LOGGING = checkedEnv.REACT_APP_ENABLE_CONSOLE_LOGGING === '1';
export const ENABLE_NATIVE_APP_LOG_FORWARDING = checkedEnv.REACT_APP_ENABLE_NATIVE_APP_LOG_FORWARDING === '1';

export const DATADOG_CLIENT_TOKEN = checkedEnv.REACT_APP_DATADOG_CLIENT_TOKEN || '';
export const DATADOG_LOGGER_ENV = checkedEnv.REACT_APP_DATADOG_LOGGER_ENV || '';
export const DATADOG_RUM_APPLICATION_ID = checkedEnv.REACT_APP_DATADOG_RUM_APPLICATION_ID;
export const DATADOG_RUM_CLIENT_TOKEN = checkedEnv.REACT_APP_DATADOG_RUM_CLIENT_TOKEN;
export const ENABLE_DD_SESSION_REPLAY = checkedEnv.REACT_APP_ENABLE_DD_SESSION_REPLAY === '1';

export const LOGGER_LEVEL =
    (checkedEnv.REACT_APP_LOGGER_LEVEL as 'debug' | 'warning' | 'error' | 'info' | 'all') || 'info';

export const ENABLE_LAUNCHDARKLY = checkedEnv.REACT_APP_ENABLE_LAUNCHDARKLY === '1';
export const LAUNCHDARKLY_CLIENT_ID = checkedEnv.REACT_APP_LAUNCHDARKLY_CLIENT_ID || '';

export const OLD_JOTFORM_BASE_HRQOL_URL = checkedEnv.REACT_APP_OLD_JOTFORM_BASE_HRQOL_URL;
export const JOTFORM_BASE_HRQOL_URL =
    checkedEnv.REACT_APP_JOTFORM_BASE_HRQOL_URL || 'https://form.jotform.com/230376734547058';

export const HEALTHIE_BASE_URL = checkedEnv.REACT_APP_HEALTHIE_BASE_URL || 'https://securestaging.gethealthie.com';

export const ZUS_ENV = checkedEnv.REACT_APP_ZUS_ENV || 'sandbox';
export const ZUS_BASE_URL = checkedEnv.REACT_APP_ZUS_BASE_URL || 'https://app.sandbox.zushealth.com/patients';
export const ZUS_IFRAME_URL = checkedEnv.REACT_APP_ZUS_IFRAME_URL || 'https://zap.sandbox.zushealth.com/v1';

export const JOTFORM_BCBS_AZ_END_OF_PILOT_SURVEY_URL =
    checkedEnv.REACT_APP_JOTFORM_BCBS_AZ_END_OF_PILOT_SURVEY_URL || 'https://form.jotform.com/232354188588165';

export const ALGOLIA_TEST_INDEX_NAME = 'test_recipe_index';
export const ALGOLIA_APP_ID = checkedEnv.REACT_APP_ALGOLIA_APP_ID || 'testingXE66OGS235';
export const ALGOLIA_INDEX_NAME = checkedEnv.REACT_APP_ALGOLIA_INDEX_NAME || 'stage_recipe_index';
// This is the public API key for frontend use. This key is only usable for search queries and sending data to the Insights API.
export const ALGOLIA_API_KEY_SEARCH_ONLY =
    checkedEnv.REACT_APP_ALGOLIA_API_KEY_SEARCH_ONLY || '636d706bd600432e356edce23aca342b';

export const METABASE_RESULTS_URL =
    checkedEnv.REACT_APP_METABASE_RESULTS_BASE_URL ||
    'https://metabase.seasonhealth.com/question/1400-hrqol-survey-results-filtered-by-user-id-and-subtitle';

export const APPLE_SIGN_ON_CLIENT_ID = checkedEnv.REACT_APP_APPLE_SIGN_ON_CLIENT_ID || 'com.seasonhealth.app.sso';
// this just needs to be on the same domain as the app itself is running and they are managed here
// https://developer.apple.com/account/resources/identifiers/list/serviceId
// note that they must not end in a backslash
export const APPLE_SIGN_ON_REDIRECT_URL =
    checkedEnv.REACT_APP_APPLE_SIGN_ON_REDIRECT_URL || 'https://app.seasonhealth.com';

export const RETOOL_BASE_URL = checkedEnv.REACT_APP_RETOOL_BASE_URL || '';

export const STRIPE_KEY = checkedEnv.REACT_APP_STRIPE_KEY || '';

export const ENABLE_OPT_IN_MONSTER = checkedEnv.REACT_APP_ENABLE_OPT_IN_MONSTER === '1';
