/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum UpdateClientDeliveryDetailsResultDTO {
    SUCCESSFUL_UPDATE_NO_CART_UPDATE = 'SUCCESSFUL_UPDATE_NO_CART_UPDATE',
    SUCCESSFUL_UPDATE_CART_UPDATED = 'SUCCESSFUL_UPDATE_CART_UPDATED',
    DELIVERY_DETAILS_NOT_UPDATED_CART_NEEDS_UPDATE = 'DELIVERY_DETAILS_NOT_UPDATED_CART_NEEDS_UPDATE',
    DELIVERY_DETAILS_NOT_UPDATED_CART_LINE_ITEMS_NEED_UPDATE = 'DELIVERY_DETAILS_NOT_UPDATED_CART_LINE_ITEMS_NEED_UPDATE',
    DELIVERY_DETAILS_NOT_UPDATED_CART_ESTIMATED_DELIVERY_OR_COST_DETAILS_NEED_UPDATE = 'DELIVERY_DETAILS_NOT_UPDATED_CART_ESTIMATED_DELIVERY_OR_COST_DETAILS_NEED_UPDATE'
};

