import checkboxSelectedIcon from 'assets/icons/stroke/checkboxSelected.svg';
import checkboxIcon from 'assets/icons/stroke/checkboxUnselected.svg';
import clsx from 'clsx';

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    label: string | JSX.Element;
    isInvalid?: boolean;
    labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
    id: string;
};

const FancyCheckbox = ({ id, label, labelProps, isInvalid = false, type, className, ...rest }: Props): JSX.Element => (
    <label
        htmlFor={id}
        className={clsx(
            'hover:shadow-selector flex grow basis-0 cursor-pointer flex-row items-center gap-4 rounded-md p-3.5',
            !!rest?.checked ? 'shadow-pill bg-mediumBlue-1 bodyMdMedium' : 'shadow-a-1 bodyMdLight bg-white',
            className
        )}
        {...labelProps}
    >
        <input
            type={type}
            data-dd-privacy="mask"
            data-dd-action-name={`${id} input`}
            id={id}
            className={clsx(
                'md:hover:drop-shadow-input focus:checked:drop-shadow-input h-4 w-4 flex-none cursor-pointer appearance-none rounded-none bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200',
                isInvalid && 'border-alert-red2'
            )}
            style={{
                backgroundImage: !!rest?.checked ? `url(${checkboxSelectedIcon})` : `url(${checkboxIcon})`,
            }}
            {...rest}
        />
        {label}
    </label>
);

export default FancyCheckbox;
