/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateWalletRequestDTO } from '../models';
// @ts-ignore
import { DepositWalletFundsRequestDTO } from '../models';
// @ts-ignore
import { GetWalletsResponseDTO } from '../models';
// @ts-ignore
import { WalletResponseDTO } from '../models';
/**
 * WalletApi - axios parameter creator
 * @export
 */
export const WalletApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create wallet
         * @param {CreateWalletRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWallet: async (request: CreateWalletRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createWallet', 'request', request)
            const localVarPath = `/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate a Wallet
         * @param {string} id The Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateWallet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateWallet', 'id', id)
            const localVarPath = `/wallets/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deposit funds into a Wallet
         * @param {string} id The Wallet ID
         * @param {DepositWalletFundsRequestDTO} request Request for funds to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        depositWalletFunds: async (id: string, request: DepositWalletFundsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('depositWalletFunds', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('depositWalletFunds', 'request', request)
            const localVarPath = `/wallets/{id}/deposit-funds`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Wallet by ID
         * @param {string} id The Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWallet', 'id', id)
            const localVarPath = `/wallets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get wallets
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallets: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getWallets', 'clientId', clientId)
            const localVarPath = `/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletApi - functional programming interface
 * @export
 */
export const WalletApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create wallet
         * @param {CreateWalletRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWallet(request: CreateWalletRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWallet(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate a Wallet
         * @param {string} id The Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateWallet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateWallet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deposit funds into a Wallet
         * @param {string} id The Wallet ID
         * @param {DepositWalletFundsRequestDTO} request Request for funds to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async depositWalletFunds(id: string, request: DepositWalletFundsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.depositWalletFunds(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Wallet by ID
         * @param {string} id The Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWallet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWallet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get wallets
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWallets(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWalletsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWallets(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WalletApi - factory interface
 * @export
 */
export const WalletApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletApiFp(configuration)
    return {
        /**
         * 
         * @summary Create wallet
         * @param {CreateWalletRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWallet(request: CreateWalletRequestDTO, options?: any): AxiosPromise<WalletResponseDTO> {
            return localVarFp.createWallet(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate a Wallet
         * @param {string} id The Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateWallet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateWallet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deposit funds into a Wallet
         * @param {string} id The Wallet ID
         * @param {DepositWalletFundsRequestDTO} request Request for funds to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        depositWalletFunds(id: string, request: DepositWalletFundsRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.depositWalletFunds(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Wallet by ID
         * @param {string} id The Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet(id: string, options?: any): AxiosPromise<WalletResponseDTO> {
            return localVarFp.getWallet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get wallets
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallets(clientId: string, options?: any): AxiosPromise<GetWalletsResponseDTO> {
            return localVarFp.getWallets(clientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletApi - object-oriented interface
 * @export
 * @class WalletApi
 * @extends {BaseAPI}
 */
export class WalletApi extends BaseAPI {
    /**
     * 
     * @summary Create wallet
     * @param {CreateWalletRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public createWallet(request: CreateWalletRequestDTO, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).createWallet(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate a Wallet
     * @param {string} id The Wallet ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public deactivateWallet(id: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).deactivateWallet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deposit funds into a Wallet
     * @param {string} id The Wallet ID
     * @param {DepositWalletFundsRequestDTO} request Request for funds to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public depositWalletFunds(id: string, request: DepositWalletFundsRequestDTO, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).depositWalletFunds(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Wallet by ID
     * @param {string} id The Wallet ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getWallet(id: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getWallet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get wallets
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getWallets(clientId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getWallets(clientId, options).then((request) => request(this.axios, this.basePath));
    }
}
