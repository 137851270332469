/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum MostRecentA1cDTO {
    LESS_THAN_7 = 'LESS_THAN_7',
    GREATER_THAN_OR_EQUAL_TO_7 = 'GREATER_THAN_OR_EQUAL_TO_7',
    UNKNOWN = 'UNKNOWN'
};

