import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Spinner } from 'galley';
import type { VariantProps } from './StepInput';
import { MinusIcon, onClickHandler, PlusIcon, TrashIcon } from './StepInput';

export const PrimaryStepInput = ({
    onIncrement,
    onDecrement,
    value,
    formatValue,
    isDisabled = false,
    isLoading = false,
    className,
    maxValue,
    minValue = 1,
}: VariantProps): JSX.Element => {
    const allowDecrement = value > 0;
    if (value === 0) {
        return (
            <button
                aria-label={t`increment`}
                onClick={onClickHandler(onIncrement)}
                disabled={isDisabled}
                className={clsx(
                    'bodyMdMedium bg-highBlue-1 border-highBlue-1 md:hover:bg-highBlue-2 active:bg-highBlue-2  focus:border-highBlue-3 shadow-button box-border flex h-[42px] w-full items-center justify-center rounded-full border-2 text-white',
                    className
                )}
            >
                {isLoading ? (
                    <Spinner color="secondary" className="!block" />
                ) : (
                    <span className="bodyMdMedium">{t`Add to Cart`}</span>
                )}
            </button>
        );
    }

    return (
        <div
            data-dd-privacy="allow"
            className={clsx(
                'rounded-round flex h-[42px] flex-row items-center justify-center gap-2 border transition-all duration-500',
                'text-white',
                value === 0 && 'bg-highBlue-1 border-highBlue-1 w-full',
                value > 0 && 'bg-highBlue-3 border-highBlue-3 w-full',
                className
            )}
        >
            <Button
                className="md:hover:bg-highBlue-2 active:bg-highBlue-2 rounded-l-round active:border-highBlue-2 group h-10 flex-none p-3"
                onClick={onClickHandler(onDecrement)}
                disabled={isDisabled || !allowDecrement}
            >
                {value > minValue ? <MinusIcon /> : value === minValue ? <TrashIcon /> : <div className="mdIcon" />}
            </Button>
            <div className="flex w-7 flex-1 items-center justify-center text-center">
                {isLoading ? (
                    <Spinner color="secondary" />
                ) : (
                    <span className="bodyMdMedium">{formatValue ? formatValue(value) : value}</span>
                )}
            </div>
            <Button
                className={clsx('rounded-r-round group h-10 flex-none border border-none p-3', {
                    'md:hover:bg-highBlue-2 active:bg-highBlue-2': !(maxValue && value >= maxValue),
                })}
                onClick={onClickHandler(onIncrement)}
                disabled={isDisabled || !!(maxValue && value >= maxValue)}
            >
                {!!(maxValue && value >= maxValue) ? <div className="mdIcon"></div> : <PlusIcon />}
            </Button>
        </div>
    );
};
