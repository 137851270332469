/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum CohortDTO {
    BCBS_AZ = 'BCBS_AZ',
    BCBS_AZ_GROUP_50 = 'BCBS_AZ_GROUP_50',
    COMMON_SPIRIT = 'COMMON_SPIRIT',
    CUSTOM_MADE = 'CUSTOM_MADE',
    DEMO = 'DEMO',
    EVRY = 'EVRY',
    FROEDTERT = 'FROEDTERT',
    GA_FOODS = 'GA_FOODS',
    GEISINGER = 'GEISINGER',
    HEALTH_ALIGN = 'HEALTH_ALIGN',
    MEDICAL_ASSOCIATES = 'MEDICAL_ASSOCIATES',
    OPTIM = 'OPTIM',
    PUBLIC = 'PUBLIC',
    SEASON_EMPLOYEE = 'SEASON_EMPLOYEE',
    STEWARD_HYPERTENSION = 'STEWARD_HYPERTENSION',
    STEWARD_FLEX_SERVICES = 'STEWARD_FLEX_SERVICES',
    UNITED_HEALTHCARE = 'UNITED_HEALTHCARE',
    UT_SOUTHWESTERN = 'UT_SOUTHWESTERN'
};

