import type { Reducer } from 'redux';
import type { RateState } from './types';
import { RateActionTypes } from './types';
import type { RateActions } from './actions';
import type { MetaActions } from '../meta/store';

export const initialRateState: RateState = {
    orderId: undefined,
    preparedMealId: undefined,
    recipeMealId: undefined,
    name: undefined,
    image: undefined,
    mostRecentFeedback: undefined,
};

const reducer: Reducer<RateState, RateActions | MetaActions> = (state = initialRateState, action) => {
    switch (action.type) {
        case RateActionTypes.LAUNCH_RATE_PREPARED_MEAL: {
            return {
                ...state,
                preparedMealId: action.payload.preparedMealId,
                name: action.payload.name,
                image: action.payload.image,
                orderId: action.payload.orderId,
                mostRecentFeedback: action.payload.mostRecentFeedback,
            };
        }
        case RateActionTypes.LAUNCH_RATE_MEAL: {
            return {
                ...state,
                recipeMealId: action.payload.recipeMealId,
                name: action.payload.name,
                image: action.payload.image,
                mostRecentFeedback: action.payload.mostRecentFeedback,
            };
        }
        case RateActionTypes.CLOSE_PROMPT: {
            return { ...initialRateState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as rateReducer };
