import type { PageDTO } from 'API';
import range from 'lodash/range';

const PAGINATION_PADDING = 5;

// Calculates the page window as a function of the current page, so that the pagination component knows
// how many pages to render and when to truncate/add an ellipsis. The padding represents the number of
// elements to display to the left and right of the current page.
export const calcPageWindow = ({ page, pageCount }: PageDTO) => {
    const zeroIndexedPage = page - 1;
    const totalDisplayedPages = Math.min(PAGINATION_PADDING * 2 + 1, pageCount);

    if (zeroIndexedPage < PAGINATION_PADDING) {
        return range(0, totalDisplayedPages);
    } else if (zeroIndexedPage >= pageCount - PAGINATION_PADDING) {
        return range(pageCount - totalDisplayedPages, pageCount);
    } else {
        return range(zeroIndexedPage - PAGINATION_PADDING, zeroIndexedPage + PAGINATION_PADDING + 1);
    }
};
