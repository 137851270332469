import type { MealFeedbackDTO } from 'API';

export enum RateActionTypes {
    LAUNCH_RATE_PREPARED_MEAL = '@@alert/LAUNCH_RATE_PREPARED_MEAL',
    LAUNCH_RATE_MEAL = '@@alert/LAUNCH_RATE_MEAL',
    CLOSE_PROMPT = '@@alert/CLOSE_PROMPT',
}

export interface RateState {
    readonly orderId?: string;
    readonly preparedMealId?: string;
    readonly recipeMealId?: string;
    readonly name?: string;
    readonly image?: string;
    readonly mostRecentFeedback?: Array<MealFeedbackDTO>;
}
