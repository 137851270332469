import { Trans } from '@lingui/macro';
import { useLocation } from 'react-use';
import { logger } from 'utils';

const OpenNativeLink = (): JSX.Element => {
    const location = useLocation();
    const link = new URLSearchParams(location.search).get('link');

    logger.debug('link', { link });
    window.location.href = link ? encodeURI(link) : '/';

    return (
        <div className="flex h-screen text-center">
            <p className="bodyMdLight m-auto items-center px-3">
                <Trans>Close this browser window to return to the Season app.</Trans>
            </p>
        </div>
    );
};

export default OpenNativeLink;
