import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { useFlags } from 'utils';

export type Props = React.PropsWithChildren<{
    title?: string;
    flag?: string;
    image?: string;
    description?: string;
    headTags?: JSX.Element;
}>;

const WithHelmet = ({ children, headTags, title, flag, image, description }: Props): JSX.Element => {
    const allFlags = useFlags();

    if (flag && !allFlags[flag]) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                {headTags}
            </Helmet>
            {children && children}
        </>
    );
};

export default WithHelmet;
