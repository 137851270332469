import { EmailValidator } from 'commons-validator-js';
import { differenceInYears, isPast, isValid, parse } from 'date-fns';
import { isValidPhoneNumber } from 'libphonenumber-js';

const validator = new EmailValidator();
export const validateEmail = (email: string): boolean => {
    return validator.isValid(email);
};

export const validatePhoneNumber = (phone: string): boolean => {
    return isValidPhoneNumber(phone || '', 'US');
};

/** 
Returns if password is valid. Valid passwords are 
between 8 and 36 characters long, do not contain whitespace,
 and contain at least 1 lowercase, at least 1 uppercase, 
at least 1 numeric, and at least 1 special character.
**/
export const isPasswordValid = (password: string): boolean => {
    return (
        password.length >= 8 &&
        password.length <= 36 &&
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\]\\^_`{|}~])/.test(password) &&
        !/\s/gm.test(password)
    );
};

export const isDateStringValidFormat = (dob: string): boolean => {
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

    if (!dateRegex.test(dob)) return false;

    const Dob = parse(dob, 'MM/dd/yyyy', new Date());
    return isValid(Dob);
};

export const isDateInPast = (dob: string, type?: 'ISO' | 'US'): boolean => {
    const Dob = parse(dob, type === 'ISO' ? 'yyyy-MM-dd' : 'MM/dd/yyyy', new Date());
    return isPast(Dob);
};
export const MINIMUM_AGE_IN_YEARS = 18;
export const isAgeVerified = (dob: string, type?: 'ISO' | 'US'): boolean => {
    const Dob = parse(dob, type === 'ISO' ? 'yyyy-MM-dd' : 'MM/dd/yyyy', new Date());
    return differenceInYears(new Date(), Dob) >= MINIMUM_AGE_IN_YEARS;
};

export const ZIP_CODE_REGEX = /^\d{5}(-\d{4})?$/;

export const isZipCodeValid = (zipCode: string): boolean => {
    return ZIP_CODE_REGEX.test(zipCode);
};
