import { t } from '@lingui/macro';

export enum PatientBannerMessageType {
    WEEKLY_PLAN_ENCOURAGEMENT_1,
    WEEKLY_PLAN_ENCOURAGEMENT_2,
    WEEKLY_PLAN_ENCOURAGEMENT_3,
    WEEKLY_PLAN_ENCOURAGEMENT_4,
    WEEKLY_PLAN_ENCOURAGEMENT_5,
    WEEKLY_PLAN_ENCOURAGEMENT_6,
}

export const getPatientBannerMessage = (patientBannerMessageType: PatientBannerMessageType): string => {
    switch (patientBannerMessageType) {
        case PatientBannerMessageType.WEEKLY_PLAN_ENCOURAGEMENT_1:
            return t`Great choice! By planning your meals, you're setting yourself up for a week of healthy eating. Keep up the great work.`;
        case PatientBannerMessageType.WEEKLY_PLAN_ENCOURAGEMENT_2:
            return t`You're on the right track! Ordering recipes in advance makes it easier to eat well by filling your home with nutritious options.  Happy cooking.`;
        case PatientBannerMessageType.WEEKLY_PLAN_ENCOURAGEMENT_3:
            return t`Welcome to your personalized menu! By shopping at Season, you're taking a big step towards a healthier lifestyle. Together, we’ll make this week a nutritious one`;
        case PatientBannerMessageType.WEEKLY_PLAN_ENCOURAGEMENT_4:
            return t`Fantastic- you've taken the first step to a healthier week. By ordering recipes, you're surrounding yourself with smart choices, making it easier to enjoy nutritious food daily.`;
        case PatientBannerMessageType.WEEKLY_PLAN_ENCOURAGEMENT_5:
            return t`Awesome choice! By selecting your recipes for the week, you're ensuring that healthy and delicious meals are always within reach. Here's to a tasty week ahead.`;
        case PatientBannerMessageType.WEEKLY_PLAN_ENCOURAGEMENT_6:
            return t`Well done! Planning ahead with our recipes means you're creating a healthy food environment for yourself and your family. Enjoy the journey to better eating habits.`;
    }
};

export enum PatientInfoMessageType {
    ACCOUNT_ONBARDING_COMPLETED,
    INTAKE_ALREADY_COMPLETED,
    UPDATE_NUTRITION_PRESCRIPTION_INSTRUCTIONS,
    MFA_REFRESH_REQUIRED,
}

export enum PatientErrorMessageType {
    ACCOUNT_ACTIVATION_FAILED,
    ACCOUNT_ALREADY_EXISTS_ON_SIGNUP,
    ALREADY_VALIDATED,
    ALREADY_EXISTS,
    APPOINTMENT_DATE_TIME_UNAVAILABLE,
    COULD_NOT_ADD_PRE_MADE_MEALS_TO_CART,
    COULD_NOT_ADD_RECIPE_TO_CART,
    COULD_NOT_CANCEL_APPOINTMENT,
    COULD_NOT_CANCEL_SUBSCRIPTION,
    COULD_NOT_CANCEL_SUBSCRIPTION_WITHOUT_A_REASON,
    COULD_NOT_CHECKOUT,
    COULD_NOT_CHECKOUT_EMPTY_PRE_MADE_CART,
    COULD_NOT_CHECKOUT_ON_WALMART,
    COULD_NOT_CHECKOUT_PRE_MADE_CART_WITH_INELIGIBLE_QUANTITIES,
    COULD_NOT_CLEAR_CART,
    COULD_NOT_COMPLETE_REGISTRATION,
    COULD_NOT_CREATE_CART,
    COULD_NOT_CREATE_INSURANCE_POLICY,
    COULD_NOT_CREATE_SHOPPING_LIST,
    COULD_NOT_DOWNLOAD_ATTACHMENT,
    COULD_NOT_FAVORITE_RECIPE,
    COULD_NOT_JOIN_APPOINTMENT,
    COULD_NOT_LOAD_ATTACHMENT,
    COULD_NOT_LOAD_BENEFITS,
    COULD_NOT_LOAD_CONVERSATION,
    COULD_NOT_LOAD_MEALS,
    COULD_NOT_LOAD_MEDICATIONS_LIST,
    COULD_NOT_LOAD_ORDER_HISTORY,
    COULD_NOT_LOAD_RECIPE,
    COULD_NOT_LOAD_RECIPES,
    COULD_NOT_LOAD_SHOPPING_LIST,
    COULD_NOT_LOAD_WEIGHT_TRACKING_LOG,
    COULD_NOT_REMOVE_INSURANCE_POLICY,
    COULD_NOT_REMOVE_RECIPE_FROM_CART,
    COULD_NOT_REMOVE_WEIGHT_TRACKING_ENTRY,
    COULD_NOT_RESEND_VERIFICATION_EMAIL,
    COULD_NOT_SAVE_INTAKE,
    COULD_NOT_SAVE_PREFERRED_DELIVERY_WINDOW,
    COULD_NOT_SCHEDULE_APPOINTMENT,
    COULD_NOT_SCHEDULE_APPOINTMENT_TIMESLOT_RESERVED,
    COULD_NOT_SEND_MESSAGE,
    COULD_NOT_SHOW_ALTERNATE_CHECKOUT_OPTIONS,
    COULD_NOT_TOGGLE_WEIGHT_TRACKING,
    COULD_NOT_UPDATE_DELIVERY_DETAILS,
    COULD_NOT_UPDATE_GROCERY_LIST,
    COULD_NOT_UPDATE_HEALTH_GOAL,
    COULD_NOT_UPDATE_INSURANCE_POLICY,
    COULD_NOT_UPDATE_NOTIFICATION_SETTINGS,
    COULD_NOT_UPDATE_NUMBER_OF_SERVINGS_OF_RECIPE_IN_CART,
    COULD_NOT_UPDATE_PERSONAL_DETAILS,
    COULD_NOT_UPDATE_PREFERRED_VENDOR,
    COULD_NOT_UPDATE_PROFILE,
    COULD_NOT_UPDATE_PROGRESS,
    COULD_NOT_UPDATE_TIP,
    COULD_NOT_UPDATE_WEIGHT_TRACKING_ENTRY,
    COULD_NOT_UPDATE_YOUR_FEEDBACK,
    COULD_NOT_UPDATE_YOUR_MEAL_PREFERENCES,
    COULD_NOT_VERIFY_EMAIL,
    DEFAULT,
    DEFAULT_COULD_NOT_VALIDATE,
    DEFAULT_RETRYABLE,
    HAS_OUTSTANDING_BALANCE_PLEASE_PROVIDE_A_NEW_PAYMENT_METHOD,
    NO_INITIAL_CONSULTATION_AVAILABILITY,
    SELECT_SUPPORTED_ATTACHMENT_TYPE,
    SELECT_SUPPORTED_IMAGE_TYPE,
    SIGNUP_INVALID_PHONE_NUMBER,
    SSO_VERIFICATION_FAILED,
    TOKEN_EXPIRED,
    UNABLE_TO_MANAGE_APPOINTMENT,
    UPDATE_PASSWORD_CURRENT_PASSWORD_INCORRECT,
}

export const getPatientInfoMessage = (patientInfoMessageType: PatientInfoMessageType): string => {
    switch (patientInfoMessageType) {
        case PatientInfoMessageType.ACCOUNT_ONBARDING_COMPLETED:
            return t`Your details have been saved. If you'd like to update your answers, please visit your Account settings.`;
        case PatientInfoMessageType.INTAKE_ALREADY_COMPLETED:
            return t`Your intake hass already been completed.`;
        case PatientInfoMessageType.MFA_REFRESH_REQUIRED:
            return t`A second step is required before accessing this feature.`;
        case PatientInfoMessageType.UPDATE_NUTRITION_PRESCRIPTION_INSTRUCTIONS:
            return t`To update your nutrition prescription, please visit your account settings.`;
    }
};
/**
 *
 * @param patientErrorMessageType One of the supported types of patient error messages.
 *
 * Please try to use similar language and maintain voice for all user facing errors.
 *
 */
export const getPatientErrorMessage = (
    patientErrorMessageType: PatientErrorMessageType = PatientErrorMessageType.DEFAULT
): string => {
    switch (patientErrorMessageType) {
        case PatientErrorMessageType.ACCOUNT_ACTIVATION_FAILED:
            return t`We could not activate your account. Please try again.`;
        case PatientErrorMessageType.ACCOUNT_ALREADY_EXISTS_ON_SIGNUP:
            return t`Your account already exists. Please sign in or reset your password.`;
        case PatientErrorMessageType.ALREADY_EXISTS:
            return t`Your account already exists. Please sign in or reset your password.`;
        case PatientErrorMessageType.ALREADY_VALIDATED:
            return t`Your account is already verified and is ready to login.`;
        case PatientErrorMessageType.APPOINTMENT_DATE_TIME_UNAVAILABLE:
            return t`The appointment time you selected is no longer available. Please select another.`;
        case PatientErrorMessageType.COULD_NOT_ADD_PRE_MADE_MEALS_TO_CART:
            return t`We could not add pre-made meals to your cart to check out.`;
        case PatientErrorMessageType.COULD_NOT_ADD_RECIPE_TO_CART:
            return t`We could not add that recipe to your cart. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_CANCEL_APPOINTMENT:
            return t`We could not cancel your appointment. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_CANCEL_SUBSCRIPTION:
            return t`We could not cancel your subscription. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_CANCEL_SUBSCRIPTION_WITHOUT_A_REASON:
            return t`Please select at least one reason for canceling your subscription.`;
        case PatientErrorMessageType.COULD_NOT_CHECKOUT:
            return t`We could not check out your order. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_CHECKOUT_EMPTY_PRE_MADE_CART:
            return t`Please add pre-made meals to your cart to check out.`;
        case PatientErrorMessageType.COULD_NOT_CHECKOUT_ON_WALMART:
            return t`We could not check out your order on Walmart. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_CHECKOUT_PRE_MADE_CART_WITH_INELIGIBLE_QUANTITIES:
            return t`Pre-made meals must be purchased in eligible quantities.`;
        case PatientErrorMessageType.COULD_NOT_CLEAR_CART:
            return t`We could not clear your cart. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_COMPLETE_REGISTRATION:
            return t`We could not complete the registration process. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_CREATE_CART:
            return t`We could not create your shopping cart. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_CREATE_INSURANCE_POLICY:
            return t`We could not update your insurance. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_CREATE_SHOPPING_LIST:
            return t`We could not create your shopping list. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_DOWNLOAD_ATTACHMENT:
            return t`We could not download that file. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_FAVORITE_RECIPE:
            return t`We could not favorite this recipe. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_JOIN_APPOINTMENT:
            return t`We could not join the appointment at this time. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_LOAD_ATTACHMENT:
            return t`We could not load that document. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_LOAD_BENEFITS:
            return t`We could not find any benefits.`;
        case PatientErrorMessageType.COULD_NOT_LOAD_CONVERSATION:
            return t`We could not load that conversation. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_LOAD_MEALS:
            return t`We could not load your meals. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_LOAD_MEDICATIONS_LIST:
            return t`We could not find any medications that match. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_LOAD_ORDER_HISTORY:
            return t`We could not load your order history. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_LOAD_RECIPE:
            return t`We could not find that recipe. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_LOAD_RECIPES:
            return t`We could not find those recipes. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_LOAD_SHOPPING_LIST:
            return t`We could not load that shopping list. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_LOAD_WEIGHT_TRACKING_LOG:
            return t`We could not load your previous entries at the moment. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_REMOVE_INSURANCE_POLICY:
            return t`We could not remove your insurance. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_REMOVE_RECIPE_FROM_CART:
            return t`We could not remove that recipe from your cart. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_REMOVE_WEIGHT_TRACKING_ENTRY:
            return t`We could not remove that log entry at the moment. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_RESEND_VERIFICATION_EMAIL:
            return t`We could not resend your verification email. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_SAVE_INTAKE:
            return t`We could not save your intake.`;
        case PatientErrorMessageType.COULD_NOT_SAVE_PREFERRED_DELIVERY_WINDOW:
            return t`We could not save your preferred delivery window details. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_SCHEDULE_APPOINTMENT:
            return t`We could not schedule your appointment at this time. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_SCHEDULE_APPOINTMENT_TIMESLOT_RESERVED:
            return t`We could not schedule your appointment; the time slot you selected has since been reserved. Please select another one.`;
        case PatientErrorMessageType.COULD_NOT_SEND_MESSAGE:
            return t`We could not send that message at the moment. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_SHOW_ALTERNATE_CHECKOUT_OPTIONS:
            return t`We could not show you other checkout options. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_TOGGLE_WEIGHT_TRACKING:
            return t`We could not toggle the weight tracking log at the moment. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_DELIVERY_DETAILS:
            return t`We could not update your delivery details. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_GROCERY_LIST:
            return t`We could not update your grocery list. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_HEALTH_GOAL:
            return t`We could not update your health goal. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_INSURANCE_POLICY:
            return t`We could not update your insurance details. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_NOTIFICATION_SETTINGS:
            return t`We could not update your notification settings. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_NUMBER_OF_SERVINGS_OF_RECIPE_IN_CART:
            return t`We could not reduce the servings of that recipe at this time. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_PERSONAL_DETAILS:
            return t`We could not update your details. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_PREFERRED_VENDOR:
            return t`We could not save your preferred vendor details. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_PROFILE:
            return t`We could not update your profile. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_PROGRESS:
            return t`We could not update your progress.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_TIP:
            return t`We could not update the tip amount. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_WEIGHT_TRACKING_ENTRY:
            return t`We could not save that entry to your tracking log. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_YOUR_FEEDBACK:
            return t`We could not save your feedback. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_UPDATE_YOUR_MEAL_PREFERENCES:
            return t`We could not update your meal preferences. Please try again.`;
        case PatientErrorMessageType.COULD_NOT_VERIFY_EMAIL:
            return t`We could not verify your email. Please try again.`;
        case PatientErrorMessageType.DEFAULT:
            return '';
        case PatientErrorMessageType.DEFAULT_COULD_NOT_VALIDATE:
            return t`We could not verify your identity.`;
        case PatientErrorMessageType.DEFAULT_RETRYABLE:
            return t`An error occurred. Please try again.`;
        case PatientErrorMessageType.HAS_OUTSTANDING_BALANCE_PLEASE_PROVIDE_A_NEW_PAYMENT_METHOD:
            return t`Please provide a new payment method to continue.`;
        case PatientErrorMessageType.NO_INITIAL_CONSULTATION_AVAILABILITY:
            return t`We're working on finding available dietitians. This usually takes between 5 minutes and an hour, but sometimes can take up to 48 hours. Please check back later.`;
        case PatientErrorMessageType.SELECT_SUPPORTED_ATTACHMENT_TYPE:
            return t`Please select a supported image type - JPEG, JPG or PNG.`;
        case PatientErrorMessageType.SELECT_SUPPORTED_IMAGE_TYPE:
            return t`Please select a supported image type - JPEG, JPG or PNG.`;
        case PatientErrorMessageType.SIGNUP_INVALID_PHONE_NUMBER:
            return t`The phone number you entered was invalid. Please correct it and try again.`;
        case PatientErrorMessageType.SSO_VERIFICATION_FAILED:
            return t`We could not verify your account. Please try again or set a password instead.`;
        case PatientErrorMessageType.TOKEN_EXPIRED:
            return t`Your invitation token has expired.`;
        case PatientErrorMessageType.UNABLE_TO_MANAGE_APPOINTMENT:
            return t`Unable to manage this appointment. Please try another appointment.`;
        case PatientErrorMessageType.UPDATE_PASSWORD_CURRENT_PASSWORD_INCORRECT:
            return t`Your current password was incorrect. Please correct it and try again.`;
    }
};

export enum PatientSuccessMessageType {
    ACCOUNT_VERIFIED,
    ALLERGIES_AND_INTOLERANCES_UPDATED,
    APPOINTMENT_CANCELED,
    COOKING_EQUIPMENT_UPDATED,
    DEFAULT,
    DELIVERY_DETAILS_UPDATED,
    DIETARY_RESTRICTIONS_UPDATED,
    INGREDIENT_PREFERENCES_UPDATED,
    INVITE_EMAIL_RESENT,
    PASSWORD_UPDATED,
    PERSONAL_DETAILS_UPDATED,
    REMOVED_INSURANCE_POLICY,
    TOGGLED_WEIGHT_TRACKING_OFF,
    TOGGLED_WEIGHT_TRACKING_ON,
    UPDATED_HEALTH_GOAL,
    UPDATED_INSURANCE_POLICY,
    UPDATED_NOTIFICATION_SETTINGS_APPOINTMENTS,
    UPDATED_NOTIFICATION_SETTINGS_MESSAGING,
    VERIFICATION_EMAIL_SENT,
}
/**
 *
 * @param patientSuccessMessageType One of the supported types of patient success messages.
 *
 * Please try to use similar language and maintain voice for all user facing errors.
 *
 */
export const getPatientSuccessMessage = (
    patientSuccessMessageType: PatientSuccessMessageType = PatientSuccessMessageType.DEFAULT
): string => {
    switch (patientSuccessMessageType) {
        case PatientSuccessMessageType.ACCOUNT_VERIFIED:
            return t`Your account has been verified.`;
        case PatientSuccessMessageType.ALLERGIES_AND_INTOLERANCES_UPDATED:
            return t`Your allergies and intolerances have been updated.`;
        case PatientSuccessMessageType.APPOINTMENT_CANCELED:
            return t`Your appointment has been canceled.`;
        case PatientSuccessMessageType.COOKING_EQUIPMENT_UPDATED:
            return t`Your cooking equipment has been updated.`;
        case PatientSuccessMessageType.DEFAULT:
            return t`Your changes have been updated.`;
        case PatientSuccessMessageType.DELIVERY_DETAILS_UPDATED:
            return t`Your delivery details have been updated.`;
        case PatientSuccessMessageType.DIETARY_RESTRICTIONS_UPDATED:
            return t`Your dietary restrictions have been updated.`;
        case PatientSuccessMessageType.INGREDIENT_PREFERENCES_UPDATED:
            return t`Your ingredient preferences have been updated.`;
        case PatientSuccessMessageType.INVITE_EMAIL_RESENT:
            return t`Your invitation email was resent. Please check your email.`;
        case PatientSuccessMessageType.PASSWORD_UPDATED:
            return t`Your password has been updated.`;
        case PatientSuccessMessageType.PERSONAL_DETAILS_UPDATED:
            return t`Your details have been updated.`;
        case PatientSuccessMessageType.REMOVED_INSURANCE_POLICY:
            return t`Your insurance has been removed.`;
        case PatientSuccessMessageType.TOGGLED_WEIGHT_TRACKING_OFF:
            return t`Your weight tracking log is now disabled.`;
        case PatientSuccessMessageType.TOGGLED_WEIGHT_TRACKING_ON:
            return t`Your weight tracking log is now enabled.`;
        case PatientSuccessMessageType.UPDATED_HEALTH_GOAL:
            return t`Your health goal has been saved!`;
        case PatientSuccessMessageType.UPDATED_INSURANCE_POLICY:
            return t`Your insurance info has been updated.`;
        case PatientSuccessMessageType.UPDATED_NOTIFICATION_SETTINGS_APPOINTMENTS:
            return t`Your notification settings for appointments have been updated.`;
        case PatientSuccessMessageType.UPDATED_NOTIFICATION_SETTINGS_MESSAGING:
            return t`Your notification settings for messaging have been updated.`;
        case PatientSuccessMessageType.VERIFICATION_EMAIL_SENT:
            return t`Your verification email has been sent. Please check your email.`;
    }
};
