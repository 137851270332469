import { Pagination } from 'galley';

import { Trans } from '@lingui/macro';
import type { PageDTO } from 'API';
import clsx from 'clsx';
import { calcPageWindow } from './utils';

export type Props = {
    pageData: PageDTO;
    onSelection(page: number): void;
    className?: string;
    showItemCounts?: boolean;
};

const TruncatedPagination = ({ pageData, onSelection, className, showItemCounts }: Props): JSX.Element => {
    const { page, pageCount, totalCount, pageSize } = pageData;
    return (
        <div className={clsx('flex w-full grow flex-col items-end gap-1', className)}>
            <Pagination
                isFirstPage={page === 1}
                isLastPage={page === pageCount}
                pages={calcPageWindow(pageData)}
                total={pageCount}
                initialPage={page - 1}
                onSelection={onSelection}
            />
            {showItemCounts && (
                <div className="bodySmLight flex flex-row items-end gap-3">
                    <p>
                        <Trans>Total items: {totalCount}</Trans>
                    </p>
                    <p>
                        <Trans>
                            Showing items:{' '}
                            {`${page * pageSize - pageSize + 1} - ${Math.min(page * pageSize, totalCount)}`}
                        </Trans>
                    </p>
                </div>
            )}
        </div>
    );
};

export default TruncatedPagination;
