import type {
    AddClientGoalGroupRequestDTO,
    ClientCancelSubscriptionRequestDTO,
    ClientNotificationPreferencesDTO,
    ClientOutstandingBalancesResponseDTO,
    ClientResponseDTO,
    CommunicationThreadDTO,
    CreateClientWeightEntryRequestDTO,
    ErrorDTO,
    FormSubmissionsResponseDTO,
    GetClientGoalGroupResponseDTO,
    GetClientOrderedRecipesAndPreparedMealsResponseDTO,
    OrderHistoryResponseDTO,
    UpdateClientAvailableCookingEquipmentRequestDTO,
    UpdateClientDeliveryDetailsRequestDTO,
    UpdateClientDeliveryDetailsResponseDTO,
    UpdateClientIngredientPreferencesRequestDTO,
    UpdateClientPersonalInformationRequestDTO,
    UpdateClientProgramStartDateRequestDTO,
    UpdateClientRequestDTO,
    UpdateClientWeightTrackingEnabledRequestDTO,
    UpdateNutritionPrescriptionAsClientRequestDTO,
} from 'API';
import type { AxiosError, AxiosResponse } from 'axios';
import type { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import { clientAPI } from 'utils';

export const clientQueryKeys = {
    clientDetails: (clientId: string) => ['getClientDetails', clientId] as const,
    getClientOrderedRecipesAndPreparedMeals: (clientId: string) =>
        ['getClientOrderedRecipesAndPreparedMeals', clientId] as const,
    getClientOutstandingBalances: (clientId: string) => ['getClientOutstandingBalances', clientId] as const,
};

export const useGetClientDetails = (
    clientId: string,
    options?: Omit<UseQueryOptions<ClientResponseDTO>, 'queryFn' | 'queryKey'>
) =>
    useQuery(clientQueryKeys.clientDetails(clientId), () => clientAPI.getClient(clientId).then((r) => r.data), options);

export const useOrderHistory = (
    clientId: string,
    sinceUpdatedDate?: string,
    limit?: number,
    options?: Omit<UseQueryOptions<OrderHistoryResponseDTO>, 'queryFn' | 'queryKey'>
) =>
    useQuery(
        ['getClientOrderHistory', clientId, sinceUpdatedDate, limit],
        () => clientAPI.getOrderHistory(clientId, sinceUpdatedDate, limit).then((r) => r.data),
        options
    );

export const useClientCommunicationThread = (
    clientId: string,
    options?: Omit<UseQueryOptions<CommunicationThreadDTO>, 'queryFn' | 'queryKey'>
) =>
    useQuery(
        ['getClientCommunicationThread', clientId],
        () => clientAPI.getCommunicationThreadForClient(clientId).then((r) => r.data),
        options
    );

interface SendClientSMSArgs {
    clientId: string;
    requestDTO: {
        content: string;
        groceryListId?: string;
        foodOrderId?: string;
    };
}

export const useSendClientSms = (
    options?: UseMutationOptions<AxiosResponse<void>, AxiosError<{ message?: string }>, SendClientSMSArgs>
) =>
    useMutation((args: SendClientSMSArgs) => {
        return clientAPI.sendSms(args.clientId, args.requestDTO);
    }, options);

interface UpdateClientFavoriteRecipeArgs {
    clientId: string;
    recipeId: string;
    favorite: boolean;
}

export const useUpdateClientFavoriteRecipe = (
    options?: UseMutationOptions<AxiosResponse<void>, unknown, UpdateClientFavoriteRecipeArgs>
) =>
    useMutation((args: UpdateClientFavoriteRecipeArgs) => {
        return clientAPI.updateFavoriteRecipe(args.clientId, { recipeId: args.recipeId, favorite: args.favorite });
    }, options);

export const useUpdateClientNotificationPreferences = (
    clientId: string,
    options?: UseMutationOptions<AxiosResponse<void>, unknown, ClientNotificationPreferencesDTO>
) =>
    useMutation((request: ClientNotificationPreferencesDTO) => {
        return clientAPI.updateClientNotificationPreferences(clientId, request);
    }, options);

interface UpdateClientDeliveryDetailsArgs {
    clientId: string;
    request: UpdateClientDeliveryDetailsRequestDTO;
}

export const useUpdateClientDeliveryDetails = (
    options?: UseMutationOptions<
        UpdateClientDeliveryDetailsResponseDTO,
        AxiosError<ErrorDTO>,
        UpdateClientDeliveryDetailsArgs
    >
) =>
    useMutation((args: UpdateClientDeliveryDetailsArgs) => {
        return clientAPI.updateClientDeliveryDetails(args.clientId, args.request).then((r) => r.data);
    }, options);

export const useGetFormSubmissions = (
    clientId: string,
    options?: Omit<UseQueryOptions<FormSubmissionsResponseDTO>, 'queryFn' | 'queryKey'>
) =>
    useQuery(
        ['getFormSubmissions', clientId],
        () => clientAPI.getFormSubmissions(clientId).then((r) => r.data),
        options
    );

export const useClientOutstandingBalances = <T = ClientOutstandingBalancesResponseDTO>(
    clientId: string,
    options?: Omit<
        UseQueryOptions<ClientOutstandingBalancesResponseDTO, AxiosError<ErrorDTO>, T>,
        'queryKey' | 'queryFn'
    >
) =>
    useQuery(
        clientQueryKeys.getClientOutstandingBalances(clientId),
        () => clientAPI.getOutstandingBalances(clientId).then((r) => r.data),
        options
    );

export const useGetClientOrderedRecipesAndPreparedMeals = (
    clientId: string,
    options?: Omit<
        UseQueryOptions<GetClientOrderedRecipesAndPreparedMealsResponseDTO, AxiosError<ErrorDTO>>,
        'queryKey' | 'queryFn'
    >
) =>
    useQuery(
        clientQueryKeys.getClientOrderedRecipesAndPreparedMeals(clientId),
        () => clientAPI.getClientOrderedRecipesAndPreparedMeals(clientId).then((r) => r.data),
        options
    );

export const useUpdateClient = (
    clientId: string,
    options?: UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorDTO>, UpdateClientRequestDTO>
) =>
    useMutation((request: UpdateClientRequestDTO) => {
        return clientAPI.updateClient(clientId, request);
    }, options);

export const useUpdateClientPersonalInformation = (
    clientId: string,
    options?: UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorDTO>, UpdateClientPersonalInformationRequestDTO>
) =>
    useMutation((request: UpdateClientPersonalInformationRequestDTO) => {
        return clientAPI.updatePersonalInformation(clientId, request);
    }, options);

export const useUpdateProgramStartDate = (
    clientId: string,
    options?: UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorDTO>, UpdateClientProgramStartDateRequestDTO>
) =>
    useMutation((request: UpdateClientProgramStartDateRequestDTO) => {
        return clientAPI.updateProgramStartDate(clientId, request);
    }, options);

export const useUpdateEnabledWeightTracking = (
    clientId: string,
    options?: UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorDTO>, UpdateClientWeightTrackingEnabledRequestDTO>
) =>
    useMutation((request: UpdateClientWeightTrackingEnabledRequestDTO) => {
        return clientAPI.updateClientWeightTrackingEnabled(clientId, request);
    }, options);

export const useUpdateNutritionPrescriptionAsClient = (
    clientId: string,
    options?: UseMutationOptions<
        AxiosResponse<void>,
        AxiosError<ErrorDTO>,
        UpdateNutritionPrescriptionAsClientRequestDTO
    >
) =>
    useMutation((request: UpdateNutritionPrescriptionAsClientRequestDTO) => {
        return clientAPI.updateNutritionPrescriptionAsClient(clientId, request);
    }, options);

export const useUpdateClientAvailableCookingEquipment = (
    clientId: string,
    options?: UseMutationOptions<
        AxiosResponse<void>,
        AxiosError<ErrorDTO>,
        UpdateClientAvailableCookingEquipmentRequestDTO
    >
) =>
    useMutation((request: UpdateClientAvailableCookingEquipmentRequestDTO) => {
        return clientAPI.updateClientAvailableCookingEquipment(clientId, request);
    }, options);

export const useUpdateClientIngredientPreferences = (
    clientId: string,
    options?: UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorDTO>, UpdateClientIngredientPreferencesRequestDTO>
) =>
    useMutation((request: UpdateClientIngredientPreferencesRequestDTO) => {
        return clientAPI.updateClientIngredientPreferences(clientId, request);
    }, options);

export const useCreateClientWeightEntry = (
    clientId: string,
    options?: UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorDTO>, CreateClientWeightEntryRequestDTO>
) =>
    useMutation((request: CreateClientWeightEntryRequestDTO) => {
        return clientAPI.createClientWeightEntry(clientId, request);
    }, options);

export const useGetClientGoals = <T>(
    clientId: string,
    options?: Omit<UseQueryOptions<GetClientGoalGroupResponseDTO, unknown, T>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        ['clientGoals', clientId],
        async () => {
            const response = await clientAPI.getClientGoalGroups(clientId);
            return response.data;
        },
        options
    );

export const useAddClientGoalGroup = (
    clientId: string,
    options?: UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorDTO>, AddClientGoalGroupRequestDTO>
) => useMutation((request: AddClientGoalGroupRequestDTO) => clientAPI.addClientGoalGroup(clientId, request), options);

export const useDeleteCurrentClientGoalGroup = (clientId: string, options?: UseMutationOptions<AxiosResponse<void>>) =>
    useMutation(() => clientAPI.deleteCurrentClientGoalGroup(clientId), options);

interface ClientCancelSubscriptionArgs {
    id: string;
    requestDTO: ClientCancelSubscriptionRequestDTO;
}

export const useClientCancelSubscription = (
    options?: UseMutationOptions<AxiosResponse<void>, unknown, ClientCancelSubscriptionArgs>
) =>
    useMutation((args: ClientCancelSubscriptionArgs) => {
        return clientAPI.cancelClientSubscription(args.id, args.requestDTO);
    }, options);
