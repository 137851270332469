import { useField } from 'formik';
import InputMasked from 'galley/Input/InputMasked';
import { parsePhoneNumber } from 'libphonenumber-js';
import type React from 'react';
import { useEffect, useState } from 'react';
import { validatePhoneNumber } from 'utils';
import { trackBlur } from 'utils/tracking/events';

export interface PhoneNumberMaskProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    className?: string;
    dataTestId?: string;
    error?: boolean;
    name?: string;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeValue?: (value: string) => void;
    allowEmpty?: boolean;
    value: string;
    useInternalValidation?: boolean;
}

const PhoneNumberMask = ({
    className,
    dataTestId,
    error = false,
    label,
    name,
    onBlur,
    onChange,
    onChangeValue,
    value,
    useInternalValidation = true,
    ...rest
}: PhoneNumberMaskProps): JSX.Element => {
    const getNationalNumber = (): string => parsePhoneNumber(value, 'US').format('NATIONAL');

    const [errorState, setErrorState] = useState(error);
    const displayNumber = validatePhoneNumber(value) ? getNationalNumber() : value;

    const blankMaskState = '(   )    -    ';
    const maskFormat = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    useEffect(() => {
        useInternalValidation && setErrorState(error);
    }, [error, useInternalValidation]);

    const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
        useInternalValidation && setErrorState(!validatePhoneNumber(event.target.value));
        onBlur && onBlur(event);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const {
            target: { value: newValue },
        } = e;
        onChange && onChange(e);
        if (!validatePhoneNumber(newValue) || !useInternalValidation) onChangeValue && onChangeValue(newValue);
        else {
            const newNumber = parsePhoneNumber(newValue.replace(/\D/g, ''), 'US');
            const e164Number = newNumber.format('E.164');
            onChangeValue && onChangeValue(e164Number);
        }
    };

    return (
        <InputMasked
            {...rest}
            className={className}
            dataTestId={dataTestId}
            isInvalid={useInternalValidation ? errorState : error}
            label={label}
            mask={maskFormat}
            name={name}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            placeholder={blankMaskState}
            type="tel"
            inputMode="numeric"
            autoComplete="tel-national"
            value={displayNumber}
        />
    );
};

export const FormikPhoneNumberMask: React.FC<Omit<PhoneNumberMaskProps, 'value' | 'onChangeValue'>> = (props) => {
    const [field, meta] = useField(props.name || '');
    const showError = Boolean(meta.touched && meta.error);
    return (
        <PhoneNumberMask
            {...field}
            {...props}
            error={showError}
            label={props.label}
            onBlur={(e) => {
                field.onBlur(e);
                trackBlur({
                    label: field.name,
                    error: meta.error,
                });
            }}
        />
    );
};

export default PhoneNumberMask;
