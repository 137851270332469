/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddClientIntakeInsuranceRequestDTO } from '../models';
// @ts-ignore
import { AddClientIntakeInsuranceResponseDTO } from '../models';
// @ts-ignore
import { CheckClientIntakeEligibilityRequestDTO } from '../models';
// @ts-ignore
import { CheckClientIntakeEligibilityResponseDTO } from '../models';
// @ts-ignore
import { GetClientIntakesResponseDTO } from '../models';
// @ts-ignore
import { RegisterClientIntakeRequestDTO } from '../models';
// @ts-ignore
import { SetupPaymentIntentResponseDTO } from '../models';
// @ts-ignore
import { SubmitClientIntakeRequestDTO } from '../models';
// @ts-ignore
import { SubmitClientIntakeResponseDTO } from '../models';
// @ts-ignore
import { SubmitClientIntakeWithInsuranceRequestDTO } from '../models';
// @ts-ignore
import { SubmitClientIntakeWithInsuranceResponseDTO } from '../models';
// @ts-ignore
import { TokenResponseDTO } from '../models';
// @ts-ignore
import { UpdateClientIntakeCohortDetailsRequestDTO } from '../models';
/**
 * ClientIntakeApi - axios parameter creator
 * @export
 */
export const ClientIntakeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Insurance to a Client Intake
         * @param {string} id The Client Intake ID
         * @param {AddClientIntakeInsuranceRequestDTO} addClientIntakeInsuranceRequest Insurance information to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClientIntakeInsurance: async (id: string, addClientIntakeInsuranceRequest: AddClientIntakeInsuranceRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addClientIntakeInsurance', 'id', id)
            // verify required parameter 'addClientIntakeInsuranceRequest' is not null or undefined
            assertParamExists('addClientIntakeInsurance', 'addClientIntakeInsuranceRequest', addClientIntakeInsuranceRequest)
            const localVarPath = `/client_intakes/{id}/insurance`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addClientIntakeInsuranceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use client intake fields to check eligibility and available providers.
         * @param {CheckClientIntakeEligibilityRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkClientIntakeEligibility: async (request: CheckClientIntakeEligibilityRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('checkClientIntakeEligibility', 'request', request)
            const localVarPath = `/client_intakes/check-eligibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clients intakes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientIntakes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/client_intakes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register Client Intake
         * @param {string} id The Client Intake ID
         * @param {RegisterClientIntakeRequestDTO} registerClientIntakeRequest Information needed to register Client Intake
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerClientIntake: async (id: string, registerClientIntakeRequest: RegisterClientIntakeRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('registerClientIntake', 'id', id)
            // verify required parameter 'registerClientIntakeRequest' is not null or undefined
            assertParamExists('registerClientIntake', 'registerClientIntakeRequest', registerClientIntakeRequest)
            const localVarPath = `/client_intakes/{id}/register`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerClientIntakeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setup the intent to add payment info for a client intake
         * @param {string} id The Client Intake ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupClientIntakePaymentIntent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setupClientIntakePaymentIntent', 'id', id)
            const localVarPath = `/client_intakes/{id}/setup-payment-intent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit a client intake.
         * @param {SubmitClientIntakeRequestDTO} submitClientIntakeRequest client intake properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitClientIntake: async (submitClientIntakeRequest: SubmitClientIntakeRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitClientIntakeRequest' is not null or undefined
            assertParamExists('submitClientIntake', 'submitClientIntakeRequest', submitClientIntakeRequest)
            const localVarPath = `/client_intakes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitClientIntakeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit a client intake with insurance. Used for signing users up outside of the normal signup flow.
         * @param {SubmitClientIntakeWithInsuranceRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitClientIntakeWithInsurance: async (request: SubmitClientIntakeWithInsuranceRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('submitClientIntakeWithInsurance', 'request', request)
            const localVarPath = `/client_intakes/submit-with-insurance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update cohort details
         * @param {string} id The Client Intake ID
         * @param {UpdateClientIntakeCohortDetailsRequestDTO} request Updated Client Intake Cohort Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientIntakeCohortDetails: async (id: string, request: UpdateClientIntakeCohortDetailsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientIntakeCohortDetails', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateClientIntakeCohortDetails', 'request', request)
            const localVarPath = `/client_intakes/{id}/update-cohort-details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientIntakeApi - functional programming interface
 * @export
 */
export const ClientIntakeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientIntakeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Insurance to a Client Intake
         * @param {string} id The Client Intake ID
         * @param {AddClientIntakeInsuranceRequestDTO} addClientIntakeInsuranceRequest Insurance information to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addClientIntakeInsurance(id: string, addClientIntakeInsuranceRequest: AddClientIntakeInsuranceRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddClientIntakeInsuranceResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addClientIntakeInsurance(id, addClientIntakeInsuranceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use client intake fields to check eligibility and available providers.
         * @param {CheckClientIntakeEligibilityRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkClientIntakeEligibility(request: CheckClientIntakeEligibilityRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckClientIntakeEligibilityResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkClientIntakeEligibility(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get clients intakes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientIntakes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientIntakesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientIntakes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register Client Intake
         * @param {string} id The Client Intake ID
         * @param {RegisterClientIntakeRequestDTO} registerClientIntakeRequest Information needed to register Client Intake
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerClientIntake(id: string, registerClientIntakeRequest: RegisterClientIntakeRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerClientIntake(id, registerClientIntakeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setup the intent to add payment info for a client intake
         * @param {string} id The Client Intake ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setupClientIntakePaymentIntent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetupPaymentIntentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setupClientIntakePaymentIntent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit a client intake.
         * @param {SubmitClientIntakeRequestDTO} submitClientIntakeRequest client intake properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitClientIntake(submitClientIntakeRequest: SubmitClientIntakeRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitClientIntakeResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitClientIntake(submitClientIntakeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit a client intake with insurance. Used for signing users up outside of the normal signup flow.
         * @param {SubmitClientIntakeWithInsuranceRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitClientIntakeWithInsurance(request: SubmitClientIntakeWithInsuranceRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitClientIntakeWithInsuranceResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitClientIntakeWithInsurance(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update cohort details
         * @param {string} id The Client Intake ID
         * @param {UpdateClientIntakeCohortDetailsRequestDTO} request Updated Client Intake Cohort Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientIntakeCohortDetails(id: string, request: UpdateClientIntakeCohortDetailsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientIntakeCohortDetails(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientIntakeApi - factory interface
 * @export
 */
export const ClientIntakeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientIntakeApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Insurance to a Client Intake
         * @param {string} id The Client Intake ID
         * @param {AddClientIntakeInsuranceRequestDTO} addClientIntakeInsuranceRequest Insurance information to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClientIntakeInsurance(id: string, addClientIntakeInsuranceRequest: AddClientIntakeInsuranceRequestDTO, options?: any): AxiosPromise<AddClientIntakeInsuranceResponseDTO> {
            return localVarFp.addClientIntakeInsurance(id, addClientIntakeInsuranceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use client intake fields to check eligibility and available providers.
         * @param {CheckClientIntakeEligibilityRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkClientIntakeEligibility(request: CheckClientIntakeEligibilityRequestDTO, options?: any): AxiosPromise<CheckClientIntakeEligibilityResponseDTO> {
            return localVarFp.checkClientIntakeEligibility(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get clients intakes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientIntakes(options?: any): AxiosPromise<GetClientIntakesResponseDTO> {
            return localVarFp.getClientIntakes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register Client Intake
         * @param {string} id The Client Intake ID
         * @param {RegisterClientIntakeRequestDTO} registerClientIntakeRequest Information needed to register Client Intake
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerClientIntake(id: string, registerClientIntakeRequest: RegisterClientIntakeRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.registerClientIntake(id, registerClientIntakeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setup the intent to add payment info for a client intake
         * @param {string} id The Client Intake ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupClientIntakePaymentIntent(id: string, options?: any): AxiosPromise<SetupPaymentIntentResponseDTO> {
            return localVarFp.setupClientIntakePaymentIntent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit a client intake.
         * @param {SubmitClientIntakeRequestDTO} submitClientIntakeRequest client intake properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitClientIntake(submitClientIntakeRequest: SubmitClientIntakeRequestDTO, options?: any): AxiosPromise<SubmitClientIntakeResponseDTO> {
            return localVarFp.submitClientIntake(submitClientIntakeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit a client intake with insurance. Used for signing users up outside of the normal signup flow.
         * @param {SubmitClientIntakeWithInsuranceRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitClientIntakeWithInsurance(request: SubmitClientIntakeWithInsuranceRequestDTO, options?: any): AxiosPromise<SubmitClientIntakeWithInsuranceResponseDTO> {
            return localVarFp.submitClientIntakeWithInsurance(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update cohort details
         * @param {string} id The Client Intake ID
         * @param {UpdateClientIntakeCohortDetailsRequestDTO} request Updated Client Intake Cohort Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientIntakeCohortDetails(id: string, request: UpdateClientIntakeCohortDetailsRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateClientIntakeCohortDetails(id, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientIntakeApi - object-oriented interface
 * @export
 * @class ClientIntakeApi
 * @extends {BaseAPI}
 */
export class ClientIntakeApi extends BaseAPI {
    /**
     * 
     * @summary Add Insurance to a Client Intake
     * @param {string} id The Client Intake ID
     * @param {AddClientIntakeInsuranceRequestDTO} addClientIntakeInsuranceRequest Insurance information to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientIntakeApi
     */
    public addClientIntakeInsurance(id: string, addClientIntakeInsuranceRequest: AddClientIntakeInsuranceRequestDTO, options?: AxiosRequestConfig) {
        return ClientIntakeApiFp(this.configuration).addClientIntakeInsurance(id, addClientIntakeInsuranceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use client intake fields to check eligibility and available providers.
     * @param {CheckClientIntakeEligibilityRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientIntakeApi
     */
    public checkClientIntakeEligibility(request: CheckClientIntakeEligibilityRequestDTO, options?: AxiosRequestConfig) {
        return ClientIntakeApiFp(this.configuration).checkClientIntakeEligibility(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get clients intakes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientIntakeApi
     */
    public getClientIntakes(options?: AxiosRequestConfig) {
        return ClientIntakeApiFp(this.configuration).getClientIntakes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register Client Intake
     * @param {string} id The Client Intake ID
     * @param {RegisterClientIntakeRequestDTO} registerClientIntakeRequest Information needed to register Client Intake
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientIntakeApi
     */
    public registerClientIntake(id: string, registerClientIntakeRequest: RegisterClientIntakeRequestDTO, options?: AxiosRequestConfig) {
        return ClientIntakeApiFp(this.configuration).registerClientIntake(id, registerClientIntakeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setup the intent to add payment info for a client intake
     * @param {string} id The Client Intake ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientIntakeApi
     */
    public setupClientIntakePaymentIntent(id: string, options?: AxiosRequestConfig) {
        return ClientIntakeApiFp(this.configuration).setupClientIntakePaymentIntent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit a client intake.
     * @param {SubmitClientIntakeRequestDTO} submitClientIntakeRequest client intake properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientIntakeApi
     */
    public submitClientIntake(submitClientIntakeRequest: SubmitClientIntakeRequestDTO, options?: AxiosRequestConfig) {
        return ClientIntakeApiFp(this.configuration).submitClientIntake(submitClientIntakeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit a client intake with insurance. Used for signing users up outside of the normal signup flow.
     * @param {SubmitClientIntakeWithInsuranceRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientIntakeApi
     */
    public submitClientIntakeWithInsurance(request: SubmitClientIntakeWithInsuranceRequestDTO, options?: AxiosRequestConfig) {
        return ClientIntakeApiFp(this.configuration).submitClientIntakeWithInsurance(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update cohort details
     * @param {string} id The Client Intake ID
     * @param {UpdateClientIntakeCohortDetailsRequestDTO} request Updated Client Intake Cohort Details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientIntakeApi
     */
    public updateClientIntakeCohortDetails(id: string, request: UpdateClientIntakeCohortDetailsRequestDTO, options?: AxiosRequestConfig) {
        return ClientIntakeApiFp(this.configuration).updateClientIntakeCohortDetails(id, request, options).then((request) => request(this.axios, this.basePath));
    }
}
