// This file holds our state type, as well as any other types related to this Redux store.

// Response object for GET /user
import type { ClientUserDetailsDTO, UserDetailsDTO } from 'API';

// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum UserActionTypes {
    SSO_LOGIN_REQUEST = '@@user/SSO_LOGIN_REQUEST',
    SIGN_OUT = '@@user/SIGN_OUT',
    LOGIN_REQUEST_SUCCESS = '@@user/LOGIN_REQUEST_SUCCESS',
    LOGIN_REQUEST_SUCCESS_PENDING_MFA = '@@user/LOGIN_REQUEST_SUCCESS_PENDING_MFA',
    LOGIN_REQUEST_ERROR = '@@user/LOGIN_REQUEST_ERROR',
    LOGIN_REFRESH = '@@user/LOGIN_REFRESH',
    USER_DETAILS_REFRESH = '@@user/USER_DETAILS_REFRESH',
    CLIENT_DETAILS_REFRESH = '@@user/CLIENT_DETAILS_REFRESH',
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface UserDetails extends UserDetailsDTO {
    client?: Omit<ClientUserDetailsDTO, 'deliveryDetails' | 'paymentMethods' | 'favoriteRecipeIds'>;
}
export interface UserState {
    readonly loading: boolean;
    readonly data: UserDetails;
    readonly errors?: string | undefined;
    readonly isSignedIn: boolean;
}

export enum UserRoles {
    ADMIN = 'ADMIN',
    CLIENT = 'CLIENT',
    PROVIDER = 'PROVIDER',
}
