import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { ASSETS_URL, ENABLE_LAUNCHDARKLY, GOOGLE_CLIENT_ID, LAUNCHDARKLY_CLIENT_ID, STRIPE_KEY } from 'utils';
import logger from 'utils/logger';
import { queryClient } from 'utils/reactQuery';
import App from './App';
import './index.css'; // Build output of index.css
import './index.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import configureStore from './store/configureStore';

// export const history = createBrowserHistory();
const store = configureStore();

const stripePromise = loadStripe(STRIPE_KEY);
stripePromise.catch((e) => logger.warn('Stripe promise raised an error', { e }));

const stripeOptions = {
    fonts: [
        {
            // eslint-disable-next-line lingui/no-unlocalized-strings
            src: `url(${ASSETS_URL}/fonts/GTAmerica/GT-America-Standard-Medium.eot), url(${ASSETS_URL}/fonts/GTAmerica/GT-America-Standard-Medium.woff)`,
            // eslint-disable-next-line lingui/no-unlocalized-strings
            family: 'GT America',
            weight: '500',
        },
    ],
};

const app = window.ReactNativeWebView ? (
    <App store={store} />
) : (
    <GoogleOAuthProvider
        onScriptLoadError={() => logger.warn('Cannot load google oauth script')}
        clientId={GOOGLE_CLIENT_ID}
    >
        <App store={store} />{' '}
    </GoogleOAuthProvider>
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

if (ENABLE_LAUNCHDARKLY) {
    (async () => {
        const LDProvider = await asyncWithLDProvider({
            clientSideID: LAUNCHDARKLY_CLIENT_ID,
        });

        root.render(
            <QueryClientProvider client={queryClient}>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                <LDProvider>
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        {app}
                    </Elements>
                </LDProvider>
            </QueryClientProvider>
        );
    })();
} else {
    root.render(
        <QueryClientProvider client={queryClient}>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <Elements stripe={stripePromise} options={stripeOptions}>
                {app}
            </Elements>
        </QueryClientProvider>
    );
}

serviceWorkerRegistration.unregister();
