/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum MajorCarrierDTO {
    AETNA = 'AETNA',
    BLUE_CROSS_BLUE_SHIELD = 'BLUE_CROSS_BLUE_SHIELD',
    CIGNA = 'CIGNA',
    HUMANA = 'HUMANA',
    UNITED_HEALTHCARE = 'UNITED_HEALTHCARE'
};

