/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum PreparedMealPreparationTypeDTO {
    SHIPS_FRESH_ARRIVES_FRESH = 'SHIPS_FRESH_ARRIVES_FRESH',
    SHIPS_FROZEN_ARRIVES_FROZEN = 'SHIPS_FROZEN_ARRIVES_FROZEN',
    SHIPS_FROZEN_ARRIVES_THAWED = 'SHIPS_FROZEN_ARRIVES_THAWED',
    INVALID = 'INVALID'
};

