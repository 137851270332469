/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePreparedMealCatalogRequestDTO } from '../models';
// @ts-ignore
import { CreatePreparedMealRequestDTO } from '../models';
// @ts-ignore
import { CreatePreparedMealResponseDTO } from '../models';
// @ts-ignore
import { PreparedMealResponseDTO } from '../models';
// @ts-ignore
import { PreparedMealsResponseDTO } from '../models';
// @ts-ignore
import { SearchPreparedMealsResponseDTO } from '../models';
// @ts-ignore
import { UpdatePreparedMealRequestDTO } from '../models';
/**
 * PreparedMealApi - axios parameter creator
 * @export
 */
export const PreparedMealApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Approve a prepared meal
         * @param {string} id The Prepared Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePreparedMeal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approvePreparedMeal', 'id', id)
            const localVarPath = `/prepared_meals/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a prepared meal
         * @param {CreatePreparedMealRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreparedMeal: async (request: CreatePreparedMealRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createPreparedMeal', 'request', request)
            const localVarPath = `/prepared_meals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a prepared meal catalog
         * @param {CreatePreparedMealCatalogRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreparedMealCatalog: async (request: CreatePreparedMealCatalogRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createPreparedMealCatalog', 'request', request)
            const localVarPath = `/prepared_meals/catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deprecate a prepared meal
         * @param {string} id The Prepared Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatePreparedMeal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deprecatePreparedMeal', 'id', id)
            const localVarPath = `/prepared_meals/{id}/deprecate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get prepared meal
         * @param {string} id The prepared meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreparedMeal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPreparedMeal', 'id', id)
            const localVarPath = `/prepared_meals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get prepared meal options for client
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreparedMealOptionsForClient: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getPreparedMealOptionsForClient', 'clientId', clientId)
            const localVarPath = `/prepared_meals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search prepared meals
         * @param {string} [nameQuery] 
         * @param {Array<'APPROVED' | 'DEPRECATED' | 'DRAFT'>} [currentStates] Empty or null defaults to all values
         * @param {Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>} [sources] Empty or null defaults to all values
         * @param {Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY' | 'NONE'>} [diets] Empty or null defaults to all values
         * @param {'NAME'} [sortBy] Sort results. Defaults to NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPreparedMeals: async (nameQuery?: string, currentStates?: Array<'APPROVED' | 'DEPRECATED' | 'DRAFT'>, sources?: Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>, diets?: Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY' | 'NONE'>, sortBy?: 'NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/prepared_meals/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (nameQuery !== undefined) {
                localVarQueryParameter['nameQuery'] = nameQuery;
            }

            if (currentStates) {
                localVarQueryParameter['currentStates'] = currentStates;
            }

            if (sources) {
                localVarQueryParameter['sources'] = sources;
            }

            if (diets) {
                localVarQueryParameter['diets'] = diets;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a prepared meal
         * @param {string} id The prepared meal ID
         * @param {UpdatePreparedMealRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreparedMeal: async (id: string, request: UpdatePreparedMealRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePreparedMeal', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updatePreparedMeal', 'request', request)
            const localVarPath = `/prepared_meals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreparedMealApi - functional programming interface
 * @export
 */
export const PreparedMealApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreparedMealApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Approve a prepared meal
         * @param {string} id The Prepared Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvePreparedMeal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvePreparedMeal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a prepared meal
         * @param {CreatePreparedMealRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPreparedMeal(request: CreatePreparedMealRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePreparedMealResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPreparedMeal(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a prepared meal catalog
         * @param {CreatePreparedMealCatalogRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPreparedMealCatalog(request: CreatePreparedMealCatalogRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPreparedMealCatalog(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deprecate a prepared meal
         * @param {string} id The Prepared Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deprecatePreparedMeal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deprecatePreparedMeal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get prepared meal
         * @param {string} id The prepared meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreparedMeal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreparedMealResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreparedMeal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get prepared meal options for client
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreparedMealOptionsForClient(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreparedMealsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreparedMealOptionsForClient(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search prepared meals
         * @param {string} [nameQuery] 
         * @param {Array<'APPROVED' | 'DEPRECATED' | 'DRAFT'>} [currentStates] Empty or null defaults to all values
         * @param {Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>} [sources] Empty or null defaults to all values
         * @param {Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY' | 'NONE'>} [diets] Empty or null defaults to all values
         * @param {'NAME'} [sortBy] Sort results. Defaults to NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPreparedMeals(nameQuery?: string, currentStates?: Array<'APPROVED' | 'DEPRECATED' | 'DRAFT'>, sources?: Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>, diets?: Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY' | 'NONE'>, sortBy?: 'NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchPreparedMealsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPreparedMeals(nameQuery, currentStates, sources, diets, sortBy, sortDir, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a prepared meal
         * @param {string} id The prepared meal ID
         * @param {UpdatePreparedMealRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreparedMeal(id: string, request: UpdatePreparedMealRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePreparedMeal(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PreparedMealApi - factory interface
 * @export
 */
export const PreparedMealApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreparedMealApiFp(configuration)
    return {
        /**
         * 
         * @summary Approve a prepared meal
         * @param {string} id The Prepared Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePreparedMeal(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.approvePreparedMeal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a prepared meal
         * @param {CreatePreparedMealRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreparedMeal(request: CreatePreparedMealRequestDTO, options?: any): AxiosPromise<CreatePreparedMealResponseDTO> {
            return localVarFp.createPreparedMeal(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a prepared meal catalog
         * @param {CreatePreparedMealCatalogRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreparedMealCatalog(request: CreatePreparedMealCatalogRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createPreparedMealCatalog(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deprecate a prepared meal
         * @param {string} id The Prepared Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatePreparedMeal(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deprecatePreparedMeal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get prepared meal
         * @param {string} id The prepared meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreparedMeal(id: string, options?: any): AxiosPromise<PreparedMealResponseDTO> {
            return localVarFp.getPreparedMeal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get prepared meal options for client
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreparedMealOptionsForClient(clientId: string, options?: any): AxiosPromise<PreparedMealsResponseDTO> {
            return localVarFp.getPreparedMealOptionsForClient(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search prepared meals
         * @param {string} [nameQuery] 
         * @param {Array<'APPROVED' | 'DEPRECATED' | 'DRAFT'>} [currentStates] Empty or null defaults to all values
         * @param {Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>} [sources] Empty or null defaults to all values
         * @param {Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY' | 'NONE'>} [diets] Empty or null defaults to all values
         * @param {'NAME'} [sortBy] Sort results. Defaults to NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPreparedMeals(nameQuery?: string, currentStates?: Array<'APPROVED' | 'DEPRECATED' | 'DRAFT'>, sources?: Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>, diets?: Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY' | 'NONE'>, sortBy?: 'NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: any): AxiosPromise<SearchPreparedMealsResponseDTO> {
            return localVarFp.searchPreparedMeals(nameQuery, currentStates, sources, diets, sortBy, sortDir, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a prepared meal
         * @param {string} id The prepared meal ID
         * @param {UpdatePreparedMealRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreparedMeal(id: string, request: UpdatePreparedMealRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updatePreparedMeal(id, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PreparedMealApi - object-oriented interface
 * @export
 * @class PreparedMealApi
 * @extends {BaseAPI}
 */
export class PreparedMealApi extends BaseAPI {
    /**
     * 
     * @summary Approve a prepared meal
     * @param {string} id The Prepared Meal ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreparedMealApi
     */
    public approvePreparedMeal(id: string, options?: AxiosRequestConfig) {
        return PreparedMealApiFp(this.configuration).approvePreparedMeal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a prepared meal
     * @param {CreatePreparedMealRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreparedMealApi
     */
    public createPreparedMeal(request: CreatePreparedMealRequestDTO, options?: AxiosRequestConfig) {
        return PreparedMealApiFp(this.configuration).createPreparedMeal(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a prepared meal catalog
     * @param {CreatePreparedMealCatalogRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreparedMealApi
     */
    public createPreparedMealCatalog(request: CreatePreparedMealCatalogRequestDTO, options?: AxiosRequestConfig) {
        return PreparedMealApiFp(this.configuration).createPreparedMealCatalog(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deprecate a prepared meal
     * @param {string} id The Prepared Meal ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreparedMealApi
     */
    public deprecatePreparedMeal(id: string, options?: AxiosRequestConfig) {
        return PreparedMealApiFp(this.configuration).deprecatePreparedMeal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get prepared meal
     * @param {string} id The prepared meal ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreparedMealApi
     */
    public getPreparedMeal(id: string, options?: AxiosRequestConfig) {
        return PreparedMealApiFp(this.configuration).getPreparedMeal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get prepared meal options for client
     * @param {string} clientId Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreparedMealApi
     */
    public getPreparedMealOptionsForClient(clientId: string, options?: AxiosRequestConfig) {
        return PreparedMealApiFp(this.configuration).getPreparedMealOptionsForClient(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search prepared meals
     * @param {string} [nameQuery] 
     * @param {Array<'APPROVED' | 'DEPRECATED' | 'DRAFT'>} [currentStates] Empty or null defaults to all values
     * @param {Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>} [sources] Empty or null defaults to all values
     * @param {Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY' | 'NONE'>} [diets] Empty or null defaults to all values
     * @param {'NAME'} [sortBy] Sort results. Defaults to NAME
     * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
     * @param {number} [limit] Limit query for pagination. Defaults to 100
     * @param {number} [offset] Offset query for pagination. Defaults to 0.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreparedMealApi
     */
    public searchPreparedMeals(nameQuery?: string, currentStates?: Array<'APPROVED' | 'DEPRECATED' | 'DRAFT'>, sources?: Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>, diets?: Array<'DASH' | 'DASH_1500' | 'DIABETES_FRIENDLY' | 'DIALYSIS_KIDNEY_FRIENDLY' | 'GENERAL_HEALTHFUL' | 'GLUTEN_FREE' | 'HIGH_FIBER' | 'KIDNEY_FRIENDLY' | 'MEDITERRANEAN' | 'PREGNANCY_FRIENDLY' | 'NONE'>, sortBy?: 'NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return PreparedMealApiFp(this.configuration).searchPreparedMeals(nameQuery, currentStates, sources, diets, sortBy, sortDir, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a prepared meal
     * @param {string} id The prepared meal ID
     * @param {UpdatePreparedMealRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreparedMealApi
     */
    public updatePreparedMeal(id: string, request: UpdatePreparedMealRequestDTO, options?: AxiosRequestConfig) {
        return PreparedMealApiFp(this.configuration).updatePreparedMeal(id, request, options).then((request) => request(this.axios, this.basePath));
    }
}
