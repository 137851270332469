import { createSelector } from '@reduxjs/toolkit';
import type {
    AdminResponseDTO,
    CardDTO,
    ClientNotificationPreferencesDTO,
    ClientSubscriptionDTO,
    ClientUserDetailsDTO,
    CohortDTO,
    IngredientPreferencesDTO,
    LoginTypeDTO,
    NutritionPrescriptionResponseDTO,
    PaymentMethodDTO,
    PracticeWithProvidersDTO,
    PreferenceItemDTO,
    ProviderResponseDTO,
    ProviderUserDetailsDTO,
} from 'API';
import { ClientStateDTO, ClientSubscriptionProductDTO, ClientSubscriptionStateDTO, UserDetailsDTORolesEnum } from 'API';
import { USER_PREFERENCE_KEYS, getPrimaryEmail } from 'utils';
import type { ApplicationState } from '..';
import type { UserDetails, UserState } from './types';

type UserSlice = Pick<ApplicationState, 'user'>;
type PaymentSlice = Pick<ApplicationState, 'payments'>;
type SubscriptionSlice = Pick<ApplicationState, 'subscription'>;
type PatientSlice = Pick<ApplicationState, 'patient'>;

export const selectUser = (state: UserSlice): UserState => state.user;
export const selectIsSignedIn = (state: UserSlice): boolean => state.user.isSignedIn;
export const selectActiveLoginTypes = (state: UserSlice): LoginTypeDTO[] => state.user.data.activeLoginTypes;

export const selectIsSignedInAndActivatedClient = (state: UserSlice): boolean => {
    const allStates = state.user.data.client?.statuses.map((status) => status.state) ?? [];
    return state.user.isSignedIn && allStates.includes(ClientStateDTO.ACTIVATED);
};

export const selectIsSignedInAndActivatedAndValidatedClient = (state: UserSlice): boolean => {
    const allStates = state.user.data.client?.statuses.map((status) => status.state) ?? [];
    return (
        state.user.isSignedIn &&
        allStates.includes(ClientStateDTO.ACTIVATED) &&
        allStates.includes(ClientStateDTO.VALIDATED)
    );
};

export const selectIsSignedInAndRegisteredClient = (state: UserSlice): boolean => {
    const allStates = state.user.data.client?.statuses.map((status) => status.state) ?? [];
    return state.user.isSignedIn && allStates.includes(ClientStateDTO.REGISTERED);
};

export const selectIsSignedInAndRequiresActivation = (state: UserSlice): boolean => {
    const allStates = state.user.data.client?.statuses.map((status) => status.state) ?? [];
    return (
        state.user.isSignedIn &&
        (state.user.data.client?.emails?.length ?? 0) > 0 &&
        !allStates.includes(ClientStateDTO.ACTIVATED)
    );
};

export const selectIsSignedInAndRequiresValidation = (state: UserSlice): boolean => {
    const allStates = state.user.data.client?.statuses.map((status) => status.state) ?? [];
    return (
        state.user.isSignedIn &&
        (state.user.data.client?.emails?.length ?? 0) > 0 &&
        allStates.includes(ClientStateDTO.ACTIVATED) &&
        !allStates.includes(ClientStateDTO.VALIDATED)
    );
};

export const selectUserData = (state: UserSlice): UserDetails => state.user.data;

export const selectUserCohort = (state: UserSlice): CohortDTO | undefined => {
    return selectClientData(state)?.cohortDetails.cohort;
};

export const selectUserPrimaryEmail = (state: UserSlice): string => {
    const userData = state.user.data;

    let emailAddress = '';
    if (userData.roles.includes(UserDetailsDTORolesEnum.PROVIDER) && userData.provider?.emails) {
        emailAddress = getPrimaryEmail(Array.from(userData.provider.emails));
    }
    if (userData.roles.includes(UserDetailsDTORolesEnum.CLIENT) && userData.client?.emails) {
        emailAddress = getPrimaryEmail(userData.client.emails);
    }
    if (userData.roles.includes(UserDetailsDTORolesEnum.ADMIN) && userData.admin?.emailAddress) {
        emailAddress = userData.admin.emailAddress;
    }

    return emailAddress;
};

export const selectClientId = (state: UserSlice): string => {
    return selectClientData(state)?.id ?? '';
};

export const selectMaybeClientData = (state: UserSlice): ClientUserDetailsDTO | undefined => {
    return selectUserData(state).client;
};

export const selectClientData = (state: UserSlice): ClientUserDetailsDTO => {
    return selectUserData(state).client as ClientUserDetailsDTO;
};

export const selectClientProvider = (state: UserSlice): ProviderResponseDTO | undefined => {
    return selectClientData(state)?.provider;
};

export const selectPractice = (state: UserSlice): PracticeWithProvidersDTO | undefined => {
    return selectProvider(state)?.practice;
};

export const selectProvider = (state: UserSlice): ProviderUserDetailsDTO | undefined => {
    return selectUserData(state).provider;
};

export const selectProviderData = (state: UserSlice): ProviderUserDetailsDTO => {
    return selectUserData(state).provider as ProviderUserDetailsDTO;
};

export const selectPracticeProviders = (state: UserSlice): ProviderResponseDTO[] | undefined => {
    return selectProvider(state)?.practice.providers;
};

export const selectClientNotificationPreferences = (state: UserSlice): ClientNotificationPreferencesDTO => {
    return selectClientData(state)?.notificationPreferences as ClientNotificationPreferencesDTO;
};

export const selectPrimaryPaymentMethod = (state: PaymentSlice): PaymentMethodDTO | undefined => {
    return state.payments.paymentMethods.find((paymentMethod) => paymentMethod.isPrimary);
};

export const selectPrimaryPaymentMethodCard = (state: PaymentSlice): CardDTO | undefined => {
    return state.payments.paymentMethods.find((paymentMethod) => paymentMethod.isPrimary)?.card;
};

export const selectCurrentSubscription = (state: SubscriptionSlice): ClientSubscriptionDTO | undefined | null => {
    return state.subscription.currentSubscription;
};

export const selectHasActiveSubscription = (state: SubscriptionSlice): undefined | boolean => {
    const currentSubscription = selectCurrentSubscription(state);
    if (currentSubscription === undefined) {
        return undefined;
    } else if (currentSubscription === null) {
        return false;
    } else {
        return currentSubscription.currentState !== ClientSubscriptionStateDTO.CANCELED;
    }
};

export const selectHasClinicalFeatures = (state: SubscriptionSlice): boolean | undefined => {
    const currentSubscription = selectCurrentSubscription(state);
    if (currentSubscription === undefined) {
        return undefined;
    } else if (currentSubscription === null) {
        return false;
    } else {
        return [ClientSubscriptionProductDTO.COMPLETE, ClientSubscriptionProductDTO.SELECT].includes(
            currentSubscription.product
        );
    }
};

export const selectPatientFavoriteRecipeIds = (state: PatientSlice): string[] => {
    return state.patient.favoriteRecipeIds;
};

export const selectAdminId = (state: UserSlice): string => {
    return selectAdmin(state)?.id ?? '';
};

export const selectAdmin = (state: UserSlice): AdminResponseDTO | undefined => {
    return selectUserData(state).admin;
};

export const selectUserFirstName = (state: UserSlice): string => {
    return selectUserData(state).firstName as string;
};

export const selectUserNames = (state: UserSlice): string => {
    const firstName = selectUserData(state).firstName as string;
    const lastName = selectUserData(state).lastName as string;

    return [firstName, lastName].join(' ');
};

export const selectUserPreferences = (state: UserSlice): PreferenceItemDTO[] => {
    return selectUserData(state).preferences ?? [];
};

export const selectClientNutritionPrescription = (state: UserSlice): NutritionPrescriptionResponseDTO => {
    return selectClientData(state)?.nutritionPrescription as NutritionPrescriptionResponseDTO;
};

export const selectClientAvailableCookingEquipment = (state: UserSlice): Array<string> => {
    return selectClientData(state)?.availableCookingEquipment ?? [];
};

export const selectClientIngredientPreferences = (state: UserSlice): IngredientPreferencesDTO => {
    return selectClientData(state)?.ingredientPreferences as IngredientPreferencesDTO;
};

export const selectIsFirstTimeUser = (state: UserSlice): boolean => {
    return !!selectUserPreferences(state).find((pref) => pref.name === USER_PREFERENCE_KEYS.patientFirstTimeUser)
        ?.value;
};

export const selectUserHasViewedRecipes = (state: UserSlice): boolean => {
    return !!selectUserPreferences(state).find((pref) => pref.name === USER_PREFERENCE_KEYS.patientHasViewedRecipes)
        ?.value;
};

export const selectUserHasViewedPurchasedMeals = (state: UserSlice): boolean => {
    return !!selectUserPreferences(state).find(
        (pref) => pref.name === USER_PREFERENCE_KEYS.patientHasViewedPurchasedMeals
    )?.value;
};

export const selectAlreadyCompletedOnboarding = (state: UserSlice): boolean => {
    return !!selectUserPreferences(state).find((pref) => pref.name === USER_PREFERENCE_KEYS.alreadyCompletedOnboarding)
        ?.value;
};

export const selectUserHasClosedEducationCard = (state: UserSlice): boolean => {
    return !!selectUserPreferences(state).find((pref) => pref.name === USER_PREFERENCE_KEYS.hasClosedEducationCard)
        ?.value;
};

export const selectUserHasSeenMessagingWelcomeToast = (state: UserSlice): boolean => {
    return !!selectUserPreferences(state).find(
        (pref) => pref.name === USER_PREFERENCE_KEYS.hasSeenMessagingWelcomeToast
    )?.value;
};

export const selectUserHasCompletedClinicalIntakeForm = (state: UserSlice): boolean => {
    return selectClientData(state)?.hasCompletedIntakeForm === true;
};

export const selectUserHasLoggedIntoMobileApp = (state: UserSlice): boolean => {
    return !!selectUserPreferences(state).find((pref) => pref.name === USER_PREFERENCE_KEYS.hasLoggedIntoMobileApp)
        ?.value;
};

export const selectUserHasCompletedNutritionPrescriptionForm = (state: UserSlice): boolean => {
    return !!selectUserPreferences(state).find(
        (pref) => pref.name === USER_PREFERENCE_KEYS.hasCompletedNutritionPrescriptionForm
    )?.value;
};

export const selectClientCanAccessWeightTracking = (state: SubscriptionSlice): boolean => {
    return selectCurrentSubscription(state)?.product !== ClientSubscriptionProductDTO.CONCIERGE;
};

export const selectClientOptedOutOfWeightTracking = (state: UserSlice): boolean => {
    return selectClientData(state)?.weightTrackingEnabled === false;
};

export const selectUserFlags = createSelector(
    [selectUser],
    (user): Record<string, boolean> =>
        Object.fromEntries(user.data.preferences?.map((flag) => [flag.name, flag.value]) ?? [])
);

export const selectHasUserGraduated = (state: UserSlice): boolean => {
    const subscriptions = state.user.data.client?.subscriptions ?? [];

    return (
        subscriptions[0].product === ClientSubscriptionProductDTO.SELECT &&
        subscriptions[1]?.product === ClientSubscriptionProductDTO.COMPLETE
    );
};
