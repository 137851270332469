import clsx from 'clsx';
import { Button } from 'galley';
import type { OptionWithLabel } from 'galley/types';

export type Props = {
    suggestion: OptionWithLabel<string>;
    activeSuggestion: number;
    index: number;
    onSelect(suggestion: OptionWithLabel): void;
    normalize?: boolean;
};

export const Suggestion = ({ suggestion, activeSuggestion, index, onSelect, normalize }: Props): JSX.Element => (
    <li key={suggestion.label}>
        <Button
            data-active={activeSuggestion === index}
            tabIndex={index}
            className={clsx(
                'hover:bg-standard-rice focus:bg-standard-rice block w-full px-4 py-1 text-left hover:cursor-pointer focus:border-none',
                activeSuggestion === index && 'bg-standard-rice'
            )}
            onClick={() => onSelect(suggestion)}
        >
            {normalize ? suggestion.name.toLowerCase() : suggestion.name}
        </Button>
    </li>
);
