/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductSearchPredictionRejectReasonDTO {
    MODIFIER_ISSUE = 'MODIFIER_ISSUE',
    INCORRECT_QUANTITY = 'INCORRECT_QUANTITY',
    DOES_NOT_MATCH_RECIPE_ITEM = 'DOES_NOT_MATCH_RECIPE_ITEM',
    PRICE_TOO_HIGH = 'PRICE_TOO_HIGH',
    NEED_MORE_DETAIL = 'NEED_MORE_DETAIL',
    PATIENT_PREFERENCE_ISSUE = 'PATIENT_PREFERENCE_ISSUE',
    OTHER = 'OTHER',
    INGREDIENT_FORM_INCORRECT = 'INGREDIENT_FORM_INCORRECT',
    GENERAL_CLINICAL_QUALITY = 'GENERAL_CLINICAL_QUALITY',
    CONDITION_SPECIFIC_CLINICAL_QUALITY = 'CONDITION_SPECIFIC_CLINICAL_QUALITY',
    BETTER_CLINICAL_OPTION_AVAILABLE = 'BETTER_CLINICAL_OPTION_AVAILABLE'
};

