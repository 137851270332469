/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum DietModuleNameDTO {
    ADDED_SUGARS_EXCLUDED = 'ADDED_SUGARS_EXCLUDED',
    ADDED_SUGARS_LIMITED = 'ADDED_SUGARS_LIMITED',
    ALLERGY_EGG = 'ALLERGY_EGG',
    ALLERGY_FISH = 'ALLERGY_FISH',
    ALLERGY_MILK = 'ALLERGY_MILK',
    ALLERGY_PEANUT = 'ALLERGY_PEANUT',
    ALLERGY_SESAME = 'ALLERGY_SESAME',
    ALLERGY_SHELLFISH = 'ALLERGY_SHELLFISH',
    ALLERGY_SOY = 'ALLERGY_SOY',
    ALLERGY_TREE_NUT = 'ALLERGY_TREE_NUT',
    ALLERGY_WHEAT = 'ALLERGY_WHEAT',
    CALCIUM_1000 = 'CALCIUM_1000',
    CALCIUM_800 = 'CALCIUM_800',
    CARBOHYDRATE_BAND_I = 'CARBOHYDRATE_BAND_I',
    CARBOHYDRATE_BAND_II = 'CARBOHYDRATE_BAND_II',
    CARBOHYDRATE_BAND_III = 'CARBOHYDRATE_BAND_III',
    CARBOHYDRATES_RX = 'CARBOHYDRATES_RX',
    CALORIES_1200 = 'CALORIES_1200',
    CALORIES_1400 = 'CALORIES_1400',
    CALORIES_1600 = 'CALORIES_1600',
    DIETARY_RESTRICTION_LACTO_OVO_VEGETARIAN = 'DIETARY_RESTRICTION_LACTO_OVO_VEGETARIAN',
    DIETARY_RESTRICTION_LACTO_VEGETARIAN = 'DIETARY_RESTRICTION_LACTO_VEGETARIAN',
    DIETARY_RESTRICTION_OVO_VEGETARIAN = 'DIETARY_RESTRICTION_OVO_VEGETARIAN',
    DIETARY_RESTRICTION_PESCATARIAN = 'DIETARY_RESTRICTION_PESCATARIAN',
    DIETARY_RESTRICTION_VEGAN = 'DIETARY_RESTRICTION_VEGAN',
    FIBER_HIGH = 'FIBER_HIGH',
    FRUIT_JUICE_OR_DRIED_FRUIT_EXCLUDED = 'FRUIT_JUICE_OR_DRIED_FRUIT_EXCLUDED',
    FRUIT_VEGETABLE_GOAL = 'FRUIT_VEGETABLE_GOAL',
    INTOLERANCE_GLUTEN = 'INTOLERANCE_GLUTEN',
    INTOLERANCE_LACTOSE = 'INTOLERANCE_LACTOSE',
    KIDNEY_FRIENDLY = 'KIDNEY_FRIENDLY',
    LOW_FAT_DAIRY_EXCLUSIVE = 'LOW_FAT_DAIRY_EXCLUSIVE',
    OLIVE_OIL_EXCLUSIVE = 'OLIVE_OIL_EXCLUSIVE',
    PHOSPHORUS_1000 = 'PHOSPHORUS_1000',
    PHOSPHORUS_1200 = 'PHOSPHORUS_1200',
    PHOSPHORUS_DEFAULT_MAX = 'PHOSPHORUS_DEFAULT_MAX',
    POTASSIUM_2000 = 'POTASSIUM_2000',
    POTASSIUM_3000 = 'POTASSIUM_3000',
    POTASSIUM_DEFAULT_MAX = 'POTASSIUM_DEFAULT_MAX',
    POTASSIUM_RICH = 'POTASSIUM_RICH',
    POTATOES_LIMITED = 'POTATOES_LIMITED',
    PREGNANCY_FRIENDLY = 'PREGNANCY_FRIENDLY',
    PROCESSED_MEAT_EXCLUDED = 'PROCESSED_MEAT_EXCLUDED',
    PROCESSED_MEAT_LIMITED = 'PROCESSED_MEAT_LIMITED',
    PROTEIN_PLANT_BASED_PREFERENCE = 'PROTEIN_PLANT_BASED_PREFERENCE',
    PROTEIN_RX = 'PROTEIN_RX',
    RED_MEAT_EXCLUDED = 'RED_MEAT_EXCLUDED',
    SATURATED_FAT_LIMITED = 'SATURATED_FAT_LIMITED',
    SODIUM_1500 = 'SODIUM_1500',
    SODIUM_2300 = 'SODIUM_2300',
    WHOLE_GRAINS_OR_POTATOES_AT_MEALS = 'WHOLE_GRAINS_OR_POTATOES_AT_MEALS',
    WHOLE_GRAINS_EXCLUSIVE = 'WHOLE_GRAINS_EXCLUSIVE'
};

