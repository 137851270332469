/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum IngredientStateDTO {
    APPROVED_WITH_AVOIDANCES_AND_DIETS = 'APPROVED_WITH_AVOIDANCES_AND_DIETS',
    AWAITING_TAG_APPROVAL = 'AWAITING_TAG_APPROVAL',
    AWAITING_MERGE = 'AWAITING_MERGE',
    DEPRECATED = 'DEPRECATED'
};

