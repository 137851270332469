/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum GroceryListExternalIdTypeDTO {
    DATA_SERVICE_SCRAPE_ADDRESS_ID = 'DATA_SERVICE_SCRAPE_ADDRESS_ID',
    VENDOR_ACCOUNT = 'VENDOR_ACCOUNT',
    ORDER_URL = 'ORDER_URL'
};

