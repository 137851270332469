/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AdminResponseDTO } from './admin-response-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ClientUserDetailsDTO } from './client-user-details-dto';
// May contain unused imports in some cases
// @ts-ignore
import { LoginTypeDTO } from './login-type-dto';
// May contain unused imports in some cases
// @ts-ignore
import { MfaCommunicationChannelDTO } from './mfa-communication-channel-dto';
// May contain unused imports in some cases
// @ts-ignore
import { PreferenceItemDTO } from './preference-item-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ProviderUserDetailsDTO } from './provider-user-details-dto';

/**
 * 
 * @export
 * @interface UserDetailsDTO
 */
export interface UserDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDTO
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDTO
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsDTO
     */
    'requiresMfa': boolean;
    /**
     * 
     * @type {Array<MfaCommunicationChannelDTO>}
     * @memberof UserDetailsDTO
     */
    'availableMfaCommunicationChannels': Array<MfaCommunicationChannelDTO>;
    /**
     * 
     * @type {Array<PreferenceItemDTO>}
     * @memberof UserDetailsDTO
     */
    'preferences'?: Array<PreferenceItemDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDetailsDTO
     */
    'roles': Array<UserDetailsDTORolesEnum>;
    /**
     * 
     * @type {ClientUserDetailsDTO}
     * @memberof UserDetailsDTO
     */
    'client'?: ClientUserDetailsDTO;
    /**
     * 
     * @type {ProviderUserDetailsDTO}
     * @memberof UserDetailsDTO
     */
    'provider'?: ProviderUserDetailsDTO;
    /**
     * 
     * @type {AdminResponseDTO}
     * @memberof UserDetailsDTO
     */
    'admin'?: AdminResponseDTO;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsDTO
     */
    'isImpersonation': boolean;
    /**
     * 
     * @type {Array<LoginTypeDTO>}
     * @memberof UserDetailsDTO
     */
    'activeLoginTypes': Array<LoginTypeDTO>;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDetailsDTORolesEnum {
    ADMIN = 'ADMIN',
    CLIENT = 'CLIENT',
    PROVIDER = 'PROVIDER'
}

