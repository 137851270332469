import { FetchError } from 'apps/patient/queries/meta';
import axios from 'axios';
import type { DefaultOptions } from 'react-query';
import { QueryClient } from 'react-query';

export const defaultOptions: DefaultOptions = {
    queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount: number, error) => {
            let status;
            if (axios.isAxiosError(error)) {
                status = error.response?.status;
            } else if (error instanceof FetchError) {
                status = error.statusCode;
            }
            return status ? status >= 500 && failureCount < 3 : false;
        },
    },
    mutations: {
        retry: (failureCount: number, error) => {
            let status;
            if (axios.isAxiosError(error)) {
                status = error.response?.status;
            } else if (error instanceof FetchError) {
                status = error.statusCode;
            }
            return status ? (status === 409 || status >= 500) && failureCount < 3 : false;
        },
    },
};
export const queryClient = new QueryClient({ defaultOptions: defaultOptions });
