import axios from 'axios';
import { getPersistedItems, setPersistedItems } from '../localStorage/persistedLocalStorage';

const clearTokens = (): void => {
    const persistedItems = getPersistedItems();

    //Clear Local Storage
    localStorage.clear();
    //Clear Axios Header
    // eslint-disable-next-line lingui/no-unlocalized-strings
    delete axios.defaults.headers.common['Authorization'];

    setPersistedItems(persistedItems);
};

export default clearTokens;
