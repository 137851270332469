import { Trans } from '@lingui/macro';
import { Button } from 'galley';
import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';
import logger from 'utils/logger';
import { ReactComponent as SeasonLogo } from './assets/images/season-wordmark.svg';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        logger.error('Unhandled error', { errorInfo }, error);
    }

    public render(): ReactNode {
        if (this.state.hasError) {
            return (
                <div>
                    <SeasonLogo width={100} className="ml-4 mt-8" />
                    <div className="h-screenWithNav flex w-screen flex-col items-center justify-center">
                        <h1 className="headerLg mb-8">
                            <Trans>Sorry... an error has occurred</Trans>
                        </h1>
                        <Button variant="secondary" className="w-max" href={'/'}>
                            <Trans>Back to home</Trans>
                        </Button>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
