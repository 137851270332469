import { useField } from 'formik';
import type { Props } from './MultiselectDropdown';
import MultiselectDropdown from './MultiselectDropdown';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

const MultiselectDropdownWithFormik = (props: Optional<Props, 'onValueUpdate' | 'selectedValues' | 'groupLabel'>) => {
    const { name, onValueUpdate, options } = props;
    const [field, meta, helpers] = useField<string[]>(name);
    const selectedItems = field.value;

    return (
        <MultiselectDropdown
            {...props}
            options={options}
            isInvalid={!!meta.error}
            selectedValues={selectedItems}
            onValueUpdate={(values: string[]) => {
                helpers.setValue([...values]);
                onValueUpdate?.(values);
            }}
        />
    );
};

export default MultiselectDropdownWithFormik;
