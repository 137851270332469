const PARAMS = {
    RECIPE_QUERY: 'q_recipe',
    RECIPE_QUERY_ID: 'queryId',
    RECIPE_ID: 'recipeId',
    SHOVELER: 'shoveler',
    Y_SCROLL: 'y_scroll',
    PAGE: 'page',
    TIME_FILTER: 'time_filter',
    LEVEL_FILTER: 'level_filter',
    CALORIES_FILTER: 'calories_filter',
    PUSH_NOTIFICATION_MESSAGES: {
        WEEKLY_PLAN_ENCOURAGEMENT: 'weekly_plan_encouragement',
    },
};

export default PARAMS;
