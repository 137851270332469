export type AlertVariant = 'error' | 'warning' | 'info' | 'success';

export interface Alert {
    duration?: number;
    alertType: AlertVariant;
    alertMessage: string | JSX.Element;
}

export enum AlertActionTypes {
    LAUNCH_ALERT = '@@alert/LAUNCH_ALERT',
    OPEN_ALERT = '@@alert/OPEN_ALERT',
    CLOSE_ALERT = '@@alert/CLOSE_ALERT',
}

export interface AlertState {
    readonly openAlert: boolean;
    readonly alert: Alert;
}
