import type { Reducer } from 'redux';
import type { AlertState } from './types';
import { AlertActionTypes } from './types';
import type { AlertActions } from './actions';
import type { MetaActions } from '../meta/store';

export const initialAlertState: AlertState = {
    openAlert: false,
    alert: {
        alertType: 'info',
        alertMessage: '',
    },
};

const reducer: Reducer<AlertState, AlertActions | MetaActions> = (state = initialAlertState, action) => {
    switch (action.type) {
        case AlertActionTypes.OPEN_ALERT: {
            return { ...state, openAlert: true, alert: action.payload };
        }
        case AlertActionTypes.CLOSE_ALERT: {
            return { ...state, openAlert: false };
        }
        default: {
            return state;
        }
    }
};

export { reducer as alertReducer };
