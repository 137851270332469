import { ReactComponent as EyeClosedIcon } from 'assets/icons/eyeClosed.svg';
import { ReactComponent as EyeOpenIcon } from 'assets/icons/eyeOpen.svg';
import clsx from 'clsx';
import { IconButton } from 'galley';
import type React from 'react';
import { forwardRef, useId, useState } from 'react';
import Checkbox from './Checkbox/Checkbox';
import ChipCheckbox from './Checkbox/ChipCheckbox';
import FancyCheckbox from './Checkbox/FancyCheckbox';
import ChipRadio from './Radio/ChipRadio';
import FancyRadio from './Radio/FancyRadio';
import Radio from './Radio/Radio';

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    label: string | JSX.Element;
    isInvalid?: boolean;
    labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
    type?: React.HTMLInputTypeAttribute;
    className?: string;
    variant?: 'fancy' | 'chip';
    isLoading?: boolean;
    inlineLabel?: string;
    hideLabel?: boolean;
    inputClassName?: string;
    checkedLabelClassName?: string;
};

const Input = forwardRef((props: Props, ref: React.Ref<HTMLInputElement>): JSX.Element => {
    const {
        label,
        labelProps,
        isInvalid = false,
        type,
        className,
        hideLabel = false,
        variant,
        inlineLabel,
        inputClassName,
        ...rest
    } = props;
    const fallbackId = useId();
    const [showPassword, setShowPassword] = useState(false);

    const id = rest.id ?? fallbackId;

    const getType = () => {
        if (type === 'password' && showPassword) {
            return 'text';
        } else if (type === 'password' && !showPassword) {
            return 'password';
        }
        return type;
    };

    if (type === 'checkbox' && variant === 'chip') return <ChipCheckbox {...props} id={id} />;
    if (type === 'checkbox' && variant === 'fancy') return <FancyCheckbox {...props} id={id} />;
    if (type === 'checkbox') return <Checkbox {...props} id={id} />;
    if (type === 'radio' && variant === 'chip') return <ChipRadio {...props} id={id} />;
    if (type === 'radio' && variant === 'fancy') return <FancyRadio {...props} id={id} />;
    if (type === 'radio') return <Radio {...props} id={id} />;

    return (
        <div className={clsx('relative', hideLabel && `[&>label]:hidden`, inlineLabel && 'h-fit', className)}>
            <label htmlFor={id} className="bodyMdLight block w-full pb-1" {...labelProps}>
                {label}
            </label>
            <input
                type={getType()}
                data-dd-privacy="mask"
                data-dd-action-name={`${id} input`}
                id={id}
                className={clsx(
                    'bodyMdMedium hover:drop-shadow-input focus:drop-shadow-input invalid:border-alert-red2 block w-full rounded border px-4 py-3 outline-none placeholder:!font-normal focus:border-2 focus:px-[15px] focus:py-[11px]',
                    isInvalid ? 'border-alert-red2' : 'border-standard-barley',
                    inputClassName
                )}
                ref={ref}
                {...rest}
            />
            {type === 'password' && (
                <div className="overlineMedium text-greyscale-inactive absolute right-4 top-1/2 h-fit">
                    <IconButton
                        className="overlineMedium"
                        role="switch"
                        onClick={() => setShowPassword((prevState) => !prevState)}
                    >
                        {showPassword ? <EyeClosedIcon /> : <EyeOpenIcon />}
                    </IconButton>
                </div>
            )}
            {inlineLabel && (
                <p className="overlineMedium text-greyscale-inactive absolute right-4 top-1/2 h-fit">{inlineLabel}</p>
            )}
        </div>
    );
});

// eslint-disable-next-line lingui/no-unlocalized-strings
Input.displayName = 'Input';

export default Input;
