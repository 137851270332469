/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateAndInviteProviderRequestDTO } from '../models';
// @ts-ignore
import { CreateProviderRequestDTO } from '../models';
// @ts-ignore
import { EligibleProvidersResponseDTO } from '../models';
// @ts-ignore
import { ProviderBiographyDTO } from '../models';
// @ts-ignore
import { ProviderBusinessHoursResponseDTO } from '../models';
// @ts-ignore
import { ProviderBusyTimesResponseDTO } from '../models';
// @ts-ignore
import { ProviderClientOrderHistoryResponseDTO } from '../models';
// @ts-ignore
import { ProviderHealthDataServiceProxyTokenResponseDTO } from '../models';
// @ts-ignore
import { ProviderResponseDTO } from '../models';
// @ts-ignore
import { ProvidersResponseDTO } from '../models';
// @ts-ignore
import { SendProviderVerificationRequestDTO } from '../models';
// @ts-ignore
import { UpdateProviderRequestDTO } from '../models';
// @ts-ignore
import { VerifyAndActivateProviderRequestDTO } from '../models';
/**
 * ProviderApi - axios parameter creator
 * @export
 */
export const ProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create and Invite a Provider
         * @summary Create and Invite a Provider.
         * @param {CreateAndInviteProviderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndInviteProvider: async (request: CreateAndInviteProviderRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createAndInviteProvider', 'request', request)
            const localVarPath = `/providers/create-and-invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Provider
         * @param {CreateProviderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProvider: async (request: CreateProviderRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createProvider', 'request', request)
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a client\'s order history
         * @param {string} clientId The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientOrderHistory: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getClientOrderHistory', 'clientId', clientId)
            const localVarPath = `/providers/client-order-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider
         * @param {string} id The Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvider: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProvider', 'id', id)
            const localVarPath = `/providers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Biography by Id
         * @param {string} id The Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderBiography: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProviderBiography', 'id', id)
            const localVarPath = `/providers/{id}/biography`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider business hours
         * @param {string} id The Provider ID
         * @param {string} startDate The starting date to get business hours for
         * @param {string} endDate The ending date to get business hours for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderBusinessHours: async (id: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProviderBusinessHours', 'id', id)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getProviderBusinessHours', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getProviderBusinessHours', 'endDate', endDate)
            const localVarPath = `/providers/{id}/business-hours`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider busy times
         * @param {string} id The Provider ID
         * @param {string} startDate The starting date to get busy times for
         * @param {string} endDate The ending date to get busy times for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderBusyTimes: async (id: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProviderBusyTimes', 'id', id)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getProviderBusyTimes', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getProviderBusyTimes', 'endDate', endDate)
            const localVarPath = `/providers/{id}/busy-times`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a proxy token for the provider to access the health data service
         * @summary Get a proxy token for the provider to access the health data service
         * @param {string} id The id of the provider to get a proxy token for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderHealthDataServiceProxyToken: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProviderHealthDataServiceProxyToken', 'id', id)
            const localVarPath = `/providers/{id}/get-health-data-service-proxy-token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get eligible providers for given client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersEligibleForClient: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getProvidersEligibleForClient', 'clientId', clientId)
            const localVarPath = `/providers/eligible-for-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invite a Provider
         * @summary Invite a Provider.
         * @param {string} id The id of the provider to invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteProvider: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inviteProvider', 'id', id)
            const localVarPath = `/providers/{id}/invite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a provider verification email
         * @param {SendProviderVerificationRequestDTO} provider Information required to send a provider verification email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProviderVerificationEmail: async (provider: SendProviderVerificationRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('sendProviderVerificationEmail', 'provider', provider)
            const localVarPath = `/providers/send-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(provider, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing provider
         * @param {string} id The Provider ID
         * @param {UpdateProviderRequestDTO} provider The updated Provider resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvider: async (id: string, provider: UpdateProviderRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProvider', 'id', id)
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('updateProvider', 'provider', provider)
            const localVarPath = `/providers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(provider, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify and activate a provider in one step, rather than calling verify, login, and activate separately
         * @param {string} id The Provider ID
         * @param {VerifyAndActivateProviderRequestDTO} request Information required to verify and activate a new provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAndActivateProvider: async (id: string, request: VerifyAndActivateProviderRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verifyAndActivateProvider', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('verifyAndActivateProvider', 'request', request)
            const localVarPath = `/providers/{id}/verify-and-activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderApi - functional programming interface
 * @export
 */
export const ProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * Create and Invite a Provider
         * @summary Create and Invite a Provider.
         * @param {CreateAndInviteProviderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndInviteProvider(request: CreateAndInviteProviderRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndInviteProvider(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Provider
         * @param {CreateProviderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProvider(request: CreateProviderRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProvider(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a client\'s order history
         * @param {string} clientId The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientOrderHistory(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderClientOrderHistoryResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientOrderHistory(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Provider
         * @param {string} id The Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvider(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvider(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Provider Biography by Id
         * @param {string} id The Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderBiography(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderBiographyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderBiography(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Provider business hours
         * @param {string} id The Provider ID
         * @param {string} startDate The starting date to get business hours for
         * @param {string} endDate The ending date to get business hours for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderBusinessHours(id: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderBusinessHoursResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderBusinessHours(id, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Provider busy times
         * @param {string} id The Provider ID
         * @param {string} startDate The starting date to get busy times for
         * @param {string} endDate The ending date to get busy times for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderBusyTimes(id: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderBusyTimesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderBusyTimes(id, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a proxy token for the provider to access the health data service
         * @summary Get a proxy token for the provider to access the health data service
         * @param {string} id The id of the provider to get a proxy token for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderHealthDataServiceProxyToken(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderHealthDataServiceProxyTokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderHealthDataServiceProxyToken(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvidersResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get eligible providers for given client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvidersEligibleForClient(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EligibleProvidersResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvidersEligibleForClient(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invite a Provider
         * @summary Invite a Provider.
         * @param {string} id The id of the provider to invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteProvider(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteProvider(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a provider verification email
         * @param {SendProviderVerificationRequestDTO} provider Information required to send a provider verification email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendProviderVerificationEmail(provider: SendProviderVerificationRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendProviderVerificationEmail(provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing provider
         * @param {string} id The Provider ID
         * @param {UpdateProviderRequestDTO} provider The updated Provider resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProvider(id: string, provider: UpdateProviderRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProvider(id, provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify and activate a provider in one step, rather than calling verify, login, and activate separately
         * @param {string} id The Provider ID
         * @param {VerifyAndActivateProviderRequestDTO} request Information required to verify and activate a new provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyAndActivateProvider(id: string, request: VerifyAndActivateProviderRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyAndActivateProvider(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderApi - factory interface
 * @export
 */
export const ProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderApiFp(configuration)
    return {
        /**
         * Create and Invite a Provider
         * @summary Create and Invite a Provider.
         * @param {CreateAndInviteProviderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndInviteProvider(request: CreateAndInviteProviderRequestDTO, options?: any): AxiosPromise<ProviderResponseDTO> {
            return localVarFp.createAndInviteProvider(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Provider
         * @param {CreateProviderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProvider(request: CreateProviderRequestDTO, options?: any): AxiosPromise<ProviderResponseDTO> {
            return localVarFp.createProvider(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a client\'s order history
         * @param {string} clientId The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientOrderHistory(clientId: string, options?: any): AxiosPromise<ProviderClientOrderHistoryResponseDTO> {
            return localVarFp.getClientOrderHistory(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider
         * @param {string} id The Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvider(id: string, options?: any): AxiosPromise<ProviderResponseDTO> {
            return localVarFp.getProvider(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Biography by Id
         * @param {string} id The Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderBiography(id: string, options?: any): AxiosPromise<ProviderBiographyDTO> {
            return localVarFp.getProviderBiography(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider business hours
         * @param {string} id The Provider ID
         * @param {string} startDate The starting date to get business hours for
         * @param {string} endDate The ending date to get business hours for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderBusinessHours(id: string, startDate: string, endDate: string, options?: any): AxiosPromise<ProviderBusinessHoursResponseDTO> {
            return localVarFp.getProviderBusinessHours(id, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider busy times
         * @param {string} id The Provider ID
         * @param {string} startDate The starting date to get busy times for
         * @param {string} endDate The ending date to get busy times for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderBusyTimes(id: string, startDate: string, endDate: string, options?: any): AxiosPromise<ProviderBusyTimesResponseDTO> {
            return localVarFp.getProviderBusyTimes(id, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a proxy token for the provider to access the health data service
         * @summary Get a proxy token for the provider to access the health data service
         * @param {string} id The id of the provider to get a proxy token for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderHealthDataServiceProxyToken(id: string, options?: any): AxiosPromise<ProviderHealthDataServiceProxyTokenResponseDTO> {
            return localVarFp.getProviderHealthDataServiceProxyToken(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders(options?: any): AxiosPromise<ProvidersResponseDTO> {
            return localVarFp.getProviders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get eligible providers for given client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersEligibleForClient(clientId: string, options?: any): AxiosPromise<EligibleProvidersResponseDTO> {
            return localVarFp.getProvidersEligibleForClient(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Invite a Provider
         * @summary Invite a Provider.
         * @param {string} id The id of the provider to invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteProvider(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.inviteProvider(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a provider verification email
         * @param {SendProviderVerificationRequestDTO} provider Information required to send a provider verification email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProviderVerificationEmail(provider: SendProviderVerificationRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.sendProviderVerificationEmail(provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing provider
         * @param {string} id The Provider ID
         * @param {UpdateProviderRequestDTO} provider The updated Provider resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvider(id: string, provider: UpdateProviderRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateProvider(id, provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify and activate a provider in one step, rather than calling verify, login, and activate separately
         * @param {string} id The Provider ID
         * @param {VerifyAndActivateProviderRequestDTO} request Information required to verify and activate a new provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAndActivateProvider(id: string, request: VerifyAndActivateProviderRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.verifyAndActivateProvider(id, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderApi - object-oriented interface
 * @export
 * @class ProviderApi
 * @extends {BaseAPI}
 */
export class ProviderApi extends BaseAPI {
    /**
     * Create and Invite a Provider
     * @summary Create and Invite a Provider.
     * @param {CreateAndInviteProviderRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public createAndInviteProvider(request: CreateAndInviteProviderRequestDTO, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).createAndInviteProvider(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Provider
     * @param {CreateProviderRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public createProvider(request: CreateProviderRequestDTO, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).createProvider(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a client\'s order history
     * @param {string} clientId The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getClientOrderHistory(clientId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getClientOrderHistory(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider
     * @param {string} id The Provider ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProvider(id: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProvider(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Biography by Id
     * @param {string} id The Provider ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderBiography(id: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderBiography(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider business hours
     * @param {string} id The Provider ID
     * @param {string} startDate The starting date to get business hours for
     * @param {string} endDate The ending date to get business hours for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderBusinessHours(id: string, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderBusinessHours(id, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider busy times
     * @param {string} id The Provider ID
     * @param {string} startDate The starting date to get busy times for
     * @param {string} endDate The ending date to get busy times for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderBusyTimes(id: string, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderBusyTimes(id, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a proxy token for the provider to access the health data service
     * @summary Get a proxy token for the provider to access the health data service
     * @param {string} id The id of the provider to get a proxy token for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderHealthDataServiceProxyToken(id: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderHealthDataServiceProxyToken(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviders(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get eligible providers for given client
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProvidersEligibleForClient(clientId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProvidersEligibleForClient(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invite a Provider
     * @summary Invite a Provider.
     * @param {string} id The id of the provider to invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public inviteProvider(id: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).inviteProvider(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a provider verification email
     * @param {SendProviderVerificationRequestDTO} provider Information required to send a provider verification email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public sendProviderVerificationEmail(provider: SendProviderVerificationRequestDTO, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).sendProviderVerificationEmail(provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing provider
     * @param {string} id The Provider ID
     * @param {UpdateProviderRequestDTO} provider The updated Provider resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public updateProvider(id: string, provider: UpdateProviderRequestDTO, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).updateProvider(id, provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify and activate a provider in one step, rather than calling verify, login, and activate separately
     * @param {string} id The Provider ID
     * @param {VerifyAndActivateProviderRequestDTO} request Information required to verify and activate a new provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public verifyAndActivateProvider(id: string, request: VerifyAndActivateProviderRequestDTO, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).verifyAndActivateProvider(id, request, options).then((request) => request(this.axios, this.basePath));
    }
}
