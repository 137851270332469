import clsx from 'clsx';
import { Button, PlusStrokeIcon } from 'galley';

export type Props = {
    isSelected: boolean;
    onSelect(): void;
    name: string;
    className?: string;
    disabled?: boolean;
    role?: string;
};

const SelectableChip = ({ isSelected, onSelect, name, className, disabled, role }: Props): JSX.Element => (
    <Button
        className={clsx(
            'shadow-a-1 flex w-fit items-center gap-2 whitespace-nowrap rounded-md px-4 py-3',
            isSelected ? 'bg-mediumBlue-2 bodyMdMedium' : 'bodyMdLight bg-white',
            !disabled && 'hover:shadow-selector cursor-pointer',
            className
        )}
        role={role}
        onClick={onSelect}
        data-selected={isSelected}
        disabled={disabled}
    >
        <PlusStrokeIcon className={clsx('smIcon', isSelected ? '-rotate-45 transition' : 'rotate-0 transition')} />
        {name}
    </Button>
);
export default SelectableChip;
