import type {
    DeleteMealsRequestDTO,
    GetClientMealsResponseDTO,
    GetClientOrderedRecipesAndPreparedMealsResponseDTO,
    GetRecipeFeedbackResponseDTO,
    MarkMealAsPreparedRequestDTO,
    RecipeFeedbackResponseDTO,
} from 'API';
import type { AxiosResponse } from 'axios';
import type { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import { clientQueryKeys, mealsAPI } from 'utils';
import { queryClient } from 'utils/reactQuery';

export const mealsQueryKeys = {
    activeMeals: (clientId: string) => ['getActiveMeals', clientId] as const,
};

export const useActiveMeals = (
    clientId: string,
    options?: Omit<UseQueryOptions<GetClientMealsResponseDTO>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        mealsQueryKeys.activeMeals(clientId),
        () => mealsAPI.getActiveMeals(clientId).then((r) => r.data),
        options
    );

export const prefetchActiveMeals = (clientId: string) => {
    queryClient.prefetchQuery({
        queryKey: mealsQueryKeys.activeMeals(clientId),
        queryFn: () => mealsAPI.getActiveMeals(clientId).then((r) => r.data),
    });
};

interface DeleteMealArgs {
    id: string;
}

export const useDeleteMeal = (options?: UseMutationOptions<AxiosResponse<void>, unknown, DeleteMealArgs>) =>
    useMutation(
        (args: DeleteMealArgs) => {
            return mealsAPI.deleteMeal(args.id);
        },
        { ...options }
    );

interface DeleteMealsArgs {
    request: DeleteMealsRequestDTO;
}

export const useDeleteMeals = (options?: UseMutationOptions<AxiosResponse<void>, unknown, DeleteMealsArgs>) =>
    useMutation(
        (args: DeleteMealsArgs) => {
            return mealsAPI.deleteMeals(args.request);
        },
        { ...options }
    );

interface MarkAsPreparedArgs {
    id: string;
    feedback: MarkMealAsPreparedRequestDTO;
}

export const useMarkAsPrepared = (options?: UseMutationOptions<AxiosResponse<void>, unknown, MarkAsPreparedArgs>) =>
    useMutation(
        (args: MarkAsPreparedArgs) => {
            return mealsAPI.markMealAsPrepared(args.id, args.feedback);
        },
        { ...options }
    );

export const USE_GET_RECIPE_FEEDBACK_KEY = 'getRecipeFeedback';
export const useGetRecipeFeedback = (
    clientId: string,
    recipeIds: Array<string>,
    optimizations: boolean,
    options?: Omit<UseQueryOptions<GetRecipeFeedbackResponseDTO>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        [USE_GET_RECIPE_FEEDBACK_KEY, recipeIds],
        () => mealsAPI.getRecipeFeedback(clientId, recipeIds).then((r) => r.data),
        {
            placeholderData: optimizations
                ? () => {
                      const queryData = queryClient.getQueryData(
                          clientQueryKeys.getClientOrderedRecipesAndPreparedMeals(clientId)
                      ) as GetClientOrderedRecipesAndPreparedMealsResponseDTO | undefined;

                      const placeholderData = queryData?.recipes
                          ?.filter((r) => recipeIds.includes(r.recipeId))
                          ?.map((r) => {
                              return {
                                  recipeId: r.recipeId,
                                  mostRecentMeal: r.mostRecentMeal,
                                  mostRecentFeedback: r.mostRecentFeedback,
                                  wasNotPrepared: r.wasNotPrepared,
                              } as RecipeFeedbackResponseDTO;
                          }) as GetRecipeFeedbackResponseDTO | undefined;

                      return !!placeholderData ? { recipeFeedback: placeholderData } : undefined;
                  }
                : undefined,
            ...options,
        } as Omit<UseQueryOptions<GetRecipeFeedbackResponseDTO>, 'queryKey' | 'queryFn'>
    );

interface MarkAsNotPreparedArgs {
    id: string;
}

export const useMarkAsNotPrepared = (
    options?: UseMutationOptions<AxiosResponse<void>, unknown, MarkAsNotPreparedArgs>
) =>
    useMutation(
        (args: MarkAsNotPreparedArgs) => {
            return mealsAPI.markMealAsNotPrepared(args.id);
        },
        { ...options }
    );
