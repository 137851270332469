import clsx from 'clsx';
import { Fragment } from 'react';
import { EllipseFillIcon } from '../Icons';
export type Props = {
    activeStep: number;
    steps: string[];
};

type StepDescriptions = {
    isFirstStep: boolean;
    isLastStep: boolean;
    isIntermediateStep: boolean;
    isActiveStep: boolean;
    isBeforeActiveStep: boolean;
    isAfterActiveStep: boolean;
};

const getStepDescriptions = (i: number, activeStep: number, steps: Props['steps']): StepDescriptions => {
    return {
        isFirstStep: i === 0,
        isLastStep: i === steps.length - 1,
        isIntermediateStep: !(i === 0 || i === steps.length - 1),
        isActiveStep: i === activeStep,
        isBeforeActiveStep: i < activeStep,
        isAfterActiveStep: i > activeStep,
    };
};

const Stepper = ({ activeStep, steps }: Props): JSX.Element => {
    return (
        <div>
            <div className="flex flex-row">
                {steps.map((step: string, i) => {
                    const { isFirstStep, isActiveStep, isLastStep, isIntermediateStep } = getStepDescriptions(
                        i,
                        activeStep,
                        steps
                    );
                    return (
                        <div
                            key={step}
                            className={clsx('w-fill', {
                                'text-left': isFirstStep,
                                'text-center': isIntermediateStep,
                                'text-right': isLastStep,
                            })}
                        >
                            <span className={clsx(isActiveStep ? 'overlineMedium' : 'overlineLight')}>{step}</span>
                        </div>
                    );
                })}
            </div>
            <div className="flex flex-row items-center justify-between">
                {steps.map((step: string, i) => {
                    const { isActiveStep, isBeforeActiveStep, isLastStep, isAfterActiveStep } = getStepDescriptions(
                        i,
                        activeStep,
                        steps
                    );
                    return (
                        <Fragment key={step}>
                            <div className="inline">
                                <EllipseFillIcon
                                    className={clsx('w-fill mt-1', {
                                        'text-highBlue-1 h-2 w-2': isBeforeActiveStep,
                                        'text-highBlue-1 h-4 w-4': isActiveStep,
                                        'text-greyscale-30 h-2 w-2': isAfterActiveStep,
                                    })}
                                    key={`stepIcon-${i}`}
                                />
                            </div>
                            <hr
                                className={clsx(
                                    isLastStep && 'hidden',
                                    'mt-1 block h-0 w-full border-b-2  border-t-white',
                                    isBeforeActiveStep
                                        ? 'border-b-highBlue-1 border-solid'
                                        : 'border-b-greyscale-30 border-dotted'
                                )}
                            />
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default Stepper;
