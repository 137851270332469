import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { track } from 'utils';
import type { ContextState, Route } from './context';

export const useRouterContext = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const [route, setRoute] = useState<Route>({ to: location.pathname, from: undefined });

    const updateRoute = useCallback(() => {
        const next: Route = { to: location.pathname, from: route.to };
        if (next !== route) {
            setRoute(next);
            track('Page view', { pageViewDetails: { location } });
        }
    }, [route, location]);

    useEffect(() => {
        if (route.to !== pathname) {
            updateRoute();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return {
        route,
        updateRoute,
    } as ContextState;
};
