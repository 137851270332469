import type React from 'react';
import type { LinkProps } from 'react-router-dom';

export type ButtonTargetProps =
    | React.ButtonHTMLAttributes<HTMLButtonElement>
    | React.AnchorHTMLAttributes<HTMLAnchorElement>
    | LinkProps;

export type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

export function shouldRenderAsAnchor(props: ButtonTargetProps): props is React.AnchorHTMLAttributes<HTMLAnchorElement> {
    return props.hasOwnProperty('href');
}

export function shouldRenderAsLink(props: ButtonTargetProps): props is LinkProps {
    return props.hasOwnProperty('to');
}

export const getLabelFromChildren = (children: React.ReactNode): string => {
    if (typeof children === 'string') {
        return children;
    } else {
        const element = children as unknown as HTMLElement;
        return element?.textContent ?? element?.innerText;
    }
};
