import type {
    CreateFoodOrderCartRequestDTO,
    FoodOrderCartResponseDTO,
    FoodOrderCartsResponseDTO,
    FoodOrderLineItemDTO,
    GetClientOrderedRecipesAndPreparedMealsResponseDTO,
    GetPreparedMealFeedbackResponseDTO,
    MarkFoodOrderLineItemAsConsumedRequestDTO,
    MarkFoodOrderLineItemAsNotConsumedRequestDTO,
    PreparedMealFeedbackResponseDTO,
    PreparedMealResponseDTO,
    PreparedMealsResponseDTO,
} from 'API';
import type { AxiosResponse } from 'axios';
import type { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query/types/react/types';
import { clientAPI, clientQueryKeys, foodOrderApi, mealsAPI, preparedMealApi } from 'utils';
import { queryClient } from 'utils/reactQuery';

export const preparedMealQueryKeys = {
    getPreparedMealsForClient: (clientId: string) => ['preparedMeals', clientId],
    activeFoodOrderCarts: (clientId: string) => ['refreshActiveFoodOrderCarts', clientId],
};

export const usePreparedMeals = (
    clientId: string,
    options?: Omit<UseQueryOptions<PreparedMealsResponseDTO>, 'queryFn' | 'queryKey'>
) =>
    useQuery(
        preparedMealQueryKeys.getPreparedMealsForClient(clientId),
        () => preparedMealApi.getPreparedMealOptionsForClient(clientId).then((r) => r.data),
        options
    );

export const usePreparedMeal = (
    mealId: string,
    clientId: string,
    optimizations: boolean,
    options?: Omit<UseQueryOptions<PreparedMealResponseDTO>, 'queryFn' | 'queryKey'>
): UseQueryResult<PreparedMealResponseDTO> =>
    useQuery(['preparedMeal', mealId], () => preparedMealApi.getPreparedMeal(mealId).then((r) => r.data), {
        initialData: optimizations
            ? () => {
                  const queryData = queryClient.getQueryData(
                      preparedMealQueryKeys.getPreparedMealsForClient(clientId)
                  ) as PreparedMealsResponseDTO | undefined;

                  const initialData = queryData?.meals?.find((m) => m.id === mealId);

                  return !!initialData ? initialData : undefined;
              }
            : undefined,
        ...options,
    } as Omit<UseQueryOptions<PreparedMealResponseDTO>, 'queryFn' | 'queryKey'>);

export const useActiveFoodOrderCarts = (
    clientId: string,
    options?: Omit<UseQueryOptions<FoodOrderCartsResponseDTO>, 'queryFn' | 'queryKey'>
): UseQueryResult<FoodOrderCartsResponseDTO> =>
    useQuery(
        preparedMealQueryKeys.activeFoodOrderCarts(clientId),
        () => clientAPI.refreshActiveFoodOrderCarts(clientId).then((r) => r.data),
        { ...{ enabled: !!clientId }, ...options } as Omit<
            UseQueryOptions<FoodOrderCartsResponseDTO>,
            'queryFn' | 'queryKey'
        >
    );

export const useCreateFoodOrderCart = (
    options?: UseMutationOptions<AxiosResponse<FoodOrderCartResponseDTO>, unknown, CreateFoodOrderCartRequestDTO>
) =>
    useMutation((args: CreateFoodOrderCartRequestDTO) => foodOrderApi.createFoodOrderCart(args), {
        ...options,
    });

interface UpdateFoodOrderCartArgs {
    cartId: string;
    items: Array<FoodOrderLineItemDTO>;
}

export const useUpdateFoodOrderCart = (
    options?: UseMutationOptions<AxiosResponse<FoodOrderCartResponseDTO>, unknown, UpdateFoodOrderCartArgs>
) =>
    useMutation(
        (args: UpdateFoodOrderCartArgs) => foodOrderApi.updateFoodOrderCart(args.cartId, { items: args.items }),
        { ...options }
    );

export const useGetFoodOrderById = (
    foodOrderId: string,
    options?: Omit<UseQueryOptions<FoodOrderCartResponseDTO>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        ['getFoodOrderById', foodOrderId],
        () => foodOrderApi.getFoodOrderCart(foodOrderId).then((r) => r.data),
        options
    );

interface markFoodOrderLineItemAsConsumedArgs {
    id: string;
    request: MarkFoodOrderLineItemAsConsumedRequestDTO;
}

export const useMarkFoodOrderLineItemAsConsumed = (
    options?: UseMutationOptions<AxiosResponse<void>, unknown, markFoodOrderLineItemAsConsumedArgs>
) =>
    useMutation(
        ({ id, request }: markFoodOrderLineItemAsConsumedArgs) => mealsAPI.markFoodOrderLineItemAsConsumed(id, request),
        options
    );

export const useGetPreparedMealFeedback = (
    clientId: string,
    preparedMealIds: Array<string>,
    optimizations: boolean,
    options?: Omit<UseQueryOptions<GetPreparedMealFeedbackResponseDTO>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        ['getPreparedMealFeedback', preparedMealIds],
        () => foodOrderApi.getPreparedMealFeedback(clientId, preparedMealIds).then((r) => r.data),
        {
            placeholderData: optimizations
                ? () => {
                      const queryData = queryClient.getQueryData(
                          clientQueryKeys.getClientOrderedRecipesAndPreparedMeals(clientId)
                      ) as GetClientOrderedRecipesAndPreparedMealsResponseDTO | undefined;

                      const placeholderData = queryData?.preparedMeals
                          ?.filter((p) => preparedMealIds.includes(p.preparedMealId))
                          ?.map((p) => {
                              return {
                                  preparedMealId: p.preparedMealId,
                                  mostRecentOrder: p.mostRecentOrder,
                                  mostRecentFeedback: p.mostRecentFeedback,
                                  wasNotConsumed: p.wasNotConsumed,
                              } as PreparedMealFeedbackResponseDTO;
                          }) as GetPreparedMealFeedbackResponseDTO | undefined;

                      return !!placeholderData ? { preparedMealFeedback: placeholderData } : undefined;
                  }
                : undefined,
            ...options,
        } as Omit<UseQueryOptions<GetPreparedMealFeedbackResponseDTO>, 'queryKey' | 'queryFn'>
    );

interface markFoodOrderLineItemAsNotConsumedArg {
    id: string;
    request: MarkFoodOrderLineItemAsNotConsumedRequestDTO;
}

export const useMarkFoodOrderLineItemAsNotConsumed = (
    options?: UseMutationOptions<AxiosResponse<void>, unknown, markFoodOrderLineItemAsNotConsumedArg>
) =>
    useMutation(
        (args: markFoodOrderLineItemAsNotConsumedArg) => {
            return foodOrderApi.markFoodOrderLineItemAsNotConsumed(args.id, args.request);
        },
        { ...options }
    );
