import type { MealResponseDTO } from 'API';
import type { Reducer } from 'redux';
import type { loginRequestSuccess, userDetailsRefresh } from 'store/user/actions';
import { UserActionTypes } from 'store/user/types';
import { action } from 'typesafe-actions';
import type { MetaActions } from '../../../../store/meta/store';

export interface PatientState {
    recipes: {
        recommendedMeals: MealResponseDTO[];
    };
    favoriteRecipeIds: string[];
}

export const initialState: PatientState = {
    recipes: {
        recommendedMeals: [],
    },
    favoriteRecipeIds: [],
};

enum PatientActionTypes {
    UPDATE_FAVORITE_RECIPE_IDS = '@@patient/UPDATE_FAVORITE_RECIPES',
}

export function updateFavoriteRecipes(recipeId: string, favorite: boolean) {
    return action(PatientActionTypes.UPDATE_FAVORITE_RECIPE_IDS, { recipeId, favorite });
}

export type PatientActions =
    | ReturnType<typeof updateFavoriteRecipes>
    | ReturnType<typeof loginRequestSuccess>
    | ReturnType<typeof userDetailsRefresh>;

const reducer: Reducer<PatientState, PatientActions | MetaActions> = (state = initialState, action) => {
    switch (action.type) {
        case PatientActionTypes.UPDATE_FAVORITE_RECIPE_IDS: {
            const { recipeId, favorite } = action.payload;
            const favoriteRecipeIds = state.favoriteRecipeIds;
            const newFavorites = favorite
                ? [...favoriteRecipeIds, recipeId]
                : favoriteRecipeIds.filter((id: string) => id !== recipeId);

            return { ...state, favoriteRecipeIds: newFavorites };
        }
        case UserActionTypes.LOGIN_REQUEST_SUCCESS: {
            return { ...state, favoriteRecipeIds: action.payload.userDetails?.client?.favoriteRecipeIds ?? [] };
        }
        case UserActionTypes.USER_DETAILS_REFRESH: {
            return { ...state, favoriteRecipeIds: action.payload.client?.favoriteRecipeIds ?? [] };
        }
        default: {
            return state;
        }
    }
};

export { reducer as patientReducer };
