import { Trans } from '@lingui/macro';
import { RouterContext } from 'Routes/useRouterContext';
import { Button, WarningStrokeIcon } from 'galley';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = ({
    homeRoute = '/',
    additionalMessage,
}: {
    homeRoute?: string;
    additionalMessage?: JSX.Element;
}): JSX.Element => {
    const navigate = useNavigate();
    const {
        route: { from },
    } = useContext(RouterContext);

    return (
        <div className="fixed left-1/2 top-1/2 min-w-fit -translate-x-1/2 -translate-y-1/2 text-center">
            <h1 className="headerMdSans mb-2">
                <Trans>Oops, we&apos;ve run into a pickle!</Trans>
            </h1>
            <WarningStrokeIcon className="lgIcon m-auto my-6" />
            <p className="mb-12">
                <Trans>Something went wrong on our end. Let&apos;s head back to where you left off.</Trans>
            </p>

            {from && (
                <Button variant="primary" onClick={() => navigate(-1)} className="bodyMdMedium m-auto mb-4">
                    <Trans>Take me back</Trans>
                </Button>
            )}
            <Button
                variant={from ? 'secondary' : 'primary'}
                onClick={() => {
                    navigate(homeRoute);
                }}
                className="bodyMdMedium m-auto"
            >
                <Trans>Visit home</Trans>
            </Button>

            {additionalMessage && additionalMessage}
        </div>
    );
};

export default NotFoundPage;
