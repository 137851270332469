import clsx from 'clsx';
import Input from 'galley/Input/Input';
import { useEffect, useState } from 'react';

export type OptionsProps = {
    label: string | JSX.Element;
    value: string;
    isDisabled?: boolean;
};

export type Props = {
    name?: string;
    classNames?: {
        fieldset?: string;
        label?: string;
        legend?: string;
        checked?: string;
    };
    groupLabel?: string;
    options: OptionsProps[];
    onValueUpdate: (value: string[]) => void;
    selectedValues: string[];
};

const CheckboxSelectorGroup = ({
    name,
    classNames,
    groupLabel,
    onValueUpdate,
    options,
    selectedValues,
}: Props): JSX.Element => {
    const [values, setValues] = useState(selectedValues);
    useEffect(() => {
        if (values.sort().toString() !== selectedValues.sort().toString()) {
            setValues(selectedValues);
        }
    }, [selectedValues, values]);

    return (
        <fieldset className={classNames?.fieldset}>
            {groupLabel && <legend className={clsx('bodyMdMedium mb-2', classNames?.legend)}>{groupLabel}</legend>}
            {options.map((opt) => {
                const { label, value, isDisabled } = opt;
                const checked = selectedValues?.includes(value);
                const toggleCheck = () => {
                    const updatedValues = checked ? values.filter((v) => v !== value) : values.concat(value);
                    setValues(updatedValues);
                    onValueUpdate(updatedValues);
                };
                return (
                    <Input
                        name={name ?? groupLabel}
                        type="checkbox"
                        key={value}
                        checked={checked}
                        label={label}
                        className={clsx('mb-2', checked && classNames?.checked, classNames?.label)}
                        disabled={isDisabled}
                        onChange={toggleCheck}
                    />
                );
            })}
        </fieldset>
    );
};

export default CheckboxSelectorGroup;
