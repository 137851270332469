import type { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { ENABLE_LAUNCHDARKLY } from '../constants/env';

export const SEASON_FLAGS_KEY = 'season-flags';

const useFlags = (): LDFlagSet => {
    const getSeasonOverrides = (): LDFlagSet => {
        try {
            return JSON.parse(localStorage.getItem(SEASON_FLAGS_KEY) || '{}');
        } catch {
            return {};
        }
    };

    const [overrides, setOverrides] = useState(getSeasonOverrides());

    window.onstorage = (): void => {
        setOverrides(getSeasonOverrides());
    };

    const ldFlags = useLDFlags();

    if (ENABLE_LAUNCHDARKLY) {
        return { ...ldFlags, ...(overrides as LDFlagSet) } as LDFlagSet;
    } else {
        return overrides as LDFlagSet;
    }
};

export default useFlags;
