import clsx from 'clsx';
import { useLayoutEffect, useRef } from 'react';

export type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    className?: string;
    dataTestId?: string;
    label?: string | JSX.Element;
    labelClassname?: string;
    autoresize?: boolean;
};

const TextArea = ({ className, dataTestId, label, labelClassname, value, autoresize, ...rest }: Props): JSX.Element => {
    const ref = useRef<HTMLTextAreaElement>(null);

    useLayoutEffect(() => {
        if (autoresize && ref?.current) {
            ref.current.style.height = 'inherit';
            ref.current.style.height = `${ref.current.scrollHeight}px`;
        }
    }, [value, autoresize]);

    return (
        <>
            {label && (
                <label className={clsx(labelClassname, 'bodyMdLight')} htmlFor={rest.id}>
                    {label}
                </label>
            )}
            <textarea
                data-dd-privacy="mask"
                data-test-id={dataTestId}
                className={clsx(
                    'border-standard-barley focus:outline-standard-barley resize-none rounded border px-4 py-3 focus:shadow',
                    className
                )}
                ref={ref}
                value={value}
                {...rest}
            />
        </>
    );
};

export default TextArea;
