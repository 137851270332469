import { t } from '@lingui/macro';

export const getGenericErrors = () => ({
    DEFAULT: t`Something went wrong.`,
    EMAIL_FORMAT: t`Invalid Email`,
    POSITIVE_NUMBER: t`Must be Greater Than 0`,
    POSTIIVE_INTEGER: t`Must be a Positive Whole Number`,
    REQUIRED: t`Required`,
    INVALID: t`Invalid`,
    UPPERCASE: t`Must be Uppercase`,
});

export const getRdnOnboardingErrors = () => ({
    EIN_ERROR: t`Please check your EIN and try again. If it is correct, please contact support@seasonhealth.com to proceed.`,
    CANT_REGISTER: t`We were unable to create your practice. Please try again or contact support if you continue to experience issues.`,
});

export const getDateErrors = () => ({
    WRONG_FORMAT: t`Date of birth should be in MM/DD/YYYY format.`,
    FUTURE_DATE: t`Date of birth must be in the past.`,
    UNDERAGE: t`You must be 18 years or older.`,
    REQUIRED: t`Required`,
});
