/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddPasswordRequestDTO } from '../models';
// @ts-ignore
import { GetAvailableLoginTypesResponseDTO } from '../models';
// @ts-ignore
import { ImpersonationRequestDTO } from '../models';
// @ts-ignore
import { LoginRequestDTO } from '../models';
// @ts-ignore
import { NewTwoFactorAuthCodeRequestDTO } from '../models';
// @ts-ignore
import { PasswordlessLoginRequestDTO } from '../models';
// @ts-ignore
import { RefreshTokenRequestDTO } from '../models';
// @ts-ignore
import { ResetPasswordRequestDTO } from '../models';
// @ts-ignore
import { SsoAddRequestDTO } from '../models';
// @ts-ignore
import { SsoLoginRequestDTO } from '../models';
// @ts-ignore
import { TokenResponseDTO } from '../models';
// @ts-ignore
import { TwoFactorAuthCodeRequestDTO } from '../models';
// @ts-ignore
import { UpdatePasswordRequestDTO } from '../models';
// @ts-ignore
import { UserDetailsDTO } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Apple SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAppleSso: async (token: SsoAddRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('addAppleSso', 'token', token)
            const localVarPath = `/auth/add-apple-sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Google SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGoogleSso: async (token: SsoAddRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('addGoogleSso', 'token', token)
            const localVarPath = `/auth/add-google-sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a password for a logged in user
         * @param {AddPasswordRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPassword: async (request: AddPasswordRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('addPassword', 'request', request)
            const localVarPath = `/auth/add-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log into the application with a Apple ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appleLogin: async (token: SsoLoginRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('appleLogin', 'token', token)
            const localVarPath = `/auth/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available login types for an email address
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableLoginTypes: async (emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('getAvailableLoginTypes', 'emailAddress', emailAddress)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the details for the current account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/user_details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log into the application with a Google ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogin: async (token: SsoLoginRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('googleLogin', 'token', token)
            const localVarPath = `/auth/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Begin an impersonation session for an Account. You must either be an admin or the provider in the same practice as the account being impersonated. 
         * @param {ImpersonationRequestDTO} credentials The details of the Account to impersonate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonationLogin: async (credentials: ImpersonationRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentials' is not null or undefined
            assertParamExists('impersonationLogin', 'credentials', credentials)
            const localVarPath = `/auth/impersonation/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End an impersonation session and swap the token back to the original impersonator\'s account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonationLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/impersonation/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs into the application with a username and a password and gets a token.
         * @param {LoginRequestDTO} credentials the credentials of the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (credentials: LoginRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentials' is not null or undefined
            assertParamExists('login', 'credentials', credentials)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a new access token given an access token that may have been expired.
         * @param {RefreshTokenRequestDTO} token A refresh token request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (token: RefreshTokenRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('refreshToken', 'token', token)
            const localVarPath = `/auth/refresh_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Apple SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAppleSso: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/remove-apple-sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Google SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGoogleSso: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/remove-google-sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a new two-factor authentication code be sent to the user.
         * @param {NewTwoFactorAuthCodeRequestDTO} channel the specific channel the user requests to have their MFA token sent to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestNewTwoFactorAuthCode: async (channel: NewTwoFactorAuthCodeRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channel' is not null or undefined
            assertParamExists('requestNewTwoFactorAuthCode', 'channel', channel)
            const localVarPath = `/auth/request-new-two-factor-auth-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(channel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a passwordless login for a given email address
         * @param {PasswordlessLoginRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordlessLogin: async (request: PasswordlessLoginRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('requestPasswordlessLogin', 'request', request)
            const localVarPath = `/auth/passwordless`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset password flow
         * @param {ResetPasswordRequestDTO} resetPasswordRequest a request to reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequest: ResetPasswordRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('resetPassword', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/auth/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update password for a logged in user
         * @param {UpdatePasswordRequestDTO} request a request to update the user\&#39;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (request: UpdatePasswordRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updatePassword', 'request', request)
            const localVarPath = `/auth/update_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete authentication for user accounts which require two-factor authentication
         * @param {TwoFactorAuthCodeRequestDTO} credentials the two-factor auth code for the current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTwoFactorAuthCode: async (credentials: TwoFactorAuthCodeRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentials' is not null or undefined
            assertParamExists('verifyTwoFactorAuthCode', 'credentials', credentials)
            const localVarPath = `/auth/verify-two-factor-auth-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Apple SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAppleSso(token: SsoAddRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAppleSso(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add Google SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGoogleSso(token: SsoAddRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGoogleSso(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a password for a logged in user
         * @param {AddPasswordRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPassword(request: AddPasswordRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPassword(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log into the application with a Apple ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appleLogin(token: SsoLoginRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appleLogin(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available login types for an email address
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableLoginTypes(emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAvailableLoginTypesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableLoginTypes(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the details for the current account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log into the application with a Google ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleLogin(token: SsoLoginRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleLogin(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Begin an impersonation session for an Account. You must either be an admin or the provider in the same practice as the account being impersonated. 
         * @param {ImpersonationRequestDTO} credentials The details of the Account to impersonate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async impersonationLogin(credentials: ImpersonationRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.impersonationLogin(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary End an impersonation session and swap the token back to the original impersonator\'s account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async impersonationLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.impersonationLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logs into the application with a username and a password and gets a token.
         * @param {LoginRequestDTO} credentials the credentials of the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(credentials: LoginRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a new access token given an access token that may have been expired.
         * @param {RefreshTokenRequestDTO} token A refresh token request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(token: RefreshTokenRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Apple SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAppleSso(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAppleSso(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Google SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeGoogleSso(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeGoogleSso(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request a new two-factor authentication code be sent to the user.
         * @param {NewTwoFactorAuthCodeRequestDTO} channel the specific channel the user requests to have their MFA token sent to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestNewTwoFactorAuthCode(channel: NewTwoFactorAuthCodeRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestNewTwoFactorAuthCode(channel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request a passwordless login for a given email address
         * @param {PasswordlessLoginRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestPasswordlessLogin(request: PasswordlessLoginRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestPasswordlessLogin(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset password flow
         * @param {ResetPasswordRequestDTO} resetPasswordRequest a request to reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequest: ResetPasswordRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update password for a logged in user
         * @param {UpdatePasswordRequestDTO} request a request to update the user\&#39;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(request: UpdatePasswordRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete authentication for user accounts which require two-factor authentication
         * @param {TwoFactorAuthCodeRequestDTO} credentials the two-factor auth code for the current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyTwoFactorAuthCode(credentials: TwoFactorAuthCodeRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyTwoFactorAuthCode(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Apple SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAppleSso(token: SsoAddRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addAppleSso(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Google SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGoogleSso(token: SsoAddRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addGoogleSso(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a password for a logged in user
         * @param {AddPasswordRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPassword(request: AddPasswordRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addPassword(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log into the application with a Apple ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appleLogin(token: SsoLoginRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.appleLogin(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available login types for an email address
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableLoginTypes(emailAddress: string, options?: any): AxiosPromise<GetAvailableLoginTypesResponseDTO> {
            return localVarFp.getAvailableLoginTypes(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the details for the current account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetails(options?: any): AxiosPromise<UserDetailsDTO> {
            return localVarFp.getUserDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log into the application with a Google ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogin(token: SsoLoginRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.googleLogin(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Begin an impersonation session for an Account. You must either be an admin or the provider in the same practice as the account being impersonated. 
         * @param {ImpersonationRequestDTO} credentials The details of the Account to impersonate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonationLogin(credentials: ImpersonationRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.impersonationLogin(credentials, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End an impersonation session and swap the token back to the original impersonator\'s account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonationLogout(options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.impersonationLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs into the application with a username and a password and gets a token.
         * @param {LoginRequestDTO} credentials the credentials of the user making the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(credentials: LoginRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.login(credentials, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a new access token given an access token that may have been expired.
         * @param {RefreshTokenRequestDTO} token A refresh token request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(token: RefreshTokenRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.refreshToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Apple SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAppleSso(options?: any): AxiosPromise<void> {
            return localVarFp.removeAppleSso(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Google SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGoogleSso(options?: any): AxiosPromise<void> {
            return localVarFp.removeGoogleSso(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a new two-factor authentication code be sent to the user.
         * @param {NewTwoFactorAuthCodeRequestDTO} channel the specific channel the user requests to have their MFA token sent to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestNewTwoFactorAuthCode(channel: NewTwoFactorAuthCodeRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.requestNewTwoFactorAuthCode(channel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a passwordless login for a given email address
         * @param {PasswordlessLoginRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordlessLogin(request: PasswordlessLoginRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.requestPasswordlessLogin(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset password flow
         * @param {ResetPasswordRequestDTO} resetPasswordRequest a request to reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest: ResetPasswordRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update password for a logged in user
         * @param {UpdatePasswordRequestDTO} request a request to update the user\&#39;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(request: UpdatePasswordRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updatePassword(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete authentication for user accounts which require two-factor authentication
         * @param {TwoFactorAuthCodeRequestDTO} credentials the two-factor auth code for the current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTwoFactorAuthCode(credentials: TwoFactorAuthCodeRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.verifyTwoFactorAuthCode(credentials, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Add Apple SSO to account
     * @param {SsoAddRequestDTO} token the ID token from Apple
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public addAppleSso(token: SsoAddRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).addAppleSso(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Google SSO to account
     * @param {SsoAddRequestDTO} token the ID token from Google
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public addGoogleSso(token: SsoAddRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).addGoogleSso(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a password for a logged in user
     * @param {AddPasswordRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public addPassword(request: AddPasswordRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).addPassword(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log into the application with a Apple ID token and gets an app token.
     * @param {SsoLoginRequestDTO} token the ID token from Apple
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public appleLogin(token: SsoLoginRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).appleLogin(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available login types for an email address
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAvailableLoginTypes(emailAddress: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAvailableLoginTypes(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the details for the current account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getUserDetails(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getUserDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log into the application with a Google ID token and gets an app token.
     * @param {SsoLoginRequestDTO} token the ID token from Google
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public googleLogin(token: SsoLoginRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).googleLogin(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Begin an impersonation session for an Account. You must either be an admin or the provider in the same practice as the account being impersonated. 
     * @param {ImpersonationRequestDTO} credentials The details of the Account to impersonate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public impersonationLogin(credentials: ImpersonationRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).impersonationLogin(credentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary End an impersonation session and swap the token back to the original impersonator\'s account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public impersonationLogout(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).impersonationLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs into the application with a username and a password and gets a token.
     * @param {LoginRequestDTO} credentials the credentials of the user making the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(credentials: LoginRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(credentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a new access token given an access token that may have been expired.
     * @param {RefreshTokenRequestDTO} token A refresh token request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshToken(token: RefreshTokenRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Apple SSO from account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public removeAppleSso(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).removeAppleSso(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Google SSO from account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public removeGoogleSso(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).removeGoogleSso(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a new two-factor authentication code be sent to the user.
     * @param {NewTwoFactorAuthCodeRequestDTO} channel the specific channel the user requests to have their MFA token sent to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public requestNewTwoFactorAuthCode(channel: NewTwoFactorAuthCodeRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).requestNewTwoFactorAuthCode(channel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a passwordless login for a given email address
     * @param {PasswordlessLoginRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public requestPasswordlessLogin(request: PasswordlessLoginRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).requestPasswordlessLogin(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset password flow
     * @param {ResetPasswordRequestDTO} resetPasswordRequest a request to reset password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPassword(resetPasswordRequest: ResetPasswordRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).resetPassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update password for a logged in user
     * @param {UpdatePasswordRequestDTO} request a request to update the user\&#39;s password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updatePassword(request: UpdatePasswordRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).updatePassword(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete authentication for user accounts which require two-factor authentication
     * @param {TwoFactorAuthCodeRequestDTO} credentials the two-factor auth code for the current account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyTwoFactorAuthCode(credentials: TwoFactorAuthCodeRequestDTO, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).verifyTwoFactorAuthCode(credentials, options).then((request) => request(this.axios, this.basePath));
    }
}
