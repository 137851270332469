/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActivateClientRequestDTO } from '../models';
// @ts-ignore
import { AddClientAbnDetailsRequestDTO } from '../models';
// @ts-ignore
import { AddClientGoalGroupRequestDTO } from '../models';
// @ts-ignore
import { AssignSubscriptionPackageRequestDTO } from '../models';
// @ts-ignore
import { ClientCancelSubscriptionRequestDTO } from '../models';
// @ts-ignore
import { ClientFavoriteRecipesResponseDTO } from '../models';
// @ts-ignore
import { ClientNotificationPreferencesDTO } from '../models';
// @ts-ignore
import { ClientNutritionPrescriptionDescriptionResponseDTO } from '../models';
// @ts-ignore
import { ClientOutstandingBalancesResponseDTO } from '../models';
// @ts-ignore
import { ClientPushNotificationSubscriptionRequestDTO } from '../models';
// @ts-ignore
import { ClientRecipePromotionDTO } from '../models';
// @ts-ignore
import { ClientRecipeSearchFiltersDTO } from '../models';
// @ts-ignore
import { ClientResponseDTO } from '../models';
// @ts-ignore
import { ClientWeightEntriesResponseDTO } from '../models';
// @ts-ignore
import { CommunicationThreadDTO } from '../models';
// @ts-ignore
import { CreateAndInviteClientRequestDTO } from '../models';
// @ts-ignore
import { CreateAndInviteClientResponseDTO } from '../models';
// @ts-ignore
import { CreateClientWeightEntryRequestDTO } from '../models';
// @ts-ignore
import { DeleteClientWeightEntryRequestDTO } from '../models';
// @ts-ignore
import { EducationProgramEnrollmentRequestDTO } from '../models';
// @ts-ignore
import { FoodOrderCartsResponseDTO } from '../models';
// @ts-ignore
import { FormSubmissionsResponseDTO } from '../models';
// @ts-ignore
import { GetAvailableStartingSubscriptionsResponseDTO } from '../models';
// @ts-ignore
import { GetClientGoalGroupResponseDTO } from '../models';
// @ts-ignore
import { GetClientHealthDataUniversalIdResponseDTO } from '../models';
// @ts-ignore
import { GetClientOrderedRecipesAndPreparedMealsResponseDTO } from '../models';
// @ts-ignore
import { GetClientsResponseDTO } from '../models';
// @ts-ignore
import { GroceryListResponseDTO } from '../models';
// @ts-ignore
import { IntakeFormResponsesDTO } from '../models';
// @ts-ignore
import { InviteClientWithEmailRequestDTO } from '../models';
// @ts-ignore
import { OrderHistoryResponseDTO } from '../models';
// @ts-ignore
import { PushNotificationMessageRequestDTO } from '../models';
// @ts-ignore
import { RecipesResponseDTO } from '../models';
// @ts-ignore
import { SearchClientsResponseDTO } from '../models';
// @ts-ignore
import { SendRecipesViaSmsRequestDTO } from '../models';
// @ts-ignore
import { SendSmsRequestDTO } from '../models';
// @ts-ignore
import { SetupPaymentIntentResponseDTO } from '../models';
// @ts-ignore
import { StartClientSubscriptionRequestDTO } from '../models';
// @ts-ignore
import { SubmitIntakeFormRequestDTO } from '../models';
// @ts-ignore
import { TokenResponseDTO } from '../models';
// @ts-ignore
import { UpdateClientAvailableCookingEquipmentRequestDTO } from '../models';
// @ts-ignore
import { UpdateClientCohortDetailsRequestDTO } from '../models';
// @ts-ignore
import { UpdateClientDeliveryDetailsRequestDTO } from '../models';
// @ts-ignore
import { UpdateClientDeliveryDetailsResponseDTO } from '../models';
// @ts-ignore
import { UpdateClientFavoriteRecipeRequestDTO } from '../models';
// @ts-ignore
import { UpdateClientFulfillmentNotesRequestDTO } from '../models';
// @ts-ignore
import { UpdateClientIngredientPreferencesRequestDTO } from '../models';
// @ts-ignore
import { UpdateClientPersonalInformationRequestDTO } from '../models';
// @ts-ignore
import { UpdateClientProgramStartDateRequestDTO } from '../models';
// @ts-ignore
import { UpdateClientProviderNotesRequestDTO } from '../models';
// @ts-ignore
import { UpdateClientRequestDTO } from '../models';
// @ts-ignore
import { UpdateClientWeightTrackingEnabledRequestDTO } from '../models';
// @ts-ignore
import { UpdateNutritionPrescriptionAsClientRequestDTO } from '../models';
// @ts-ignore
import { UpdateNutritionPrescriptionRequestDTO } from '../models';
// @ts-ignore
import { ValidateClientRequestDTO } from '../models';
/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate a client with password, or a Google SSO token
         * @param {string} id The Client ID
         * @param {ActivateClientRequestDTO} request Client activation information. If Google token included, overwrite client email with email provided from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateClient: async (id: string, request: ActivateClientRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activateClient', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('activateClient', 'request', request)
            const localVarPath = `/clients/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add ABN Consent for client
         * @param {string} id The Client ID
         * @param {AddClientAbnDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAbnConsent: async (id: string, request: AddClientAbnDetailsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addAbnConsent', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('addAbnConsent', 'request', request)
            const localVarPath = `/clients/{id}/add-abn-consent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add client goal group
         * @param {string} id The Client ID
         * @param {AddClientGoalGroupRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClientGoalGroup: async (id: string, request: AddClientGoalGroupRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addClientGoalGroup', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('addClientGoalGroup', 'request', request)
            const localVarPath = `/clients/{id}/goal-groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe a client\'s device to push notifications
         * @param {string} id The Client ID
         * @param {ClientPushNotificationSubscriptionRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClientPushNotificationSubscription: async (id: string, request: ClientPushNotificationSubscriptionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addClientPushNotificationSubscription', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('addClientPushNotificationSubscription', 'request', request)
            const localVarPath = `/clients/{id}/add-push-notification-subscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign a subscription package to a client
         * @param {string} id The Client ID
         * @param {AssignSubscriptionPackageRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignSubscriptionPackageToClient: async (id: string, request: AssignSubscriptionPackageRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignSubscriptionPackageToClient', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('assignSubscriptionPackageToClient', 'request', request)
            const localVarPath = `/clients/{id}/assign-subscription-package`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a client\'s subscription
         * @param {string} id The Client ID
         * @param {ClientCancelSubscriptionRequestDTO} cancelDetails Details about the cancellation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelClientSubscription: async (id: string, cancelDetails: ClientCancelSubscriptionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelClientSubscription', 'id', id)
            // verify required parameter 'cancelDetails' is not null or undefined
            assertParamExists('cancelClientSubscription', 'cancelDetails', cancelDetails)
            const localVarPath = `/clients/{id}/cancel-subscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create and invite a new Client
         * @param {CreateAndInviteClientRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndInviteClient: async (client: CreateAndInviteClientRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('createAndInviteClient', 'client', client)
            const localVarPath = `/clients/create-and-invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(client, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create weight entry for client
         * @param {string} id 
         * @param {CreateClientWeightEntryRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientWeightEntry: async (id: string, request: CreateClientWeightEntryRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createClientWeightEntry', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createClientWeightEntry', 'request', request)
            const localVarPath = `/clients/{id}/weight-entries`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete weight entry for client
         * @param {string} id 
         * @param {DeleteClientWeightEntryRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientWeightEntry: async (id: string, request: DeleteClientWeightEntryRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClientWeightEntry', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('deleteClientWeightEntry', 'request', request)
            const localVarPath = `/clients/{id}/delete-weight-entry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete current client goal group
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCurrentClientGoalGroup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCurrentClientGoalGroup', 'id', id)
            const localVarPath = `/clients/{id}/delete-current-goal-group`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a recipe promotion for a client
         * @param {string} id The Client ID
         * @param {ClientRecipePromotionDTO} recipePromotion The recipe promotion to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipePromotion: async (id: string, recipePromotion: ClientRecipePromotionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRecipePromotion', 'id', id)
            // verify required parameter 'recipePromotion' is not null or undefined
            assertParamExists('deleteRecipePromotion', 'recipePromotion', recipePromotion)
            const localVarPath = `/clients/{id}/delete-recipe-promotion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recipePromotion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enroll client in education program
         * @param {string} id Client ID to enroll in education program.
         * @param {EducationProgramEnrollmentRequestDTO} educationProgramEnrollmentRequest Request describing which program to enroll the client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollClientInEducationProgram: async (id: string, educationProgramEnrollmentRequest: EducationProgramEnrollmentRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enrollClientInEducationProgram', 'id', id)
            // verify required parameter 'educationProgramEnrollmentRequest' is not null or undefined
            assertParamExists('enrollClientInEducationProgram', 'educationProgramEnrollmentRequest', educationProgramEnrollmentRequest)
            const localVarPath = `/clients/{id}/enroll-in-education-program`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationProgramEnrollmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active grocery list for client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveGroceryList: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActiveGroceryList', 'id', id)
            const localVarPath = `/clients/{id}/active-grocery-list`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active promoted recipes for a client
         * @param {string} id The client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivePromotedRecipes: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivePromotedRecipes', 'id', id)
            const localVarPath = `/clients/{id}/active-promoted-recipes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Subscription Packages available for Client to start subscription with
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStartingSubscriptionsForClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAvailableStartingSubscriptionsForClient', 'id', id)
            const localVarPath = `/clients/{id}/start-subscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Client by their ID
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClient', 'id', id)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client goal group
         * @param {string} id The Client ID
         * @param {number} [limit] Optional number of goal groups to return. If null, returns all.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientGoalGroups: async (id: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClientGoalGroups', 'id', id)
            const localVarPath = `/clients/{id}/goal-groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Client Health Data Universal Id
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientHealthDataUniversalId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClientHealthDataUniversalId', 'id', id)
            const localVarPath = `/clients/{id}/health-data-universal-id`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get ordered recipes and prepared meals, with feedback, for client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientOrderedRecipesAndPreparedMeals: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClientOrderedRecipesAndPreparedMeals', 'id', id)
            const localVarPath = `/clients/{id}/ordered-recipes-and-prepared-meals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get weight entries for client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientWeightEntries: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClientWeightEntries', 'id', id)
            const localVarPath = `/clients/{id}/weight-entries`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clients
         * @param {boolean} [hasActiveSubscription] If true, only return patients with active subscriptions. Defaults to false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients: async (hasActiveSubscription?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (hasActiveSubscription !== undefined) {
                localVarQueryParameter['hasActiveSubscription'] = hasActiveSubscription;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get thread of all communications for a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommunicationThreadForClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCommunicationThreadForClient', 'id', id)
            const localVarPath = `/clients/{id}/communication-thread`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the clients favorite recipes sorted by date they made it a favorite descending
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteRecipes: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFavoriteRecipes', 'id', id)
            const localVarPath = `/clients/{id}/favorite-recipes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get form submissions by client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFormSubmissions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFormSubmissions', 'id', id)
            const localVarPath = `/clients/{id}/form-submissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get intake form submissions for Client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakeFormSubmissions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntakeFormSubmissions', 'id', id)
            const localVarPath = `/clients/{id}/intake-form-submissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get description of client\'s personalized nutrition prescription
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionPrescriptionDescription: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNutritionPrescriptionDescription', 'id', id)
            const localVarPath = `/clients/{id}/nutrition-prescription-description`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order history for client
         * @param {string} id 
         * @param {string} [sinceUpdatedDate] If provided, only orders that have had their status updated since this date
         * @param {number} [limit] If provided, returns up to a maximum of this number of orders, sorted by most recently updated date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistory: async (id: string, sinceUpdatedDate?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderHistory', 'id', id)
            const localVarPath = `/clients/{id}/order-history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sinceUpdatedDate !== undefined) {
                localVarQueryParameter['sinceUpdatedDate'] = (sinceUpdatedDate as any instanceof Date) ?
                    (sinceUpdatedDate as any).toISOString() :
                    sinceUpdatedDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get outstanding order and subscription balances for client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutstandingBalances: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOutstandingBalances', 'id', id)
            const localVarPath = `/clients/{id}/outstanding-balances`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get promoted recipes for a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotedRecipes: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPromotedRecipes', 'id', id)
            const localVarPath = `/clients/{id}/promote-recipe`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of strings containing diet module search filters to use for recipe search queries.
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeSearchFilters: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecipeSearchFilters', 'id', id)
            const localVarPath = `/clients/{id}/recipe-search-filters`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite a client to complete their profile
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inviteClient', 'id', id)
            const localVarPath = `/clients/{id}/invite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite a client using email address
         * @param {InviteClientWithEmailRequestDTO} emailAddress Email address to send the invite to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteClientWithEmail: async (emailAddress: InviteClientWithEmailRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('inviteClientWithEmail', 'emailAddress', emailAddress)
            const localVarPath = `/clients/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Promote a recipe for a client
         * @param {string} id The Client ID
         * @param {ClientRecipePromotionDTO} recipePromotion The recipe promotion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoteRecipe: async (id: string, recipePromotion: ClientRecipePromotionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promoteRecipe', 'id', id)
            // verify required parameter 'recipePromotion' is not null or undefined
            assertParamExists('promoteRecipe', 'recipePromotion', recipePromotion)
            const localVarPath = `/clients/{id}/promote-recipe`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recipePromotion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh active food order carts for client and return them
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshActiveFoodOrderCarts: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refreshActiveFoodOrderCarts', 'id', id)
            const localVarPath = `/clients/{id}/refresh-active-food-order-carts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('registerClient', 'id', id)
            const localVarPath = `/clients/{id}/register`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search clients
         * @param {string} [firstNameQuery] 
         * @param {string} [lastNameQuery] 
         * @param {string} [phoneNumberQuery] 
         * @param {string} [addressQuery] Street address line 1
         * @param {string} [emailQuery] 
         * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Empty or null defaults to all values
         * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [currentStates] Empty or null defaults to all values
         * @param {'LAST_NAME'} [sortBy] Sort results. Defaults to LAST_NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClients: async (firstNameQuery?: string, lastNameQuery?: string, phoneNumberQuery?: string, addressQuery?: string, emailQuery?: string, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, currentStates?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, sortBy?: 'LAST_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (firstNameQuery !== undefined) {
                localVarQueryParameter['firstNameQuery'] = firstNameQuery;
            }

            if (lastNameQuery !== undefined) {
                localVarQueryParameter['lastNameQuery'] = lastNameQuery;
            }

            if (phoneNumberQuery !== undefined) {
                localVarQueryParameter['phoneNumberQuery'] = phoneNumberQuery;
            }

            if (addressQuery !== undefined) {
                localVarQueryParameter['addressQuery'] = addressQuery;
            }

            if (emailQuery !== undefined) {
                localVarQueryParameter['emailQuery'] = emailQuery;
            }

            if (cohorts) {
                localVarQueryParameter['cohorts'] = cohorts;
            }

            if (currentStates) {
                localVarQueryParameter['currentStates'] = currentStates;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a push notification to a client given their id and a message
         * @param {PushNotificationMessageRequestDTO} notification The notification being sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendClientPushNotification: async (notification: PushNotificationMessageRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notification' is not null or undefined
            assertParamExists('sendClientPushNotification', 'notification', notification)
            const localVarPath = `/external/send-push-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send recipes to a Client via SMS
         * @param {string} id The Client ID
         * @param {SendRecipesViaSmsRequestDTO} request Details about the recipes to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRecipesViaSms: async (id: string, request: SendRecipesViaSmsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendRecipesViaSms', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('sendRecipesViaSms', 'request', request)
            const localVarPath = `/clients/{id}/send-recipes-via-sms`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send an SMS to a client
         * @param {string} id The Client ID
         * @param {SendSmsRequestDTO} sendSmsRequest The details of the SMS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSms: async (id: string, sendSmsRequest: SendSmsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendSms', 'id', id)
            // verify required parameter 'sendSmsRequest' is not null or undefined
            assertParamExists('sendSms', 'sendSmsRequest', sendSmsRequest)
            const localVarPath = `/clients/{id}/send-sms`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendSmsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setup the intent to add payment info for a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupPaymentIntent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setupPaymentIntent', 'id', id)
            const localVarPath = `/clients/{id}/setup-payment-intent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a subscription for a new Client
         * @param {string} id The Client ID
         * @param {StartClientSubscriptionRequestDTO} request Client and subscription information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startClientSubscription: async (id: string, request: StartClientSubscriptionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startClientSubscription', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('startClientSubscription', 'request', request)
            const localVarPath = `/clients/{id}/start-subscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit intake form for new Client ahead of their initial appointment
         * @param {string} id The Client ID
         * @param {SubmitIntakeFormRequestDTO} submitIntakeFormRequest The values of the intake form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitIntakeForm: async (id: string, submitIntakeFormRequest: SubmitIntakeFormRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitIntakeForm', 'id', id)
            // verify required parameter 'submitIntakeFormRequest' is not null or undefined
            assertParamExists('submitIntakeForm', 'submitIntakeFormRequest', submitIntakeFormRequest)
            const localVarPath = `/clients/{id}/submit-intake-form`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitIntakeFormRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing client
         * @param {string} id The Client ID
         * @param {UpdateClientRequestDTO} client The updated Client resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient: async (id: string, client: UpdateClientRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClient', 'id', id)
            // verify required parameter 'client' is not null or undefined
            assertParamExists('updateClient', 'client', client)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(client, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update available cooking equipment
         * @param {string} id The Client ID
         * @param {UpdateClientAvailableCookingEquipmentRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientAvailableCookingEquipment: async (id: string, client: UpdateClientAvailableCookingEquipmentRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientAvailableCookingEquipment', 'id', id)
            // verify required parameter 'client' is not null or undefined
            assertParamExists('updateClientAvailableCookingEquipment', 'client', client)
            const localVarPath = `/clients/{id}/update-available-cooking-equipment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(client, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update cohort details
         * @param {string} id The Client ID
         * @param {UpdateClientCohortDetailsRequestDTO} request Updated Client Cohort Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientCohortDetails: async (id: string, request: UpdateClientCohortDetailsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientCohortDetails', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateClientCohortDetails', 'request', request)
            const localVarPath = `/clients/{id}/update-cohort-details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a client\'s delivery details
         * @param {string} id The Client ID
         * @param {UpdateClientDeliveryDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientDeliveryDetails: async (id: string, request: UpdateClientDeliveryDetailsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientDeliveryDetails', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateClientDeliveryDetails', 'request', request)
            const localVarPath = `/clients/{id}/update-delivery-details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a client\'s fulfillment notes
         * @param {string} id The Client ID
         * @param {UpdateClientFulfillmentNotesRequestDTO} request The updated fulfillment notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientFulfillmentNotes: async (id: string, request: UpdateClientFulfillmentNotesRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientFulfillmentNotes', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateClientFulfillmentNotes', 'request', request)
            const localVarPath = `/clients/{id}/update-fulfillment-notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update client ingredient preferences
         * @param {string} id The Client ID
         * @param {UpdateClientIngredientPreferencesRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientIngredientPreferences: async (id: string, client: UpdateClientIngredientPreferencesRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientIngredientPreferences', 'id', id)
            // verify required parameter 'client' is not null or undefined
            assertParamExists('updateClientIngredientPreferences', 'client', client)
            const localVarPath = `/clients/{id}/update-ingredient-preferences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(client, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a client\'s notification preferences
         * @param {string} id The Client ID
         * @param {ClientNotificationPreferencesDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientNotificationPreferences: async (id: string, request: ClientNotificationPreferencesDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientNotificationPreferences', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateClientNotificationPreferences', 'request', request)
            const localVarPath = `/clients/{id}/update-notification-preferences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a client\'s provider notes
         * @param {string} id The Client ID
         * @param {UpdateClientProviderNotesRequestDTO} request The updated provider notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientProviderNotes: async (id: string, request: UpdateClientProviderNotesRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientProviderNotes', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateClientProviderNotes', 'request', request)
            const localVarPath = `/clients/{id}/update-provider-notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a client\'s weight tracking enabled setting
         * @param {string} id The Client ID
         * @param {UpdateClientWeightTrackingEnabledRequestDTO} request The updated weight tracking enabled setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientWeightTrackingEnabled: async (id: string, request: UpdateClientWeightTrackingEnabledRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientWeightTrackingEnabled', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateClientWeightTrackingEnabled', 'request', request)
            const localVarPath = `/clients/{id}/update-weight-tracking-enabled`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update client favorite recipe
         * @param {string} id The Client ID
         * @param {UpdateClientFavoriteRecipeRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteRecipe: async (id: string, request: UpdateClientFavoriteRecipeRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFavoriteRecipe', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateFavoriteRecipe', 'request', request)
            const localVarPath = `/clients/{id}/favorite-recipes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a client\'s nutrition prescription
         * @param {string} id The Client ID
         * @param {UpdateNutritionPrescriptionRequestDTO} request The updated nutrition prescription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNutritionPrescription: async (id: string, request: UpdateNutritionPrescriptionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNutritionPrescription', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateNutritionPrescription', 'request', request)
            const localVarPath = `/clients/{id}/update-nutrition-prescription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a client\'s nutrition prescription as a client (Season Select only)
         * @param {string} id The Client ID
         * @param {UpdateNutritionPrescriptionAsClientRequestDTO} request The updated nutrition prescription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNutritionPrescriptionAsClient: async (id: string, request: UpdateNutritionPrescriptionAsClientRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNutritionPrescriptionAsClient', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateNutritionPrescriptionAsClient', 'request', request)
            const localVarPath = `/clients/{id}/update-nutrition-prescription-as-client`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a client\'s personal information
         * @param {string} id The Client ID
         * @param {UpdateClientPersonalInformationRequestDTO} request The updated personal information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalInformation: async (id: string, request: UpdateClientPersonalInformationRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePersonalInformation', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updatePersonalInformation', 'request', request)
            const localVarPath = `/clients/{id}/update-personal-information`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update program start date
         * @param {string} id The Client ID
         * @param {UpdateClientProgramStartDateRequestDTO} request Updated Client Program Start Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramStartDate: async (id: string, request: UpdateClientProgramStartDateRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProgramStartDate', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateProgramStartDate', 'request', request)
            const localVarPath = `/clients/{id}/update-program-start-date`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate a client\'s information
         * @param {string} id The Client ID
         * @param {ValidateClientRequestDTO} request Client information. Once validated, overwrite existing data with values provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClient: async (id: string, request: ValidateClientRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('validateClient', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('validateClient', 'request', request)
            const localVarPath = `/clients/{id}/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate a client with password, or a Google SSO token
         * @param {string} id The Client ID
         * @param {ActivateClientRequestDTO} request Client activation information. If Google token included, overwrite client email with email provided from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateClient(id: string, request: ActivateClientRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateClient(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add ABN Consent for client
         * @param {string} id The Client ID
         * @param {AddClientAbnDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAbnConsent(id: string, request: AddClientAbnDetailsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAbnConsent(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add client goal group
         * @param {string} id The Client ID
         * @param {AddClientGoalGroupRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addClientGoalGroup(id: string, request: AddClientGoalGroupRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addClientGoalGroup(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subscribe a client\'s device to push notifications
         * @param {string} id The Client ID
         * @param {ClientPushNotificationSubscriptionRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addClientPushNotificationSubscription(id: string, request: ClientPushNotificationSubscriptionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addClientPushNotificationSubscription(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign a subscription package to a client
         * @param {string} id The Client ID
         * @param {AssignSubscriptionPackageRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignSubscriptionPackageToClient(id: string, request: AssignSubscriptionPackageRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignSubscriptionPackageToClient(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel a client\'s subscription
         * @param {string} id The Client ID
         * @param {ClientCancelSubscriptionRequestDTO} cancelDetails Details about the cancellation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelClientSubscription(id: string, cancelDetails: ClientCancelSubscriptionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelClientSubscription(id, cancelDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create and invite a new Client
         * @param {CreateAndInviteClientRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndInviteClient(client: CreateAndInviteClientRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAndInviteClientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndInviteClient(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create weight entry for client
         * @param {string} id 
         * @param {CreateClientWeightEntryRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientWeightEntry(id: string, request: CreateClientWeightEntryRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClientWeightEntry(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete weight entry for client
         * @param {string} id 
         * @param {DeleteClientWeightEntryRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClientWeightEntry(id: string, request: DeleteClientWeightEntryRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClientWeightEntry(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete current client goal group
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCurrentClientGoalGroup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCurrentClientGoalGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a recipe promotion for a client
         * @param {string} id The Client ID
         * @param {ClientRecipePromotionDTO} recipePromotion The recipe promotion to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecipePromotion(id: string, recipePromotion: ClientRecipePromotionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecipePromotion(id, recipePromotion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enroll client in education program
         * @param {string} id Client ID to enroll in education program.
         * @param {EducationProgramEnrollmentRequestDTO} educationProgramEnrollmentRequest Request describing which program to enroll the client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrollClientInEducationProgram(id: string, educationProgramEnrollmentRequest: EducationProgramEnrollmentRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrollClientInEducationProgram(id, educationProgramEnrollmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get active grocery list for client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveGroceryList(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveGroceryList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get active promoted recipes for a client
         * @param {string} id The client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivePromotedRecipes(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivePromotedRecipes(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Subscription Packages available for Client to start subscription with
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableStartingSubscriptionsForClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAvailableStartingSubscriptionsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableStartingSubscriptionsForClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Client by their ID
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get client goal group
         * @param {string} id The Client ID
         * @param {number} [limit] Optional number of goal groups to return. If null, returns all.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientGoalGroups(id: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientGoalGroupResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientGoalGroups(id, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Client Health Data Universal Id
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientHealthDataUniversalId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientHealthDataUniversalIdResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientHealthDataUniversalId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get ordered recipes and prepared meals, with feedback, for client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientOrderedRecipesAndPreparedMeals(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientOrderedRecipesAndPreparedMealsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientOrderedRecipesAndPreparedMeals(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get weight entries for client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientWeightEntries(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientWeightEntriesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientWeightEntries(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get clients
         * @param {boolean} [hasActiveSubscription] If true, only return patients with active subscriptions. Defaults to false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClients(hasActiveSubscription?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClients(hasActiveSubscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get thread of all communications for a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommunicationThreadForClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunicationThreadDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommunicationThreadForClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the clients favorite recipes sorted by date they made it a favorite descending
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFavoriteRecipes(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientFavoriteRecipesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFavoriteRecipes(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get form submissions by client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFormSubmissions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormSubmissionsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFormSubmissions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get intake form submissions for Client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntakeFormSubmissions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeFormResponsesDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntakeFormSubmissions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get description of client\'s personalized nutrition prescription
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNutritionPrescriptionDescription(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientNutritionPrescriptionDescriptionResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNutritionPrescriptionDescription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order history for client
         * @param {string} id 
         * @param {string} [sinceUpdatedDate] If provided, only orders that have had their status updated since this date
         * @param {number} [limit] If provided, returns up to a maximum of this number of orders, sorted by most recently updated date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistory(id: string, sinceUpdatedDate?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderHistoryResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistory(id, sinceUpdatedDate, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get outstanding order and subscription balances for client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutstandingBalances(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientOutstandingBalancesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOutstandingBalances(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get promoted recipes for a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotedRecipes(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientRecipePromotionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotedRecipes(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a list of strings containing diet module search filters to use for recipe search queries.
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeSearchFilters(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientRecipeSearchFiltersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeSearchFilters(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invite a client to complete their profile
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invite a client using email address
         * @param {InviteClientWithEmailRequestDTO} emailAddress Email address to send the invite to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteClientWithEmail(emailAddress: InviteClientWithEmailRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteClientWithEmail(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Promote a recipe for a client
         * @param {string} id The Client ID
         * @param {ClientRecipePromotionDTO} recipePromotion The recipe promotion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoteRecipe(id: string, recipePromotion: ClientRecipePromotionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoteRecipe(id, recipePromotion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refresh active food order carts for client and return them
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshActiveFoodOrderCarts(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodOrderCartsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshActiveFoodOrderCarts(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search clients
         * @param {string} [firstNameQuery] 
         * @param {string} [lastNameQuery] 
         * @param {string} [phoneNumberQuery] 
         * @param {string} [addressQuery] Street address line 1
         * @param {string} [emailQuery] 
         * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Empty or null defaults to all values
         * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [currentStates] Empty or null defaults to all values
         * @param {'LAST_NAME'} [sortBy] Sort results. Defaults to LAST_NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClients(firstNameQuery?: string, lastNameQuery?: string, phoneNumberQuery?: string, addressQuery?: string, emailQuery?: string, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, currentStates?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, sortBy?: 'LAST_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchClientsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClients(firstNameQuery, lastNameQuery, phoneNumberQuery, addressQuery, emailQuery, cohorts, currentStates, sortBy, sortDir, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a push notification to a client given their id and a message
         * @param {PushNotificationMessageRequestDTO} notification The notification being sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendClientPushNotification(notification: PushNotificationMessageRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendClientPushNotification(notification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send recipes to a Client via SMS
         * @param {string} id The Client ID
         * @param {SendRecipesViaSmsRequestDTO} request Details about the recipes to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRecipesViaSms(id: string, request: SendRecipesViaSmsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendRecipesViaSms(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send an SMS to a client
         * @param {string} id The Client ID
         * @param {SendSmsRequestDTO} sendSmsRequest The details of the SMS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSms(id: string, sendSmsRequest: SendSmsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSms(id, sendSmsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setup the intent to add payment info for a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setupPaymentIntent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetupPaymentIntentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setupPaymentIntent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start a subscription for a new Client
         * @param {string} id The Client ID
         * @param {StartClientSubscriptionRequestDTO} request Client and subscription information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startClientSubscription(id: string, request: StartClientSubscriptionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startClientSubscription(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit intake form for new Client ahead of their initial appointment
         * @param {string} id The Client ID
         * @param {SubmitIntakeFormRequestDTO} submitIntakeFormRequest The values of the intake form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitIntakeForm(id: string, submitIntakeFormRequest: SubmitIntakeFormRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitIntakeForm(id, submitIntakeFormRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing client
         * @param {string} id The Client ID
         * @param {UpdateClientRequestDTO} client The updated Client resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClient(id: string, client: UpdateClientRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClient(id, client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update available cooking equipment
         * @param {string} id The Client ID
         * @param {UpdateClientAvailableCookingEquipmentRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientAvailableCookingEquipment(id: string, client: UpdateClientAvailableCookingEquipmentRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientAvailableCookingEquipment(id, client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update cohort details
         * @param {string} id The Client ID
         * @param {UpdateClientCohortDetailsRequestDTO} request Updated Client Cohort Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientCohortDetails(id: string, request: UpdateClientCohortDetailsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientCohortDetails(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a client\'s delivery details
         * @param {string} id The Client ID
         * @param {UpdateClientDeliveryDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientDeliveryDetails(id: string, request: UpdateClientDeliveryDetailsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateClientDeliveryDetailsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientDeliveryDetails(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a client\'s fulfillment notes
         * @param {string} id The Client ID
         * @param {UpdateClientFulfillmentNotesRequestDTO} request The updated fulfillment notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientFulfillmentNotes(id: string, request: UpdateClientFulfillmentNotesRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientFulfillmentNotes(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update client ingredient preferences
         * @param {string} id The Client ID
         * @param {UpdateClientIngredientPreferencesRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientIngredientPreferences(id: string, client: UpdateClientIngredientPreferencesRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientIngredientPreferences(id, client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a client\'s notification preferences
         * @param {string} id The Client ID
         * @param {ClientNotificationPreferencesDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientNotificationPreferences(id: string, request: ClientNotificationPreferencesDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientNotificationPreferences(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a client\'s provider notes
         * @param {string} id The Client ID
         * @param {UpdateClientProviderNotesRequestDTO} request The updated provider notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientProviderNotes(id: string, request: UpdateClientProviderNotesRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientProviderNotes(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a client\'s weight tracking enabled setting
         * @param {string} id The Client ID
         * @param {UpdateClientWeightTrackingEnabledRequestDTO} request The updated weight tracking enabled setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientWeightTrackingEnabled(id: string, request: UpdateClientWeightTrackingEnabledRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientWeightTrackingEnabled(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update client favorite recipe
         * @param {string} id The Client ID
         * @param {UpdateClientFavoriteRecipeRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFavoriteRecipe(id: string, request: UpdateClientFavoriteRecipeRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFavoriteRecipe(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a client\'s nutrition prescription
         * @param {string} id The Client ID
         * @param {UpdateNutritionPrescriptionRequestDTO} request The updated nutrition prescription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNutritionPrescription(id: string, request: UpdateNutritionPrescriptionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNutritionPrescription(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a client\'s nutrition prescription as a client (Season Select only)
         * @param {string} id The Client ID
         * @param {UpdateNutritionPrescriptionAsClientRequestDTO} request The updated nutrition prescription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNutritionPrescriptionAsClient(id: string, request: UpdateNutritionPrescriptionAsClientRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNutritionPrescriptionAsClient(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a client\'s personal information
         * @param {string} id The Client ID
         * @param {UpdateClientPersonalInformationRequestDTO} request The updated personal information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonalInformation(id: string, request: UpdateClientPersonalInformationRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonalInformation(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update program start date
         * @param {string} id The Client ID
         * @param {UpdateClientProgramStartDateRequestDTO} request Updated Client Program Start Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramStartDate(id: string, request: UpdateClientProgramStartDateRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramStartDate(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate a client\'s information
         * @param {string} id The Client ID
         * @param {ValidateClientRequestDTO} request Client information. Once validated, overwrite existing data with values provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateClient(id: string, request: ValidateClientRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateClient(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate a client with password, or a Google SSO token
         * @param {string} id The Client ID
         * @param {ActivateClientRequestDTO} request Client activation information. If Google token included, overwrite client email with email provided from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateClient(id: string, request: ActivateClientRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.activateClient(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add ABN Consent for client
         * @param {string} id The Client ID
         * @param {AddClientAbnDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAbnConsent(id: string, request: AddClientAbnDetailsRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addAbnConsent(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add client goal group
         * @param {string} id The Client ID
         * @param {AddClientGoalGroupRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClientGoalGroup(id: string, request: AddClientGoalGroupRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addClientGoalGroup(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscribe a client\'s device to push notifications
         * @param {string} id The Client ID
         * @param {ClientPushNotificationSubscriptionRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClientPushNotificationSubscription(id: string, request: ClientPushNotificationSubscriptionRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addClientPushNotificationSubscription(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign a subscription package to a client
         * @param {string} id The Client ID
         * @param {AssignSubscriptionPackageRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignSubscriptionPackageToClient(id: string, request: AssignSubscriptionPackageRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.assignSubscriptionPackageToClient(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a client\'s subscription
         * @param {string} id The Client ID
         * @param {ClientCancelSubscriptionRequestDTO} cancelDetails Details about the cancellation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelClientSubscription(id: string, cancelDetails: ClientCancelSubscriptionRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.cancelClientSubscription(id, cancelDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create and invite a new Client
         * @param {CreateAndInviteClientRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndInviteClient(client: CreateAndInviteClientRequestDTO, options?: any): AxiosPromise<CreateAndInviteClientResponseDTO> {
            return localVarFp.createAndInviteClient(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create weight entry for client
         * @param {string} id 
         * @param {CreateClientWeightEntryRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientWeightEntry(id: string, request: CreateClientWeightEntryRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createClientWeightEntry(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete weight entry for client
         * @param {string} id 
         * @param {DeleteClientWeightEntryRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientWeightEntry(id: string, request: DeleteClientWeightEntryRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClientWeightEntry(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete current client goal group
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCurrentClientGoalGroup(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCurrentClientGoalGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a recipe promotion for a client
         * @param {string} id The Client ID
         * @param {ClientRecipePromotionDTO} recipePromotion The recipe promotion to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipePromotion(id: string, recipePromotion: ClientRecipePromotionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRecipePromotion(id, recipePromotion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enroll client in education program
         * @param {string} id Client ID to enroll in education program.
         * @param {EducationProgramEnrollmentRequestDTO} educationProgramEnrollmentRequest Request describing which program to enroll the client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollClientInEducationProgram(id: string, educationProgramEnrollmentRequest: EducationProgramEnrollmentRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.enrollClientInEducationProgram(id, educationProgramEnrollmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active grocery list for client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveGroceryList(id: string, options?: any): AxiosPromise<GroceryListResponseDTO> {
            return localVarFp.getActiveGroceryList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active promoted recipes for a client
         * @param {string} id The client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivePromotedRecipes(id: string, options?: any): AxiosPromise<RecipesResponseDTO> {
            return localVarFp.getActivePromotedRecipes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Subscription Packages available for Client to start subscription with
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStartingSubscriptionsForClient(id: string, options?: any): AxiosPromise<GetAvailableStartingSubscriptionsResponseDTO> {
            return localVarFp.getAvailableStartingSubscriptionsForClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Client by their ID
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClient(id: string, options?: any): AxiosPromise<ClientResponseDTO> {
            return localVarFp.getClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get client goal group
         * @param {string} id The Client ID
         * @param {number} [limit] Optional number of goal groups to return. If null, returns all.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientGoalGroups(id: string, limit?: number, options?: any): AxiosPromise<GetClientGoalGroupResponseDTO> {
            return localVarFp.getClientGoalGroups(id, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Client Health Data Universal Id
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientHealthDataUniversalId(id: string, options?: any): AxiosPromise<GetClientHealthDataUniversalIdResponseDTO> {
            return localVarFp.getClientHealthDataUniversalId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get ordered recipes and prepared meals, with feedback, for client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientOrderedRecipesAndPreparedMeals(id: string, options?: any): AxiosPromise<GetClientOrderedRecipesAndPreparedMealsResponseDTO> {
            return localVarFp.getClientOrderedRecipesAndPreparedMeals(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get weight entries for client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientWeightEntries(id: string, options?: any): AxiosPromise<ClientWeightEntriesResponseDTO> {
            return localVarFp.getClientWeightEntries(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get clients
         * @param {boolean} [hasActiveSubscription] If true, only return patients with active subscriptions. Defaults to false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients(hasActiveSubscription?: boolean, options?: any): AxiosPromise<GetClientsResponseDTO> {
            return localVarFp.getClients(hasActiveSubscription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get thread of all communications for a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommunicationThreadForClient(id: string, options?: any): AxiosPromise<CommunicationThreadDTO> {
            return localVarFp.getCommunicationThreadForClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the clients favorite recipes sorted by date they made it a favorite descending
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteRecipes(id: string, options?: any): AxiosPromise<ClientFavoriteRecipesResponseDTO> {
            return localVarFp.getFavoriteRecipes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get form submissions by client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFormSubmissions(id: string, options?: any): AxiosPromise<FormSubmissionsResponseDTO> {
            return localVarFp.getFormSubmissions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get intake form submissions for Client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakeFormSubmissions(id: string, options?: any): AxiosPromise<IntakeFormResponsesDTO> {
            return localVarFp.getIntakeFormSubmissions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get description of client\'s personalized nutrition prescription
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionPrescriptionDescription(id: string, options?: any): AxiosPromise<ClientNutritionPrescriptionDescriptionResponseDTO> {
            return localVarFp.getNutritionPrescriptionDescription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order history for client
         * @param {string} id 
         * @param {string} [sinceUpdatedDate] If provided, only orders that have had their status updated since this date
         * @param {number} [limit] If provided, returns up to a maximum of this number of orders, sorted by most recently updated date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistory(id: string, sinceUpdatedDate?: string, limit?: number, options?: any): AxiosPromise<OrderHistoryResponseDTO> {
            return localVarFp.getOrderHistory(id, sinceUpdatedDate, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get outstanding order and subscription balances for client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutstandingBalances(id: string, options?: any): AxiosPromise<ClientOutstandingBalancesResponseDTO> {
            return localVarFp.getOutstandingBalances(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get promoted recipes for a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotedRecipes(id: string, options?: any): AxiosPromise<Array<ClientRecipePromotionDTO>> {
            return localVarFp.getPromotedRecipes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of strings containing diet module search filters to use for recipe search queries.
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeSearchFilters(id: string, options?: any): AxiosPromise<ClientRecipeSearchFiltersDTO> {
            return localVarFp.getRecipeSearchFilters(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite a client to complete their profile
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteClient(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.inviteClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite a client using email address
         * @param {InviteClientWithEmailRequestDTO} emailAddress Email address to send the invite to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteClientWithEmail(emailAddress: InviteClientWithEmailRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.inviteClientWithEmail(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Promote a recipe for a client
         * @param {string} id The Client ID
         * @param {ClientRecipePromotionDTO} recipePromotion The recipe promotion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoteRecipe(id: string, recipePromotion: ClientRecipePromotionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.promoteRecipe(id, recipePromotion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh active food order carts for client and return them
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshActiveFoodOrderCarts(id: string, options?: any): AxiosPromise<FoodOrderCartsResponseDTO> {
            return localVarFp.refreshActiveFoodOrderCarts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerClient(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.registerClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search clients
         * @param {string} [firstNameQuery] 
         * @param {string} [lastNameQuery] 
         * @param {string} [phoneNumberQuery] 
         * @param {string} [addressQuery] Street address line 1
         * @param {string} [emailQuery] 
         * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Empty or null defaults to all values
         * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [currentStates] Empty or null defaults to all values
         * @param {'LAST_NAME'} [sortBy] Sort results. Defaults to LAST_NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClients(firstNameQuery?: string, lastNameQuery?: string, phoneNumberQuery?: string, addressQuery?: string, emailQuery?: string, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, currentStates?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, sortBy?: 'LAST_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: any): AxiosPromise<SearchClientsResponseDTO> {
            return localVarFp.searchClients(firstNameQuery, lastNameQuery, phoneNumberQuery, addressQuery, emailQuery, cohorts, currentStates, sortBy, sortDir, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a push notification to a client given their id and a message
         * @param {PushNotificationMessageRequestDTO} notification The notification being sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendClientPushNotification(notification: PushNotificationMessageRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.sendClientPushNotification(notification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send recipes to a Client via SMS
         * @param {string} id The Client ID
         * @param {SendRecipesViaSmsRequestDTO} request Details about the recipes to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRecipesViaSms(id: string, request: SendRecipesViaSmsRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.sendRecipesViaSms(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send an SMS to a client
         * @param {string} id The Client ID
         * @param {SendSmsRequestDTO} sendSmsRequest The details of the SMS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSms(id: string, sendSmsRequest: SendSmsRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.sendSms(id, sendSmsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setup the intent to add payment info for a client
         * @param {string} id The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupPaymentIntent(id: string, options?: any): AxiosPromise<SetupPaymentIntentResponseDTO> {
            return localVarFp.setupPaymentIntent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start a subscription for a new Client
         * @param {string} id The Client ID
         * @param {StartClientSubscriptionRequestDTO} request Client and subscription information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startClientSubscription(id: string, request: StartClientSubscriptionRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.startClientSubscription(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit intake form for new Client ahead of their initial appointment
         * @param {string} id The Client ID
         * @param {SubmitIntakeFormRequestDTO} submitIntakeFormRequest The values of the intake form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitIntakeForm(id: string, submitIntakeFormRequest: SubmitIntakeFormRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.submitIntakeForm(id, submitIntakeFormRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing client
         * @param {string} id The Client ID
         * @param {UpdateClientRequestDTO} client The updated Client resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient(id: string, client: UpdateClientRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateClient(id, client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update available cooking equipment
         * @param {string} id The Client ID
         * @param {UpdateClientAvailableCookingEquipmentRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientAvailableCookingEquipment(id: string, client: UpdateClientAvailableCookingEquipmentRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateClientAvailableCookingEquipment(id, client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update cohort details
         * @param {string} id The Client ID
         * @param {UpdateClientCohortDetailsRequestDTO} request Updated Client Cohort Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientCohortDetails(id: string, request: UpdateClientCohortDetailsRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateClientCohortDetails(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a client\'s delivery details
         * @param {string} id The Client ID
         * @param {UpdateClientDeliveryDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientDeliveryDetails(id: string, request: UpdateClientDeliveryDetailsRequestDTO, options?: any): AxiosPromise<UpdateClientDeliveryDetailsResponseDTO> {
            return localVarFp.updateClientDeliveryDetails(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a client\'s fulfillment notes
         * @param {string} id The Client ID
         * @param {UpdateClientFulfillmentNotesRequestDTO} request The updated fulfillment notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientFulfillmentNotes(id: string, request: UpdateClientFulfillmentNotesRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateClientFulfillmentNotes(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update client ingredient preferences
         * @param {string} id The Client ID
         * @param {UpdateClientIngredientPreferencesRequestDTO} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientIngredientPreferences(id: string, client: UpdateClientIngredientPreferencesRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateClientIngredientPreferences(id, client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a client\'s notification preferences
         * @param {string} id The Client ID
         * @param {ClientNotificationPreferencesDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientNotificationPreferences(id: string, request: ClientNotificationPreferencesDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateClientNotificationPreferences(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a client\'s provider notes
         * @param {string} id The Client ID
         * @param {UpdateClientProviderNotesRequestDTO} request The updated provider notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientProviderNotes(id: string, request: UpdateClientProviderNotesRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateClientProviderNotes(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a client\'s weight tracking enabled setting
         * @param {string} id The Client ID
         * @param {UpdateClientWeightTrackingEnabledRequestDTO} request The updated weight tracking enabled setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientWeightTrackingEnabled(id: string, request: UpdateClientWeightTrackingEnabledRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateClientWeightTrackingEnabled(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update client favorite recipe
         * @param {string} id The Client ID
         * @param {UpdateClientFavoriteRecipeRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteRecipe(id: string, request: UpdateClientFavoriteRecipeRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateFavoriteRecipe(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a client\'s nutrition prescription
         * @param {string} id The Client ID
         * @param {UpdateNutritionPrescriptionRequestDTO} request The updated nutrition prescription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNutritionPrescription(id: string, request: UpdateNutritionPrescriptionRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateNutritionPrescription(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a client\'s nutrition prescription as a client (Season Select only)
         * @param {string} id The Client ID
         * @param {UpdateNutritionPrescriptionAsClientRequestDTO} request The updated nutrition prescription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNutritionPrescriptionAsClient(id: string, request: UpdateNutritionPrescriptionAsClientRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateNutritionPrescriptionAsClient(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a client\'s personal information
         * @param {string} id The Client ID
         * @param {UpdateClientPersonalInformationRequestDTO} request The updated personal information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalInformation(id: string, request: UpdateClientPersonalInformationRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updatePersonalInformation(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update program start date
         * @param {string} id The Client ID
         * @param {UpdateClientProgramStartDateRequestDTO} request Updated Client Program Start Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramStartDate(id: string, request: UpdateClientProgramStartDateRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateProgramStartDate(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate a client\'s information
         * @param {string} id The Client ID
         * @param {ValidateClientRequestDTO} request Client information. Once validated, overwrite existing data with values provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClient(id: string, request: ValidateClientRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.validateClient(id, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @summary Activate a client with password, or a Google SSO token
     * @param {string} id The Client ID
     * @param {ActivateClientRequestDTO} request Client activation information. If Google token included, overwrite client email with email provided from Google
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public activateClient(id: string, request: ActivateClientRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).activateClient(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add ABN Consent for client
     * @param {string} id The Client ID
     * @param {AddClientAbnDetailsRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public addAbnConsent(id: string, request: AddClientAbnDetailsRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).addAbnConsent(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add client goal group
     * @param {string} id The Client ID
     * @param {AddClientGoalGroupRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public addClientGoalGroup(id: string, request: AddClientGoalGroupRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).addClientGoalGroup(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscribe a client\'s device to push notifications
     * @param {string} id The Client ID
     * @param {ClientPushNotificationSubscriptionRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public addClientPushNotificationSubscription(id: string, request: ClientPushNotificationSubscriptionRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).addClientPushNotificationSubscription(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign a subscription package to a client
     * @param {string} id The Client ID
     * @param {AssignSubscriptionPackageRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public assignSubscriptionPackageToClient(id: string, request: AssignSubscriptionPackageRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).assignSubscriptionPackageToClient(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a client\'s subscription
     * @param {string} id The Client ID
     * @param {ClientCancelSubscriptionRequestDTO} cancelDetails Details about the cancellation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public cancelClientSubscription(id: string, cancelDetails: ClientCancelSubscriptionRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).cancelClientSubscription(id, cancelDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create and invite a new Client
     * @param {CreateAndInviteClientRequestDTO} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createAndInviteClient(client: CreateAndInviteClientRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).createAndInviteClient(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create weight entry for client
     * @param {string} id 
     * @param {CreateClientWeightEntryRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createClientWeightEntry(id: string, request: CreateClientWeightEntryRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).createClientWeightEntry(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete weight entry for client
     * @param {string} id 
     * @param {DeleteClientWeightEntryRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public deleteClientWeightEntry(id: string, request: DeleteClientWeightEntryRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).deleteClientWeightEntry(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete current client goal group
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public deleteCurrentClientGoalGroup(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).deleteCurrentClientGoalGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a recipe promotion for a client
     * @param {string} id The Client ID
     * @param {ClientRecipePromotionDTO} recipePromotion The recipe promotion to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public deleteRecipePromotion(id: string, recipePromotion: ClientRecipePromotionDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).deleteRecipePromotion(id, recipePromotion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enroll client in education program
     * @param {string} id Client ID to enroll in education program.
     * @param {EducationProgramEnrollmentRequestDTO} educationProgramEnrollmentRequest Request describing which program to enroll the client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public enrollClientInEducationProgram(id: string, educationProgramEnrollmentRequest: EducationProgramEnrollmentRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).enrollClientInEducationProgram(id, educationProgramEnrollmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active grocery list for client
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getActiveGroceryList(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getActiveGroceryList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active promoted recipes for a client
     * @param {string} id The client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getActivePromotedRecipes(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getActivePromotedRecipes(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Subscription Packages available for Client to start subscription with
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getAvailableStartingSubscriptionsForClient(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getAvailableStartingSubscriptionsForClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Client by their ID
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClient(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get client goal group
     * @param {string} id The Client ID
     * @param {number} [limit] Optional number of goal groups to return. If null, returns all.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientGoalGroups(id: string, limit?: number, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientGoalGroups(id, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Client Health Data Universal Id
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientHealthDataUniversalId(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientHealthDataUniversalId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get ordered recipes and prepared meals, with feedback, for client
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientOrderedRecipesAndPreparedMeals(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientOrderedRecipesAndPreparedMeals(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get weight entries for client
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientWeightEntries(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientWeightEntries(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get clients
     * @param {boolean} [hasActiveSubscription] If true, only return patients with active subscriptions. Defaults to false.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClients(hasActiveSubscription?: boolean, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClients(hasActiveSubscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get thread of all communications for a client
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getCommunicationThreadForClient(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getCommunicationThreadForClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the clients favorite recipes sorted by date they made it a favorite descending
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getFavoriteRecipes(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getFavoriteRecipes(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get form submissions by client
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getFormSubmissions(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getFormSubmissions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get intake form submissions for Client
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getIntakeFormSubmissions(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getIntakeFormSubmissions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get description of client\'s personalized nutrition prescription
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getNutritionPrescriptionDescription(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getNutritionPrescriptionDescription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order history for client
     * @param {string} id 
     * @param {string} [sinceUpdatedDate] If provided, only orders that have had their status updated since this date
     * @param {number} [limit] If provided, returns up to a maximum of this number of orders, sorted by most recently updated date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getOrderHistory(id: string, sinceUpdatedDate?: string, limit?: number, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getOrderHistory(id, sinceUpdatedDate, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get outstanding order and subscription balances for client
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getOutstandingBalances(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getOutstandingBalances(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get promoted recipes for a client
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getPromotedRecipes(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getPromotedRecipes(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of strings containing diet module search filters to use for recipe search queries.
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getRecipeSearchFilters(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getRecipeSearchFilters(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite a client to complete their profile
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public inviteClient(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).inviteClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite a client using email address
     * @param {InviteClientWithEmailRequestDTO} emailAddress Email address to send the invite to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public inviteClientWithEmail(emailAddress: InviteClientWithEmailRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).inviteClientWithEmail(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Promote a recipe for a client
     * @param {string} id The Client ID
     * @param {ClientRecipePromotionDTO} recipePromotion The recipe promotion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public promoteRecipe(id: string, recipePromotion: ClientRecipePromotionDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).promoteRecipe(id, recipePromotion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh active food order carts for client and return them
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public refreshActiveFoodOrderCarts(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).refreshActiveFoodOrderCarts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register a client
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public registerClient(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).registerClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search clients
     * @param {string} [firstNameQuery] 
     * @param {string} [lastNameQuery] 
     * @param {string} [phoneNumberQuery] 
     * @param {string} [addressQuery] Street address line 1
     * @param {string} [emailQuery] 
     * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Empty or null defaults to all values
     * @param {Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>} [currentStates] Empty or null defaults to all values
     * @param {'LAST_NAME'} [sortBy] Sort results. Defaults to LAST_NAME
     * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
     * @param {number} [limit] Limit query for pagination. Defaults to 100
     * @param {number} [offset] Offset query for pagination. Defaults to 0.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public searchClients(firstNameQuery?: string, lastNameQuery?: string, phoneNumberQuery?: string, addressQuery?: string, emailQuery?: string, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, currentStates?: Array<'CREATED' | 'INVITED' | 'VALIDATED' | 'ACTIVATED' | 'REGISTERED' | 'INACTIVE'>, sortBy?: 'LAST_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).searchClients(firstNameQuery, lastNameQuery, phoneNumberQuery, addressQuery, emailQuery, cohorts, currentStates, sortBy, sortDir, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a push notification to a client given their id and a message
     * @param {PushNotificationMessageRequestDTO} notification The notification being sent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public sendClientPushNotification(notification: PushNotificationMessageRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).sendClientPushNotification(notification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send recipes to a Client via SMS
     * @param {string} id The Client ID
     * @param {SendRecipesViaSmsRequestDTO} request Details about the recipes to send
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public sendRecipesViaSms(id: string, request: SendRecipesViaSmsRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).sendRecipesViaSms(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send an SMS to a client
     * @param {string} id The Client ID
     * @param {SendSmsRequestDTO} sendSmsRequest The details of the SMS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public sendSms(id: string, sendSmsRequest: SendSmsRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).sendSms(id, sendSmsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setup the intent to add payment info for a client
     * @param {string} id The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public setupPaymentIntent(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).setupPaymentIntent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start a subscription for a new Client
     * @param {string} id The Client ID
     * @param {StartClientSubscriptionRequestDTO} request Client and subscription information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public startClientSubscription(id: string, request: StartClientSubscriptionRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).startClientSubscription(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit intake form for new Client ahead of their initial appointment
     * @param {string} id The Client ID
     * @param {SubmitIntakeFormRequestDTO} submitIntakeFormRequest The values of the intake form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public submitIntakeForm(id: string, submitIntakeFormRequest: SubmitIntakeFormRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).submitIntakeForm(id, submitIntakeFormRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing client
     * @param {string} id The Client ID
     * @param {UpdateClientRequestDTO} client The updated Client resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClient(id: string, client: UpdateClientRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClient(id, client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update available cooking equipment
     * @param {string} id The Client ID
     * @param {UpdateClientAvailableCookingEquipmentRequestDTO} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClientAvailableCookingEquipment(id: string, client: UpdateClientAvailableCookingEquipmentRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClientAvailableCookingEquipment(id, client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update cohort details
     * @param {string} id The Client ID
     * @param {UpdateClientCohortDetailsRequestDTO} request Updated Client Cohort Details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClientCohortDetails(id: string, request: UpdateClientCohortDetailsRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClientCohortDetails(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a client\'s delivery details
     * @param {string} id The Client ID
     * @param {UpdateClientDeliveryDetailsRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClientDeliveryDetails(id: string, request: UpdateClientDeliveryDetailsRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClientDeliveryDetails(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a client\'s fulfillment notes
     * @param {string} id The Client ID
     * @param {UpdateClientFulfillmentNotesRequestDTO} request The updated fulfillment notes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClientFulfillmentNotes(id: string, request: UpdateClientFulfillmentNotesRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClientFulfillmentNotes(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update client ingredient preferences
     * @param {string} id The Client ID
     * @param {UpdateClientIngredientPreferencesRequestDTO} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClientIngredientPreferences(id: string, client: UpdateClientIngredientPreferencesRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClientIngredientPreferences(id, client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a client\'s notification preferences
     * @param {string} id The Client ID
     * @param {ClientNotificationPreferencesDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClientNotificationPreferences(id: string, request: ClientNotificationPreferencesDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClientNotificationPreferences(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a client\'s provider notes
     * @param {string} id The Client ID
     * @param {UpdateClientProviderNotesRequestDTO} request The updated provider notes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClientProviderNotes(id: string, request: UpdateClientProviderNotesRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClientProviderNotes(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a client\'s weight tracking enabled setting
     * @param {string} id The Client ID
     * @param {UpdateClientWeightTrackingEnabledRequestDTO} request The updated weight tracking enabled setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClientWeightTrackingEnabled(id: string, request: UpdateClientWeightTrackingEnabledRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClientWeightTrackingEnabled(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update client favorite recipe
     * @param {string} id The Client ID
     * @param {UpdateClientFavoriteRecipeRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateFavoriteRecipe(id: string, request: UpdateClientFavoriteRecipeRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateFavoriteRecipe(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a client\'s nutrition prescription
     * @param {string} id The Client ID
     * @param {UpdateNutritionPrescriptionRequestDTO} request The updated nutrition prescription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateNutritionPrescription(id: string, request: UpdateNutritionPrescriptionRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateNutritionPrescription(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a client\'s nutrition prescription as a client (Season Select only)
     * @param {string} id The Client ID
     * @param {UpdateNutritionPrescriptionAsClientRequestDTO} request The updated nutrition prescription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateNutritionPrescriptionAsClient(id: string, request: UpdateNutritionPrescriptionAsClientRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateNutritionPrescriptionAsClient(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a client\'s personal information
     * @param {string} id The Client ID
     * @param {UpdateClientPersonalInformationRequestDTO} request The updated personal information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updatePersonalInformation(id: string, request: UpdateClientPersonalInformationRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updatePersonalInformation(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update program start date
     * @param {string} id The Client ID
     * @param {UpdateClientProgramStartDateRequestDTO} request Updated Client Program Start Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateProgramStartDate(id: string, request: UpdateClientProgramStartDateRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateProgramStartDate(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate a client\'s information
     * @param {string} id The Client ID
     * @param {ValidateClientRequestDTO} request Client information. Once validated, overwrite existing data with values provided
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public validateClient(id: string, request: ValidateClientRequestDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).validateClient(id, request, options).then((request) => request(this.axios, this.basePath));
    }
}
