import type { Reducer } from 'redux';
import type { CheckoutDrawerState } from './types';
import { CheckoutDrawerActionTypes } from './types';
import type { CheckoutDrawerActions } from './actions';
import type { MetaActions } from '../meta/store';

export const initialCheckoutDrawerState: CheckoutDrawerState = {
    open: false,
    closeable: true,
    showCloseButton: true,
};

const reducer: Reducer<CheckoutDrawerState, CheckoutDrawerActions | MetaActions> = (
    state = initialCheckoutDrawerState,
    action
) => {
    switch (action.type) {
        case CheckoutDrawerActionTypes.TOGGLE_CHECKOUT_DRAWER: {
            return {
                ...state,
                open: !state.open,
            };
        }
        case CheckoutDrawerActionTypes.SET_CHECKOUT_DRAWER_CLOSEABLE: {
            return {
                ...state,
                closeable: action.payload,
            };
        }
        case CheckoutDrawerActionTypes.SET_CHECKOUT_DRAWER_SHOW_CLOSE_BUTTON: {
            return {
                ...state,
                showCloseButton: action.payload,
            };
        }
        case CheckoutDrawerActionTypes.RESET_CHECKOUT_DRAWER: {
            return initialCheckoutDrawerState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as checkoutDrawerReducer };
