import clsx from 'clsx';
import type { Props as InputProps } from './Input';
import InputWithFormik from './InputWithFormik';

export type Props = {
    type: 'checkbox' | 'radio';
    name: string;
    variant?: 'fancy' | 'chip';
    classNames?: {
        fieldset?: string;
        label?: string;
        legend?: string;
    };
    groupLabel?: string;
    options: InputProps[];
};

const InputGroupWithFormik = ({ type, name, classNames, groupLabel, options, variant }: Props): JSX.Element => {
    return (
        <fieldset className={classNames?.fieldset}>
            {groupLabel && <legend className={clsx('bodyMdMedium mb-2', classNames?.legend)}>{groupLabel}</legend>}

            {options.map((opt) => {
                return (
                    <InputWithFormik
                        className={classNames?.label}
                        type={type}
                        variant={variant}
                        key={opt.label.toString()}
                        {...opt}
                        name={name}
                    />
                );
            })}
        </fieldset>
    );
};

export default InputGroupWithFormik;
