import {
    ErrorDTOReasonEnum,
    type AddClientIntakeInsuranceRequestDTO,
    type AddClientIntakeInsuranceResponseDTO,
    type ErrorDTO,
    type RegisterClientIntakeRequestDTO,
    type SubmitClientIntakeRequestDTO,
    type SubmitClientIntakeResponseDTO,
    type TokenResponseDTO,
} from 'API';
import type { AxiosError, AxiosResponse } from 'axios';
import type { UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';
import { clientIntakeAPI } from '../index';

interface AddClientIntakeInsuranceArgs {
    id: string;
    request: AddClientIntakeInsuranceRequestDTO;
}

export const useAddClientIntakeInsurance = (
    options?: UseMutationOptions<
        AxiosResponse<AddClientIntakeInsuranceResponseDTO>,
        AxiosError<ErrorDTO>,
        AddClientIntakeInsuranceArgs
    >
) =>
    useMutation(
        (args: AddClientIntakeInsuranceArgs) => clientIntakeAPI.addClientIntakeInsurance(args.id, args.request),
        {
            ...options,
        }
    );

interface SubmitClientIntakeArgs {
    request: SubmitClientIntakeRequestDTO;
}

export const useSubmitClientIntake = (
    options?: UseMutationOptions<
        AxiosResponse<SubmitClientIntakeResponseDTO>,
        AxiosError<ErrorDTO>,
        SubmitClientIntakeArgs
    >
) => useMutation((args: SubmitClientIntakeArgs) => clientIntakeAPI.submitClientIntake(args.request), { ...options });

interface RegisterClientIntakeArgs {
    id: string;
    request: RegisterClientIntakeRequestDTO;
}

export const useRegisterClientIntake = (
    options?: UseMutationOptions<AxiosResponse<TokenResponseDTO>, AxiosError<ErrorDTO>, RegisterClientIntakeArgs>
) =>
    useMutation((args: RegisterClientIntakeArgs) => clientIntakeAPI.registerClientIntake(args.id, args.request), {
        ...options,
        retry: (failureCount, error) => {
            if (error.response?.data?.reason === ErrorDTOReasonEnum.APPOINTMENT_DATE_TIME_UNAVAILABLE_ERROR) {
                return false;
            } else {
                return failureCount < 3;
            }
        },
    });
