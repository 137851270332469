import clsx from 'clsx';

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    label: string | JSX.Element;
    isInvalid?: boolean;
    labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
    id: string;
};

const FancyRadio = ({ id, label, labelProps, isInvalid = false, type, className, ...rest }: Props): JSX.Element => (
    <label
        htmlFor={id}
        className={clsx(
            'drop-shadow-input hover:shadow-selector focus:drop-shadow-input checked:shadow-a-2 rounded-8 flex w-full cursor-pointer space-x-1.5',
            !!rest?.checked ? 'bg-mediumBlue-1 bodyMdMedium p-3.5' : 'bodyMdLight bg-white p-[15px]',
            rest?.disabled && 'input-disabled hover:shadow-transparent',
            className
        )}
        {...labelProps}
    >
        <input
            type={type}
            data-dd-privacy="mask"
            data-dd-action-name={`${id} input`}
            id={id}
            className={clsx(
                'border-greyscale-offBlack checked:after:bg-greyscale-offBlack m-0 mr-2 h-4 w-4 appearance-none items-center justify-center self-center rounded-full border border-solid bg-white transition duration-200 after:m-[3px] after:block after:h-2 after:w-2 after:rounded-full checked:border checked:hover:cursor-default disabled:hover:cursor-not-allowed',
                isInvalid && 'border-alert-red2'
            )}
            {...rest}
        />
        <span>{label}</span>
    </label>
);

export default FancyRadio;
