import clsx from 'clsx';
import { Input } from 'galley';

export type OptionsProps = {
    label: string | JSX.Element;
    value: string;
    isDisabled?: boolean;
};

export type Props = {
    classNames?: {
        fieldset?: string;
        label?: string;
        groupLabel?: string;
    };
    name?: string;
    groupLabel?: string;
    options: OptionsProps[];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectedValue?: string;
    variant?: 'chip';
};

const RadioSelectorGroup = ({
    name,
    classNames,
    groupLabel,
    onChange,
    options,
    selectedValue,
    variant,
}: Props): JSX.Element => {
    return (
        <fieldset className={classNames?.fieldset}>
            {groupLabel && <legend className={clsx('mb-1', classNames?.groupLabel)}>{groupLabel}</legend>}
            {options.map((opt) => {
                const { label, value, isDisabled } = opt;
                return (
                    <Input
                        type="radio"
                        key={value}
                        checked={selectedValue === value}
                        value={value}
                        label={label}
                        className={clsx(classNames?.label, { 'cursor-not-allowed': isDisabled })}
                        disabled={isDisabled}
                        onChange={onChange}
                        variant={variant}
                        name={name}
                    />
                );
            })}
        </fieldset>
    );
};

export default RadioSelectorGroup;
