import { logger } from 'utils';
import usePluginIdentifierOnLogin from 'utils/hooks/usePluginIdentifierOnLogin';

const PluginUpdatesContainer = ({ children }: { children: JSX.Element }): JSX.Element => {
    try {
        usePluginIdentifierOnLogin();
    } catch (e) {
        logger.error('Error in plugin identifier', undefined, e as unknown as Error);
    }
    return children;
};

export default PluginUpdatesContainer;
