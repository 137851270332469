import { t } from '@lingui/macro';
import clsx from 'clsx';
import { IconButton, Spinner, XStrokeIcon } from 'galley';
import type { MouseEvent } from 'react';
import { useRef } from 'react';
import { useKeypress, useRemoveUnderlyingScroll } from 'utils/hooks';

export type Props = React.PropsWithChildren<{
    onClose?: () => void;
    title?: string;
    isOpen: boolean;
    isLoading?: boolean;
    className?: string;
    disableCloseButton?: boolean;
    customHeader?: JSX.Element;
}>;

const DrawerModal = ({
    title,
    onClose,
    children,
    isOpen,
    isLoading,
    className,
    disableCloseButton,
    customHeader,
}: Props): JSX.Element => {
    const backdropRef = useRef(null);

    useRemoveUnderlyingScroll(isOpen);

    const dismissModal = (event: MouseEvent) => {
        if (event.target === backdropRef.current && !disableCloseButton) {
            onClose && onClose();
        }
    };

    useKeypress('Escape', () => {
        !disableCloseButton && onClose && onClose();
    });

    return (
        <div
            role="presentation"
            onClick={dismissModal}
            ref={backdropRef}
            className={clsx(
                'bg-greyscale-offBlack/30 fixed inset-0 z-50 overscroll-contain backdrop-blur ease-in-out md:grid md:place-content-center',
                isOpen
                    ? 'translate-y-0 opacity-100 transition-opacity duration-[400ms] md:transition-none'
                    : 'translate-y-full opacity-0 transition-all md:transition-none'
            )}
        >
            <div
                role="dialog"
                className={clsx(
                    'absolute bottom-0 flex w-full flex-col rounded-t-lg bg-white p-6 transition-all duration-[450ms] ease-in-out md:relative md:max-h-[90vh] md:max-w-md md:rounded-lg md:transition-none',
                    isOpen ? 'translate-y-0' : 'translate-y-full',
                    className
                )}
                title={title}
            >
                {isLoading && (
                    <div className="bg-greyscale-offBlack/20 bottom absolute inset-0 z-50 grid place-content-center rounded-lg">
                        <Spinner />
                    </div>
                )}
                {customHeader ? (
                    customHeader
                ) : (
                    <>
                        {!disableCloseButton && (
                            <IconButton aria-label={t`Close`} className="absolute right-6 top-6" onClick={onClose}>
                                <XStrokeIcon className="mdIcon" />
                            </IconButton>
                        )}
                        <div className="flex flex-row items-center px-4 pb-5 text-center">
                            <div className="bodyMdMedium grow capitalize">{title}</div>
                        </div>
                    </>
                )}
                <div className="flex h-full flex-col overflow-scroll">{children}</div>
            </div>
        </div>
    );
};

export default DrawerModal;
