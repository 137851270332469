/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum HealthGoalDTO {
    DECREASED_INFLAMMATION = 'DECREASED_INFLAMMATION',
    GUT_HEALTH = 'GUT_HEALTH',
    HEALTHY_AGING = 'HEALTHY_AGING',
    HEART_HEALTH = 'HEART_HEALTH',
    PLANT_FORWARD = 'PLANT_FORWARD'
};

