import type { FormikContextType } from 'formik';

export * from './ScrollToFormikError';

export const getFormikFieldValues = <T, K extends keyof T>(context: FormikContextType<T>, field: K) => {
    return {
        value: context.values[field],
        errors: context.errors[field],
        touched: context.touched[field],
    };
};
