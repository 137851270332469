import type { TokenResponseDTO } from 'API';
import axios from 'axios';
import { addMinutes } from 'date-fns';
import { CLIENT_EMAIL_ACCESSOR } from 'utils/constants/auth';
import { setPersistedItem } from 'utils/localStorage/persistedLocalStorage';
import { NativeAppOutboundMessageTypes } from 'utils/native-app-bridge/messageTypes';
import { postMessageToNativeApp } from 'utils/native-app-bridge/utils';
import { REFRESH_TOKEN_ACCESSOR, TOKEN_EXPIRATION_ACCESSOR } from '..';

const setTokens = (tokens: TokenResponseDTO): void => {
    const accessToken = tokens.accessToken;
    const refreshToken = tokens.refreshToken;
    const now = new Date();
    const expirationDate = addMinutes(now, 14);

    // Store in Local Storage for persistence
    localStorage.setItem(REFRESH_TOKEN_ACCESSOR, refreshToken);
    localStorage.setItem(TOKEN_EXPIRATION_ACCESSOR, JSON.stringify(expirationDate));

    // Persist the client email in Local Storage, in case they get logged out for not having validated their email
    // In this case we redirect to an unauthed page where we need their email to allow them to resend the validation link
    const primaryEmail = tokens.userDetails.client?.emails?.find((email) => email.isPrimary)?.emailAddress;
    primaryEmail && setPersistedItem(CLIENT_EMAIL_ACCESSOR, primaryEmail);

    // Set Header based on token
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, lingui/no-unlocalized-strings
    axios.defaults.headers!.common['Authorization'] = `Bearer ${accessToken}`;

    // Post for native app (if we're actually running in a web view and we logged in as a client)
    if (tokens.userDetails.client?.id) {
        postMessageToNativeApp(NativeAppOutboundMessageTypes.LOGIN_SUCCEEDED, {
            data: {
                authToken: accessToken,
                clientId: tokens.userDetails.client.id,
                pushNotificationSubscriptions: tokens.userDetails.client.pushNotificationSubscriptions,
            },
        });
    } else if (!tokens.userDetails.requiresMfa) {
        // if not a client and not in the MFA flow, post the unsupported message
        postMessageToNativeApp(NativeAppOutboundMessageTypes.UNSUPPORTED_USER_TYPE);
    }
};

export default setTokens;
