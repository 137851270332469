import type { BenefitDTO, BenefitsResponseDTO, UpdateBenefitDeliveryDateRequestDTO } from 'API';
import type { AxiosResponse } from 'axios';
import type { QueryKey, UseMutationOptions, UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import { benefitApi } from 'utils';

export const GET_BENEFITS_KEY = 'getBenefits';
export const useGetBenefits = (
    clientId: string,
    options?: Omit<UseQueryOptions<BenefitsResponseDTO, unknown, BenefitsResponseDTO, QueryKey>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        [GET_BENEFITS_KEY, clientId],
        () => benefitApi.getBenefits(clientId).then((response) => response.data),
        options
    );

export const GET_BENEFIT_KEY = 'getBenefit';
export const useGetBenefit = (
    benefitId: string,
    options?: Omit<UseQueryOptions<BenefitDTO, unknown, BenefitDTO, QueryKey>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        [GET_BENEFIT_KEY, benefitId],
        () => benefitApi.getBenefit(benefitId).then((response) => response.data),
        options
    );

interface UpdateBenefitDeliveryDateArgs {
    benefitId: string;
    request: UpdateBenefitDeliveryDateRequestDTO;
}

export const useUpdateBenefitDeliveryDate = (
    options?: UseMutationOptions<AxiosResponse<void>, unknown, UpdateBenefitDeliveryDateArgs>
) =>
    useMutation(
        ({ benefitId, request }: UpdateBenefitDeliveryDateArgs) =>
            benefitApi.updateBenefitDeliveryDate(benefitId, request),
        options
    );
