/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminDTO } from '../models';
// @ts-ignore
import { AdminEmailResponseDTO } from '../models';
// @ts-ignore
import { AdminsResponseDTO } from '../models';
// @ts-ignore
import { SearchOrdersResponseDTO } from '../models';
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Admin.
         * @param {AdminDTO} request Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin: async (request: AdminDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createAdmin', 'request', request)
            const localVarPath = `/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deactivate an admin
         * @summary Deactivate an Admin.
         * @param {string} id The id of the admin to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateAdmin: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateAdmin', 'id', id)
            const localVarPath = `/admins/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deactivate a Practice and all of the providers within it, which will mark the practice, those providers, and their accounts as no longer active 
         * @summary Deactivate a Practice.
         * @param {string} id The id of the practice to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePractice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivatePractice', 'id', id)
            const localVarPath = `/practices/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deactivate a Provider in a practice
         * @summary Deactivate a Provider.
         * @param {string} id The id of the provider to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateProvider: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateProvider', 'id', id)
            const localVarPath = `/providers/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Admins
         * @param {boolean} [includeOnlyOrderOwners] Optionally filter to only admins that have ever been an order owner. Defaults to false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmins: async (includeOnlyOrderOwners?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeOnlyOrderOwners !== undefined) {
                localVarQueryParameter['includeOnlyOrderOwners'] = includeOnlyOrderOwners;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details about an email given an email ID
         * @param {string} emailId The ID of the email to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailMessage: async (emailId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailId' is not null or undefined
            assertParamExists('getEmailMessage', 'emailId', emailId)
            const localVarPath = `/admins/email-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (emailId !== undefined) {
                localVarQueryParameter['emailId'] = emailId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search both grocery orders and food orders
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>} [orderStates] Superset of grocery list and food order states
         * @param {Array<'GROCERY' | 'PREPARED_MEAL'>} [orderTypes] 
         * @param {Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>} [foodOrderVendor] Filter food order vendor. PREPARED_MEAL must be included as an orderType for this filter to have effect
         * @param {Array<'NONE' | 'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>} [groceryBundleTypes] Filter to bundle type if GROCERY is included as an orderType. Providing NONE will include orders not tied to benefits. Defaults to all values. 
         * @param {Array<'NONE' | 'BLOCKED' | 'PREVIOUS_VENDOR' | 'PRODUCT_SEARCH'>} [fulfillmentIssueTypes] Filter to grocery orders with certain fulfillment issues. Defaults to no filter; providing NONE will look for null explicitly. 
         * @param {string} [estimatedDeliveryStartMin] Optionally provide a minimum estimated delivery window start time
         * @param {string} [estimatedDeliveryStartMax] Optionally provide a maximum estimated delivery start time
         * @param {string} [estimatedDeliveryEndMin] Optionally provide a minimum estimated delivery window end time
         * @param {string} [estimatedDeliveryEndMax] Optionally provide a maximum estimated delivery window end time
         * @param {string} [owningAdminId] Optionally filter to a certain owning admin
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {'EARLIEST_PREFERRED_DELIVERY_WINDOW_START' | 'ESTIMATED_DELIVERY' | 'PLACED_DATE'} [sortBy] Sort results. Defaults to PLACED_DATE. Orders with null in the sort field will come last
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOrders: async (clientId?: string, orderStates?: Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>, orderTypes?: Array<'GROCERY' | 'PREPARED_MEAL'>, foodOrderVendor?: Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>, groceryBundleTypes?: Array<'NONE' | 'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>, fulfillmentIssueTypes?: Array<'NONE' | 'BLOCKED' | 'PREVIOUS_VENDOR' | 'PRODUCT_SEARCH'>, estimatedDeliveryStartMin?: string, estimatedDeliveryStartMax?: string, estimatedDeliveryEndMin?: string, estimatedDeliveryEndMax?: string, owningAdminId?: string, limit?: number, offset?: number, sortBy?: 'EARLIEST_PREFERRED_DELIVERY_WINDOW_START' | 'ESTIMATED_DELIVERY' | 'PLACED_DATE', sortDir?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admins/search-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (orderStates) {
                localVarQueryParameter['orderStates'] = orderStates;
            }

            if (orderTypes) {
                localVarQueryParameter['orderTypes'] = orderTypes;
            }

            if (foodOrderVendor) {
                localVarQueryParameter['foodOrderVendor'] = foodOrderVendor;
            }

            if (groceryBundleTypes) {
                localVarQueryParameter['groceryBundleTypes'] = groceryBundleTypes;
            }

            if (fulfillmentIssueTypes) {
                localVarQueryParameter['fulfillmentIssueTypes'] = fulfillmentIssueTypes;
            }

            if (estimatedDeliveryStartMin !== undefined) {
                localVarQueryParameter['estimatedDeliveryStartMin'] = (estimatedDeliveryStartMin as any instanceof Date) ?
                    (estimatedDeliveryStartMin as any).toISOString() :
                    estimatedDeliveryStartMin;
            }

            if (estimatedDeliveryStartMax !== undefined) {
                localVarQueryParameter['estimatedDeliveryStartMax'] = (estimatedDeliveryStartMax as any instanceof Date) ?
                    (estimatedDeliveryStartMax as any).toISOString() :
                    estimatedDeliveryStartMax;
            }

            if (estimatedDeliveryEndMin !== undefined) {
                localVarQueryParameter['estimatedDeliveryEndMin'] = (estimatedDeliveryEndMin as any instanceof Date) ?
                    (estimatedDeliveryEndMin as any).toISOString() :
                    estimatedDeliveryEndMin;
            }

            if (estimatedDeliveryEndMax !== undefined) {
                localVarQueryParameter['estimatedDeliveryEndMax'] = (estimatedDeliveryEndMax as any instanceof Date) ?
                    (estimatedDeliveryEndMax as any).toISOString() :
                    estimatedDeliveryEndMax;
            }

            if (owningAdminId !== undefined) {
                localVarQueryParameter['owningAdminId'] = owningAdminId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Admin.
         * @param {AdminDTO} request Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdmin(request: AdminDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdmin(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deactivate an admin
         * @summary Deactivate an Admin.
         * @param {string} id The id of the admin to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateAdmin(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateAdmin(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deactivate a Practice and all of the providers within it, which will mark the practice, those providers, and their accounts as no longer active 
         * @summary Deactivate a Practice.
         * @param {string} id The id of the practice to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivatePractice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivatePractice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deactivate a Provider in a practice
         * @summary Deactivate a Provider.
         * @param {string} id The id of the provider to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateProvider(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateProvider(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Admins
         * @param {boolean} [includeOnlyOrderOwners] Optionally filter to only admins that have ever been an order owner. Defaults to false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdmins(includeOnlyOrderOwners?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdmins(includeOnlyOrderOwners, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details about an email given an email ID
         * @param {string} emailId The ID of the email to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailMessage(emailId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEmailResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailMessage(emailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search both grocery orders and food orders
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>} [orderStates] Superset of grocery list and food order states
         * @param {Array<'GROCERY' | 'PREPARED_MEAL'>} [orderTypes] 
         * @param {Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>} [foodOrderVendor] Filter food order vendor. PREPARED_MEAL must be included as an orderType for this filter to have effect
         * @param {Array<'NONE' | 'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>} [groceryBundleTypes] Filter to bundle type if GROCERY is included as an orderType. Providing NONE will include orders not tied to benefits. Defaults to all values. 
         * @param {Array<'NONE' | 'BLOCKED' | 'PREVIOUS_VENDOR' | 'PRODUCT_SEARCH'>} [fulfillmentIssueTypes] Filter to grocery orders with certain fulfillment issues. Defaults to no filter; providing NONE will look for null explicitly. 
         * @param {string} [estimatedDeliveryStartMin] Optionally provide a minimum estimated delivery window start time
         * @param {string} [estimatedDeliveryStartMax] Optionally provide a maximum estimated delivery start time
         * @param {string} [estimatedDeliveryEndMin] Optionally provide a minimum estimated delivery window end time
         * @param {string} [estimatedDeliveryEndMax] Optionally provide a maximum estimated delivery window end time
         * @param {string} [owningAdminId] Optionally filter to a certain owning admin
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {'EARLIEST_PREFERRED_DELIVERY_WINDOW_START' | 'ESTIMATED_DELIVERY' | 'PLACED_DATE'} [sortBy] Sort results. Defaults to PLACED_DATE. Orders with null in the sort field will come last
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchOrders(clientId?: string, orderStates?: Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>, orderTypes?: Array<'GROCERY' | 'PREPARED_MEAL'>, foodOrderVendor?: Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>, groceryBundleTypes?: Array<'NONE' | 'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>, fulfillmentIssueTypes?: Array<'NONE' | 'BLOCKED' | 'PREVIOUS_VENDOR' | 'PRODUCT_SEARCH'>, estimatedDeliveryStartMin?: string, estimatedDeliveryStartMax?: string, estimatedDeliveryEndMin?: string, estimatedDeliveryEndMax?: string, owningAdminId?: string, limit?: number, offset?: number, sortBy?: 'EARLIEST_PREFERRED_DELIVERY_WINDOW_START' | 'ESTIMATED_DELIVERY' | 'PLACED_DATE', sortDir?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchOrdersResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchOrders(clientId, orderStates, orderTypes, foodOrderVendor, groceryBundleTypes, fulfillmentIssueTypes, estimatedDeliveryStartMin, estimatedDeliveryStartMax, estimatedDeliveryEndMin, estimatedDeliveryEndMax, owningAdminId, limit, offset, sortBy, sortDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Admin.
         * @param {AdminDTO} request Admin resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin(request: AdminDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createAdmin(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Deactivate an admin
         * @summary Deactivate an Admin.
         * @param {string} id The id of the admin to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateAdmin(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deactivate a Practice and all of the providers within it, which will mark the practice, those providers, and their accounts as no longer active 
         * @summary Deactivate a Practice.
         * @param {string} id The id of the practice to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePractice(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivatePractice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deactivate a Provider in a practice
         * @summary Deactivate a Provider.
         * @param {string} id The id of the provider to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateProvider(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateProvider(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Admins
         * @param {boolean} [includeOnlyOrderOwners] Optionally filter to only admins that have ever been an order owner. Defaults to false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmins(includeOnlyOrderOwners?: boolean, options?: any): AxiosPromise<AdminsResponseDTO> {
            return localVarFp.getAdmins(includeOnlyOrderOwners, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details about an email given an email ID
         * @param {string} emailId The ID of the email to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailMessage(emailId: string, options?: any): AxiosPromise<AdminEmailResponseDTO> {
            return localVarFp.getEmailMessage(emailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search both grocery orders and food orders
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>} [orderStates] Superset of grocery list and food order states
         * @param {Array<'GROCERY' | 'PREPARED_MEAL'>} [orderTypes] 
         * @param {Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>} [foodOrderVendor] Filter food order vendor. PREPARED_MEAL must be included as an orderType for this filter to have effect
         * @param {Array<'NONE' | 'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>} [groceryBundleTypes] Filter to bundle type if GROCERY is included as an orderType. Providing NONE will include orders not tied to benefits. Defaults to all values. 
         * @param {Array<'NONE' | 'BLOCKED' | 'PREVIOUS_VENDOR' | 'PRODUCT_SEARCH'>} [fulfillmentIssueTypes] Filter to grocery orders with certain fulfillment issues. Defaults to no filter; providing NONE will look for null explicitly. 
         * @param {string} [estimatedDeliveryStartMin] Optionally provide a minimum estimated delivery window start time
         * @param {string} [estimatedDeliveryStartMax] Optionally provide a maximum estimated delivery start time
         * @param {string} [estimatedDeliveryEndMin] Optionally provide a minimum estimated delivery window end time
         * @param {string} [estimatedDeliveryEndMax] Optionally provide a maximum estimated delivery window end time
         * @param {string} [owningAdminId] Optionally filter to a certain owning admin
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {'EARLIEST_PREFERRED_DELIVERY_WINDOW_START' | 'ESTIMATED_DELIVERY' | 'PLACED_DATE'} [sortBy] Sort results. Defaults to PLACED_DATE. Orders with null in the sort field will come last
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOrders(clientId?: string, orderStates?: Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>, orderTypes?: Array<'GROCERY' | 'PREPARED_MEAL'>, foodOrderVendor?: Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>, groceryBundleTypes?: Array<'NONE' | 'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>, fulfillmentIssueTypes?: Array<'NONE' | 'BLOCKED' | 'PREVIOUS_VENDOR' | 'PRODUCT_SEARCH'>, estimatedDeliveryStartMin?: string, estimatedDeliveryStartMax?: string, estimatedDeliveryEndMin?: string, estimatedDeliveryEndMax?: string, owningAdminId?: string, limit?: number, offset?: number, sortBy?: 'EARLIEST_PREFERRED_DELIVERY_WINDOW_START' | 'ESTIMATED_DELIVERY' | 'PLACED_DATE', sortDir?: 'ASC' | 'DESC', options?: any): AxiosPromise<SearchOrdersResponseDTO> {
            return localVarFp.searchOrders(clientId, orderStates, orderTypes, foodOrderVendor, groceryBundleTypes, fulfillmentIssueTypes, estimatedDeliveryStartMin, estimatedDeliveryStartMax, estimatedDeliveryEndMin, estimatedDeliveryEndMax, owningAdminId, limit, offset, sortBy, sortDir, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Admin.
     * @param {AdminDTO} request Admin resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public createAdmin(request: AdminDTO, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).createAdmin(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deactivate an admin
     * @summary Deactivate an Admin.
     * @param {string} id The id of the admin to deactivate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deactivateAdmin(id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deactivateAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deactivate a Practice and all of the providers within it, which will mark the practice, those providers, and their accounts as no longer active 
     * @summary Deactivate a Practice.
     * @param {string} id The id of the practice to deactivate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deactivatePractice(id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deactivatePractice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deactivate a Provider in a practice
     * @summary Deactivate a Provider.
     * @param {string} id The id of the provider to deactivate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deactivateProvider(id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deactivateProvider(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Admins
     * @param {boolean} [includeOnlyOrderOwners] Optionally filter to only admins that have ever been an order owner. Defaults to false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdmins(includeOnlyOrderOwners?: boolean, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdmins(includeOnlyOrderOwners, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details about an email given an email ID
     * @param {string} emailId The ID of the email to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getEmailMessage(emailId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getEmailMessage(emailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search both grocery orders and food orders
     * @param {string} [clientId] Optionally filter to a certain client
     * @param {Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>} [orderStates] Superset of grocery list and food order states
     * @param {Array<'GROCERY' | 'PREPARED_MEAL'>} [orderTypes] 
     * @param {Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>} [foodOrderVendor] Filter food order vendor. PREPARED_MEAL must be included as an orderType for this filter to have effect
     * @param {Array<'NONE' | 'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>} [groceryBundleTypes] Filter to bundle type if GROCERY is included as an orderType. Providing NONE will include orders not tied to benefits. Defaults to all values. 
     * @param {Array<'NONE' | 'BLOCKED' | 'PREVIOUS_VENDOR' | 'PRODUCT_SEARCH'>} [fulfillmentIssueTypes] Filter to grocery orders with certain fulfillment issues. Defaults to no filter; providing NONE will look for null explicitly. 
     * @param {string} [estimatedDeliveryStartMin] Optionally provide a minimum estimated delivery window start time
     * @param {string} [estimatedDeliveryStartMax] Optionally provide a maximum estimated delivery start time
     * @param {string} [estimatedDeliveryEndMin] Optionally provide a minimum estimated delivery window end time
     * @param {string} [estimatedDeliveryEndMax] Optionally provide a maximum estimated delivery window end time
     * @param {string} [owningAdminId] Optionally filter to a certain owning admin
     * @param {number} [limit] Limit query for pagination. Defaults to 100
     * @param {number} [offset] Offset query for pagination. Defaults to 0.
     * @param {'EARLIEST_PREFERRED_DELIVERY_WINDOW_START' | 'ESTIMATED_DELIVERY' | 'PLACED_DATE'} [sortBy] Sort results. Defaults to PLACED_DATE. Orders with null in the sort field will come last
     * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to DESC
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public searchOrders(clientId?: string, orderStates?: Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>, orderTypes?: Array<'GROCERY' | 'PREPARED_MEAL'>, foodOrderVendor?: Array<'DAILY_HARVEST' | 'MODIFY_HEALTH' | 'TERRITORY' | 'TOPCHEF'>, groceryBundleTypes?: Array<'NONE' | 'HOME_ESSENTIALS_1' | 'HOME_ESSENTIALS_2' | 'HOME_ESSENTIALS_3' | 'STANDARD' | 'PANTRY' | 'REFRESH_YOUR_PLATE' | 'POWERFUL_SNACKING' | 'SUMMERTIME_NUTRITION_MADE_EASY' | 'GET_GOING_WITH_BREAKFAST' | 'TAKE_CARE_OF_YOUR_GUT' | 'ONE_POT_ESSENTIALS' | 'SWEETEN_YOUR_HEALTH' | 'TRAVEL_THE_WORLD_WITH_FOOD' | 'LIGHTEN_UP_YOUR_HOLIDAYS'>, fulfillmentIssueTypes?: Array<'NONE' | 'BLOCKED' | 'PREVIOUS_VENDOR' | 'PRODUCT_SEARCH'>, estimatedDeliveryStartMin?: string, estimatedDeliveryStartMax?: string, estimatedDeliveryEndMin?: string, estimatedDeliveryEndMax?: string, owningAdminId?: string, limit?: number, offset?: number, sortBy?: 'EARLIEST_PREFERRED_DELIVERY_WINDOW_START' | 'ESTIMATED_DELIVERY' | 'PLACED_DATE', sortDir?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).searchOrders(clientId, orderStates, orderTypes, foodOrderVendor, groceryBundleTypes, fulfillmentIssueTypes, estimatedDeliveryStartMin, estimatedDeliveryStartMax, estimatedDeliveryEndMin, estimatedDeliveryEndMax, owningAdminId, limit, offset, sortBy, sortDir, options).then((request) => request(this.axios, this.basePath));
    }
}
