import Tab from './Tab';
import clsx from 'clsx';

export type Props = {
    labels: string[];
    selectedLabel: string;
    setSelectedLabel: React.Dispatch<React.SetStateAction<string>>;
};

const Tablist = ({ labels, selectedLabel, setSelectedLabel }: Props): JSX.Element => {
    return (
        <div role="tablist" className={clsx('flex')}>
            {labels.map((label, i) => (
                <Tab
                    key={`label-${i}`}
                    label={label}
                    isSelected={label === selectedLabel}
                    setSelectedLabel={setSelectedLabel}
                />
            ))}
        </div>
    );
};

export default Tablist;
