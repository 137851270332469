import clsx from 'clsx';
import type { MaskedInputProps } from 'react-text-mask';
import MaskedInput from 'react-text-mask';

export interface InputMaskedProps extends MaskedInputProps {
    dataTestId?: string;
    isInvalid?: boolean;
    label?: string;
    inputClassName?: string;
}

const InputMasked = ({
    dataTestId,
    isInvalid = false,
    label,
    disabled = false,
    className,
    inputClassName,
    ...props
}: InputMaskedProps): JSX.Element => {
    return (
        <div className={className}>
            <label htmlFor={label} className="bodyMdLight block w-full pb-1">
                {label}
            </label>
            <MaskedInput
                data-dd-privacy="mask"
                data-dd-action-name={`${label} input`}
                id={label}
                className={clsx(
                    'border-standard-barley bodyMdMedium hover:drop-shadow-input focus:drop-shadow-input invalid:!border-alert-red2 block w-full rounded border px-4 py-3 outline-none placeholder:!font-normal focus:border-2 focus:px-[15px] focus:py-[11px]',
                    isInvalid && '!border-alert-red2',
                    inputClassName
                )}
                data-test-id={dataTestId}
                guide={false}
                disabled={disabled}
                {...props}
            />
        </div>
    );
};

export default InputMasked;
