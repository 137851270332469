import { t } from '@lingui/macro';
import clsx from 'clsx';
import Button from 'galley/Button/Button';
import { ChevronLeftStrokeIcon, ChevronRightStrokeIcon } from 'galley/Icons';
import { useLayoutEffect, useMemo, useState } from 'react';

export type Props = {
    isFirstPage: boolean;
    isLastPage: boolean;
    pages: number[];
    total: number;
    initialPage: number;
    onSelection(page: number): void;
    className?: string;
};

const Pagination = ({
    isFirstPage,
    isLastPage,
    pages,
    total,
    initialPage,
    onSelection,
    className,
}: Props): JSX.Element => {
    const hasFirstPage = pages.includes(0);
    const hasLastPage = pages.includes(total - 1);
    const [currentPage, setCurrentPage] = useState(initialPage);

    useLayoutEffect(() => {
        initialPage !== currentPage && setCurrentPage(initialPage);
    }, [initialPage, currentPage]);

    const pagesToShow = useMemo(() => {
        const startIndex = hasFirstPage ? 0 : 2;
        const endIndex = hasLastPage ? pages.length : pages.length - 2;
        return pages.slice(startIndex, endIndex);
    }, [hasFirstPage, hasLastPage, pages]);

    const renderPageItem = (page: number) => (
        <li key={`page-${page}`}>
            <Button
                onClick={() => {
                    handlePageSelection(page);
                }}
            >
                <div
                    className={clsx(
                        'md:hover:bg-lowBlue-1 w-14 rounded-full p-4 transition-colors',
                        page === currentPage && 'bg-standard-oat'
                    )}
                >
                    <span className={clsx('bodyMdMedium text-highBlue-1')}>{page + 1}</span>
                </div>
            </Button>
        </li>
    );

    const renderEllipses = (key: string) => (
        <li key={`pagination-ellipses-${key}`}>
            <div className="w-14 text-center">
                <span className="bodyMdMedium text-greyscale-inactive">...</span>
            </div>
        </li>
    );

    const handlePageSelection = (page: number) => {
        setCurrentPage(page);
        onSelection(page);
    };

    return (
        <ul className={clsx('my-2 flex items-center', className)} aria-label={t`pagination`}>
            <div className="flex w-6 items-center">
                {!isFirstPage && (
                    <Button aria-label={t`Previous page`} onClick={() => handlePageSelection(currentPage - 1)}>
                        <ChevronLeftStrokeIcon className="text-highBlue-1 iconLg" />
                    </Button>
                )}
            </div>
            {!hasFirstPage && (
                <>
                    {renderPageItem(0)}
                    {renderEllipses('start')}
                </>
            )}
            {pagesToShow.map((page) => renderPageItem(page))}
            {!hasLastPage && (
                <>
                    {renderEllipses('end')}
                    {renderPageItem(total - 1)}
                </>
            )}
            <div className="flex w-6 items-center">
                {!isLastPage && (
                    <Button aria-label={t`Next page`} onClick={() => handlePageSelection(currentPage + 1)}>
                        <ChevronRightStrokeIcon className="text-highBlue-1 iconLg" />
                    </Button>
                )}
            </div>
        </ul>
    );
};

export default Pagination;
