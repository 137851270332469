import type { I18n, Messages } from '@lingui/core';
import { i18n } from '@lingui/core';
import { fromNavigator, multipleDetect } from '@lingui/detect-locale';
import { I18nProvider } from '@lingui/react';
import type React from 'react';
import { useEffect } from 'react';
import { logger, useFlags } from 'utils';

export type Props = React.PropsWithChildren<unknown>;

const DEFAULT_LOCALE = 'en';
const PSEUDO_LOCALE = 'en-fake';

const tryLoadMessages = async (locale: string) => {
    try {
        const { messages } = await import(`../locales/${locale}/messages`);
        return messages;
    } catch {
        return undefined;
    }
};

const activateLanguageWithMessages = (i18n: I18n, locale: string, messages: Messages) => {
    if (locale === i18n.locale) {
        logger.debug(`Skipping locale activation, as the locale is already ${locale}`);
        return;
    }

    i18n.load(locale, messages);
    i18n.activate(locale);
};

const activateFirstSupportedLanguageOrDefault = async (i18n: I18n, locales: string[]) => {
    // for languages with variants, such as en-US vs en-UK, try to load the locale as requested and
    // if we cannot find messages, then fallback to attempting to load the base language (first part before the hyphen)
    const localesWithFallback = locales
        .map((it) => (it.includes('-') ? [it, it.split('-')[0]] : it))
        .flatMap((it) => it);
    const uniqueDetectedLocales = localesWithFallback.reduce((acc, curr) => {
        if (acc.includes(curr)) {
            return acc;
        } else {
            return [...acc, curr];
        }
    }, [] as string[]);

    for (const locale of [...uniqueDetectedLocales, DEFAULT_LOCALE]) {
        const m = await tryLoadMessages(locale);
        logger.debug(`Result of trying to load messages for locale ${locale}, ${m}`);

        if (m) {
            activateLanguageWithMessages(i18n, locale, m);
            break;
        }
    }
};

export const activateLanguage = async (i18n: I18n, locale: string) => {
    await activateFirstSupportedLanguageOrDefault(i18n, [locale]);
};

function Provider({ children, ...props }: Props): React.ReactElement {
    const detectedLocales = multipleDetect(fromNavigator());
    const { enableLocalization, enablePseudolocalization } = useFlags();

    useEffect(() => {
        if (enablePseudolocalization) {
            activateFirstSupportedLanguageOrDefault(i18n, [PSEUDO_LOCALE]);
        } else {
            activateFirstSupportedLanguageOrDefault(i18n, enableLocalization ? detectedLocales : []);
        }
    }, [enableLocalization, detectedLocales, enablePseudolocalization]);

    return (
        <I18nProvider {...props} i18n={i18n}>
            {children}
        </I18nProvider>
    );
}
export default Provider;
