import type { RecipeIngredientResponseDTO } from 'API';
import { MealTypeDTO } from 'API';
import placeholderImageSrc from 'assets/images/recipes/placeholder.png';
import type { RecipeIngredient } from 'types';
import { ASSETS_URL } from 'utils/constants/env';

export const getIngredientAllergies = (
    ingredients: Array<RecipeIngredient | RecipeIngredientResponseDTO>
): Array<string> => {
    const allergies: Set<string> = new Set();
    ingredients.forEach((ing) => ing.allergies && ing.allergies.forEach((allergy) => allergies.add(allergy)));
    return Array.from(allergies);
};

export const getIngredientIntolerances = (
    ingredients: Array<RecipeIngredient | RecipeIngredientResponseDTO>
): Array<string> => {
    const intolerances: Set<string> = new Set();
    ingredients.forEach(
        (ing) => ing.intolerances && ing.intolerances.forEach((intolerance) => intolerances.add(intolerance))
    );
    return Array.from(intolerances);
};

export const getIngredientQualifyingDietaryRestrictions = (
    ingredients: Array<RecipeIngredient | RecipeIngredientResponseDTO>
): Array<string> => {
    const allRestrictions: Set<string> = new Set();
    ingredients.forEach(
        (ing) =>
            ing.qualifyingDietaryRestrictions &&
            ing.qualifyingDietaryRestrictions.forEach((restriction) => allRestrictions.add(restriction))
    );

    return Array.from(allRestrictions).filter((restriction) =>
        ingredients.every(
            (ingredient) =>
                ingredient.qualifyingDietaryRestrictions &&
                (ingredient.qualifyingDietaryRestrictions as string[]).includes(restriction)
        )
    );
};

export const getSeasonTintForMeal = (mealType: MealTypeDTO) => {
    const colorMap = {
        [MealTypeDTO.BREAKFAST]: 'bg-watermelon-light',
        [MealTypeDTO.LUNCH]: 'bg-bloodOrange-medium',
        [MealTypeDTO.DINNER]: 'bg-accent-orange',
        [MealTypeDTO.SNACK]: 'bg-accent-yellow',
    };
    return colorMap[mealType];
};

/**
 * Gets the image for the recipe, or a fallback placeholder, at the width specified if it qualifies for resizing. See notes.
 *
 * @param recipe the recipe to get the image for
 * @param atWidth an optional parameter that will request a scaled version of the original image at this width
 *              up to the width of the original image.  This parameter will only take effect when the recipe image URL is:
 *              1. It is on our assets bucket that has the edge lambda attached
 *              2. ends in `.png` (as we do not scale any other image type)
 * @returns a string of the image url or a fallback placeholder image
 */
export const getRecipeImageOrPlaceholder = (recipe: { images?: Array<{ url: string }> }, atWidth?: number) => {
    const originalUrl: string | undefined = recipe?.images?.[0]?.url;
    return originalUrl ? appendResizeToImageUrl(originalUrl, atWidth) : getPlaceHolderImageForRecipe();
};

export const SUPPORTED_IMG_EXTENSIONS = ['.png', '.jpg', '.jpeg'];

export const appendResizeToImageUrl = (imageUrl: string, atWidth?: number) => {
    const extension = imageUrl.toLowerCase().slice(imageUrl.toLowerCase().lastIndexOf('.'));
    const resizeAppend =
        atWidth != null &&
        imageUrl.toLowerCase().includes(ASSETS_URL) &&
        SUPPORTED_IMG_EXTENSIONS.includes(extension) &&
        atWidth > 0
            ? `/resize/${atWidth}${extension}`
            : '';

    return `${imageUrl}${resizeAppend}`;
};

export const getPreparedMealImageOrPlaceholder = (imageUrl?: string, atWidth?: number) => {
    const originalUrl: string | undefined = imageUrl?.trim();
    return originalUrl ? appendResizeToImageUrl(originalUrl, atWidth) : getPlaceHolderImageForRecipe();
};

export const getPlaceHolderImageForRecipe = (): string => {
    return placeholderImageSrc;
};
