import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import type React from 'react';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { useEffectOnce } from 'react-use';
import type { Store } from 'redux';
import { configureResponseErrorInterceptor, refreshAuthLogic, useFlags } from 'utils';
import logger from 'utils/logger';
import { addGlobalTrackContext } from 'utils/tracking/events';
import ErrorBoundary from './ErrorBoundary';
import Routes from './Routes/Routes';
import PluginUpdatesContainer from './components/organisms/PluginUpdatesContainer/PluginUpdatesContainer';
import RefreshLogin from './components/organisms/RefreshLogin/RefreshLogin';
import I18nProvider from './i18n/Provider';
import type { ApplicationActions, ApplicationState } from './store';
import type { MetaActions } from './store/meta/store';

// Any additional component props go here.
export interface AppProps {
    store: Store<ApplicationState, ApplicationActions | MetaActions>;
}

const App: React.FC<AppProps> = ({ store }) => {
    const flags = useFlags();

    useEffect(() => {
        addGlobalTrackContext('feature_flags', flags);
        logger.addGlobalContext('feature_flags', flags);
    }, [flags]);

    useEffectOnce(() => {
        const errorInterceptorId = configureResponseErrorInterceptor(axios);
        const authRefreshInterceptorId = createAuthRefreshInterceptor(axios, refreshAuthLogic, { statusCodes: [401] });
        return () => {
            axios.interceptors.request.eject(errorInterceptorId);
            axios.interceptors.request.eject(authRefreshInterceptorId);
        };
    });

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <RefreshLogin>
                    <PluginUpdatesContainer>
                        <I18nProvider>
                            <Routes />
                        </I18nProvider>
                    </PluginUpdatesContainer>
                </RefreshLogin>
            </Provider>
        </ErrorBoundary>
    );
};

export default App;
