import { useKeypress } from 'utils/hooks';

export type keyActionsOptionsProps = {
    canAdvance?: boolean;
    canGoBack?: boolean;
};

export const useKeyActions = (
    onNext: () => void,
    onBack: () => void,
    { canAdvance = true, canGoBack = true }: keyActionsOptionsProps
) => {
    useKeypress('Enter', () => {
        if (canAdvance) onNext();
    });
};

export default useKeyActions;
