import type React from 'react';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';

// Rendering this hook within a formik context will make it so that
// The first error will be scrolled into view after validation occurs
export const ScrollToFormikError: React.FC = () => {
    const { errors, isSubmitting } = useFormikContext();
    useEffect(() => {
        if (isSubmitting && Object.keys(errors).length > 0) {
            document.getElementsByName(Object.keys(errors)[0])[0].focus();
        }
    }, [errors, isSubmitting]);
    return null;
};
