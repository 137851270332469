import { t } from '@lingui/macro';
import { ConditionDTO, CookingEquipmentDTO, FoodAllergyDTO, FoodIntoleranceDTO, HealthGoalDTO } from 'API';

export const US_STATE_SELECTOR = [
    { value: 'AK', display: 'AK' },
    { value: 'AL', display: 'AL' },
    { value: 'AR', display: 'AR' },
    { value: 'AZ', display: 'AZ' },
    { value: 'CA', display: 'CA' },
    { value: 'CO', display: 'CO' },
    { value: 'CT', display: 'CT' },
    { value: 'DE', display: 'DE' },
    { value: 'FL', display: 'FL' },
    { value: 'GA', display: 'GA' },
    { value: 'HI', display: 'HI' },
    { value: 'IA', display: 'IA' },
    { value: 'ID', display: 'ID' },
    { value: 'IL', display: 'IL' },
    { value: 'IN', display: 'IN' },
    { value: 'KS', display: 'KS' },
    { value: 'KY', display: 'KY' },
    { value: 'LA', display: 'LA' },
    { value: 'MA', display: 'MA' },
    { value: 'MD', display: 'MD' },
    { value: 'ME', display: 'ME' },
    { value: 'MI', display: 'MI' },
    { value: 'MN', display: 'MN' },
    { value: 'MO', display: 'MO' },
    { value: 'MS', display: 'MS' },
    { value: 'MT', display: 'MT' },
    { value: 'NC', display: 'NC' },
    { value: 'ND', display: 'ND' },
    { value: 'NE', display: 'NE' },
    { value: 'NH', display: 'NH' },
    { value: 'NJ', display: 'NJ' },
    { value: 'NM', display: 'NM' },
    { value: 'NV', display: 'NV' },
    { value: 'NY', display: 'NY' },
    { value: 'OH', display: 'OH' },
    { value: 'OK', display: 'OK' },
    { value: 'OR', display: 'OR' },
    { value: 'PA', display: 'PA' },
    { value: 'RI', display: 'RI' },
    { value: 'SC', display: 'SC' },
    { value: 'SD', display: 'SD' },
    { value: 'TN', display: 'TN' },
    { value: 'TX', display: 'TX' },
    { value: 'UT', display: 'UT' },
    { value: 'VA', display: 'VA' },
    { value: 'VT', display: 'VT' },
    { value: 'WA', display: 'WA' },
    { value: 'WI', display: 'WI' },
    { value: 'WV', display: 'WV' },
    { value: 'WY', display: 'WY' },
];

export const getDietaryRestrictionsSelectors = () => [
    {
        name: 'noRestrictions',
        text: t`No Restrictions`,
        subText: t`Eats most things.`,
    },
    {
        name: 'PESCATARIAN',
        text: t`Pescatarian`,
        subText: t`Eats fish and other seafood. Doesn't eat other meat.`,
    },
    {
        name: 'LACTO_OVO_VEGETARIAN',
        text: t`Lacto-Ovo Vegetarian`,
        subText: t`Eats eggs and dairy. Doesn't eat meat, poultry, fish or other seafood.`,
    },
    {
        name: 'VEGAN',
        text: t`Vegan`,
        subText: t`No animal products or by-products.`,
    },
];

export const ingredientsToAvoidDefault = [
    'BEETS',
    'BLUE CHEESE',
    'BRUSSELS SPROUTS',
    'CILANTRO',
    'COCONUT',
    'EGGPLANT',
    'MUSHROOMS',
    'OLIVES',
    'TEMPEH',
    'TOFU',
];

export const disallowedIngredientGroupExclusions = [
    'OLIVE OIL',
    'CANOLA OIL',
    'SALT',
    'GARLIC',
    'LEMONS',
    'ARUGULA',
    'SPINACH',
];

export enum IngredientsToAvoid {
    BEETS = 'BEETS',
    BLUE_CHEESE = 'BLUE CHEESE',
    BRUSSELS_SPROUTS = 'BRUSSELS SPROUTS',
    CILANTRO = 'CILANTRO',
    COCONUT = 'COCONUT',
    EGGPLANT = 'EGGPLANT',
    MUSHROOMS = 'MUSHROOMS',
    OLIVES = 'OLIVES',
    TEMPEH = 'TEMPEH',
    TOFU = 'TOFU',
}

export const getAllergyOptions = () => [
    { name: t`Egg`, value: FoodAllergyDTO.EGG },
    { name: t`Fish`, value: FoodAllergyDTO.FISH },
    { name: t`Milk`, value: FoodAllergyDTO.MILK },
    { name: t`Peanut`, value: FoodAllergyDTO.PEANUT },
    { name: t`Sesame`, value: FoodAllergyDTO.SESAME },
    { name: t`Shellfish`, value: FoodAllergyDTO.SHELLFISH },
    { name: t`Soy`, value: FoodAllergyDTO.SOY },
    { name: t`Tree nut`, value: FoodAllergyDTO.TREE_NUT },
    { name: t`Wheat`, value: FoodAllergyDTO.WHEAT },
];

export const getIntoleranceOptions = () => [{ name: t`Lactose Intolerance`, value: FoodIntoleranceDTO.LACTOSE }];

export const getCookingEquipmentOptions = () => [
    { name: t`Air fryer`, value: CookingEquipmentDTO.AIR_FRYER },
    { name: t`Grill`, value: CookingEquipmentDTO.GRILL },
    { name: t`Blender`, value: CookingEquipmentDTO.BLENDER },
    { name: t`Pressure cooker`, value: CookingEquipmentDTO.PRESSURE_COOKER },
    { name: t`Slow cooker`, value: CookingEquipmentDTO.SLOW_COOKER },
];

export const getMedicalConditionOptions = () => [
    {
        name: t`Celiac Disease / Gluten Intolerance`,
        value: ConditionDTO.CELIAC,
    },
    {
        name: t`Diabetes`,
        value: ConditionDTO.DIABETES,
    },
    {
        name: t`Heart Disease`,
        value: ConditionDTO.CARDIOVASCULAR_DISEASE,
    },
    {
        name: t`Heart Failure`,
        value: ConditionDTO.CHF,
    },
    {
        name: t`High Cholesterol and / or Triglycerides`,
        value: ConditionDTO.HIGH_CHOLESTEROL,
    },
    {
        name: t`High Blood Pressure`,
        value: ConditionDTO.HYPERTENSION,
    },
    {
        name: t`Kidney Disease (CKD or ESRD)`,
        value: ConditionDTO.CKD,
    },
    {
        name: t`Non-alcoholic Fatty Liver Disease`,
        value: ConditionDTO.NON_ALCOHOLIC_FATTY_LIVER_DISEASE,
    },
    {
        name: t`Pre-diabetes`,
        value: ConditionDTO.PRE_DIABETES,
    },
    {
        name: t`Pregnancy`,
        value: ConditionDTO.PREGNANCY,
    },
    {
        name: t`Weight Management`,
        value: ConditionDTO.OVERWEIGHT,
    },
];

export const getHealthGoalOptions = () => [
    {
        name: t`Gut health`,
        value: HealthGoalDTO.GUT_HEALTH,
    },
    {
        name: t`Decreased inflammation`,
        value: HealthGoalDTO.DECREASED_INFLAMMATION,
    },
    {
        name: t`Healthy aging`,
        value: HealthGoalDTO.HEALTHY_AGING,
    },
    {
        name: t`Heart health`,
        value: HealthGoalDTO.HEART_HEALTH,
    },
    {
        name: t`General health and wellness`,
        value: '',
    },
];
