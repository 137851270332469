import type { ComponentType } from 'react';
import { lazy } from 'react';
import { logger } from '../utils';

export const LOCAL_STORAGE_PREFIX = 'window-reload-has-occurred';

export const lazyWithRetry = (componentImport: () => Promise<{ default: ComponentType }>, key: string) =>
    lazy(async () => {
        const localStorageKey = `${LOCAL_STORAGE_PREFIX}-${key}`;
        const windowReloadHasOccurred = JSON.parse(window.localStorage.getItem(localStorageKey) || 'false');

        try {
            const component = await componentImport();
            window.localStorage.setItem(localStorageKey, 'false');
            return component;
        } catch (error) {
            if (!windowReloadHasOccurred) {
                // likely some chunk load error has occurred; reload the window
                logger.info('Error has occurred lazily importing a component; reloading the window', { error });
                window.localStorage.setItem(localStorageKey, 'true');
                window.location.reload();

                // return to make typescript happy
                return { default: () => <></> };
            }

            // Page has already been force refreshed, re-raise the error
            throw error;
        }
    });
