import clsx from 'clsx';

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    label: string | JSX.Element;
    isInvalid?: boolean;
    labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
    id: string;
    inputClassName?: string;
    checkedLabelClassName?: string;
};

const Radio = ({
    id,
    label,
    labelProps,
    isInvalid = false,
    className,
    inputClassName,
    checkedLabelClassName,
    ...rest
}: Props): JSX.Element => (
    <label
        className={clsx(
            'flex content-center hover:cursor-pointer',
            className || 'bodySmLight',
            rest.checked && checkedLabelClassName,
            '',
            {
                '!font-medium': rest.checked,
                'input-disabled': rest.disabled,
            }
        )}
    >
        <input
            data-dd-privacy="mask"
            data-dd-action-name={`${id} input`}
            type="radio"
            className={clsx(
                'border-greyscale-offBlack hover:shadow-b-2 checked:after:bg-greyscale-offBlack m-0 mr-2 flex h-4 w-4 appearance-none items-center justify-center self-center rounded-full border border-solid bg-white after:m-[2px] after:block after:h-2 after:w-2 after:rounded-full checked:border checked:hover:cursor-default hover:checked:border hover:[&:not(checked)]:border-2',
                inputClassName
            )}
            checked={rest.checked}
            disabled={rest.disabled}
            {...rest}
        />
        {label}
    </label>
);

export default Radio;
