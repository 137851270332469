import { t } from '@lingui/macro';
import { useState } from 'react';
import { useInterval } from 'react-use';

export const getSuggestions = () => {
    return [
        t`breakfast`,
        t`snack`,
        t`italian`,
        t`smoothie`,
        t`salad`,
        t`soup`,
        t`chicken`,
        t`eggs`,
        t`shrimp`,
        t`pasta`,
        t`mexican`,
        t`indian`,
        t`greek`,
        t`sandwich`,
        t`salmon`,
        t`ground beef`,
        t`sheet pan`,
        t`spicy`,
        t`steak, potato`,
        t`chicken, broccoli, lemon`,
    ];
};

export const SUGGESTION_INTERVAL_MS = 2000;

const useSearchPlaceholderSuggestions = () => {
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    useInterval(() => setSuggestionIndex(suggestionIndex + 1), SUGGESTION_INTERVAL_MS);

    const suggestions = getSuggestions();
    const curTerm = suggestions[suggestionIndex % suggestions.length];

    return t`Search recipes for "${curTerm}"`;
};

export default useSearchPlaceholderSuggestions;
