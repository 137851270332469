/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { ClientUserDetailsDTO, SsoLoginRequestDTO, TokenResponseDTO, UserDetailsDTO } from 'API';
import { action } from 'typesafe-actions';
import { UserActionTypes } from './types';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
export const signOut = () => action(UserActionTypes.SIGN_OUT);
export const loginRefresh = () => action(UserActionTypes.LOGIN_REFRESH);

// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.
export const ssoLoginRequest = (data: { request: SsoLoginRequestDTO; type: 'apple' | 'google' }) =>
    action(UserActionTypes.SSO_LOGIN_REQUEST, data);
export const loginRequestSuccess = (data: TokenResponseDTO) => action(UserActionTypes.LOGIN_REQUEST_SUCCESS, data);
export const loginRequestSuccessPendingMfa = (data: TokenResponseDTO) =>
    action(UserActionTypes.LOGIN_REQUEST_SUCCESS_PENDING_MFA, data);
export const loginRequestError = (message: string) => action(UserActionTypes.LOGIN_REQUEST_ERROR, message);
export const userDetailsRefresh = (data: UserDetailsDTO) => action(UserActionTypes.USER_DETAILS_REFRESH, data);
export const clientDetailsRefresh = (data: ClientUserDetailsDTO) =>
    action(UserActionTypes.CLIENT_DETAILS_REFRESH, data);

export type UserActions =
    | ReturnType<typeof signOut>
    | ReturnType<typeof loginRefresh>
    | ReturnType<typeof ssoLoginRequest>
    | ReturnType<typeof loginRequestSuccess>
    | ReturnType<typeof loginRequestSuccessPendingMfa>
    | ReturnType<typeof loginRequestError>
    | ReturnType<typeof userDetailsRefresh>
    | ReturnType<typeof clientDetailsRefresh>;
