/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum FoodAllergyDTO {
    EGG = 'EGG',
    FISH = 'FISH',
    MILK = 'MILK',
    PEANUT = 'PEANUT',
    SESAME = 'SESAME',
    SHELLFISH = 'SHELLFISH',
    SOY = 'SOY',
    TREE_NUT = 'TREE_NUT',
    WHEAT = 'WHEAT'
};

