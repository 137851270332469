/* generic utilities across apps */

import { UserDetailsDTORolesEnum } from 'API';
import { ADMIN_APP_HOME, GATEWAY_APP_HOME, PATIENT_APP_HOME, PROVIDER_APP_HOME } from './constants/routeConstants';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const arrayMove = (arr: Array<any>, fromIndex: number, toIndex: number): Array<any> => {
    const copy = [...arr];
    const element = copy[fromIndex];
    copy.splice(fromIndex, 1);
    copy.splice(toIndex, 0, element);
    return copy;
};

export const getHomePageRedirectForRole = (role: UserDetailsDTORolesEnum): string => {
    switch (role) {
        case UserDetailsDTORolesEnum.ADMIN:
            return ADMIN_APP_HOME;
        case UserDetailsDTORolesEnum.CLIENT:
            return PATIENT_APP_HOME;
        case UserDetailsDTORolesEnum.PROVIDER:
            return PROVIDER_APP_HOME;
        default:
            return GATEWAY_APP_HOME;
    }
};
