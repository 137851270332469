import type {
    ClientStateDTO,
    CohortDTO,
    DietDTO,
    EligibleProvidersResponseDTO,
    ErrorDTO,
    PracticeDashboardFiltersResponseDTO,
    PracticeDashboardResponseDTO,
    PracticeWithProvidersDTO,
    ProviderClientOrderHistoryResponseDTO,
    ProviderResponseDTO,
    SubscriptionPackageResponseDTO,
} from 'API';
import type { PatientTableField } from 'apps/provider/types';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { practiceAPI, providerAPI, subscriptionPackagesApi } from '..';
import type { AxiosError } from 'axios';

export const useSubscriptionPackage = (
    packageId: string,
    options?: Omit<UseQueryOptions<SubscriptionPackageResponseDTO>, 'queryFn' | 'queryKey'>
) =>
    useQuery(
        ['getSubscriptionPackage', packageId],
        () => subscriptionPackagesApi.getSubscriptionPackage(packageId).then((r) => r.data),
        options
    );

type PaginationSearchCriteria = {
    providerIds?: Array<string>;
    subscriptionPackageIds?: Array<string>;
    hasSubscription?: boolean;
    cohorts?: Array<CohortDTO>;
    diets?: Array<DietDTO>;
    states?: Array<ClientStateDTO>;
    mostRecentOrderAfter?: string;
    programStartDateMin?: string;
    programStartDateMax?: string;
    nameFilter?: string;
    sortKey?: PatientTableField;
    sortDir?: 'ASC' | 'DESC';
    limit?: number;
    offset?: number;
};

export const usePracticeDashboard = (
    practiceId: string,
    searchCriterias: PaginationSearchCriteria,
    options?: Omit<UseQueryOptions<PracticeDashboardResponseDTO>, 'queryFn' | 'queryKey'>
) => {
    const {
        providerIds,
        subscriptionPackageIds,
        hasSubscription,
        cohorts,
        diets,
        states,
        mostRecentOrderAfter,
        programStartDateMin,
        programStartDateMax,
        nameFilter,
        sortKey,
        sortDir,
        limit,
        offset,
    } = searchCriterias;

    return useQuery<PracticeDashboardResponseDTO>(
        [
            'practiceDashboard',
            practiceId,
            providerIds,
            subscriptionPackageIds,
            cohorts,
            hasSubscription,
            diets,
            states,
            mostRecentOrderAfter,
            programStartDateMin,
            programStartDateMax,
            nameFilter,
            sortKey,
            sortDir,
            limit,
            offset,
        ],
        () =>
            practiceAPI
                .getPracticeDashboard(
                    practiceId,
                    providerIds,
                    subscriptionPackageIds,
                    hasSubscription,
                    cohorts,
                    diets,
                    states,
                    mostRecentOrderAfter,
                    programStartDateMin,
                    programStartDateMax,
                    nameFilter,
                    sortKey,
                    sortDir,
                    limit,
                    offset
                )
                .then((r) => r.data),
        options
    );
};

export const usePracticeDashboardFilter = (
    practiceId: string,
    options?: Omit<UseQueryOptions<PracticeDashboardFiltersResponseDTO>, 'queryFn' | 'queryKey'>
) =>
    useQuery<PracticeDashboardFiltersResponseDTO>(
        ['practiceDashboardFilters', practiceId],
        () => practiceAPI.getPracticeDashboardFilters(practiceId).then((r) => r.data),
        options
    );

export const usePracticeDetails = (
    practiceId: string,
    options?: Omit<UseQueryOptions<PracticeWithProvidersDTO>, 'queryFn' | 'queryKey'>
) =>
    useQuery<PracticeWithProvidersDTO>(
        ['getPracticeDetails', practiceId],
        () => practiceAPI.getPractice(practiceId).then((r) => r.data),
        options
    );

export const useClientOrderHistory = <T>(
    clientId: string,
    options?: Omit<
        UseQueryOptions<ProviderClientOrderHistoryResponseDTO, AxiosError<ErrorDTO>, T>,
        'queryKey' | 'queryFn'
    >
) =>
    useQuery(
        ['clientOrderHistory', clientId],
        () => providerAPI.getClientOrderHistory(clientId).then((res) => res.data),
        options
    );

export const useGetProvider = (
    providerId: string,
    options?: Omit<UseQueryOptions<ProviderResponseDTO>, 'queryFn' | 'queryKey'>
) =>
    useQuery<ProviderResponseDTO>(
        ['getProvider', providerId],
        () => providerAPI.getProvider(providerId).then((res) => res.data),
        options
    );

export const useGetProvidersEligibleForClient = (
    clientId: string,
    options?: Omit<UseQueryOptions<EligibleProvidersResponseDTO>, 'queryFn' | 'queryKey'>
) =>
    useQuery<EligibleProvidersResponseDTO>(
        ['getProvidersEligibleForClient', clientId],
        () => providerAPI.getProvidersEligibleForClient(clientId).then((res) => res.data),
        options
    );
