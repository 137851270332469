export const USER_PREFERENCE_KEYS = {
    hasUpdatedServingSizes: 'hasUpdatedServingSizes',
    safariDownloadPrompt: 'safariDownloadPrompt',
    patientFirstTimeUser: 'patientFirstTimeUser',
    patientHasViewedRecipes: 'patientHasViewedRecipes',
    patientHasViewedPurchasedMeals: 'patientHasViewedPurchasedMeals',
    hasClosedEducationCard: 'hasClosedEducationCard',
    alreadyCompletedOnboarding: 'alreadyCompletedOnboarding',
    hasSeenMessagingWelcomeToast: 'hasSeenMessagingWelcomeToast',
    hasCompletedClientIntakeForm: 'hasCompletedClientIntakeForm',
    hasLoggedIntoMobileApp: 'hasLoggedIntoMobileApp',
    hasCompletedNutritionPrescriptionForm: 'hasCompletedNutritionPrescriptionForm',
    hasDismissedGraduationBanner: 'hasDismissedGraduationBanner',
    completedWeightTrackingOnboarding: 'completedWeightTrackingOnboarding',
};
