import { t } from '@lingui/macro';
import clsx from 'clsx';
import { IconButton, XStrokeIcon } from 'galley';
import { useMemo, useRef } from 'react';
import { useKeypress, useRemoveUnderlyingScroll } from 'utils/hooks';

type backdropProps = {
    onClose: () => void;
    className?: string;
};

export enum OPEN_POSITIONS {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    RIGHT = 'RIGHT',
}

export type Props = React.PropsWithChildren<{
    isOpen?: boolean;
    openFrom?: OPEN_POSITIONS;
    width?: string;
    height?: string;
    className?: string;
    onClose?: () => void;
    backdrop?: backdropProps;
    closeable?: boolean;
}>;

const Drawer = ({
    isOpen,
    width = 'w-screen',
    height = 'h-fit',
    openFrom = OPEN_POSITIONS.BOTTOM,
    children,
    onClose,
    className,
    backdrop,
    closeable = true,
}: Props): JSX.Element => {
    const styles = useMemo(() => {
        /* eslint-disable lingui/no-unlocalized-strings */
        switch (openFrom) {
            case OPEN_POSITIONS.TOP:
                return {
                    slide: isOpen ? '-translate-y-0 ease-out visibile' : '-translate-y-full ease-in invisible',
                    borderRadius: 'rounded-b-lg',
                    postion: 'top-0 right-0',
                };
            case OPEN_POSITIONS.RIGHT:
                return {
                    slide: isOpen ? 'translate-x-0 ease-out visibile' : 'translate-x-full ease-in invisible',
                    borderRadius: 'rounded-b-lg',
                    postion: 'top-0 right-0',
                };
            default:
                return {
                    slide: isOpen ? 'translate-y-0 ease-out visibile' : 'translate-y-full ease-in invisible',
                    borderRadius: 'rounded-t-lg',
                    postion: 'bottom-0 right-0',
                };
        }
        /* eslint-enable lingui/no-unlocalized-strings */
    }, [openFrom, isOpen]);

    const backdropRef = useRef(null);

    useRemoveUnderlyingScroll(!!isOpen && !!backdrop);

    useKeypress('Escape', () => {
        closeable && backdrop && backdrop.onClose();
    });

    return (
        <div>
            {backdrop && isOpen && (
                <div
                    role="presentation"
                    onClick={(e) => {
                        if (e.currentTarget === backdropRef.current) {
                            closeable && backdrop && backdrop.onClose();
                        }
                    }}
                    ref={backdropRef}
                    className={clsx(
                        'bg-greyscale-offBlack/30 fixed inset-0 z-[-1] overscroll-contain backdrop-blur ease-in-out md:grid md:place-content-center',
                        backdrop.className
                    )}
                ></div>
            )}
            <div
                className={clsx(
                    `fixed z-20`,
                    isOpen !== undefined && `duration-300`,
                    Object.values(styles),
                    width,
                    height,
                    className
                )}
            >
                {onClose && (
                    <IconButton
                        aria-label={t`Close`}
                        className="absolute right-6 top-6"
                        onClick={onClose}
                        disabled={!closeable}
                    >
                        <XStrokeIcon className="mdIcon" />
                    </IconButton>
                )}

                {children}
            </div>
        </div>
    );
};

export default Drawer;
