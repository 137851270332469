import type { AppointmentTypeDTO } from 'API';
import PARAMS from 'apps/patient/utils/urlParams';
import { format, parseISO } from 'date-fns';
import pickBy from 'lodash/pickBy';
import aa from 'search-insights';
import { ALGOLIA_INDEX_NAME } from 'utils/constants/env';
import logger from 'utils/logger';
import type { apiObject } from '../../../types/freshpaint';

let globalTrackContext = {};
export const addGlobalTrackContext = (key: string, value: unknown): void => {
    globalTrackContext = { ...globalTrackContext, ...{ [key]: value } };
};

let globalTrackEventProperties = {};
export const addGlobalTrackEventProperties = (key: string, value: unknown): void => {
    globalTrackEventProperties = { ...globalTrackEventProperties, ...{ [key]: value } };

    logger.debug(`GLOBAL TRACK ${JSON.stringify(globalTrackEventProperties)}`);
};

export const track = (eventName: string, eventProperties: Record<string, unknown> = {}): void => {
    const eventPropertiesWithGlobalContext = Object.assign(
        { ...eventProperties, ...globalTrackEventProperties },
        { globalContext: globalTrackContext }
    );

    logger.debug(`Tracking ${eventName}`, { eventPayload: eventPropertiesWithGlobalContext });
    window.freshpaint?.track(eventName, eventPropertiesWithGlobalContext as unknown as apiObject);
};

enum TRACK_TYPE {
    BROWSE = 'Browse',
    CLICK = 'Click',
    VISIBLE = 'Visible',
    OPEN = 'Open',
    BLUR = 'Blur',
}

type ClickOptions = {
    label: string;
    link?: string;
};

export const trackClick = (options: ClickOptions) => {
    track(TRACK_TYPE.CLICK, options);
};

type BlurOptions = {
    label: string;
    error?: string;
};

export const trackBlur = (options: BlurOptions) => track(TRACK_TYPE.BLUR, options);

type ViewOptions = {
    view: string;
    context: string;
    payload?: Record<string, unknown>;
};

//Implicit - loaded and rendered
export const trackVisible = (options: ViewOptions) => {
    track(TRACK_TYPE.VISIBLE, options);
};

// Explicit, user intended
export const trackOpen = (options: ViewOptions) => {
    track(TRACK_TYPE.OPEN, options);
};

type BrowseOption = ViewOptions & {
    size: number;
    percent?: string;
    position?: number;
};

export const trackBrowse = (options: BrowseOption) => {
    track(TRACK_TYPE.BROWSE, options);
};

export enum ALGOLIA_EVENT_NAME {
    ADD_TO_CART_MEAL_DETAIL = 'Add to Cart from Meal Detail',
    ADD_TO_CART_MEAL_TILE = 'Add to Cart from Meal Tile',
    REMOVE_FROM_CART_MEAL_DETAIL = 'Remove from Cart from Meal Detail',
    REMOVE_FROM_CART_MEAL_TILE = 'Remove from Cart from Meal Tile',
}

export enum ALGOLIA_AA_EVENT_TYPE {
    CONVERSION = 'convertedObjectIDsAfterSearch',
}

export enum ALGOLIA_SEND_EVENT_TYPE {
    CONVERSION = 'conversion',
}

export const trackAlgoliaConversion = (clientId: string, recipeId: string, eventName: ALGOLIA_EVENT_NAME) => {
    const queryID = new URLSearchParams(window.location.search).get(PARAMS.RECIPE_QUERY_ID);
    queryID &&
        aa(ALGOLIA_AA_EVENT_TYPE.CONVERSION, {
            userToken: clientId,
            index: ALGOLIA_INDEX_NAME,
            eventName: eventName,
            queryID: queryID,
            objectIDs: [recipeId],
        });
};

export function trackAvailableAppointmentDates({
    view,
    availableAppointmentDates,
    thirdAvailableAppointmentDateTime,
    appointmentType,
    postalCode,
    emailAddress,
    clientId,
}: {
    view: string;
    availableAppointmentDates: string[];
    thirdAvailableAppointmentDateTime?: string;
    appointmentType: AppointmentTypeDTO;
    postalCode?: string;
    emailAddress?: string;
    clientId?: string;
}) {
    const now = new Date();
    const formattedAvailableAppointments = availableAppointmentDates.map((appointment) =>
        format(parseISO(appointment), 'MM/dd/yyyy')
    );
    const payload = {
        view,
        today: format(now, 'MM/dd/yyyy'),
        availableAppointmentDates: formattedAvailableAppointments,
        thirdAvailableAppointmentDate: thirdAvailableAppointmentDateTime, // analytics cares most about the 3rd avail appointment, so this makes it easy
        appointmentType,
        postalCode,
        emailAddress,
        clientId,
    };

    const cleanedPayload = pickBy(payload, (v) => v !== undefined);

    logger.debug("Tracking 'Next Available Appointment Dates'", cleanedPayload);
    track('Next Available Appointment Dates', cleanedPayload);
}
