import { useQuery } from 'react-query';
import { APP_BUILD_DATE, logger, PUBLIC_URL, USE_LOCAL_BUILD_DATE } from 'utils';

export const APP_BUILD_DATE_POLL_INTERVAL_MS = 1000 * 60 * 15; // 15 minutes

export class FetchError extends Error {
    constructor(public message: string, public statusCode: number) {
        super(message);
    }
}

export const useGetAppBuildDate = () =>
    useQuery<{ buildDate?: number }>(
        ['metaData'],
        async () => {
            if (USE_LOCAL_BUILD_DATE) {
                logger.debug('Using local app build date');
                return { buildDate: APP_BUILD_DATE };
            } else {
                logger.debug('Fetching meta.json to check for a new build date');
                const response = await fetch(`${PUBLIC_URL}/meta.json?now=${new Date().getTime()}`, {
                    cache: 'no-store',
                });
                if (!!response && !response.ok) {
                    throw new FetchError(
                        // eslint-disable-next-line lingui/no-unlocalized-strings
                        `Network response was not ok ${!!response && JSON.stringify(response)}`,
                        response.status
                    );
                }
                return response.json();
            }
        },
        {
            staleTime: APP_BUILD_DATE_POLL_INTERVAL_MS,
            refetchInterval: APP_BUILD_DATE_POLL_INTERVAL_MS,
            refetchIntervalInBackground: true,
        }
    );
