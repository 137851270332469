import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { alertError } from 'store/alerts/actions';
import logger from 'utils/logger';
import { NativeAppOutboundMessageTypes } from 'utils/native-app-bridge/messageTypes';
import { isRunningInsideSeasonNativeApp, postMessageToNativeApp } from 'utils/native-app-bridge/utils';

/**
 * usePrint
 * @description This hook invokes window.print on browsers other than Safari, invokes document.execCommand("print") on Safari, and opens the print menu with document.body.innerHTML on Native
 * @returns {():void} handlePrint - triggers the print
 */
function usePrint() {
    const dispatch = useDispatch();

    const handlePrint = () => {
        if (isRunningInsideSeasonNativeApp) {
            const html = document.querySelector('html')?.outerHTML;
            if (html) {
                postMessageToNativeApp(NativeAppOutboundMessageTypes.OPEN_PRINT_DIALOG, {
                    data: {
                        html: html,
                    },
                });
            } else {
                dispatch(alertError(t`Something went wrong while trying to print. Please try again.`));
                logger.error('Unable to print on native. HTML is undefined.');
            }
        } else {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (isSafari) {
                document.execCommand('print');
            } else {
                // This should be the only direct useage of window.print() in Web-App
                // eslint-disable-next-line no-restricted-properties
                window.print();
            }
        }
    };

    return handlePrint;
}

export default usePrint;
