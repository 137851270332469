/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ConditionDTO {
    CANCER = 'CANCER',
    CARDIOVASCULAR_DISEASE = 'CARDIOVASCULAR_DISEASE',
    CELIAC = 'CELIAC',
    CHF = 'CHF',
    CHRONIC_CHOLECYSTITIS = 'CHRONIC_CHOLECYSTITIS',
    CKD = 'CKD',
    CKD_1 = 'CKD_1',
    CKD_1_3A = 'CKD_1_3A',
    CKD_2 = 'CKD_2',
    CKD_3A = 'CKD_3A',
    CKD_3B = 'CKD_3B',
    CKD_3B_5 = 'CKD_3B_5',
    CKD_4 = 'CKD_4',
    CKD_5 = 'CKD_5',
    CONSTIPATION = 'CONSTIPATION',
    DIABETES = 'DIABETES',
    EATING_DISORDER = 'EATING_DISORDER',
    FOOD_ALLERGIES = 'FOOD_ALLERGIES',
    GESTATIONAL_DIABETES = 'GESTATIONAL_DIABETES',
    HIGH_CHOLESTEROL = 'HIGH_CHOLESTEROL',
    HIGH_TRIGLYCERIDES = 'HIGH_TRIGLYCERIDES',
    HISTORY_OF_CANCER = 'HISTORY_OF_CANCER',
    HYPERTENSION = 'HYPERTENSION',
    IBS = 'IBS',
    IRON_DEFICIENCY = 'IRON_DEFICIENCY',
    MALNUTRITION = 'MALNUTRITION',
    METABOLIC_SYNDROME = 'METABOLIC_SYNDROME',
    NON_ALCOHOLIC_FATTY_LIVER_DISEASE = 'NON_ALCOHOLIC_FATTY_LIVER_DISEASE',
    OBESITY = 'OBESITY',
    OTHER_GI_RELATED_FOOD_ISSUES = 'OTHER_GI_RELATED_FOOD_ISSUES',
    OVERWEIGHT = 'OVERWEIGHT',
    PCOS = 'PCOS',
    PRE_DIABETES = 'PRE_DIABETES',
    PREGNANCY = 'PREGNANCY',
    SPORTS_PERFORMANCE = 'SPORTS_PERFORMANCE',
    TYPE_1_DIABETES = 'TYPE_1_DIABETES',
    TYPE_2_DIABETES = 'TYPE_2_DIABETES',
    VEGAN = 'VEGAN',
    VEGETARIAN = 'VEGETARIAN',
    VITAMIN_B12_OR_FOLATE_DEFICIENCY = 'VITAMIN_B12_OR_FOLATE_DEFICIENCY',
    WOMENS_HEALTH = 'WOMENS_HEALTH'
};

