import clsx from 'clsx';
import { QuestionStrokeIcon } from 'galley';

export type Props = React.PropsWithChildren<{
    title: JSX.Element | string;
    placement?: 'top' | 'bottom' | 'left';
    className?: string;
}>;

const Tooltip = ({
    title,
    placement = 'top',
    children = <QuestionStrokeIcon className="smIcon" />,
}: Props): JSX.Element => {
    if (placement === 'left') {
        return (
            <div className="relative flex items-center justify-center gap-x-2">
                {children}
                <div className="absolute right-2 z-10 mr-0.5 hidden items-center peer-hover:flex">
                    <div className="bg-greyscale-offBlack bodyXsLight flex w-64 rounded-lg p-3 text-sm text-white peer-hover:block">
                        {title}
                    </div>
                    <div className="bg-greyscale-offBlack relative h-4 w-4 origin-center -translate-x-1/2 rotate-45"></div>
                </div>
            </div>
        );
    }
    return (
        <div className="relative h-fit w-fit" role="tooltip">
            <div className="peer hover:cursor-pointer">{children}</div>
            <div
                className={clsx(
                    'absolute left-1/2 top-full z-10 hidden w-64 -translate-x-1/2 items-center peer-hover:flex peer-hover:flex-col',
                    placement === 'top' && '-mt-4 -translate-y-full',
                    placement === 'bottom' && '-mb-4 -translate-y-0'
                )}
            >
                <div
                    className={clsx(
                        'bg-greyscale-offBlack relative top-1/2 ml-4 h-4 w-4 origin-center -translate-x-1/2 rotate-45',
                        placement === 'top' && 'order-2'
                    )}
                />
                <div
                    className={clsx(
                        'bg-greyscale-offBlack bodyXsLight flex rounded-lg p-3 text-sm text-white peer-hover:block',
                        placement === 'top' && '-mb-2',
                        placement === 'bottom' && '-mt-2'
                    )}
                >
                    {title}
                </div>
            </div>
        </div>
    );
};

export default Tooltip;
