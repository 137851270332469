import { APP_BUILD_DATE_POLL_INTERVAL_MS, useGetAppBuildDate } from 'apps/patient/queries';
import { millisecondsToMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import TopBanners from 'TopBanners';
import { APP_BUILD_DATE, logger } from 'utils';

const NewVersionBanner = (): JSX.Element => {
    const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
    const { data, error, isError, isLoading } = useGetAppBuildDate();

    useEffect(() => {
        if (isError) {
            logger.warn(`Cannot fetch version information `, {
                exceptionDetails: error,
                exceptionDetailsString: JSON.stringify(error),
            });
            setIsNewVersionAvailable(true);
        }
    }, [error, isError]);

    useEffect(() => {
        if (!isLoading) {
            const buildDate = data && data?.buildDate;
            if (buildDate) {
                if (APP_BUILD_DATE) {
                    if (buildDate === APP_BUILD_DATE) {
                        logger.debug(`The client is running the most recent app version from ${APP_BUILD_DATE}`);
                    } else {
                        logger.debug(
                            `A new app version was made available. The client is currently running code from ${APP_BUILD_DATE} and the new version was built ${buildDate}`
                        );
                        setIsNewVersionAvailable(true);
                    }
                }
            } else {
                logger.warn(`Could not fetch buildDate from meta.json.`);
                setIsNewVersionAvailable(true);
            }
        }
    }, [data, isLoading]);

    useEffect(() => {
        if (isNewVersionAvailable) {
            logger.debug(
                `Setting a timeout to hard refresh in ${millisecondsToMinutes(
                    APP_BUILD_DATE_POLL_INTERVAL_MS
                )} minutes to get updated app version`
            );
            const timeoutId = setTimeout(() => {
                window.location.reload();
            }, APP_BUILD_DATE_POLL_INTERVAL_MS);
            return () => clearTimeout(timeoutId);
        }
    }, [isNewVersionAvailable]);

    return (
        <>
            <TopBanners isNewVersionAvailable={isNewVersionAvailable} />
            <Outlet />
        </>
    );
};

export default NewVersionBanner;
