import { useField } from 'formik';
import { trackBlur } from 'utils/tracking/events';
import type { Props } from './TextArea';
import TextArea from './TextArea';

const FormikTextArea = ({ name, value, ...props }: Props) => {
    const [field, meta] = useField({
        name: name || '',
        value: value,
    });
    return (
        <TextArea
            {...props}
            label={props.label}
            {...field}
            onBlur={(e) => {
                field.onBlur(e);
                trackBlur({ label: field.name, error: meta.error });
            }}
        />
    );
};

export default FormikTextArea;
