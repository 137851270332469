import clsx from 'clsx';
import { Button, Spinner } from 'galley';
import type { VariantProps } from './StepInput';
import { MinusIcon, onClickHandler, PlusIcon } from './StepInput';

export const TertiaryStepInput = ({
    onIncrement,
    onDecrement,
    value,
    isDisabled = false,
    isLoading = false,
    formatValue,
    className,
    maxValue,
    minValue = 1,
}: VariantProps): JSX.Element => {
    return (
        <div
            data-dd-privacy="allow"
            className={clsx(
                'rounded-round border-standard-barley max-width-content flex h-[42px] w-fit flex-row items-center justify-center gap-2 border bg-white text-black transition-all duration-500',
                className
            )}
            role="presentation"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {value > minValue ? (
                <Button
                    className="md:hover:bg-standard-soy active:bg-standard-soy rounded-l-round active:border-highBlue-2 group h-10 flex-none p-3"
                    onClick={onClickHandler(onDecrement)}
                    disabled={isDisabled}
                >
                    <MinusIcon />
                </Button>
            ) : (
                <Button
                    className="rounded-l-round group h-10 flex-none p-3"
                    onClick={onClickHandler(onDecrement)}
                    disabled={true}
                >
                    <div className="mdIcon" />
                </Button>
            )}

            <div className="flex w-16 flex-1 items-center justify-center text-center">
                {isLoading ? (
                    <Spinner />
                ) : (
                    <span className="bodyMdMedium">{formatValue ? formatValue(value) : value}</span>
                )}
            </div>

            <Button
                className={clsx('rounded-r-round group h-10 flex-none border border-none p-3', {
                    'md:hover:bg-standard-soy active:bg-standard-soy': !(maxValue && value >= maxValue),
                })}
                onClick={onClickHandler(onIncrement)}
                disabled={isDisabled || !!(maxValue && value >= maxValue)}
            >
                {!!(maxValue && value >= maxValue) ? <div className="mdIcon"></div> : <PlusIcon />}
            </Button>
        </div>
    );
};
