/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum RecipePreparationTypeDTO {
    LOW_PREP = 'LOW_PREP',
    NO_PREP = 'NO_PREP',
    NO_COOK = 'NO_COOK',
    OVEN = 'OVEN',
    OVERNIGHT_COOK = 'OVERNIGHT_COOK',
    SHEET_PAN = 'SHEET_PAN',
    SLOW_COOK = 'SLOW_COOK',
    STOVETOP = 'STOVETOP'
};

