export enum CheckoutDrawerActionTypes {
    TOGGLE_CHECKOUT_DRAWER = '@@checkoutDrawer/TOGGLE_CHECKOUT_DRAWER',
    SET_CHECKOUT_DRAWER_CLOSEABLE = '@@checkoutDrawer/SET_CHECKOUT_DRAWER_CLOSEABLE',
    SET_CHECKOUT_DRAWER_SHOW_CLOSE_BUTTON = '@@checkoutDrawer/SET_CHECKOUT_DRAWER_SHOW_CLOSE_BUTTON',
    RESET_CHECKOUT_DRAWER = '@@checkoutDrawer/RESET_CHECKOUT_DRAWER',
}

export interface CheckoutDrawerState {
    readonly open: boolean;
    readonly closeable: boolean;
    readonly showCloseButton: boolean;
}
