/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum GroceryBundleTypeDTO {
    HOME_ESSENTIALS_1 = 'HOME_ESSENTIALS_1',
    HOME_ESSENTIALS_2 = 'HOME_ESSENTIALS_2',
    HOME_ESSENTIALS_3 = 'HOME_ESSENTIALS_3',
    STANDARD = 'STANDARD',
    PANTRY = 'PANTRY',
    REFRESH_YOUR_PLATE = 'REFRESH_YOUR_PLATE',
    POWERFUL_SNACKING = 'POWERFUL_SNACKING',
    SUMMERTIME_NUTRITION_MADE_EASY = 'SUMMERTIME_NUTRITION_MADE_EASY',
    GET_GOING_WITH_BREAKFAST = 'GET_GOING_WITH_BREAKFAST',
    TAKE_CARE_OF_YOUR_GUT = 'TAKE_CARE_OF_YOUR_GUT',
    ONE_POT_ESSENTIALS = 'ONE_POT_ESSENTIALS',
    SWEETEN_YOUR_HEALTH = 'SWEETEN_YOUR_HEALTH',
    TRAVEL_THE_WORLD_WITH_FOOD = 'TRAVEL_THE_WORLD_WITH_FOOD',
    LIGHTEN_UP_YOUR_HOLIDAYS = 'LIGHTEN_UP_YOUR_HOLIDAYS'
};

