/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum GroceryVendorNameDTO {
    ALBERTSONS = 'ALBERTSONS',
    ALDI = 'ALDI',
    GIANT = 'GIANT',
    HEB = 'HEB',
    KROGER = 'KROGER',
    MEIJER = 'MEIJER',
    PUBLIX = 'PUBLIX',
    SAFEWAY = 'SAFEWAY',
    SPROUTS = 'SPROUTS',
    WALMART = 'WALMART',
    WEGMANS = 'WEGMANS',
    WHOLE_FOODS = 'WHOLE_FOODS'
};

