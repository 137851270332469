// App route prefixes
export const ADMIN_APP_HOME = '/admin/';
export const GATEWAY_APP_HOME = '/auth/';
export const PROVIDER_APP_HOME = '/provider/';
export const PATIENT_APP_HOME = '/patient/';
export const CARE_APP_HOME = '/provider/care/';
export const HOSTNAME = 'seasonhealth.com';

// Shared routes linked across experiences, sometimes used by the admin or provider apps to link to patient pages
export const LOGIN_PATH = 'login';
export const LOGIN_ROUTE = `${GATEWAY_APP_HOME}${LOGIN_PATH}`;

export const TWO_FACTOR_PATH = 'two-factor';
export const TWO_FACTOR_ROUTE = `${GATEWAY_APP_HOME}${TWO_FACTOR_PATH}`;

export const PATIENT_SHOPPING_LIST_PRINT = 'shoppingList';
export const PATIENT_SHOPPING_LIST_PRINT_ROUTE = `${PATIENT_APP_HOME}${PATIENT_SHOPPING_LIST_PRINT}`;

export const PATIENT_RECIPES_PRINT = 'print';
export const PATIENT_RECIPES_PRINT_ROUTE = `${PATIENT_APP_HOME}${PATIENT_RECIPES_PRINT}`;

export const PATIENT_SIGN_UP = 'sign-up';
export const PATIENT_SIGN_UP_ROUTE = `${PATIENT_APP_HOME}${PATIENT_SIGN_UP}`;
export const PATIENT_SIGN_UP_META_TITLE = 'Season - Sign Up';

export const PATIENT_ONBOARDING_BENEFITS = 'onboarding-benefits';
export const PATIENT_ONBOARDING_BENEFITS_ROUTE = `${PATIENT_APP_HOME}${PATIENT_ONBOARDING_BENEFITS}`;

export const PATIENT_EMAIL_VERIFICATION_AFTER_ACTIVATION = 'verification-required';
export const PATIENT_EMAIL_VERIFICATION_AFTER_ACTIVATION_ROUTE = `${PATIENT_APP_HOME}${PATIENT_EMAIL_VERIFICATION_AFTER_ACTIVATION}`;
