import { t } from '@lingui/macro';

export const CARE_EMAIL_ADDRESS = 'care@seasonhealth.com';
export const SUPPORT_EMAIL_ADDRESS = 'support@seasonhealth.com';
export const SUPPORT_EMAIL_SUBJECT = 'My Season Feedback';
export const SUPPORT_PHONE_NUMBER = '(888) 807-4117';
export const VIDEO_URL = 'https://player.vimeo.com/video/481299981?autoplay=1&controls=true';

// Resources without translations
export const FAQ_URL = 'https://support.seasonhealth.com';
export const PRIVACY_POLICY_URL = 'https://seasonhealth.com/privacy-policy/';
export const TERMS_OF_USE_URL = 'https://seasonhealth.com/terms-of-use/';
export const NOTICE_OF_LANGUAGE_ASSISTANCE_URL =
    'https://www.seasonhealth.com/notice-of-availability-of-language-assistance-services/';

// Resources with translations
export const getHipaaPrivacyPolicyUrl = () => t`https://seasonhealth.com/hipaa-notice-of-privacy-policy/`;
export const getNoticeOfNondiscriminationUrl = () => t`https://www.seasonhealth.com/notice-of-nondiscrimination/`;
