import clsx from 'clsx';
import { BannerColors } from './BannerStyles';

export type Props = {
    text: string | JSX.Element;
    color?: BannerColors;
    icon?: JSX.Element;
    radius?: string;
    className?: string;
};

const Banner = ({ text, color = BannerColors.GREEN, radius = 'rounded-8', icon, className }: Props): JSX.Element => {
    return (
        <div className={clsx('bodySmLight border px-6 py-3', color, radius, className)}>
            {icon}
            {text}
        </div>
    );
};

export default Banner;
