/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, takeLatest, delay } from 'redux-saga/effects';
import { AlertActionTypes } from './types';
import { openAlert, closeAlert } from './actions';

export function* handleOpenAlert(action: ReturnType<typeof openAlert>) {
    yield put(openAlert(action.payload));
    yield delay(7000);
    yield put(closeAlert());
}

function* alertSaga() {
    yield takeLatest(AlertActionTypes.LAUNCH_ALERT, handleOpenAlert);
}

export default alertSaga;
