import { useEffect } from 'react';

const useRemoveUnderlyingScroll = (hideBodyOverFlow: boolean) => {
    useEffect(() => {
        const body = document.body;
        if (hideBodyOverFlow) body.classList.add('overflow-y-hidden');
        else body.classList.remove('overflow-y-hidden');
        return () => body.classList.remove('overflow-y-hidden');
    }, [hideBodyOverFlow]);
};

export default useRemoveUnderlyingScroll;
