/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateRecipeTemplateComponentRequestDTO } from '../models';
// @ts-ignore
import { RecipeTemplateComponentResponseDTO } from '../models';
// @ts-ignore
import { RecipeTemplateComponentsResponseDTO } from '../models';
/**
 * RecipeTemplateComponentApi - axios parameter creator
 * @export
 */
export const RecipeTemplateComponentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new RecipeTemplateComponent
         * @param {CreateRecipeTemplateComponentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipeTemplateComponent: async (request: CreateRecipeTemplateComponentRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createRecipeTemplateComponent', 'request', request)
            const localVarPath = `/recipe_template_components`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get RecipeTemplateComponent
         * @param {string} id The RecipeTemplateComponent ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeTemplateComponent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecipeTemplateComponent', 'id', id)
            const localVarPath = `/recipe_template_components/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get RecipeTemplateComponents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeTemplateComponents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recipe_template_components`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecipeTemplateComponentApi - functional programming interface
 * @export
 */
export const RecipeTemplateComponentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecipeTemplateComponentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new RecipeTemplateComponent
         * @param {CreateRecipeTemplateComponentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecipeTemplateComponent(request: CreateRecipeTemplateComponentRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeTemplateComponentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecipeTemplateComponent(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get RecipeTemplateComponent
         * @param {string} id The RecipeTemplateComponent ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeTemplateComponent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeTemplateComponentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeTemplateComponent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get RecipeTemplateComponents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeTemplateComponents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeTemplateComponentsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeTemplateComponents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecipeTemplateComponentApi - factory interface
 * @export
 */
export const RecipeTemplateComponentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecipeTemplateComponentApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new RecipeTemplateComponent
         * @param {CreateRecipeTemplateComponentRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipeTemplateComponent(request: CreateRecipeTemplateComponentRequestDTO, options?: any): AxiosPromise<RecipeTemplateComponentResponseDTO> {
            return localVarFp.createRecipeTemplateComponent(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get RecipeTemplateComponent
         * @param {string} id The RecipeTemplateComponent ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeTemplateComponent(id: string, options?: any): AxiosPromise<RecipeTemplateComponentResponseDTO> {
            return localVarFp.getRecipeTemplateComponent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get RecipeTemplateComponents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeTemplateComponents(options?: any): AxiosPromise<RecipeTemplateComponentsResponseDTO> {
            return localVarFp.getRecipeTemplateComponents(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecipeTemplateComponentApi - object-oriented interface
 * @export
 * @class RecipeTemplateComponentApi
 * @extends {BaseAPI}
 */
export class RecipeTemplateComponentApi extends BaseAPI {
    /**
     * 
     * @summary Create a new RecipeTemplateComponent
     * @param {CreateRecipeTemplateComponentRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeTemplateComponentApi
     */
    public createRecipeTemplateComponent(request: CreateRecipeTemplateComponentRequestDTO, options?: AxiosRequestConfig) {
        return RecipeTemplateComponentApiFp(this.configuration).createRecipeTemplateComponent(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get RecipeTemplateComponent
     * @param {string} id The RecipeTemplateComponent ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeTemplateComponentApi
     */
    public getRecipeTemplateComponent(id: string, options?: AxiosRequestConfig) {
        return RecipeTemplateComponentApiFp(this.configuration).getRecipeTemplateComponent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get RecipeTemplateComponents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeTemplateComponentApi
     */
    public getRecipeTemplateComponents(options?: AxiosRequestConfig) {
        return RecipeTemplateComponentApiFp(this.configuration).getRecipeTemplateComponents(options).then((request) => request(this.axios, this.basePath));
    }
}
