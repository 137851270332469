import type {
    ActivateClientRequestDTO,
    ClientNutritionPrescriptionDescriptionResponseDTO,
    ErrorDTO,
    GetClientGoalGroupResponseDTO,
    TokenResponseDTO,
    ValidateClientRequestDTO,
} from 'API';
import type { AxiosError, AxiosResponse } from 'axios';
import type { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import { clientAPI } from 'utils';

interface ValidateClientArgs {
    id: string;
    requestDTO: ValidateClientRequestDTO;
}

export const useValidateClient = (
    options?: UseMutationOptions<AxiosResponse<TokenResponseDTO>, AxiosError<ErrorDTO>, ValidateClientArgs>
) =>
    useMutation((args: ValidateClientArgs) => {
        return clientAPI.validateClient(args.id, args.requestDTO);
    }, options);

export const useActivateClient = (
    clientId: string,
    options?: UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorDTO>, ActivateClientRequestDTO>
) =>
    useMutation((request: ActivateClientRequestDTO) => {
        return clientAPI.activateClient(clientId, request);
    }, options);

interface RegisterClientArgs {
    id: string;
}

export const useRegisterClient = (
    options?: UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorDTO>, RegisterClientArgs>
) =>
    useMutation((args: RegisterClientArgs) => {
        return clientAPI.registerClient(args.id);
    }, options);

export const useGetNutritionPrescriptionDescription = (
    clientId: string,
    options?: Omit<UseQueryOptions<ClientNutritionPrescriptionDescriptionResponseDTO>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        ['getNutritionPrescription'],
        () => clientAPI.getNutritionPrescriptionDescription(clientId).then((r) => r.data),
        options
    );

export const useGetClientGoals = <T>(
    clientId: string,
    options?: Omit<UseQueryOptions<GetClientGoalGroupResponseDTO, unknown, T>, 'queryKey' | 'queryFn'>
) =>
    useQuery(
        ['getClientGoals'],
        async () => {
            const response = await clientAPI.getClientGoalGroups(clientId);
            return response.data;
        },
        options
    );
