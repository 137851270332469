import { ReactComponent as SeasonLogo } from 'assets/images/season-wordmark.svg';
import clsx from 'clsx';
import GlobalAlertSystem from '../GlobalAlertSystem/GlobalAlertSystem';
import s from './Layout.module.scss';

export enum LAYOUT_TYPE {
    PAGE = 'page',
    PRINT = 'print',
    LEFT_INFOGRAPHIC = 'with_infographic',
}

export type Props = React.PropsWithChildren<
    React.HTMLAttributes<HTMLDivElement> & {
        type: LAYOUT_TYPE;
        sideImage?: string;
    }
>;

const Layout = ({ type, sideImage, children, ...rest }: Props): JSX.Element => {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    const containerStyle = 'h-screen w-full overflow-x-hidden';

    if (type === LAYOUT_TYPE.LEFT_INFOGRAPHIC) {
        return (
            <div className={clsx(containerStyle, 'flex')} {...rest}>
                <div
                    className="h-100vh hidden min-w-[40%] bg-cover bg-no-repeat p-10 md:block xl:min-w-[576px]"
                    style={{
                        backgroundImage: `url(${sideImage})`,
                    }}
                >
                    <SeasonLogo className="h-[55px] w-auto" />
                </div>
                <div className="align-center relative flex h-screen w-full flex-col justify-center overflow-y-scroll">
                    {children}
                </div>
                <GlobalAlertSystem />
            </div>
        );
    } else if (type === LAYOUT_TYPE.PRINT) {
        return (
            <div className={s.mainLayoutContainerForPrint} {...rest}>
                {children} <GlobalAlertSystem />
            </div>
        );
    } else if (type === LAYOUT_TYPE.PAGE) {
        return (
            <div {...rest}>
                {children} <GlobalAlertSystem />
            </div>
        );
    } else {
        return (
            <div className={containerStyle} {...rest}>
                {children} <GlobalAlertSystem />
            </div>
        );
    }
};

export default Layout;
