import { t } from '@lingui/macro';
import { action } from 'typesafe-actions';
import type { PatientInfoMessageType } from '../../apps/patient/utils';
import {
    PatientErrorMessageType,
    PatientSuccessMessageType,
    getPatientErrorMessage,
    getPatientInfoMessage,
    getPatientSuccessMessage,
} from '../../apps/patient/utils';
import { SUPPORT_EMAIL_ADDRESS } from '../../utils';
import type { Alert } from './types';
import { AlertActionTypes } from './types';

export const launchAlert = (
    alert: Alert
): {
    type: AlertActionTypes.LAUNCH_ALERT;
    payload: Alert;
} => action(AlertActionTypes.LAUNCH_ALERT, alert);

export const openAlert = (
    alert: Alert
): {
    type: AlertActionTypes.OPEN_ALERT;
    payload: Alert;
} => action(AlertActionTypes.OPEN_ALERT, alert);

export const closeAlert = (): {
    type: AlertActionTypes.CLOSE_ALERT;
} => action(AlertActionTypes.CLOSE_ALERT);

export const alertError = (
    message: string
): {
    type: AlertActionTypes.LAUNCH_ALERT;
    payload: Alert;
} =>
    action(AlertActionTypes.LAUNCH_ALERT, {
        alertType: 'error',
        alertMessage: message,
    });

/**
 * Alert the user of an error and, optionally, direct them to contact support for more assistance.
 * The message that will be visible is standardized in the form of the template string:
 *
 * `We're sorry, but something went wrong.
 * {patientErrorMessage}.{showSupportMessage && ' Contact ${SUPPORT_EMAIL_ADDRESS}
 * if you need any assistance.'}`
 *
 *
 * @param patientErrorMessageType
 * @param showSupportMessage If true, then we will direct the user to contact support for assistance. Omitting this sets
 * the default to true.
 *
 *
 */
export const alertPatientError = (
    patientErrorMessageType: PatientErrorMessageType = PatientErrorMessageType.DEFAULT,
    showSupportMessage: boolean = true
): {
    type: AlertActionTypes.LAUNCH_ALERT;
    payload: Alert;
} => {
    const patientErrorMessage = getPatientErrorMessage(patientErrorMessageType);

    const supportMessage = showSupportMessage
        ? t`Contact ${SUPPORT_EMAIL_ADDRESS} if you need any assistance.`
        : undefined;

    const finalAlertMessage = [t`We're sorry, but something went wrong.`, patientErrorMessage, supportMessage]
        .filter(Boolean)
        .join(' ');

    return action(AlertActionTypes.LAUNCH_ALERT, {
        alertType: 'error',
        alertMessage: finalAlertMessage,
    });
};

/**
 * Alert the user of a success.
 *
 * @param patientSuccessMessageType
 *
 */
export const alertPatientSuccess = (
    patientSuccessMessageType: PatientSuccessMessageType = PatientSuccessMessageType.DEFAULT
): {
    type: AlertActionTypes.LAUNCH_ALERT;
    payload: Alert;
} => {
    return action(AlertActionTypes.LAUNCH_ALERT, {
        alertType: 'success',
        alertMessage: getPatientSuccessMessage(patientSuccessMessageType),
    });
};

/**
 * Alert the user of an informational message.
 *
 * @param patientInfoMessageType
 *
 */
export const alertPatientInfo = (
    patientInfoMessageType: PatientInfoMessageType
): {
    type: AlertActionTypes.LAUNCH_ALERT;
    payload: Alert;
} => {
    return action(AlertActionTypes.LAUNCH_ALERT, {
        alertType: 'info',
        alertMessage: getPatientInfoMessage(patientInfoMessageType),
    });
};

export const alertInfo = (
    message: string
): {
    type: AlertActionTypes.LAUNCH_ALERT;
    payload: Alert;
} =>
    action(AlertActionTypes.LAUNCH_ALERT, {
        alertType: 'info',
        alertMessage: message,
    });

export const alertSuccess = (
    message: string
): {
    type: AlertActionTypes.LAUNCH_ALERT;
    payload: Alert;
} =>
    action(AlertActionTypes.LAUNCH_ALERT, {
        alertType: 'success',
        alertMessage: message,
    });

export type AlertActions =
    | ReturnType<typeof launchAlert>
    | ReturnType<typeof openAlert>
    | ReturnType<typeof closeAlert>
    | ReturnType<typeof alertError>
    | ReturnType<typeof alertPatientError>
    | ReturnType<typeof alertPatientInfo>
    | ReturnType<typeof alertPatientSuccess>
    | ReturnType<typeof alertInfo>
    | ReturnType<typeof alertSuccess>;
