import clsx from 'clsx';
import type { OptionWithLabel } from 'galley';
import { Input, SearchStrokeIcon } from 'galley';
import { useEffect, useState } from 'react';
import Suggestions from './Suggestions';

export type Props = {
    suggestions: OptionWithLabel[];
    fallbackSuggestions?: OptionWithLabel[];
    placeholder?: string;
    label: string;
    onSelect?: (suggestion: OptionWithLabel) => void;
    className?: string;
    predictionVariant?: 'index' | 'startsWith';
    normalize?: boolean;
    isInvalid?: boolean;
    name?: string;
    value?: string;
    setValue?(value: unknown, shouldValidate?: boolean): void;
    setTouched?(value: boolean, shouldValidate?: boolean): void;
    showSearchIcon?: boolean;
    isFormik?: boolean;
    clearOnCustomSelect?: boolean;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>, setShowSuggestions: (value: boolean) => void) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export const Autocomplete = ({
    suggestions,
    fallbackSuggestions,
    placeholder,
    label,
    onSelect,
    predictionVariant = 'index',
    className,
    normalize = false,
    isInvalid,
    name,
    value,
    setValue,
    setTouched,
    showSearchIcon = false,
    isFormik = false,
    clearOnCustomSelect = true,
    onFocus,
    onBlur,
}: Props): JSX.Element => {
    const [activeSuggestion, setActiveSuggestion] = useState<number>(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState<OptionWithLabel[]>([]);
    const [showSuggestions, setShowSuggestions] = useState<boolean>();
    const [userInput, setUserInput] = useState<string>(value ?? '');

    useEffect(() => {
        if (!isFormik) {
            setUserInput(value ?? '');
        }
    }, [value, isFormik]);

    const setInput = (input: string) => {
        setUserInput(input);
        if (setValue != null) {
            setValue(input);
        }
    };

    const onSelectDefault = (suggestion: OptionWithLabel) => {
        setInput(normalize ? suggestion.name.toLowerCase() : suggestion.name);
        setShowSuggestions(false);
    };

    const handleCustomSelect = (suggestion: OptionWithLabel) => {
        if (onSelect != null) {
            onSelect(suggestion);
        }
        setShowSuggestions(false);
        if (clearOnCustomSelect) {
            setInput('');
        } else {
            setInput(normalize ? suggestion.name.toLowerCase() : suggestion.name);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const userInput = e.currentTarget.value.trim();

        const filteredSuggestions = suggestions.filter((suggestion) => {
            if (predictionVariant === 'startsWith') {
                return suggestion.name.toLowerCase().startsWith(userInput.toLowerCase());
            }
            return suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1;
        });

        setActiveSuggestion(0);
        setFilteredSuggestions(filteredSuggestions);
        setShowSuggestions(true);
        setInput(e.currentTarget.value);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        const suggestions = filteredSuggestions.concat(fallbackSuggestions ?? []);
        if (e.key === 'Enter' && showSuggestions && suggestions.length > 0) {
            setActiveSuggestion(0);
            setShowSuggestions(false);
            const suggestion = suggestions[activeSuggestion];
            if (onSelect != null) {
                handleCustomSelect(suggestion);
            } else {
                onSelectDefault(suggestion);
            }
        } else if (e.key === 'ArrowUp') {
            if (activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion - 1);
        } else if (e.key === 'ArrowDown') {
            if (activeSuggestion >= suggestions.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1);
        }
    };

    return (
        <div className={clsx(className, 'relative')}>
            <Input
                name={name}
                label={label}
                placeholder={placeholder}
                type="text"
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={isFormik ? value : userInput}
                isInvalid={isInvalid}
                onBlur={(e) => {
                    setTouched && setTouched(true, true);
                    onBlur && onBlur(e);
                }}
                onFocus={(e) => {
                    if (onFocus != null) {
                        onFocus(e, setShowSuggestions);
                    }
                }}
            />
            {showSuggestions && (isFormik ? value : userInput) && (
                <Suggestions
                    filteredSuggestions={filteredSuggestions}
                    fallbackSuggestions={fallbackSuggestions}
                    className={className}
                    activeSuggestion={activeSuggestion}
                    onSelect={(suggestion: OptionWithLabel) => {
                        if (onSelect != null) {
                            handleCustomSelect(suggestion);
                        } else {
                            onSelectDefault(suggestion);
                        }
                    }}
                    normalize={normalize}
                />
            )}
            {showSearchIcon && <SearchStrokeIcon className="absolute bottom-4 right-4" />}
        </div>
    );
};

export default Autocomplete;
