import type { TokenResponseDTO } from 'API';
import { ErrorDTOReasonEnum } from 'API/index';
import type { AxiosInstance } from 'axios';
import axios from 'axios';
import logger from 'utils/logger';
import {
    ADMIN_APP_HOME,
    PATIENT_EMAIL_VERIFICATION_AFTER_ACTIVATION_ROUTE,
    REFRESH_TOKEN_ACCESSOR,
    SEASON_API_BASE_URL,
    TWO_FACTOR_ROUTE,
} from '..';
import clearTokens from './clearTokens';
import setTokens from './setTokens';

const IGNORED_STATUS_CODES = [0, 401, 403];
const WARNING_ONLY_STATUS_CODES = [404];
const INFO_ERROR_REASONS = [
    ErrorDTOReasonEnum.ACCOUNT_INACTIVE,
    ErrorDTOReasonEnum.ACCOUNT_LOCKED,
    ErrorDTOReasonEnum.ACCOUNT_WITH_EMAIL_ALREADY_EXISTS,
    ErrorDTOReasonEnum.APPOINTMENT_DATE_TIME_UNAVAILABLE_ERROR,
    ErrorDTOReasonEnum.CLIENT_ALREADY_VALIDATED,
    ErrorDTOReasonEnum.CLIENT_TOKEN_EXPIRED,
    ErrorDTOReasonEnum.FOOD_ORDER_CART_LINE_ITEMS_NEED_UPDATE,
    ErrorDTOReasonEnum.FOOD_ORDER_CART_ESTIMATED_DELIVERY_OR_COST_DETAILS_NEED_UPDATE,
    ErrorDTOReasonEnum.FOOD_ORDER_VIOLATES_ELIGIBLE_MEAL_QUANTITIES_ERROR,
    ErrorDTOReasonEnum.INCORRECT_CARD_NUMBER,
    ErrorDTOReasonEnum.INVALID_CARD_CVC,
    ErrorDTOReasonEnum.PAYMENT_DECLINED,
    ErrorDTOReasonEnum.PAYMENT_METHOD_MISSING,
    ErrorDTOReasonEnum.CARD_EXPIRED,
    ErrorDTOReasonEnum.PROVIDER_ALREADY_VERIFIED,
];

/**
 * Configures an [Axios interceptor](https://axios-http.com/docs/interceptors) to log API response errors to Datadog.
 *
 * @param axiosInstance Global axios instance.
 * @return the id of the interceptor
 */
export const configureResponseErrorInterceptor = (axiosInstance: AxiosInstance): number => {
    logger.debug('Configuring response error interceptor');
    return axiosInstance.interceptors.response.use(
        function (res) {
            return res;
        },
        function (error) {
            if (error?.response?.headers?.authorization) {
                delete error.response.headers.authorization;
            }
            const errorContext = error.config && error.config.errorContext;
            const statusCode = error?.response?.data?.code || error?.response?.status;

            if (error.response?.data?.reason === ErrorDTOReasonEnum.CLIENT_REQUIRES_VALIDATION) {
                clearTokens();
                window.location.href = PATIENT_EMAIL_VERIFICATION_AFTER_ACTIVATION_ROUTE;
            } else if (
                error.response?.data?.reason === ErrorDTOReasonEnum.RECENT_MFA_VERIFICATION_REQUIRED_FOR_ENDPOINT
            ) {
                logger.warn('Redirecting to two fafctor auth route due to MFA_REQUIRED_FOR_ENDPOINT 403', {
                    appRoute: window.location.pathname,
                    apiUrl: error.request_url,
                });
                window.location.href = `${TWO_FACTOR_ROUTE}?from=${window.location.pathname}`;
            } else if (statusCode && statusCode >= 400 && !IGNORED_STATUS_CODES.includes(statusCode)) {
                const severity = getSeverity(error);
                const context = {
                    error_msg: error.response?.data?.message,
                    error_code: error.response?.data?.code,
                    error_reason: error.response?.data?.reason,
                    request_id: error.response?.headers['x-correlation-id'],
                    request_url: error.config?.url,
                    request_method: error.config?.method,
                    callingStack: errorContext && errorContext.stack,
                };
                if (severity === 'error') {
                    const apiUrl = error.config?.url ? (error.config.url as string).toLowerCase() : '';
                    if (statusCode === 409 && apiUrl.includes('/client') && apiUrl.endsWith('/register')) {
                        logger.warn(`Api Error (${statusCode}): ${error.response?.data?.message}`, context);
                    } else if (
                        error.response?.data?.code === 403 &&
                        error.response?.data?.reason === ErrorDTOReasonEnum.OUTSIDE_US
                    ) {
                        //hard redirect to the error page
                        window.location.href = '%PUBLIC_URL%/geo_error.html';
                    } else {
                        logger.error(
                            `Api Error (${statusCode}): ${error.response?.data?.message}`,
                            context,
                            new Error(`${statusCode} returned by ${error.config?.url}`, { cause: error })
                        );
                    }
                } else {
                    // eslint-disable-next-line lingui/no-unlocalized-strings
                    logger[severity](`Api Error (${statusCode}): ${error.response?.data?.message}`, context);
                }
            }
            return Promise.reject(error);
        }
    );
};

// eslint-disable-next-line
const getSeverity = (error: any): 'info' | 'warn' | 'error' => {
    const statusCode = error?.response?.data?.code;
    const isAdminApp404 = statusCode === 404 && window.location.pathname.startsWith(ADMIN_APP_HOME);
    const isAdminApp409 = statusCode === 409 && window.location.pathname.startsWith(ADMIN_APP_HOME);
    const isAdminApp4xx = statusCode < 500 && window.location.pathname.startsWith(ADMIN_APP_HOME);
    const apiUrl = error.config?.url ? (error.config.url as string).toLowerCase() : '';

    if (INFO_ERROR_REASONS.includes(error.response?.data?.reason) || isAdminApp404 || isAdminApp409) return 'info';
    if (
        WARNING_ONLY_STATUS_CODES.includes(statusCode) ||
        isAdminApp4xx ||
        (statusCode === 409 && apiUrl.includes('/client') && apiUrl.endsWith('/register'))
    ) {
        return 'warn';
    }
    return 'error';
};

// eslint-disable-next-line
export const refreshAuthLogic = (failedRequest: any): Promise<any> => {
    const storedRefreshToken = localStorage.getItem(REFRESH_TOKEN_ACCESSOR);
    if (storedRefreshToken && storedRefreshToken !== '') {
        return axios
            .post(`${SEASON_API_BASE_URL}/auth/refresh_token`, { refreshToken: storedRefreshToken })
            .then((tokenRefreshResponse) => {
                const response = tokenRefreshResponse.data as TokenResponseDTO;
                setTokens(response);
                // eslint-disable-next-line lingui/no-unlocalized-strings
                failedRequest.response.config.headers['Authorization'] = `Bearer ${response.accessToken}`;
                return Promise.resolve();
            })
            .catch((error) => {
                clearTokens();
                return Promise.reject(error);
            });
    }
    return Promise.reject(failedRequest);
};
