import type { AutocompleteMedicationPredictionsResponseDTO, StateDTO } from 'API';
import { useQuery } from 'react-query';
import type { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import { autocompleteApi } from 'utils';

export const useGetStateFromPostalCode = (
    postalCode: string,
    options?: Omit<UseQueryOptions<StateDTO | undefined>, 'queryKey' | 'queryFn'>
): UseQueryResult<StateDTO | undefined> => {
    return useQuery<StateDTO | undefined>(
        ['getStateFromPostalCode', postalCode],
        () => autocompleteApi.getStateFromPostalCode(postalCode).then((response) => response.data.state),
        options
    );
};

export const useAutocompleteMedicationPredictions = (
    input: string,
    options?: Omit<UseQueryOptions<AutocompleteMedicationPredictionsResponseDTO>, 'queryKey' | 'queryFn'>
): UseQueryResult<AutocompleteMedicationPredictionsResponseDTO> => {
    return useQuery<AutocompleteMedicationPredictionsResponseDTO>(
        ['getAutocompleteMedicationPredictions', input],
        () => autocompleteApi.getMedicationPredictions(input).then((response) => response.data),
        {
            ...options,
            enabled: input.length > 0,
        }
    );
};
