import type { ClientSubscriptionDTO } from 'API';
import type { Reducer } from 'redux';
import type { loginRequestSuccess, userDetailsRefresh } from 'store/user/actions';
import { UserActionTypes } from 'store/user/types';
import { setTokens } from 'utils';
import type { MetaActions } from '../../../../store/meta/store';

export interface SubscriptionState {
    currentSubscription: ClientSubscriptionDTO | null | undefined;
}

export const initialState: SubscriptionState = { currentSubscription: undefined };

export type SubscriptionActions = ReturnType<typeof loginRequestSuccess> | ReturnType<typeof userDetailsRefresh>;

export const subscriptionReducer: Reducer<SubscriptionState, SubscriptionActions | MetaActions> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case UserActionTypes.LOGIN_REQUEST_SUCCESS: {
            setTokens(action.payload);
            return {
                ...state,
                currentSubscription: action.payload.userDetails.client?.currentSubscription,
            };
        }
        case UserActionTypes.USER_DETAILS_REFRESH: {
            return {
                ...state,
                currentSubscription: action.payload.client?.currentSubscription,
            };
        }
        default: {
            return state;
        }
    }
};
