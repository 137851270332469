import { t } from '@lingui/macro';

export const getAllergiesAndIntolerances = () => [
    { name: 'EGG', type: 'allergy', overrideName: t`Egg` },
    { name: 'FISH', type: 'allergy', overrideName: t`Fish` },
    { name: 'LACTOSE', type: 'intolerance', overrideName: t`Lactose Intolerance` },
    { name: 'MILK', type: 'allergy', overrideName: t`Milk` },
    { name: 'PEANUT', type: 'allergy', overrideName: t`Peanut` },
    { name: 'SESAME', type: 'allergy', overrideName: t`Sesame` },
    { name: 'SHELLFISH', type: 'allergy', overrideName: t`Shellfish` },
    { name: 'SOY', type: 'allergy', overrideName: t`Soy` },
    { name: 'TREE_NUT', type: 'allergy', overrideName: t`Tree nut` },
    { name: 'WHEAT', type: 'allergy', overrideName: t`Wheat` },
];

export const getCookingEquipmentDefault = () => [
    { name: 'AIR_FRYER', overrideName: t`Air fryer` },
    { name: 'GRILL', overrideName: t`Grill` },
    { name: 'BLENDER', overrideName: t`Blender` },
    { name: 'PRESSURE_COOKER', overrideName: t`Pressure cooker` },
    { name: 'SLOW_COOKER', overrideName: t`Slow cooker` },
];
