export type Props = {
    children?: React.ReactNode;
    isSelected: boolean;
    label: string;
};

const Tabpanel = ({ children, label, isSelected }: Props): JSX.Element => {
    return (
        <div
            role="tabpanel"
            hidden={!isSelected}
            id={`tabpanel-${label}`}
            aria-labelledby={`tab-${label}`}
            tabIndex={0}
        >
            {children}
        </div>
    );
};

export default Tabpanel;
