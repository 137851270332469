import { googleLogout } from '@react-oauth/google';
import { UserDetailsDTORolesEnum } from 'API';
import type { Reducer } from 'redux';
import { clearTokens, logger, setTokens } from 'utils';
import { NativeAppOutboundMessageTypes } from 'utils/native-app-bridge/messageTypes';
import { postMessageToNativeApp } from 'utils/native-app-bridge/utils';
import { deidentify } from 'utils/tracking/identify';
import type { MetaActions } from '../meta/store';
import type { UserActions } from './actions';
import type { UserState } from './types';
import { UserActionTypes } from './types';

export const initialState: UserState = {
    data: {
        firstName: '',
        lastName: '',
        roles: [UserDetailsDTORolesEnum.CLIENT],
        isImpersonation: false,
        activeLoginTypes: [],
        requiresMfa: false,
        availableMfaCommunicationChannels: [],
    },
    loading: false,
    isSignedIn: false,
};

const tryDeidentify = () => {
    try {
        logger.debug('Attempting to call deidentify');
        deidentify();
    } catch (e) {
        logger.debug('Failed to call deidentify, ignoring', { exception: e });
    }
};

const reducer: Reducer<UserState, UserActions | MetaActions> = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.SIGN_OUT: {
            googleLogout();
            clearTokens();
            tryDeidentify();
            postMessageToNativeApp(NativeAppOutboundMessageTypes.LOGOUT_SUCCEEDED);
            return initialState;
        }

        case UserActionTypes.SSO_LOGIN_REQUEST: {
            return { ...state, loading: true };
        }
        case UserActionTypes.LOGIN_REQUEST_ERROR: {
            clearTokens();
            tryDeidentify();
            return { ...state, loading: false, error: action.payload, isSignedIn: false };
        }
        case UserActionTypes.LOGIN_REQUEST_SUCCESS: {
            setTokens(action.payload);
            return { ...state, loading: false, isSignedIn: true, data: action.payload.userDetails };
        }
        case UserActionTypes.LOGIN_REQUEST_SUCCESS_PENDING_MFA: {
            setTokens(action.payload);
            return { ...state, loading: false, isSignedIn: false, data: action.payload.userDetails };
        }
        case UserActionTypes.LOGIN_REFRESH: {
            return { ...state, loading: true };
        }
        case UserActionTypes.USER_DETAILS_REFRESH: {
            return { ...state, data: { ...state.data, ...action.payload } };
        }
        case UserActionTypes.CLIENT_DETAILS_REFRESH: {
            return {
                ...state,
                data: {
                    ...state.data,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    client: { ...action.payload },
                },
            };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as userReducer };
