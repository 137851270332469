import type { PaymentMethodDTO } from 'API';
import type { Reducer } from 'redux';
import type { loginRequestSuccess, userDetailsRefresh } from 'store/user/actions';
import { UserActionTypes } from 'store/user/types';
import { setTokens } from 'utils';
import type { MetaActions } from '../../../../store/meta/store';
import { action } from 'typesafe-actions';

export interface PaymentsState {
    paymentMethods: PaymentMethodDTO[];
}

export const initialState: PaymentsState = {
    paymentMethods: [],
};

enum PaymentActionTypes {
    SET_PAYMENT_METHODS = '@@patient/SET_PAYMENT_METHODS',
}

export function setPaymentMethods(methods: PaymentMethodDTO[]) {
    return action(PaymentActionTypes.SET_PAYMENT_METHODS, methods);
}

export type PaymentActions =
    | ReturnType<typeof setPaymentMethods>
    | ReturnType<typeof loginRequestSuccess>
    | ReturnType<typeof userDetailsRefresh>;

export const paymentReducer: Reducer<PaymentsState, PaymentActions | MetaActions> = (state = initialState, action) => {
    switch (action.type) {
        case PaymentActionTypes.SET_PAYMENT_METHODS: {
            return { ...state, paymentMethods: action.payload };
        }
        case UserActionTypes.LOGIN_REQUEST_SUCCESS: {
            setTokens(action.payload);
            return { ...state, paymentMethods: action.payload.userDetails.client?.paymentMethods ?? [] };
        }
        case UserActionTypes.USER_DETAILS_REFRESH: {
            return { ...state, paymentMethods: action.payload.client?.paymentMethods ?? [] };
        }
        default: {
            return state;
        }
    }
};
