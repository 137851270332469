import checkboxSelectedIcon from 'assets/icons/stroke/checkboxSelected.svg';
import checkboxIcon from 'assets/icons/stroke/checkboxUnselected.svg';
import clsx from 'clsx';
import { Spinner } from 'galley';

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    label: string | JSX.Element;
    isInvalid?: boolean;
    labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
    id: string;
    isLoading?: boolean;
};

const Checkbox = ({
    id,
    label,
    labelProps,
    isLoading,
    isInvalid = false,
    type,
    className,
    ...rest
}: Props): JSX.Element => (
    <label
        className={clsx('group flex w-full cursor-pointer content-center items-center gap-2', className)}
        {...labelProps}
    >
        {isLoading ? (
            <Spinner color="primary" className="flex-none" />
        ) : (
            <input
                type="checkbox"
                data-dd-action-name={`${id} input`}
                className="hover:shadow-b-2 h-4 w-4 flex-none cursor-pointer appearance-none"
                style={{
                    backgroundImage: rest.checked ? `url(${checkboxSelectedIcon})` : `url(${checkboxIcon})`,
                }}
                {...rest}
            />
        )}

        <span className="bodyMdLight group-hover:font-medium">{label}</span>
    </label>
);

export default Checkbox;
