import clsx from 'clsx';

export interface Props extends React.ComponentPropsWithoutRef<'span'> {
    className?: string;
}

const StatusIndicator = ({ className, ...rest }: Props): JSX.Element => {
    return (
        <span
            role="status"
            className={clsx('bg-traffic-pink h-2 w-2 rounded-full border-[1.5px] border-white', className)}
            {...rest}
        />
    );
};

export default StatusIndicator;
