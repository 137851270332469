import { Trans } from '@lingui/macro';
import { APP_BUILD_DATE_POLL_INTERVAL_MS } from 'apps/patient/queries';
import { millisecondsToMinutes } from 'date-fns';
import { Button } from 'galley';

const UpgradeAppMessage = (): JSX.Element => {
    return (
        <Button className="bg-alert-lightYellow w-full px-3 py-1" onClick={() => window.location.reload()}>
            <div role="alert">
                <Trans>
                    <span className="bodySmMedium">A new version of Season is available. </span>
                    <span className="paragraphLink14 whitespace-nowrap">Click to upgrade now</span>{' '}
                    <span className="bodySmLight">
                        or Season will automatically refresh in {millisecondsToMinutes(APP_BUILD_DATE_POLL_INTERVAL_MS)}{' '}
                        minutes
                    </span>
                </Trans>
            </div>
        </Button>
    );
};

export default UpgradeAppMessage;
