/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ClientSubscriptionStateDTO {
    ACTIVE = 'ACTIVE',
    CANCELED = 'CANCELED',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    PAYMENT_ACTION_REQUIRED = 'PAYMENT_ACTION_REQUIRED'
};

