import type { InsuranceCarrierDisplayResponseDTO } from 'API';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { insuranceCarrierApi } from '..';

const GET_CARRIERS_KEY = 'getInsuranceCarriersDisplay';
export const useGetInsuranceCarriersDisplay = (
    options?: Omit<UseQueryOptions<InsuranceCarrierDisplayResponseDTO[]>, 'queryFn' | 'queryKey'>
) =>
    useQuery(
        [GET_CARRIERS_KEY],
        () => insuranceCarrierApi.getInsuranceCarriersDisplay().then((r) => r.data.insuranceCarriers),
        options
    );
