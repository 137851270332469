/**
 * This component is to be used for Search Fields
 * Use galley/Input for most inputs in forms
 */

import { t } from '@lingui/macro';
import { ReactComponent as ClearButton } from 'assets/icons/clear.svg';
import clsx from 'clsx';
import type { InputHTMLAttributes } from 'react';

export interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    className?: string;
    onChange?: (input: string) => void;
    icon?: React.ReactNode;
    inlineLabel?: string;
    onClear?: () => void;
}

const InputField = ({ className, onChange, onClear, value, icon, inlineLabel, ...rest }: Props): JSX.Element => {
    return (
        <div className="relative">
            <input
                autoComplete="off"
                autoCapitalize="off"
                spellCheck="false"
                className={clsx('input-field peer', className)}
                onChange={(event) => onChange?.(event.target.value)}
                value={value}
                {...rest}
            />
            {onClear && value && (
                <ClearButton
                    aria-label={t`Clear`}
                    role="button"
                    className="absolute right-4 top-0 h-full"
                    onClick={onClear}
                />
            )}
            {icon && icon}
            {inlineLabel && (
                <p className="overlineMedium text-greyscale-inactive absolute right-4 top-1/3 h-fit">{inlineLabel}</p>
            )}
        </div>
    );
};

export default InputField;
