/* eslint-disable lingui/no-unlocalized-strings */
import { differenceInDays, format, isFuture, isSameYear, isToday, parse, startOfDay } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { t } from '@lingui/macro';

export const formatTime = (value: Date | string | number, type: 'US' | 'ISO'): string => {
    const date = typeof value === 'string' ? new Date(value) : value;
    return format(date, type === 'US' ? 'h:mm a' : 'HH:mm');
};

/**
 * Helper wrapper on top of date-fns that calls the `formatInTimeZone` function if timeZone is defined, and
 * the standard `format` function if not.
 */
const formatDateInTimeZone = (date: Date, formatStr: string, timeZone: string) => {
    if (timeZone) return formatInTimeZone(date, timeZone, formatStr);
    else return format(date, formatStr);
};

export const friendlyFormatDate = (isoDate: Date, timeZone = '') => {
    if (isToday(isoDate)) return 'Today';
    if (isFuture(isoDate)) return formatDateInTimeZone(isoDate, 'ccc, LLL d', timeZone);
    if (isSameYear(isoDate, new Date())) return formatDateInTimeZone(isoDate, 'LLL d', timeZone);
    return formatDateInTimeZone(isoDate, 'LLL d, yyyy', timeZone);
};

export const friendlyFormatTime = (isoDate: Date, timeZone = '', includeTzDisplay = false) => {
    const hour = formatDateInTimeZone(isoDate, 'h', timeZone);
    const minute = formatDateInTimeZone(isoDate, 'mm', timeZone);
    const ampm = formatDateInTimeZone(isoDate, 'aaa', timeZone);
    const tz = timeZone ? formatDateInTimeZone(isoDate, 'zzz', timeZone) : undefined;

    return [hour, minute !== '00' ? `:${minute}` : undefined, ampm, includeTzDisplay ? ` ${tz}` : undefined]
        .filter(Boolean)
        .join('');
};

export const friendlyFormatDateTime = (isoDate: Date) => {
    return [friendlyFormatDate(isoDate), friendlyFormatTime(isoDate)].join(', ');
};

export const formatDate = (value: Date | string | number, type: 'US' | 'ISO'): string => {
    const date = typeof value === 'string' ? new Date(value) : value;
    return format(date, type === 'US' ? 'MM/dd/yyyy' : 'yyyy-MM-dd');
};

export const formatUSDateWithTimestamp = (value: Date | string | number): string => {
    const date = typeof value === 'string' ? new Date(value) : value;
    return format(date, 'MM/dd/yy, h:mm aaa');
};

export const formatFriendlyDateTimestamp = (value: Date | string | number): string => {
    const date = typeof value === 'string' ? new Date(value) : value;
    return format(date, 'MMMM do yyyy, h:mm:ss aaa');
};

export const parseDateString = (value: string, type: 'US' | 'ISO'): Date => {
    return parse(value, type === 'US' ? 'MM/dd/yyyy' : 'yyyy-MM-dd', new Date());
};

export const asUTC = (date: Date): Date => {
    return new Date(
        Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
        )
    );
};

export const calcDaysEnrolled = (startDate: string) => {
    const today = startOfDay(new Date());
    // Note: convert 2023-06-14 to 2023/06/14 to avoid date offset
    const start = new Date(startDate.replace(/-/g, '/'));

    const daysEnrolled = differenceInDays(today, start);
    return { daysEnrolled, start };
};

export const getDateOnly = (date: Date | string) => {
    if (date instanceof Date) {
        return date.toISOString().split('T')[0];
    } else {
        return date.split('T')[0];
    }
};

const TIME_ZONE_IANA_MAP: Record<string, string> = {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    'America/Boise': 'America/Denver',
    // eslint-disable-next-line lingui/no-unlocalized-strings
    'America/Detroit': 'America/New_York',
    // eslint-disable-next-line lingui/no-unlocalized-strings
    'America/Indianapolis': 'America/New_York',
};

export const TIME_FORMAT = 'h:mm aaa';

export const getTimeZoneDisplay = () => ({
    'America/New_York': t`Eastern Time`,
    'America/Chicago': t`Central Time`,
    'America/Denver': t`Mountain Time`,
    'America/Phoenix': t`Arizona Time`,
    'America/Los_Angeles': t`Pacific Time`,
});

export const getTimeZoneOptions = (includeCurrentTime = true) =>
    Object.entries(getTimeZoneDisplay()).map(([id, label]) => {
        const currentTime = formatInTimeZone(new Date(), id, TIME_FORMAT);
        const name = includeCurrentTime ? t`${label} ( ${currentTime} )` : label;
        return { name, value: id };
    });

export const getCurrentTimeZone = () => {
    const tz = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    return TIME_ZONE_IANA_MAP[tz] ?? tz;
};

export const filterTimesBeforeHourInTimeZone = (times: string[], cutoffHour: number, timeZoneName: string): string[] =>
    times.filter((rawTime) => utcToZonedTime(rawTime, timeZoneName).getHours() >= cutoffHour);
