import clsx from 'clsx';
import { ChevronDownStrokeIcon } from 'galley';
import { useId } from 'react';
import type { Props } from './types';

const SelectField = ({
    className,
    disabled,
    isInvalid,
    options,
    placeholder,
    value,
    label,
    optionGroups,
    hideLabel,
    variant = 'primary',
    ...rest
}: Props): JSX.Element => {
    const fallbackId = useId();
    const id = rest.id ?? fallbackId;
    const isPrimary = variant === 'primary';
    const isSecondary = variant === 'secondary';
    return (
        <div className={clsx(isPrimary && 'relative', isSecondary && 'inline')}>
            {label && (
                <label className={clsx('bodyMdLight block w-full pb-1', hideLabel && 'sr-only')} htmlFor={id}>
                    {label}
                </label>
            )}
            <select
                id={id}
                data-dd-privacy="mask"
                data-dd-action-name={`${label} dropdown`}
                aria-label={label}
                disabled={disabled}
                className={clsx(
                    isPrimary &&
                        'hover:drop-shadow-input focus:drop-shadow-input bodyMdMedium border-standard-barley placeholder:text-greyscale-inactive focus:outline-standard-barley h-fit w-full appearance-none rounded border bg-white px-4 py-3 !pr-8 focus:outline',
                    isPrimary && {
                        '!text-greyscale-inactive': !value,
                        '!bg-greyscale-grey10 cursor-not-allowed': disabled,
                        '!border-alert-red2 focus:outline-none': isInvalid,
                    },
                    isSecondary && 'bodyMdLight standardLinkColors bg-transparent',
                    className
                )}
                style={
                    isSecondary
                        ? {
                              width: `${
                                  (options?.find((option) => option.value === value)?.name.length ?? 0) * 10 - 45
                              }px`,
                          }
                        : undefined
                }
                value={value || placeholder}
                {...rest}
            >
                <>
                    {placeholder && <option disabled>{placeholder}</option>}
                    {options?.map(({ name, value }) => (
                        <option key={`${id}_${value}`} value={value}>
                            {name}
                        </option>
                    ))}
                    {optionGroups?.map(({ label, options }) => (
                        <optgroup key={label} label={label}>
                            {options.map(({ name, value }) => (
                                <option key={`${id}_${value}`} value={value}>
                                    {name}
                                </option>
                            ))}
                        </optgroup>
                    ))}
                </>
            </select>
            {isPrimary && (
                <ChevronDownStrokeIcon
                    className={clsx(
                        'smIcon pointer-events-none absolute right-4 h-full',
                        label && label !== '' ? 'top-[calc(50%_+_3px)]' : 'top-[calc(50%_-_6px)]',
                        disabled && 'stroke-greyscale-inactive',
                        { '!border-y-alert-red2': isInvalid }
                    )}
                />
            )}
        </div>
    );
};

export default SelectField;
