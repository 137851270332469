import clsx from 'clsx';
import {
    CheckboxSelectedIcon,
    CheckboxUnselectedIcon,
    RadioSelectedStrokeIcon,
    RadioUnselectedStrokeIcon,
} from 'galley';

export enum Layout {
    BLOCK,
    INLINE,
}

export type Props = React.PropsWithChildren<{
    isMultiselect?: boolean;
    isSelected: boolean;
    layout: Layout;
    onSelect(): void;
    id: string;
    labelClassname?: string;
    className?: string;
}>;

const SelectablePill = ({
    children,
    layout,
    isMultiselect = false,
    isSelected,
    onSelect,
    id,
    labelClassname,
    className,
}: Props): JSX.Element => (
    <div
        className={clsx(
            'hover:shadow-selector flex grow basis-0 cursor-pointer rounded-md',
            layout === Layout.INLINE ? 'flex-row items-center' : 'flex-col',
            isSelected ? 'shadow-pill bg-mediumBlue-1 p-3.5' : 'shadow-a-1 bg-white p-[15px]',
            className
        )}
        role={isMultiselect ? 'checkbox' : 'radio'}
        onMouseUp={onSelect}
        onKeyDown={onSelect}
        aria-checked={isSelected}
        aria-labelledby={id}
        aria-label={id}
    >
        {isMultiselect ? (
            isSelected ? (
                <div>
                    <CheckboxSelectedIcon data-test-id="check-selected-icon" />
                </div>
            ) : (
                <div>
                    <CheckboxUnselectedIcon data-test-id="check-unselected-icon" />
                </div>
            )
        ) : isSelected ? (
            <div>
                <RadioSelectedStrokeIcon data-test-id="radio-selected-icon" />
            </div>
        ) : (
            <div>
                <RadioUnselectedStrokeIcon data-test-id="radio-unselected-icon" />
            </div>
        )}
        <label className={clsx('cursor-pointer', isSelected ? 'bodyMdMedium' : 'bodyMdLight', labelClassname)} id={id}>
            {children}
        </label>
    </div>
);

export default SelectablePill;
