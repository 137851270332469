/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import type { EducationActions, EducationState } from '../apps/patient/Education/store';
import { educationReducer } from '../apps/patient/Education/store';
import type { DeliveryDetailsActions, DeliveryDetailsState } from '../apps/patient/store/reducers/deliveryDetails';
import { deliveryDetailsReducer } from '../apps/patient/store/reducers/deliveryDetails';
import type { PatientActions, PatientState } from '../apps/patient/store/reducers/patient';
import { patientReducer } from '../apps/patient/store/reducers/patient';
import type { PaymentActions, PaymentsState } from '../apps/patient/store/reducers/payment';
import { paymentReducer } from '../apps/patient/store/reducers/payment';
import type { SubscriptionActions, SubscriptionState } from '../apps/patient/store/reducers/subscription';
import { subscriptionReducer } from '../apps/patient/store/reducers/subscription';
import type { AlertActions } from './alerts/actions';
import { alertReducer } from './alerts/reducers';
import alertSaga from './alerts/sagas';
import type { AlertState } from './alerts/types';
import type { CheckoutDrawerActions } from './checkoutDrawer/actions';
import { checkoutDrawerReducer } from './checkoutDrawer/reducers';
import type { CheckoutDrawerState } from './checkoutDrawer/types';
import type { MetaActions } from './meta/store';
import { MetaActionTypes } from './meta/store';
import type { RateActions } from './ratingPrompt/actions';
import { rateReducer } from './ratingPrompt/reducers';
import type { RateState } from './ratingPrompt/types';
import type { UserActions } from './user/actions';
import { userReducer } from './user/reducers';
import userSaga from './user/sagas';
import type { UserState } from './user/types';
import { UserActionTypes } from './user/types';

// The top-level state object
export interface ApplicationState {
    alert: AlertState;
    checkoutDrawer: CheckoutDrawerState;
    deliveryDetails: DeliveryDetailsState;
    education: EducationState;
    patient: PatientState;
    payments: PaymentsState;
    ratePrompt: RateState;
    subscription: SubscriptionState;
    user: UserState;
}

export type ApplicationActions =
    | AlertActions
    | CheckoutDrawerActions
    | DeliveryDetailsActions
    | EducationActions
    | PatientActions
    | PaymentActions
    | RateActions
    | SubscriptionActions
    | UserActions;

const createAppReducer = () =>
    combineReducers({
        alert: alertReducer,
        checkoutDrawer: checkoutDrawerReducer,
        deliveryDetails: deliveryDetailsReducer,
        education: educationReducer,
        patient: patientReducer,
        payments: paymentReducer,
        ratePrompt: rateReducer,
        subscription: subscriptionReducer,
        user: userReducer,
    });

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = () => {
    const appReducer = createAppReducer();

    return (state: ApplicationState | undefined, action: ApplicationActions | MetaActions): ApplicationState => {
        if (action.type === MetaActionTypes.RESET_STORE || action.type === UserActionTypes.SIGN_OUT) {
            return appReducer(undefined, action);
        }
        return appReducer(state, action);
    };
};
// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
    yield all([fork(userSaga), fork(alertSaga)]);
}
