/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum PregnancyRiskFactorDTO {
    DIAGNOSED_WITH_DEPRESSION = 'DIAGNOSED_WITH_DEPRESSION',
    HIGH_BLOOD_PRESSURE = 'HIGH_BLOOD_PRESSURE',
    OCCASIONAL_FOOD_INSTABILITY = 'OCCASIONAL_FOOD_INSTABILITY',
    PREDIABETES_DIABETES_OR_PREVIOUS_GESTATIONAL_DIABETES = 'PREDIABETES_DIABETES_OR_PREVIOUS_GESTATIONAL_DIABETES',
    PREGNANT_WITH_2_OR_MORE = 'PREGNANT_WITH_2_OR_MORE',
    PREVIOUS_LOW_OR_HIGH_BIRTH_WEIGHT = 'PREVIOUS_LOW_OR_HIGH_BIRTH_WEIGHT',
    PREVIOUS_MISCARRIAGE_OR_STILLBIRTH = 'PREVIOUS_MISCARRIAGE_OR_STILLBIRTH',
    PREVIOUS_PREMATURE_LABOR_OR_DELIVERY = 'PREVIOUS_PREMATURE_LABOR_OR_DELIVERY',
    RECENT_CIGARETTES_OR_ELECTRONIC_NICOTINE = 'RECENT_CIGARETTES_OR_ELECTRONIC_NICOTINE'
};

