import type { FieldAttributes } from 'formik';
import { useField } from 'formik';
import { trackBlur } from 'utils/tracking/events';
import SelectField from './SelectField';
import type { Props } from './types';

const SelectFieldWithFormik = ({
    label,
    placeholder,
    isInvalid,
    options,
    optionGroups,
    ...props
}: FieldAttributes<Props>): JSX.Element => {
    const [field, meta] = useField(props);
    return (
        <SelectField
            label={label}
            placeholder={placeholder}
            isInvalid={meta.touched && !!meta.error}
            {...field}
            {...props}
            {...(optionGroups ? { optionGroups: optionGroups } : { options: options })}
            onBlur={(e) => {
                field.onBlur(e);
                trackBlur({
                    label: field.name,
                    error: meta.error,
                });
            }}
        />
    );
};

export default SelectFieldWithFormik;
