/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClearProductSearchPredictionRequestDTO } from '../models';
// @ts-ignore
import { DeselectProductSearchPredictionRequestDTO } from '../models';
// @ts-ignore
import { GroceryListAvailableDeliveryWindowsResponseDTO } from '../models';
// @ts-ignore
import { GroceryListAvailableVendorsResponseDTO } from '../models';
// @ts-ignore
import { GroceryListFulfillmentExceptionsResponseDTO } from '../models';
// @ts-ignore
import { GroceryListOrderResponseDTO } from '../models';
// @ts-ignore
import { GroceryListOrdersResponseDTO } from '../models';
// @ts-ignore
import { GroceryListResponseDTO } from '../models';
// @ts-ignore
import { GroceryOrderRequestDTO } from '../models';
// @ts-ignore
import { InitiateGroceryListOfflineShoppingRequestDTO } from '../models';
// @ts-ignore
import { MarkGroceryListDeliveredRequestDTO } from '../models';
// @ts-ignore
import { MarkGroceryListSentToVendorRequestDTO } from '../models';
// @ts-ignore
import { OrderPaymentsDTO } from '../models';
// @ts-ignore
import { ProcessProductSearchRequestDTO } from '../models';
// @ts-ignore
import { RejectProductSearchPredictionRequestDTO } from '../models';
// @ts-ignore
import { RemoveOrderItemsRequestDTO } from '../models';
// @ts-ignore
import { RequestGroceryOrderResponseDTO } from '../models';
// @ts-ignore
import { RequestProductSearchRequestDTO } from '../models';
// @ts-ignore
import { SelectProductSearchPredictionRequestDTO } from '../models';
// @ts-ignore
import { ShopGroceryOrderOfflineRequestDTO } from '../models';
// @ts-ignore
import { UpdateEstimatedDeliveryWindowRequestDTO } from '../models';
// @ts-ignore
import { UpdateGroceryListAllowSubstitutionsRequestDTO } from '../models';
// @ts-ignore
import { UpdateGroceryListClientCartCostDetailsRequestDTO } from '../models';
// @ts-ignore
import { UpdateGroceryListClientDeliveryDetailsRequestDTO } from '../models';
// @ts-ignore
import { UpdateGroceryListExternalIdsRequestDTO } from '../models';
// @ts-ignore
import { UpdateGroceryListFulfillmentIssueRequestDTO } from '../models';
// @ts-ignore
import { UpdateGroceryListIntentToUseSNAPRequestDTO } from '../models';
// @ts-ignore
import { UpdateGroceryListRequestDTO } from '../models';
// @ts-ignore
import { UpdateGroceryVendorPreferenceRequestDTO } from '../models';
// @ts-ignore
import { UpdateOrderItemsRequestDTO } from '../models';
// @ts-ignore
import { UpdatePreferredDeliveryWindowsRequestDTO } from '../models';
/**
 * GroceryListApi - axios parameter creator
 * @export
 */
export const GroceryListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Mark a grocery list as no longer offline shopping
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortGroceryListOfflineShopping: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('abortGroceryListOfflineShopping', 'id', id)
            const localVarPath = `/grocery_lists/{id}/abort-offline-shopping`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a grocery order and mark all Meals that are contained in it as deleted
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelOrder', 'id', id)
            const localVarPath = `/grocery_lists/{id}/cancel-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Claim an order for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGroceryOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('claimGroceryOrder', 'id', id)
            const localVarPath = `/grocery_lists/{id}/claim-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clear a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {ClearProductSearchPredictionRequestDTO} clearProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearProductSearchPrediction: async (id: string, clearProductSearchPredictionRequest: ClearProductSearchPredictionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clearProductSearchPrediction', 'id', id)
            // verify required parameter 'clearProductSearchPredictionRequest' is not null or undefined
            assertParamExists('clearProductSearchPrediction', 'clearProductSearchPredictionRequest', clearProductSearchPredictionRequest)
            const localVarPath = `/grocery_lists/{id}/clear-product-search-prediction`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clearProductSearchPredictionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deselect a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {DeselectProductSearchPredictionRequestDTO} deselectProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deselectProductSearchPrediction: async (id: string, deselectProductSearchPredictionRequest: DeselectProductSearchPredictionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deselectProductSearchPrediction', 'id', id)
            // verify required parameter 'deselectProductSearchPredictionRequest' is not null or undefined
            assertParamExists('deselectProductSearchPrediction', 'deselectProductSearchPredictionRequest', deselectProductSearchPredictionRequest)
            const localVarPath = `/grocery_lists/{id}/deselect-product-search-prediction`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deselectProductSearchPredictionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available delivery windows for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDeliveryWindows: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAvailableDeliveryWindows', 'id', id)
            const localVarPath = `/grocery_lists/{id}/available-delivery-windows`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available vendors for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableVendors: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAvailableVendors', 'id', id)
            const localVarPath = `/grocery_lists/{id}/available-vendors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroceryList: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroceryList', 'id', id)
            const localVarPath = `/grocery_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get grocery list fulfillment exceptions for a month
         * @param {string} [month] An optional year-month, defaults to the current month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroceryListFulfillmentExceptions: async (month?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/grocery_lists/fulfillment-exceptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get grocery list order
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroceryListOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroceryListOrder', 'id', id)
            const localVarPath = `/grocery_lists/{id}/order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payments for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroceryListPayments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroceryListPayments', 'id', id)
            const localVarPath = `/grocery_lists/{id}/payments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get grocery lists that satisfy query parameters.
         * @param {string} clientId Client ID
         * @param {Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>} states Grocery List states requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroceryLists: async (clientId: string, states: Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getGroceryLists', 'clientId', clientId)
            // verify required parameter 'states' is not null or undefined
            assertParamExists('getGroceryLists', 'states', states)
            const localVarPath = `/grocery_lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a grocery list as offline shopping initiated
         * @param {string} id Grocery List ID
         * @param {InitiateGroceryListOfflineShoppingRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateGroceryListOfflineShopping: async (id: string, request: InitiateGroceryListOfflineShoppingRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('initiateGroceryListOfflineShopping', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('initiateGroceryListOfflineShopping', 'request', request)
            const localVarPath = `/grocery_lists/{id}/initiate-offline-shopping`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a grocery list delivered with items and cost
         * @param {string} id Grocery List ID
         * @param {MarkGroceryListDeliveredRequestDTO} markGroceryListDeliveredRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markGroceryOrderDelivered: async (id: string, markGroceryListDeliveredRequest: MarkGroceryListDeliveredRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markGroceryOrderDelivered', 'id', id)
            // verify required parameter 'markGroceryListDeliveredRequest' is not null or undefined
            assertParamExists('markGroceryOrderDelivered', 'markGroceryListDeliveredRequest', markGroceryListDeliveredRequest)
            const localVarPath = `/grocery_lists/{id}/mark-delivered`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markGroceryListDeliveredRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a grocery list as sent to vendor with items and cost
         * @param {string} id Grocery List ID
         * @param {MarkGroceryListSentToVendorRequestDTO} markGroceryListSentToVendorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markGroceryOrderSentToVendor: async (id: string, markGroceryListSentToVendorRequest: MarkGroceryListSentToVendorRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markGroceryOrderSentToVendor', 'id', id)
            // verify required parameter 'markGroceryListSentToVendorRequest' is not null or undefined
            assertParamExists('markGroceryOrderSentToVendor', 'markGroceryListSentToVendorRequest', markGroceryListSentToVendorRequest)
            const localVarPath = `/grocery_lists/{id}/mark-sent-to-vendor`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markGroceryListSentToVendorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a grocery order as shopped
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markGroceryOrderShopped: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markGroceryOrderShopped', 'id', id)
            const localVarPath = `/grocery_lists/{id}/mark-shopped`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a grocery order as shopping now
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markGroceryOrderShoppingNow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markGroceryOrderShoppingNow', 'id', id)
            const localVarPath = `/grocery_lists/{id}/mark-shopping-now`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Place an order for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeGroceryListOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('placeGroceryListOrder', 'id', id)
            const localVarPath = `/grocery_lists/{id}/place-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process (or re-process) product search for a grocery list
         * @param {string} id Grocery List ID
         * @param {ProcessProductSearchRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProductSearch: async (id: string, request: ProcessProductSearchRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('processProductSearch', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('processProductSearch', 'request', request)
            const localVarPath = `/grocery_lists/{id}/process-product-search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {RejectProductSearchPredictionRequestDTO} rejectProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectProductSearchPrediction: async (id: string, rejectProductSearchPredictionRequest: RejectProductSearchPredictionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rejectProductSearchPrediction', 'id', id)
            // verify required parameter 'rejectProductSearchPredictionRequest' is not null or undefined
            assertParamExists('rejectProductSearchPrediction', 'rejectProductSearchPredictionRequest', rejectProductSearchPredictionRequest)
            const localVarPath = `/grocery_lists/{id}/reject-product-search-prediction`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectProductSearchPredictionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove order items from a grocery list. Only items without an `ingredientId` or `text` can be removed
         * @param {string} id Grocery List ID
         * @param {RemoveOrderItemsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrderItems: async (id: string, request: RemoveOrderItemsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeOrderItems', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('removeOrderItems', 'request', request)
            const localVarPath = `/grocery_lists/{id}/remove-order-items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request product search for a grocery list order
         * @param {string} id Grocery List ID
         * @param {RequestProductSearchRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGroceryListProductSearch: async (id: string, request: RequestProductSearchRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestGroceryListProductSearch', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('requestGroceryListProductSearch', 'request', request)
            const localVarPath = `/grocery_lists/{id}/request-product-search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a new empty order for a client
         * @param {GroceryOrderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGroceryOrder: async (request: GroceryOrderRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('requestGroceryOrder', 'request', request)
            const localVarPath = `/grocery_lists/request-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revert an order cancellation
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertGroceryOrderCancellation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('revertGroceryOrderCancellation', 'id', id)
            const localVarPath = `/grocery_lists/{id}/revert-order-cancellation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Select a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {SelectProductSearchPredictionRequestDTO} selectProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectProductSearchPrediction: async (id: string, selectProductSearchPredictionRequest: SelectProductSearchPredictionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selectProductSearchPrediction', 'id', id)
            // verify required parameter 'selectProductSearchPredictionRequest' is not null or undefined
            assertParamExists('selectProductSearchPrediction', 'selectProductSearchPredictionRequest', selectProductSearchPredictionRequest)
            const localVarPath = `/grocery_lists/{id}/select-product-search-prediction`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectProductSearchPredictionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a grocery list as shopped offline
         * @param {string} id Grocery List ID
         * @param {ShopGroceryOrderOfflineRequestDTO} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopOrderOffline: async (id: string, request?: ShopGroceryOrderOfflineRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shopOrderOffline', 'id', id)
            const localVarPath = `/grocery_lists/{id}/shop-offline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update substitutions preference for grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListAllowSubstitutionsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllowSubstitutions: async (id: string, request: UpdateGroceryListAllowSubstitutionsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAllowSubstitutions', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateAllowSubstitutions', 'request', request)
            const localVarPath = `/grocery_lists/{id}/update-allow-substitutions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update grocery list estimated delivery window
         * @param {string} id Grocery List ID
         * @param {UpdateEstimatedDeliveryWindowRequestDTO} updateEstimatedDeliveryWindowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstimatedDeliveryWindow: async (id: string, updateEstimatedDeliveryWindowRequest: UpdateEstimatedDeliveryWindowRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEstimatedDeliveryWindow', 'id', id)
            // verify required parameter 'updateEstimatedDeliveryWindowRequest' is not null or undefined
            assertParamExists('updateEstimatedDeliveryWindow', 'updateEstimatedDeliveryWindowRequest', updateEstimatedDeliveryWindowRequest)
            const localVarPath = `/grocery_lists/{id}/update-estimated-delivery-window`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEstimatedDeliveryWindowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update external IDs for a grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListExternalIdsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalIds: async (id: string, request: UpdateGroceryListExternalIdsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExternalIds', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateExternalIds', 'request', request)
            const localVarPath = `/grocery_lists/{id}/update-external-ids`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update grocery list
         * @param {string} id Grocery List ID
         * @param {UpdateGroceryListRequestDTO} updateGroceryListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroceryList: async (id: string, updateGroceryListRequest: UpdateGroceryListRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroceryList', 'id', id)
            // verify required parameter 'updateGroceryListRequest' is not null or undefined
            assertParamExists('updateGroceryList', 'updateGroceryListRequest', updateGroceryListRequest)
            const localVarPath = `/grocery_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGroceryListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a grocery list client cart cost details
         * @param {string} id Grocery List ID
         * @param {UpdateGroceryListClientCartCostDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroceryListClientCartCostDetails: async (id: string, request: UpdateGroceryListClientCartCostDetailsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroceryListClientCartCostDetails', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateGroceryListClientCartCostDetails', 'request', request)
            const localVarPath = `/grocery_lists/{id}/update-client-cart-cost-details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update grocery list client delivery details
         * @param {string} id Grocery List ID
         * @param {UpdateGroceryListClientDeliveryDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroceryListClientDeliveryDetails: async (id: string, request: UpdateGroceryListClientDeliveryDetailsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroceryListClientDeliveryDetails', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateGroceryListClientDeliveryDetails', 'request', request)
            const localVarPath = `/grocery_lists/{id}/update-client-delivery-details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update grocery list fulfillment issue
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListFulfillmentIssueRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroceryListFulfillmentIssue: async (id: string, request: UpdateGroceryListFulfillmentIssueRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroceryListFulfillmentIssue', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateGroceryListFulfillmentIssue', 'request', request)
            const localVarPath = `/grocery_lists/{id}/update-fulfillment-issue`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update intent to use SNAP benefits for grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListIntentToUseSNAPRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntentToUseSNAP: async (id: string, request: UpdateGroceryListIntentToUseSNAPRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIntentToUseSNAP', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateIntentToUseSNAP', 'request', request)
            const localVarPath = `/grocery_lists/{id}/update-intent-to-use-snap`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update ordered or purchased items for grocery list
         * @param {string} id Grocery List ID
         * @param {UpdateOrderItemsRequestDTO} updateOrderItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderItems: async (id: string, updateOrderItemsRequest: UpdateOrderItemsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrderItems', 'id', id)
            // verify required parameter 'updateOrderItemsRequest' is not null or undefined
            assertParamExists('updateOrderItems', 'updateOrderItemsRequest', updateOrderItemsRequest)
            const localVarPath = `/grocery_lists/{id}/update-order-items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderItemsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a grocery list with preferred delivery window(s)
         * @param {string} id Grocery List ID
         * @param {UpdatePreferredDeliveryWindowsRequestDTO} updatePreferredDeliveryWindowsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreferredDeliveryWindows: async (id: string, updatePreferredDeliveryWindowsRequest: UpdatePreferredDeliveryWindowsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePreferredDeliveryWindows', 'id', id)
            // verify required parameter 'updatePreferredDeliveryWindowsRequest' is not null or undefined
            assertParamExists('updatePreferredDeliveryWindows', 'updatePreferredDeliveryWindowsRequest', updatePreferredDeliveryWindowsRequest)
            const localVarPath = `/grocery_lists/{id}/update-preferred-delivery-windows`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePreferredDeliveryWindowsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vendor preference for grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryVendorPreferenceRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorPreference: async (id: string, request: UpdateGroceryVendorPreferenceRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVendorPreference', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateVendorPreference', 'request', request)
            const localVarPath = `/grocery_lists/{id}/update-vendor-preference`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroceryListApi - functional programming interface
 * @export
 */
export const GroceryListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroceryListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Mark a grocery list as no longer offline shopping
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abortGroceryListOfflineShopping(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abortGroceryListOfflineShopping(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel a grocery order and mark all Meals that are contained in it as deleted
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Claim an order for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimGroceryOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimGroceryOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clear a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {ClearProductSearchPredictionRequestDTO} clearProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearProductSearchPrediction(id: string, clearProductSearchPredictionRequest: ClearProductSearchPredictionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearProductSearchPrediction(id, clearProductSearchPredictionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deselect a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {DeselectProductSearchPredictionRequestDTO} deselectProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deselectProductSearchPrediction(id: string, deselectProductSearchPredictionRequest: DeselectProductSearchPredictionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deselectProductSearchPrediction(id, deselectProductSearchPredictionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available delivery windows for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableDeliveryWindows(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListAvailableDeliveryWindowsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableDeliveryWindows(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available vendors for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableVendors(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListAvailableVendorsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableVendors(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroceryList(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroceryList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get grocery list fulfillment exceptions for a month
         * @param {string} [month] An optional year-month, defaults to the current month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroceryListFulfillmentExceptions(month?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListFulfillmentExceptionsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroceryListFulfillmentExceptions(month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get grocery list order
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroceryListOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroceryListOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get payments for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroceryListPayments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPaymentsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroceryListPayments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get grocery lists that satisfy query parameters.
         * @param {string} clientId Client ID
         * @param {Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>} states Grocery List states requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroceryLists(clientId: string, states: Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrdersResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroceryLists(clientId, states, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a grocery list as offline shopping initiated
         * @param {string} id Grocery List ID
         * @param {InitiateGroceryListOfflineShoppingRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateGroceryListOfflineShopping(id: string, request: InitiateGroceryListOfflineShoppingRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateGroceryListOfflineShopping(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a grocery list delivered with items and cost
         * @param {string} id Grocery List ID
         * @param {MarkGroceryListDeliveredRequestDTO} markGroceryListDeliveredRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markGroceryOrderDelivered(id: string, markGroceryListDeliveredRequest: MarkGroceryListDeliveredRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markGroceryOrderDelivered(id, markGroceryListDeliveredRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a grocery list as sent to vendor with items and cost
         * @param {string} id Grocery List ID
         * @param {MarkGroceryListSentToVendorRequestDTO} markGroceryListSentToVendorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markGroceryOrderSentToVendor(id: string, markGroceryListSentToVendorRequest: MarkGroceryListSentToVendorRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markGroceryOrderSentToVendor(id, markGroceryListSentToVendorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a grocery order as shopped
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markGroceryOrderShopped(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markGroceryOrderShopped(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a grocery order as shopping now
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markGroceryOrderShoppingNow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markGroceryOrderShoppingNow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Place an order for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placeGroceryListOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placeGroceryListOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Process (or re-process) product search for a grocery list
         * @param {string} id Grocery List ID
         * @param {ProcessProductSearchRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processProductSearch(id: string, request: ProcessProductSearchRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processProductSearch(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {RejectProductSearchPredictionRequestDTO} rejectProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectProductSearchPrediction(id: string, rejectProductSearchPredictionRequest: RejectProductSearchPredictionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectProductSearchPrediction(id, rejectProductSearchPredictionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove order items from a grocery list. Only items without an `ingredientId` or `text` can be removed
         * @param {string} id Grocery List ID
         * @param {RemoveOrderItemsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOrderItems(id: string, request: RemoveOrderItemsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOrderItems(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request product search for a grocery list order
         * @param {string} id Grocery List ID
         * @param {RequestProductSearchRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestGroceryListProductSearch(id: string, request: RequestProductSearchRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestGroceryListProductSearch(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request a new empty order for a client
         * @param {GroceryOrderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestGroceryOrder(request: GroceryOrderRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestGroceryOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestGroceryOrder(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Revert an order cancellation
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revertGroceryOrderCancellation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revertGroceryOrderCancellation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Select a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {SelectProductSearchPredictionRequestDTO} selectProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectProductSearchPrediction(id: string, selectProductSearchPredictionRequest: SelectProductSearchPredictionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectProductSearchPrediction(id, selectProductSearchPredictionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a grocery list as shopped offline
         * @param {string} id Grocery List ID
         * @param {ShopGroceryOrderOfflineRequestDTO} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopOrderOffline(id: string, request?: ShopGroceryOrderOfflineRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopOrderOffline(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update substitutions preference for grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListAllowSubstitutionsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllowSubstitutions(id: string, request: UpdateGroceryListAllowSubstitutionsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllowSubstitutions(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update grocery list estimated delivery window
         * @param {string} id Grocery List ID
         * @param {UpdateEstimatedDeliveryWindowRequestDTO} updateEstimatedDeliveryWindowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEstimatedDeliveryWindow(id: string, updateEstimatedDeliveryWindowRequest: UpdateEstimatedDeliveryWindowRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEstimatedDeliveryWindow(id, updateEstimatedDeliveryWindowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update external IDs for a grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListExternalIdsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExternalIds(id: string, request: UpdateGroceryListExternalIdsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExternalIds(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update grocery list
         * @param {string} id Grocery List ID
         * @param {UpdateGroceryListRequestDTO} updateGroceryListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroceryList(id: string, updateGroceryListRequest: UpdateGroceryListRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroceryList(id, updateGroceryListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a grocery list client cart cost details
         * @param {string} id Grocery List ID
         * @param {UpdateGroceryListClientCartCostDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroceryListClientCartCostDetails(id: string, request: UpdateGroceryListClientCartCostDetailsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroceryListClientCartCostDetails(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update grocery list client delivery details
         * @param {string} id Grocery List ID
         * @param {UpdateGroceryListClientDeliveryDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroceryListClientDeliveryDetails(id: string, request: UpdateGroceryListClientDeliveryDetailsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroceryListClientDeliveryDetails(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update grocery list fulfillment issue
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListFulfillmentIssueRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroceryListFulfillmentIssue(id: string, request: UpdateGroceryListFulfillmentIssueRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroceryListFulfillmentIssue(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update intent to use SNAP benefits for grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListIntentToUseSNAPRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIntentToUseSNAP(id: string, request: UpdateGroceryListIntentToUseSNAPRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIntentToUseSNAP(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update ordered or purchased items for grocery list
         * @param {string} id Grocery List ID
         * @param {UpdateOrderItemsRequestDTO} updateOrderItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderItems(id: string, updateOrderItemsRequest: UpdateOrderItemsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderItems(id, updateOrderItemsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a grocery list with preferred delivery window(s)
         * @param {string} id Grocery List ID
         * @param {UpdatePreferredDeliveryWindowsRequestDTO} updatePreferredDeliveryWindowsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreferredDeliveryWindows(id: string, updatePreferredDeliveryWindowsRequest: UpdatePreferredDeliveryWindowsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePreferredDeliveryWindows(id, updatePreferredDeliveryWindowsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update vendor preference for grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryVendorPreferenceRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVendorPreference(id: string, request: UpdateGroceryVendorPreferenceRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroceryListResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVendorPreference(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroceryListApi - factory interface
 * @export
 */
export const GroceryListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroceryListApiFp(configuration)
    return {
        /**
         * 
         * @summary Mark a grocery list as no longer offline shopping
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortGroceryListOfflineShopping(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.abortGroceryListOfflineShopping(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a grocery order and mark all Meals that are contained in it as deleted
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Claim an order for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGroceryOrder(id: string, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.claimGroceryOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clear a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {ClearProductSearchPredictionRequestDTO} clearProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearProductSearchPrediction(id: string, clearProductSearchPredictionRequest: ClearProductSearchPredictionRequestDTO, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.clearProductSearchPrediction(id, clearProductSearchPredictionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deselect a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {DeselectProductSearchPredictionRequestDTO} deselectProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deselectProductSearchPrediction(id: string, deselectProductSearchPredictionRequest: DeselectProductSearchPredictionRequestDTO, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.deselectProductSearchPrediction(id, deselectProductSearchPredictionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available delivery windows for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDeliveryWindows(id: string, options?: any): AxiosPromise<GroceryListAvailableDeliveryWindowsResponseDTO> {
            return localVarFp.getAvailableDeliveryWindows(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available vendors for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableVendors(id: string, options?: any): AxiosPromise<GroceryListAvailableVendorsResponseDTO> {
            return localVarFp.getAvailableVendors(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroceryList(id: string, options?: any): AxiosPromise<GroceryListResponseDTO> {
            return localVarFp.getGroceryList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get grocery list fulfillment exceptions for a month
         * @param {string} [month] An optional year-month, defaults to the current month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroceryListFulfillmentExceptions(month?: string, options?: any): AxiosPromise<GroceryListFulfillmentExceptionsResponseDTO> {
            return localVarFp.getGroceryListFulfillmentExceptions(month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get grocery list order
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroceryListOrder(id: string, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.getGroceryListOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payments for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroceryListPayments(id: string, options?: any): AxiosPromise<OrderPaymentsDTO> {
            return localVarFp.getGroceryListPayments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get grocery lists that satisfy query parameters.
         * @param {string} clientId Client ID
         * @param {Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>} states Grocery List states requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroceryLists(clientId: string, states: Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>, options?: any): AxiosPromise<GroceryListOrdersResponseDTO> {
            return localVarFp.getGroceryLists(clientId, states, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a grocery list as offline shopping initiated
         * @param {string} id Grocery List ID
         * @param {InitiateGroceryListOfflineShoppingRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateGroceryListOfflineShopping(id: string, request: InitiateGroceryListOfflineShoppingRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.initiateGroceryListOfflineShopping(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a grocery list delivered with items and cost
         * @param {string} id Grocery List ID
         * @param {MarkGroceryListDeliveredRequestDTO} markGroceryListDeliveredRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markGroceryOrderDelivered(id: string, markGroceryListDeliveredRequest: MarkGroceryListDeliveredRequestDTO, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.markGroceryOrderDelivered(id, markGroceryListDeliveredRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a grocery list as sent to vendor with items and cost
         * @param {string} id Grocery List ID
         * @param {MarkGroceryListSentToVendorRequestDTO} markGroceryListSentToVendorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markGroceryOrderSentToVendor(id: string, markGroceryListSentToVendorRequest: MarkGroceryListSentToVendorRequestDTO, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.markGroceryOrderSentToVendor(id, markGroceryListSentToVendorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a grocery order as shopped
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markGroceryOrderShopped(id: string, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.markGroceryOrderShopped(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a grocery order as shopping now
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markGroceryOrderShoppingNow(id: string, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.markGroceryOrderShoppingNow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Place an order for a grocery list
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeGroceryListOrder(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.placeGroceryListOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process (or re-process) product search for a grocery list
         * @param {string} id Grocery List ID
         * @param {ProcessProductSearchRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProductSearch(id: string, request: ProcessProductSearchRequestDTO, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.processProductSearch(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {RejectProductSearchPredictionRequestDTO} rejectProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectProductSearchPrediction(id: string, rejectProductSearchPredictionRequest: RejectProductSearchPredictionRequestDTO, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.rejectProductSearchPrediction(id, rejectProductSearchPredictionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove order items from a grocery list. Only items without an `ingredientId` or `text` can be removed
         * @param {string} id Grocery List ID
         * @param {RemoveOrderItemsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrderItems(id: string, request: RemoveOrderItemsRequestDTO, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.removeOrderItems(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request product search for a grocery list order
         * @param {string} id Grocery List ID
         * @param {RequestProductSearchRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGroceryListProductSearch(id: string, request: RequestProductSearchRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.requestGroceryListProductSearch(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a new empty order for a client
         * @param {GroceryOrderRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGroceryOrder(request: GroceryOrderRequestDTO, options?: any): AxiosPromise<RequestGroceryOrderResponseDTO> {
            return localVarFp.requestGroceryOrder(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Revert an order cancellation
         * @param {string} id Grocery List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertGroceryOrderCancellation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.revertGroceryOrderCancellation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Select a product search prediction for an order
         * @param {string} id Grocery List ID
         * @param {SelectProductSearchPredictionRequestDTO} selectProductSearchPredictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectProductSearchPrediction(id: string, selectProductSearchPredictionRequest: SelectProductSearchPredictionRequestDTO, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.selectProductSearchPrediction(id, selectProductSearchPredictionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a grocery list as shopped offline
         * @param {string} id Grocery List ID
         * @param {ShopGroceryOrderOfflineRequestDTO} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopOrderOffline(id: string, request?: ShopGroceryOrderOfflineRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.shopOrderOffline(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update substitutions preference for grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListAllowSubstitutionsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllowSubstitutions(id: string, request: UpdateGroceryListAllowSubstitutionsRequestDTO, options?: any): AxiosPromise<GroceryListResponseDTO> {
            return localVarFp.updateAllowSubstitutions(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update grocery list estimated delivery window
         * @param {string} id Grocery List ID
         * @param {UpdateEstimatedDeliveryWindowRequestDTO} updateEstimatedDeliveryWindowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstimatedDeliveryWindow(id: string, updateEstimatedDeliveryWindowRequest: UpdateEstimatedDeliveryWindowRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateEstimatedDeliveryWindow(id, updateEstimatedDeliveryWindowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update external IDs for a grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListExternalIdsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalIds(id: string, request: UpdateGroceryListExternalIdsRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateExternalIds(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update grocery list
         * @param {string} id Grocery List ID
         * @param {UpdateGroceryListRequestDTO} updateGroceryListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroceryList(id: string, updateGroceryListRequest: UpdateGroceryListRequestDTO, options?: any): AxiosPromise<GroceryListResponseDTO> {
            return localVarFp.updateGroceryList(id, updateGroceryListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a grocery list client cart cost details
         * @param {string} id Grocery List ID
         * @param {UpdateGroceryListClientCartCostDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroceryListClientCartCostDetails(id: string, request: UpdateGroceryListClientCartCostDetailsRequestDTO, options?: any): AxiosPromise<GroceryListResponseDTO> {
            return localVarFp.updateGroceryListClientCartCostDetails(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update grocery list client delivery details
         * @param {string} id Grocery List ID
         * @param {UpdateGroceryListClientDeliveryDetailsRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroceryListClientDeliveryDetails(id: string, request: UpdateGroceryListClientDeliveryDetailsRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateGroceryListClientDeliveryDetails(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update grocery list fulfillment issue
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListFulfillmentIssueRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroceryListFulfillmentIssue(id: string, request: UpdateGroceryListFulfillmentIssueRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateGroceryListFulfillmentIssue(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update intent to use SNAP benefits for grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryListIntentToUseSNAPRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntentToUseSNAP(id: string, request: UpdateGroceryListIntentToUseSNAPRequestDTO, options?: any): AxiosPromise<GroceryListResponseDTO> {
            return localVarFp.updateIntentToUseSNAP(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update ordered or purchased items for grocery list
         * @param {string} id Grocery List ID
         * @param {UpdateOrderItemsRequestDTO} updateOrderItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderItems(id: string, updateOrderItemsRequest: UpdateOrderItemsRequestDTO, options?: any): AxiosPromise<GroceryListOrderResponseDTO> {
            return localVarFp.updateOrderItems(id, updateOrderItemsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a grocery list with preferred delivery window(s)
         * @param {string} id Grocery List ID
         * @param {UpdatePreferredDeliveryWindowsRequestDTO} updatePreferredDeliveryWindowsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreferredDeliveryWindows(id: string, updatePreferredDeliveryWindowsRequest: UpdatePreferredDeliveryWindowsRequestDTO, options?: any): AxiosPromise<GroceryListResponseDTO> {
            return localVarFp.updatePreferredDeliveryWindows(id, updatePreferredDeliveryWindowsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vendor preference for grocery list
         * @param {string} id The Grocery List ID
         * @param {UpdateGroceryVendorPreferenceRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorPreference(id: string, request: UpdateGroceryVendorPreferenceRequestDTO, options?: any): AxiosPromise<GroceryListResponseDTO> {
            return localVarFp.updateVendorPreference(id, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroceryListApi - object-oriented interface
 * @export
 * @class GroceryListApi
 * @extends {BaseAPI}
 */
export class GroceryListApi extends BaseAPI {
    /**
     * 
     * @summary Mark a grocery list as no longer offline shopping
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public abortGroceryListOfflineShopping(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).abortGroceryListOfflineShopping(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a grocery order and mark all Meals that are contained in it as deleted
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public cancelOrder(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).cancelOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Claim an order for a grocery list
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public claimGroceryOrder(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).claimGroceryOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clear a product search prediction for an order
     * @param {string} id Grocery List ID
     * @param {ClearProductSearchPredictionRequestDTO} clearProductSearchPredictionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public clearProductSearchPrediction(id: string, clearProductSearchPredictionRequest: ClearProductSearchPredictionRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).clearProductSearchPrediction(id, clearProductSearchPredictionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deselect a product search prediction for an order
     * @param {string} id Grocery List ID
     * @param {DeselectProductSearchPredictionRequestDTO} deselectProductSearchPredictionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public deselectProductSearchPrediction(id: string, deselectProductSearchPredictionRequest: DeselectProductSearchPredictionRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).deselectProductSearchPrediction(id, deselectProductSearchPredictionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available delivery windows for a grocery list
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public getAvailableDeliveryWindows(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).getAvailableDeliveryWindows(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available vendors for a grocery list
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public getAvailableVendors(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).getAvailableVendors(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get grocery list
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public getGroceryList(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).getGroceryList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get grocery list fulfillment exceptions for a month
     * @param {string} [month] An optional year-month, defaults to the current month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public getGroceryListFulfillmentExceptions(month?: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).getGroceryListFulfillmentExceptions(month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get grocery list order
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public getGroceryListOrder(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).getGroceryListOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payments for a grocery list
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public getGroceryListPayments(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).getGroceryListPayments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get grocery lists that satisfy query parameters.
     * @param {string} clientId Client ID
     * @param {Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>} states Grocery List states requested.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public getGroceryLists(clientId: string, states: Array<'CREATED' | 'DEACTIVATED' | 'OFFLINE_SHOPPING_INITIATED' | 'ORDER_SHOPPED_OFFLINE' | 'ORDER_PLACED' | 'ORDER_REQUESTED' | 'PRODUCTS_FOUND' | 'PRODUCT_SEARCH_MANUAL_INTERVENTION_REQUIRED' | 'ORDER_CLAIMED' | 'ORDER_RELEASED' | 'ORDER_SENT_TO_VENDOR' | 'ORDER_SHOPPING_NOW' | 'ORDER_SHOPPED' | 'ORDER_DELIVERED' | 'ORDER_CANCELED'>, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).getGroceryLists(clientId, states, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a grocery list as offline shopping initiated
     * @param {string} id Grocery List ID
     * @param {InitiateGroceryListOfflineShoppingRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public initiateGroceryListOfflineShopping(id: string, request: InitiateGroceryListOfflineShoppingRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).initiateGroceryListOfflineShopping(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a grocery list delivered with items and cost
     * @param {string} id Grocery List ID
     * @param {MarkGroceryListDeliveredRequestDTO} markGroceryListDeliveredRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public markGroceryOrderDelivered(id: string, markGroceryListDeliveredRequest: MarkGroceryListDeliveredRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).markGroceryOrderDelivered(id, markGroceryListDeliveredRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a grocery list as sent to vendor with items and cost
     * @param {string} id Grocery List ID
     * @param {MarkGroceryListSentToVendorRequestDTO} markGroceryListSentToVendorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public markGroceryOrderSentToVendor(id: string, markGroceryListSentToVendorRequest: MarkGroceryListSentToVendorRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).markGroceryOrderSentToVendor(id, markGroceryListSentToVendorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a grocery order as shopped
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public markGroceryOrderShopped(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).markGroceryOrderShopped(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a grocery order as shopping now
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public markGroceryOrderShoppingNow(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).markGroceryOrderShoppingNow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Place an order for a grocery list
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public placeGroceryListOrder(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).placeGroceryListOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process (or re-process) product search for a grocery list
     * @param {string} id Grocery List ID
     * @param {ProcessProductSearchRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public processProductSearch(id: string, request: ProcessProductSearchRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).processProductSearch(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject a product search prediction for an order
     * @param {string} id Grocery List ID
     * @param {RejectProductSearchPredictionRequestDTO} rejectProductSearchPredictionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public rejectProductSearchPrediction(id: string, rejectProductSearchPredictionRequest: RejectProductSearchPredictionRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).rejectProductSearchPrediction(id, rejectProductSearchPredictionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove order items from a grocery list. Only items without an `ingredientId` or `text` can be removed
     * @param {string} id Grocery List ID
     * @param {RemoveOrderItemsRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public removeOrderItems(id: string, request: RemoveOrderItemsRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).removeOrderItems(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request product search for a grocery list order
     * @param {string} id Grocery List ID
     * @param {RequestProductSearchRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public requestGroceryListProductSearch(id: string, request: RequestProductSearchRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).requestGroceryListProductSearch(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a new empty order for a client
     * @param {GroceryOrderRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public requestGroceryOrder(request: GroceryOrderRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).requestGroceryOrder(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Revert an order cancellation
     * @param {string} id Grocery List ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public revertGroceryOrderCancellation(id: string, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).revertGroceryOrderCancellation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Select a product search prediction for an order
     * @param {string} id Grocery List ID
     * @param {SelectProductSearchPredictionRequestDTO} selectProductSearchPredictionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public selectProductSearchPrediction(id: string, selectProductSearchPredictionRequest: SelectProductSearchPredictionRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).selectProductSearchPrediction(id, selectProductSearchPredictionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a grocery list as shopped offline
     * @param {string} id Grocery List ID
     * @param {ShopGroceryOrderOfflineRequestDTO} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public shopOrderOffline(id: string, request?: ShopGroceryOrderOfflineRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).shopOrderOffline(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update substitutions preference for grocery list
     * @param {string} id The Grocery List ID
     * @param {UpdateGroceryListAllowSubstitutionsRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updateAllowSubstitutions(id: string, request: UpdateGroceryListAllowSubstitutionsRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updateAllowSubstitutions(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update grocery list estimated delivery window
     * @param {string} id Grocery List ID
     * @param {UpdateEstimatedDeliveryWindowRequestDTO} updateEstimatedDeliveryWindowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updateEstimatedDeliveryWindow(id: string, updateEstimatedDeliveryWindowRequest: UpdateEstimatedDeliveryWindowRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updateEstimatedDeliveryWindow(id, updateEstimatedDeliveryWindowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update external IDs for a grocery list
     * @param {string} id The Grocery List ID
     * @param {UpdateGroceryListExternalIdsRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updateExternalIds(id: string, request: UpdateGroceryListExternalIdsRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updateExternalIds(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update grocery list
     * @param {string} id Grocery List ID
     * @param {UpdateGroceryListRequestDTO} updateGroceryListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updateGroceryList(id: string, updateGroceryListRequest: UpdateGroceryListRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updateGroceryList(id, updateGroceryListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a grocery list client cart cost details
     * @param {string} id Grocery List ID
     * @param {UpdateGroceryListClientCartCostDetailsRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updateGroceryListClientCartCostDetails(id: string, request: UpdateGroceryListClientCartCostDetailsRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updateGroceryListClientCartCostDetails(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update grocery list client delivery details
     * @param {string} id Grocery List ID
     * @param {UpdateGroceryListClientDeliveryDetailsRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updateGroceryListClientDeliveryDetails(id: string, request: UpdateGroceryListClientDeliveryDetailsRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updateGroceryListClientDeliveryDetails(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update grocery list fulfillment issue
     * @param {string} id The Grocery List ID
     * @param {UpdateGroceryListFulfillmentIssueRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updateGroceryListFulfillmentIssue(id: string, request: UpdateGroceryListFulfillmentIssueRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updateGroceryListFulfillmentIssue(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update intent to use SNAP benefits for grocery list
     * @param {string} id The Grocery List ID
     * @param {UpdateGroceryListIntentToUseSNAPRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updateIntentToUseSNAP(id: string, request: UpdateGroceryListIntentToUseSNAPRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updateIntentToUseSNAP(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update ordered or purchased items for grocery list
     * @param {string} id Grocery List ID
     * @param {UpdateOrderItemsRequestDTO} updateOrderItemsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updateOrderItems(id: string, updateOrderItemsRequest: UpdateOrderItemsRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updateOrderItems(id, updateOrderItemsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a grocery list with preferred delivery window(s)
     * @param {string} id Grocery List ID
     * @param {UpdatePreferredDeliveryWindowsRequestDTO} updatePreferredDeliveryWindowsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updatePreferredDeliveryWindows(id: string, updatePreferredDeliveryWindowsRequest: UpdatePreferredDeliveryWindowsRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updatePreferredDeliveryWindows(id, updatePreferredDeliveryWindowsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vendor preference for grocery list
     * @param {string} id The Grocery List ID
     * @param {UpdateGroceryVendorPreferenceRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroceryListApi
     */
    public updateVendorPreference(id: string, request: UpdateGroceryVendorPreferenceRequestDTO, options?: AxiosRequestConfig) {
        return GroceryListApiFp(this.configuration).updateVendorPreference(id, request, options).then((request) => request(this.axios, this.basePath));
    }
}
