import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectDeliveryDetails } from 'store/deliveryDetails/selectors';
import {
    selectCurrentSubscription,
    selectIsSignedIn,
    selectUserData,
    selectUserPrimaryEmail,
} from 'store/user/selectors';
import { getIdentityDetailsFromUserData, useIdentify } from 'utils/tracking/identify';

const usePluginIdentifierOnLogin = (): void => {
    const isSignedIn = useSelector(selectIsSignedIn);
    const userData = useSelector(selectUserData);
    const deliveryDetails = useSelector(selectDeliveryDetails);
    const currentSubscription = useSelector(selectCurrentSubscription);
    const primaryEmail = useSelector(selectUserPrimaryEmail);

    const identify = useIdentify();
    const identifyDetails = useMemo(
        () => getIdentityDetailsFromUserData(userData, deliveryDetails, currentSubscription, primaryEmail),
        [userData, deliveryDetails, currentSubscription, primaryEmail]
    );

    useEffect(() => {
        if (isSignedIn && identify && identifyDetails) {
            identify(identifyDetails);
        }
    }, [isSignedIn, identify, identifyDetails]);
};

export default usePluginIdentifierOnLogin;
