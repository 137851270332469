/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { EducationProgramProgressResponseDTO } from 'API';
import type { ApplicationState } from 'store';
import { action } from 'typesafe-actions';
import type { MetaActions } from '../../../store/meta/store';
import type { Reducer } from 'redux';

export enum EducationActionTypes {
    SET_PROGRESS = '@@education/SET_PROGRESS',
    GET_PROGRESS = '@@education/UPDATE_PROGRESS',
}

export const setProgress = (data: EducationProgramProgressResponseDTO) =>
    action(EducationActionTypes.SET_PROGRESS, data);
export const getProgress = () => action(EducationActionTypes.GET_PROGRESS);

export type EducationActions = ReturnType<typeof setProgress> | ReturnType<typeof getProgress>;

// REDUCER
export interface EducationState {
    enrolled: boolean;
    progress: EducationProgramProgressResponseDTO | null;
}

export const initialState: EducationState = {
    enrolled: false,
    progress: null,
};

export const educationReducer: Reducer<EducationState, EducationActions | MetaActions> = (
    state = initialState,
    action
): EducationState => {
    switch (action.type) {
        case EducationActionTypes.GET_PROGRESS:
            return state;
        case EducationActionTypes.SET_PROGRESS:
            return { ...state, progress: action.payload, enrolled: true };
        default:
            return state;
    }
};

// SELECTORS
type EducationSlice = Pick<ApplicationState, 'education'>;

export const isEnrolled = (state: EducationSlice) => state.education.enrolled;
export const selectEducationProgress = (state: EducationSlice) => state.education.progress;
export const selectEducationProgramProgressId = (state: EducationSlice) => state.education.progress?.id;
