/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddReferralClientIntakeIdRequestDTO } from '../models';
// @ts-ignore
import { CreateReferralRequestDTO } from '../models';
// @ts-ignore
import { ReferralResponseDTO } from '../models';
// @ts-ignore
import { ReferralsResponseDTO } from '../models';
// @ts-ignore
import { SearchReferralsResponseDTO } from '../models';
// @ts-ignore
import { UpdateReferralRequestDTO } from '../models';
/**
 * ReferralApi - axios parameter creator
 * @export
 */
export const ReferralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add referral client intake id
         * @param {string} id 
         * @param {AddReferralClientIntakeIdRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReferralClientIntakeId: async (id: string, request: AddReferralClientIntakeIdRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addReferralClientIntakeId', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('addReferralClientIntakeId', 'request', request)
            const localVarPath = `/referrals/{id}/add-client-intake-id`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Referral
         * @param {CreateReferralRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferral: async (request: CreateReferralRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createReferral', 'request', request)
            const localVarPath = `/referrals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get referral by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferral: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReferral', 'id', id)
            const localVarPath = `/referrals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Referrals For Client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralsForClient: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getReferralsForClient', 'clientId', clientId)
            const localVarPath = `/referrals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search referrals
         * @param {string} [clientIntakeId] Optionally filter to a certain client intake
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {string} [firstNameQuery] 
         * @param {string} [lastNameQuery] 
         * @param {string} [emailQuery] 
         * @param {string} [referringProviderFirstNameQuery] 
         * @param {string} [referringProviderLastNameQuery] 
         * @param {string} [referringProviderHealthSystemQuery] 
         * @param {string} [referringProviderAddressQuery] 
         * @param {string} [primaryReferrerFirstNameQuery] 
         * @param {string} [primaryReferrerLastNameQuery] 
         * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Empty or null defaults to all values
         * @param {Array<string>} [cohortExternalIds] Empty or null returns all cohort external ids
         * @param {Array<'ELIGIBILITY_FILE' | 'PROVIDER'>} [types] Empty or null defaults to all values
         * @param {'DATE_OF_REFERRAL'} [sortKey] Property to sort by, defaults to date of referral
         * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
         * @param {number} [limit] Number of records to include, defaults to 50
         * @param {number} [offset] Number of records to skip, defaults to 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchReferrals: async (clientIntakeId?: string, clientId?: string, firstNameQuery?: string, lastNameQuery?: string, emailQuery?: string, referringProviderFirstNameQuery?: string, referringProviderLastNameQuery?: string, referringProviderHealthSystemQuery?: string, referringProviderAddressQuery?: string, primaryReferrerFirstNameQuery?: string, primaryReferrerLastNameQuery?: string, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, cohortExternalIds?: Array<string>, types?: Array<'ELIGIBILITY_FILE' | 'PROVIDER'>, sortKey?: 'DATE_OF_REFERRAL', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/referrals/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientIntakeId !== undefined) {
                localVarQueryParameter['clientIntakeId'] = clientIntakeId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (firstNameQuery !== undefined) {
                localVarQueryParameter['firstNameQuery'] = firstNameQuery;
            }

            if (lastNameQuery !== undefined) {
                localVarQueryParameter['lastNameQuery'] = lastNameQuery;
            }

            if (emailQuery !== undefined) {
                localVarQueryParameter['emailQuery'] = emailQuery;
            }

            if (referringProviderFirstNameQuery !== undefined) {
                localVarQueryParameter['referringProviderFirstNameQuery'] = referringProviderFirstNameQuery;
            }

            if (referringProviderLastNameQuery !== undefined) {
                localVarQueryParameter['referringProviderLastNameQuery'] = referringProviderLastNameQuery;
            }

            if (referringProviderHealthSystemQuery !== undefined) {
                localVarQueryParameter['referringProviderHealthSystemQuery'] = referringProviderHealthSystemQuery;
            }

            if (referringProviderAddressQuery !== undefined) {
                localVarQueryParameter['referringProviderAddressQuery'] = referringProviderAddressQuery;
            }

            if (primaryReferrerFirstNameQuery !== undefined) {
                localVarQueryParameter['primaryReferrerFirstNameQuery'] = primaryReferrerFirstNameQuery;
            }

            if (primaryReferrerLastNameQuery !== undefined) {
                localVarQueryParameter['primaryReferrerLastNameQuery'] = primaryReferrerLastNameQuery;
            }

            if (cohorts) {
                localVarQueryParameter['cohorts'] = cohorts;
            }

            if (cohortExternalIds) {
                localVarQueryParameter['cohortExternalIds'] = cohortExternalIds;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (sortKey !== undefined) {
                localVarQueryParameter['sortKey'] = sortKey;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing referral
         * @param {string} id The Referral ID
         * @param {UpdateReferralRequestDTO} request The updated Referral resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferral: async (id: string, request: UpdateReferralRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReferral', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateReferral', 'request', request)
            const localVarPath = `/referrals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferralApi - functional programming interface
 * @export
 */
export const ReferralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add referral client intake id
         * @param {string} id 
         * @param {AddReferralClientIntakeIdRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReferralClientIntakeId(id: string, request: AddReferralClientIntakeIdRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addReferralClientIntakeId(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Referral
         * @param {CreateReferralRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReferral(request: CreateReferralRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReferral(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get referral by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferral(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferral(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Referrals For Client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralsForClient(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralsForClient(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search referrals
         * @param {string} [clientIntakeId] Optionally filter to a certain client intake
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {string} [firstNameQuery] 
         * @param {string} [lastNameQuery] 
         * @param {string} [emailQuery] 
         * @param {string} [referringProviderFirstNameQuery] 
         * @param {string} [referringProviderLastNameQuery] 
         * @param {string} [referringProviderHealthSystemQuery] 
         * @param {string} [referringProviderAddressQuery] 
         * @param {string} [primaryReferrerFirstNameQuery] 
         * @param {string} [primaryReferrerLastNameQuery] 
         * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Empty or null defaults to all values
         * @param {Array<string>} [cohortExternalIds] Empty or null returns all cohort external ids
         * @param {Array<'ELIGIBILITY_FILE' | 'PROVIDER'>} [types] Empty or null defaults to all values
         * @param {'DATE_OF_REFERRAL'} [sortKey] Property to sort by, defaults to date of referral
         * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
         * @param {number} [limit] Number of records to include, defaults to 50
         * @param {number} [offset] Number of records to skip, defaults to 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchReferrals(clientIntakeId?: string, clientId?: string, firstNameQuery?: string, lastNameQuery?: string, emailQuery?: string, referringProviderFirstNameQuery?: string, referringProviderLastNameQuery?: string, referringProviderHealthSystemQuery?: string, referringProviderAddressQuery?: string, primaryReferrerFirstNameQuery?: string, primaryReferrerLastNameQuery?: string, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, cohortExternalIds?: Array<string>, types?: Array<'ELIGIBILITY_FILE' | 'PROVIDER'>, sortKey?: 'DATE_OF_REFERRAL', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchReferralsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchReferrals(clientIntakeId, clientId, firstNameQuery, lastNameQuery, emailQuery, referringProviderFirstNameQuery, referringProviderLastNameQuery, referringProviderHealthSystemQuery, referringProviderAddressQuery, primaryReferrerFirstNameQuery, primaryReferrerLastNameQuery, cohorts, cohortExternalIds, types, sortKey, sortDir, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing referral
         * @param {string} id The Referral ID
         * @param {UpdateReferralRequestDTO} request The updated Referral resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReferral(id: string, request: UpdateReferralRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReferral(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferralApi - factory interface
 * @export
 */
export const ReferralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferralApiFp(configuration)
    return {
        /**
         * 
         * @summary Add referral client intake id
         * @param {string} id 
         * @param {AddReferralClientIntakeIdRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReferralClientIntakeId(id: string, request: AddReferralClientIntakeIdRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addReferralClientIntakeId(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Referral
         * @param {CreateReferralRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferral(request: CreateReferralRequestDTO, options?: any): AxiosPromise<ReferralResponseDTO> {
            return localVarFp.createReferral(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get referral by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferral(id: string, options?: any): AxiosPromise<ReferralResponseDTO> {
            return localVarFp.getReferral(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Referrals For Client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralsForClient(clientId: string, options?: any): AxiosPromise<ReferralsResponseDTO> {
            return localVarFp.getReferralsForClient(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search referrals
         * @param {string} [clientIntakeId] Optionally filter to a certain client intake
         * @param {string} [clientId] Optionally filter to a certain client
         * @param {string} [firstNameQuery] 
         * @param {string} [lastNameQuery] 
         * @param {string} [emailQuery] 
         * @param {string} [referringProviderFirstNameQuery] 
         * @param {string} [referringProviderLastNameQuery] 
         * @param {string} [referringProviderHealthSystemQuery] 
         * @param {string} [referringProviderAddressQuery] 
         * @param {string} [primaryReferrerFirstNameQuery] 
         * @param {string} [primaryReferrerLastNameQuery] 
         * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Empty or null defaults to all values
         * @param {Array<string>} [cohortExternalIds] Empty or null returns all cohort external ids
         * @param {Array<'ELIGIBILITY_FILE' | 'PROVIDER'>} [types] Empty or null defaults to all values
         * @param {'DATE_OF_REFERRAL'} [sortKey] Property to sort by, defaults to date of referral
         * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
         * @param {number} [limit] Number of records to include, defaults to 50
         * @param {number} [offset] Number of records to skip, defaults to 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchReferrals(clientIntakeId?: string, clientId?: string, firstNameQuery?: string, lastNameQuery?: string, emailQuery?: string, referringProviderFirstNameQuery?: string, referringProviderLastNameQuery?: string, referringProviderHealthSystemQuery?: string, referringProviderAddressQuery?: string, primaryReferrerFirstNameQuery?: string, primaryReferrerLastNameQuery?: string, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, cohortExternalIds?: Array<string>, types?: Array<'ELIGIBILITY_FILE' | 'PROVIDER'>, sortKey?: 'DATE_OF_REFERRAL', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: any): AxiosPromise<SearchReferralsResponseDTO> {
            return localVarFp.searchReferrals(clientIntakeId, clientId, firstNameQuery, lastNameQuery, emailQuery, referringProviderFirstNameQuery, referringProviderLastNameQuery, referringProviderHealthSystemQuery, referringProviderAddressQuery, primaryReferrerFirstNameQuery, primaryReferrerLastNameQuery, cohorts, cohortExternalIds, types, sortKey, sortDir, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing referral
         * @param {string} id The Referral ID
         * @param {UpdateReferralRequestDTO} request The updated Referral resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferral(id: string, request: UpdateReferralRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateReferral(id, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferralApi - object-oriented interface
 * @export
 * @class ReferralApi
 * @extends {BaseAPI}
 */
export class ReferralApi extends BaseAPI {
    /**
     * 
     * @summary Add referral client intake id
     * @param {string} id 
     * @param {AddReferralClientIntakeIdRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public addReferralClientIntakeId(id: string, request: AddReferralClientIntakeIdRequestDTO, options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).addReferralClientIntakeId(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Referral
     * @param {CreateReferralRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public createReferral(request: CreateReferralRequestDTO, options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).createReferral(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get referral by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public getReferral(id: string, options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).getReferral(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Referrals For Client
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public getReferralsForClient(clientId: string, options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).getReferralsForClient(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search referrals
     * @param {string} [clientIntakeId] Optionally filter to a certain client intake
     * @param {string} [clientId] Optionally filter to a certain client
     * @param {string} [firstNameQuery] 
     * @param {string} [lastNameQuery] 
     * @param {string} [emailQuery] 
     * @param {string} [referringProviderFirstNameQuery] 
     * @param {string} [referringProviderLastNameQuery] 
     * @param {string} [referringProviderHealthSystemQuery] 
     * @param {string} [referringProviderAddressQuery] 
     * @param {string} [primaryReferrerFirstNameQuery] 
     * @param {string} [primaryReferrerLastNameQuery] 
     * @param {Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>} [cohorts] Empty or null defaults to all values
     * @param {Array<string>} [cohortExternalIds] Empty or null returns all cohort external ids
     * @param {Array<'ELIGIBILITY_FILE' | 'PROVIDER'>} [types] Empty or null defaults to all values
     * @param {'DATE_OF_REFERRAL'} [sortKey] Property to sort by, defaults to date of referral
     * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
     * @param {number} [limit] Number of records to include, defaults to 50
     * @param {number} [offset] Number of records to skip, defaults to 0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public searchReferrals(clientIntakeId?: string, clientId?: string, firstNameQuery?: string, lastNameQuery?: string, emailQuery?: string, referringProviderFirstNameQuery?: string, referringProviderLastNameQuery?: string, referringProviderHealthSystemQuery?: string, referringProviderAddressQuery?: string, primaryReferrerFirstNameQuery?: string, primaryReferrerLastNameQuery?: string, cohorts?: Array<'BCBS_AZ' | 'BCBS_AZ_GROUP_50' | 'COMMON_SPIRIT' | 'CUSTOM_MADE' | 'DEMO' | 'EVRY' | 'FROEDTERT' | 'GA_FOODS' | 'GEISINGER' | 'HEALTH_ALIGN' | 'MEDICAL_ASSOCIATES' | 'OPTIM' | 'PUBLIC' | 'SEASON_EMPLOYEE' | 'STEWARD_HYPERTENSION' | 'STEWARD_FLEX_SERVICES' | 'UNITED_HEALTHCARE' | 'UT_SOUTHWESTERN'>, cohortExternalIds?: Array<string>, types?: Array<'ELIGIBILITY_FILE' | 'PROVIDER'>, sortKey?: 'DATE_OF_REFERRAL', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).searchReferrals(clientIntakeId, clientId, firstNameQuery, lastNameQuery, emailQuery, referringProviderFirstNameQuery, referringProviderLastNameQuery, referringProviderHealthSystemQuery, referringProviderAddressQuery, primaryReferrerFirstNameQuery, primaryReferrerLastNameQuery, cohorts, cohortExternalIds, types, sortKey, sortDir, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing referral
     * @param {string} id The Referral ID
     * @param {UpdateReferralRequestDTO} request The updated Referral resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public updateReferral(id: string, request: UpdateReferralRequestDTO, options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).updateReferral(id, request, options).then((request) => request(this.axios, this.basePath));
    }
}
