/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseRecipesResponseDTO } from '../models';
// @ts-ignore
import { CreateRecipeResponseDTO } from '../models';
// @ts-ignore
import { CreateUpdateRecipeDTO } from '../models';
// @ts-ignore
import { EstimatedCostDTO } from '../models';
// @ts-ignore
import { GetRecipeIngredientsResponseDTO } from '../models';
// @ts-ignore
import { GetRecipeResponseDTO } from '../models';
// @ts-ignore
import { ImageUploadUrlResponseDTO } from '../models';
// @ts-ignore
import { RecipeCalculationRequestDTO } from '../models';
// @ts-ignore
import { RecipeDietModuleConfigurationResponseDTO } from '../models';
// @ts-ignore
import { RecipeDietTagRequestDTO } from '../models';
// @ts-ignore
import { RecipeInventoryRequestDTO } from '../models';
// @ts-ignore
import { RecipeInventoryResponseDTO } from '../models';
// @ts-ignore
import { RecipeNutritionDTO } from '../models';
// @ts-ignore
import { RecipeShovelerStrategiesResponseDTO } from '../models';
// @ts-ignore
import { RecipeShovelersResponseDTO } from '../models';
// @ts-ignore
import { RecipeStatusDTO } from '../models';
// @ts-ignore
import { RecipesCostRequestDTO } from '../models';
// @ts-ignore
import { RecipesCostResponseDTO } from '../models';
// @ts-ignore
import { RecipesDietTagResponseDTO } from '../models';
// @ts-ignore
import { RecipesResponseDTO } from '../models';
// @ts-ignore
import { SearchRecipesRequestDTO } from '../models';
// @ts-ignore
import { SearchRecipesResponseDTO } from '../models';
// @ts-ignore
import { UpdateRecipesImagesRequestDTO } from '../models';
/**
 * RecipeApi - axios parameter creator
 * @export
 */
export const RecipeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate the estimated cost for a recipe. Use cost refers to the cost when only considering cost of each ingredient actually _used_ in the recipe,rather than the cost of the amount purchased. Purchase cost refers to the cost when considering total cost of each product needed to buy in order to make the recipe. 
         * @param {RecipeCalculationRequestDTO} data The ingredients and serving count for which to compute true cost.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateEstimatedCost: async (data: RecipeCalculationRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('calculateEstimatedCost', 'data', data)
            const localVarPath = `/recipes/estimated-cost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate the nutritional information for a recipe
         * @param {RecipeCalculationRequestDTO} data The ingredients and serving count for which to compute nutritional content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRecipeNutrition: async (data: RecipeCalculationRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('calculateRecipeNutrition', 'data', data)
            const localVarPath = `/recipes/nutrition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new Recipe
         * @param {CreateUpdateRecipeDTO} recipe Recipe resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipe: async (recipe: CreateUpdateRecipeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipe' is not null or undefined
            assertParamExists('createRecipe', 'recipe', recipe)
            const localVarPath = `/recipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recipe, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an image upload URL for a Recipe
         * @param {string} id The Recipe ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRecipeImageUploadUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateRecipeImageUploadUrl', 'id', id)
            const localVarPath = `/recipes/{id}/image-upload-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipe shovelers strategies for client
         * @param {string} clientId 
         * @param {number} [minimumRecipesPerShoveler] Optionally provide to only return shoveler strategies that meet the minimum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveRecipeShovelerStrategies: async (clientId: string, minimumRecipesPerShoveler?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getActiveRecipeShovelerStrategies', 'clientId', clientId)
            const localVarPath = `/recipes/shoveler-strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (minimumRecipesPerShoveler !== undefined) {
                localVarQueryParameter['minimumRecipesPerShoveler'] = minimumRecipesPerShoveler;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipe shovelers for client
         * @param {string} clientId 
         * @param {boolean} excludeCostData Optionally exclude cost data from response. Recipe cost data can be fetched from another endpoint. Defaults to false
         * @param {Array<string>} [shovelerStrategyNames] Optionally provide to only return specified shovelers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveRecipeShovelers: async (clientId: string, excludeCostData: boolean, shovelerStrategyNames?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getActiveRecipeShovelers', 'clientId', clientId)
            // verify required parameter 'excludeCostData' is not null or undefined
            assertParamExists('getActiveRecipeShovelers', 'excludeCostData', excludeCostData)
            const localVarPath = `/recipes/shovelers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (shovelerStrategyNames) {
                localVarQueryParameter['shovelerStrategyNames'] = shovelerStrategyNames;
            }

            if (excludeCostData !== undefined) {
                localVarQueryParameter['excludeCostData'] = excludeCostData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Recipe
         * @param {string} id The Recipe ID
         * @param {string} [clientId] If included, recipe returned using dynamic local product pricing based on the client address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipe: async (id: string, clientId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecipe', 'id', id)
            const localVarPath = `/recipes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get diet module configuration, which includes each Diet and what Diet Modules are required to satisfy that Diet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeDietModuleConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recipes/diet-module-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for ingredients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeIngredients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recipes/ingredients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipe inventory for parameters
         * @param {RecipeInventoryRequestDTO} recipe Recipe inventory request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeInventory: async (recipe: RecipeInventoryRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipe' is not null or undefined
            assertParamExists('getRecipeInventory', 'recipe', recipe)
            const localVarPath = `/recipes/inventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recipe, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipe recommendations for a client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeRecommendationsForClient: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getRecipeRecommendationsForClient', 'clientId', clientId)
            const localVarPath = `/recipes/client-recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipes
         * @param {Array<string>} recipeIds 
         * @param {string} [clientId] If included, recipes returned using dynamic local product pricing based on the client address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipes: async (recipeIds: Array<string>, clientId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipeIds' is not null or undefined
            assertParamExists('getRecipes', 'recipeIds', recipeIds)
            const localVarPath = `/recipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (recipeIds) {
                localVarQueryParameter['recipeIds'] = recipeIds;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Recipes Cost for Client
         * @param {RecipesCostRequestDTO} request Recipes Cost Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesCostForClient: async (request: RecipesCostRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('getRecipesCostForClient', 'request', request)
            const localVarPath = `/recipes/cost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search recipes
         * @param {SearchRecipesRequestDTO} searchRecipesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRecipes: async (searchRecipesRequest: SearchRecipesRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRecipesRequest' is not null or undefined
            assertParamExists('searchRecipes', 'searchRecipesRequest', searchRecipesRequest)
            const localVarPath = `/recipes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRecipesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate diet tags (deprecated) and diet modules for recipes
         * @param {RecipeDietTagRequestDTO} recipeDietTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagDiets: async (recipeDietTagRequest: RecipeDietTagRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipeDietTagRequest' is not null or undefined
            assertParamExists('tagDiets', 'recipeDietTagRequest', recipeDietTagRequest)
            const localVarPath = `/recipes/tag-diets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recipeDietTagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing recipe
         * @param {string} id The Recipe ID
         * @param {CreateUpdateRecipeDTO} recipe The updated Recipe resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipe: async (id: string, recipe: CreateUpdateRecipeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRecipe', 'id', id)
            // verify required parameter 'recipe' is not null or undefined
            assertParamExists('updateRecipe', 'recipe', recipe)
            const localVarPath = `/recipes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recipe, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update recipe status
         * @param {string} id The Recipe ID
         * @param {RecipeStatusDTO} recipe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeStatus: async (id: string, recipe: RecipeStatusDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRecipeStatus', 'id', id)
            // verify required parameter 'recipe' is not null or undefined
            assertParamExists('updateRecipeStatus', 'recipe', recipe)
            const localVarPath = `/recipes/{id}/update-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recipe, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update images for multiple recipes at once
         * @param {UpdateRecipesImagesRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipesImages: async (request: UpdateRecipesImagesRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateRecipesImages', 'request', request)
            const localVarPath = `/recipes/update-images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload an image for an Recipe
         * @param {string} id The Recipe ID
         * @param {File} image The image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRecipeImage: async (id: string, image: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadRecipeImage', 'id', id)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('uploadRecipeImage', 'image', image)
            const localVarPath = `/recipes/{id}/upload-image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecipeApi - functional programming interface
 * @export
 */
export const RecipeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecipeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculate the estimated cost for a recipe. Use cost refers to the cost when only considering cost of each ingredient actually _used_ in the recipe,rather than the cost of the amount purchased. Purchase cost refers to the cost when considering total cost of each product needed to buy in order to make the recipe. 
         * @param {RecipeCalculationRequestDTO} data The ingredients and serving count for which to compute true cost.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateEstimatedCost(data: RecipeCalculationRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimatedCostDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateEstimatedCost(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate the nutritional information for a recipe
         * @param {RecipeCalculationRequestDTO} data The ingredients and serving count for which to compute nutritional content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateRecipeNutrition(data: RecipeCalculationRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeNutritionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateRecipeNutrition(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new Recipe
         * @param {CreateUpdateRecipeDTO} recipe Recipe resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecipe(recipe: CreateUpdateRecipeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRecipeResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecipe(recipe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an image upload URL for a Recipe
         * @param {string} id The Recipe ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateRecipeImageUploadUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageUploadUrlResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateRecipeImageUploadUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipe shovelers strategies for client
         * @param {string} clientId 
         * @param {number} [minimumRecipesPerShoveler] Optionally provide to only return shoveler strategies that meet the minimum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveRecipeShovelerStrategies(clientId: string, minimumRecipesPerShoveler?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeShovelerStrategiesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveRecipeShovelerStrategies(clientId, minimumRecipesPerShoveler, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipe shovelers for client
         * @param {string} clientId 
         * @param {boolean} excludeCostData Optionally exclude cost data from response. Recipe cost data can be fetched from another endpoint. Defaults to false
         * @param {Array<string>} [shovelerStrategyNames] Optionally provide to only return specified shovelers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveRecipeShovelers(clientId: string, excludeCostData: boolean, shovelerStrategyNames?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeShovelersResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveRecipeShovelers(clientId, excludeCostData, shovelerStrategyNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Recipe
         * @param {string} id The Recipe ID
         * @param {string} [clientId] If included, recipe returned using dynamic local product pricing based on the client address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipe(id: string, clientId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecipeResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipe(id, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get diet module configuration, which includes each Diet and what Diet Modules are required to satisfy that Diet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeDietModuleConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeDietModuleConfigurationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeDietModuleConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for ingredients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeIngredients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecipeIngredientsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeIngredients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipe inventory for parameters
         * @param {RecipeInventoryRequestDTO} recipe Recipe inventory request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeInventory(recipe: RecipeInventoryRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeInventoryResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeInventory(recipe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipe recommendations for a client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeRecommendationsForClient(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeRecommendationsForClient(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipes
         * @param {Array<string>} recipeIds 
         * @param {string} [clientId] If included, recipes returned using dynamic local product pricing based on the client address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipes(recipeIds: Array<string>, clientId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseRecipesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipes(recipeIds, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Recipes Cost for Client
         * @param {RecipesCostRequestDTO} request Recipes Cost Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipesCostForClient(request: RecipesCostRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipesCostResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipesCostForClient(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search recipes
         * @param {SearchRecipesRequestDTO} searchRecipesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRecipes(searchRecipesRequest: SearchRecipesRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchRecipesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRecipes(searchRecipesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate diet tags (deprecated) and diet modules for recipes
         * @param {RecipeDietTagRequestDTO} recipeDietTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagDiets(recipeDietTagRequest: RecipeDietTagRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipesDietTagResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagDiets(recipeDietTagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing recipe
         * @param {string} id The Recipe ID
         * @param {CreateUpdateRecipeDTO} recipe The updated Recipe resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecipe(id: string, recipe: CreateUpdateRecipeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecipe(id, recipe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update recipe status
         * @param {string} id The Recipe ID
         * @param {RecipeStatusDTO} recipe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecipeStatus(id: string, recipe: RecipeStatusDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecipeStatus(id, recipe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update images for multiple recipes at once
         * @param {UpdateRecipesImagesRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecipesImages(request: UpdateRecipesImagesRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecipesImages(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload an image for an Recipe
         * @param {string} id The Recipe ID
         * @param {File} image The image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadRecipeImage(id: string, image: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadRecipeImage(id, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecipeApi - factory interface
 * @export
 */
export const RecipeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecipeApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculate the estimated cost for a recipe. Use cost refers to the cost when only considering cost of each ingredient actually _used_ in the recipe,rather than the cost of the amount purchased. Purchase cost refers to the cost when considering total cost of each product needed to buy in order to make the recipe. 
         * @param {RecipeCalculationRequestDTO} data The ingredients and serving count for which to compute true cost.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateEstimatedCost(data: RecipeCalculationRequestDTO, options?: any): AxiosPromise<EstimatedCostDTO> {
            return localVarFp.calculateEstimatedCost(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate the nutritional information for a recipe
         * @param {RecipeCalculationRequestDTO} data The ingredients and serving count for which to compute nutritional content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRecipeNutrition(data: RecipeCalculationRequestDTO, options?: any): AxiosPromise<RecipeNutritionDTO> {
            return localVarFp.calculateRecipeNutrition(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new Recipe
         * @param {CreateUpdateRecipeDTO} recipe Recipe resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipe(recipe: CreateUpdateRecipeDTO, options?: any): AxiosPromise<CreateRecipeResponseDTO> {
            return localVarFp.createRecipe(recipe, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an image upload URL for a Recipe
         * @param {string} id The Recipe ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRecipeImageUploadUrl(id: string, options?: any): AxiosPromise<ImageUploadUrlResponseDTO> {
            return localVarFp.generateRecipeImageUploadUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipe shovelers strategies for client
         * @param {string} clientId 
         * @param {number} [minimumRecipesPerShoveler] Optionally provide to only return shoveler strategies that meet the minimum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveRecipeShovelerStrategies(clientId: string, minimumRecipesPerShoveler?: number, options?: any): AxiosPromise<RecipeShovelerStrategiesResponseDTO> {
            return localVarFp.getActiveRecipeShovelerStrategies(clientId, minimumRecipesPerShoveler, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipe shovelers for client
         * @param {string} clientId 
         * @param {boolean} excludeCostData Optionally exclude cost data from response. Recipe cost data can be fetched from another endpoint. Defaults to false
         * @param {Array<string>} [shovelerStrategyNames] Optionally provide to only return specified shovelers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveRecipeShovelers(clientId: string, excludeCostData: boolean, shovelerStrategyNames?: Array<string>, options?: any): AxiosPromise<RecipeShovelersResponseDTO> {
            return localVarFp.getActiveRecipeShovelers(clientId, excludeCostData, shovelerStrategyNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Recipe
         * @param {string} id The Recipe ID
         * @param {string} [clientId] If included, recipe returned using dynamic local product pricing based on the client address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipe(id: string, clientId?: string, options?: any): AxiosPromise<GetRecipeResponseDTO> {
            return localVarFp.getRecipe(id, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get diet module configuration, which includes each Diet and what Diet Modules are required to satisfy that Diet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeDietModuleConfiguration(options?: any): AxiosPromise<RecipeDietModuleConfigurationResponseDTO> {
            return localVarFp.getRecipeDietModuleConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for ingredients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeIngredients(options?: any): AxiosPromise<GetRecipeIngredientsResponseDTO> {
            return localVarFp.getRecipeIngredients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipe inventory for parameters
         * @param {RecipeInventoryRequestDTO} recipe Recipe inventory request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeInventory(recipe: RecipeInventoryRequestDTO, options?: any): AxiosPromise<RecipeInventoryResponseDTO> {
            return localVarFp.getRecipeInventory(recipe, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipe recommendations for a client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeRecommendationsForClient(clientId: string, options?: any): AxiosPromise<RecipesResponseDTO> {
            return localVarFp.getRecipeRecommendationsForClient(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipes
         * @param {Array<string>} recipeIds 
         * @param {string} [clientId] If included, recipes returned using dynamic local product pricing based on the client address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipes(recipeIds: Array<string>, clientId?: string, options?: any): AxiosPromise<BaseRecipesResponseDTO> {
            return localVarFp.getRecipes(recipeIds, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Recipes Cost for Client
         * @param {RecipesCostRequestDTO} request Recipes Cost Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesCostForClient(request: RecipesCostRequestDTO, options?: any): AxiosPromise<RecipesCostResponseDTO> {
            return localVarFp.getRecipesCostForClient(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search recipes
         * @param {SearchRecipesRequestDTO} searchRecipesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRecipes(searchRecipesRequest: SearchRecipesRequestDTO, options?: any): AxiosPromise<SearchRecipesResponseDTO> {
            return localVarFp.searchRecipes(searchRecipesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate diet tags (deprecated) and diet modules for recipes
         * @param {RecipeDietTagRequestDTO} recipeDietTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagDiets(recipeDietTagRequest: RecipeDietTagRequestDTO, options?: any): AxiosPromise<RecipesDietTagResponseDTO> {
            return localVarFp.tagDiets(recipeDietTagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing recipe
         * @param {string} id The Recipe ID
         * @param {CreateUpdateRecipeDTO} recipe The updated Recipe resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipe(id: string, recipe: CreateUpdateRecipeDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateRecipe(id, recipe, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update recipe status
         * @param {string} id The Recipe ID
         * @param {RecipeStatusDTO} recipe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeStatus(id: string, recipe: RecipeStatusDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateRecipeStatus(id, recipe, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update images for multiple recipes at once
         * @param {UpdateRecipesImagesRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipesImages(request: UpdateRecipesImagesRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateRecipesImages(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload an image for an Recipe
         * @param {string} id The Recipe ID
         * @param {File} image The image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRecipeImage(id: string, image: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadRecipeImage(id, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecipeApi - object-oriented interface
 * @export
 * @class RecipeApi
 * @extends {BaseAPI}
 */
export class RecipeApi extends BaseAPI {
    /**
     * 
     * @summary Calculate the estimated cost for a recipe. Use cost refers to the cost when only considering cost of each ingredient actually _used_ in the recipe,rather than the cost of the amount purchased. Purchase cost refers to the cost when considering total cost of each product needed to buy in order to make the recipe. 
     * @param {RecipeCalculationRequestDTO} data The ingredients and serving count for which to compute true cost.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public calculateEstimatedCost(data: RecipeCalculationRequestDTO, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).calculateEstimatedCost(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate the nutritional information for a recipe
     * @param {RecipeCalculationRequestDTO} data The ingredients and serving count for which to compute nutritional content.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public calculateRecipeNutrition(data: RecipeCalculationRequestDTO, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).calculateRecipeNutrition(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new Recipe
     * @param {CreateUpdateRecipeDTO} recipe Recipe resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public createRecipe(recipe: CreateUpdateRecipeDTO, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).createRecipe(recipe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an image upload URL for a Recipe
     * @param {string} id The Recipe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public generateRecipeImageUploadUrl(id: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).generateRecipeImageUploadUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipe shovelers strategies for client
     * @param {string} clientId 
     * @param {number} [minimumRecipesPerShoveler] Optionally provide to only return shoveler strategies that meet the minimum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getActiveRecipeShovelerStrategies(clientId: string, minimumRecipesPerShoveler?: number, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getActiveRecipeShovelerStrategies(clientId, minimumRecipesPerShoveler, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipe shovelers for client
     * @param {string} clientId 
     * @param {boolean} excludeCostData Optionally exclude cost data from response. Recipe cost data can be fetched from another endpoint. Defaults to false
     * @param {Array<string>} [shovelerStrategyNames] Optionally provide to only return specified shovelers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getActiveRecipeShovelers(clientId: string, excludeCostData: boolean, shovelerStrategyNames?: Array<string>, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getActiveRecipeShovelers(clientId, excludeCostData, shovelerStrategyNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Recipe
     * @param {string} id The Recipe ID
     * @param {string} [clientId] If included, recipe returned using dynamic local product pricing based on the client address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipe(id: string, clientId?: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipe(id, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get diet module configuration, which includes each Diet and what Diet Modules are required to satisfy that Diet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipeDietModuleConfiguration(options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipeDietModuleConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for ingredients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipeIngredients(options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipeIngredients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipe inventory for parameters
     * @param {RecipeInventoryRequestDTO} recipe Recipe inventory request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipeInventory(recipe: RecipeInventoryRequestDTO, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipeInventory(recipe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipe recommendations for a client
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipeRecommendationsForClient(clientId: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipeRecommendationsForClient(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipes
     * @param {Array<string>} recipeIds 
     * @param {string} [clientId] If included, recipes returned using dynamic local product pricing based on the client address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipes(recipeIds: Array<string>, clientId?: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipes(recipeIds, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Recipes Cost for Client
     * @param {RecipesCostRequestDTO} request Recipes Cost Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipesCostForClient(request: RecipesCostRequestDTO, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipesCostForClient(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search recipes
     * @param {SearchRecipesRequestDTO} searchRecipesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public searchRecipes(searchRecipesRequest: SearchRecipesRequestDTO, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).searchRecipes(searchRecipesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate diet tags (deprecated) and diet modules for recipes
     * @param {RecipeDietTagRequestDTO} recipeDietTagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public tagDiets(recipeDietTagRequest: RecipeDietTagRequestDTO, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).tagDiets(recipeDietTagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing recipe
     * @param {string} id The Recipe ID
     * @param {CreateUpdateRecipeDTO} recipe The updated Recipe resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public updateRecipe(id: string, recipe: CreateUpdateRecipeDTO, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).updateRecipe(id, recipe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update recipe status
     * @param {string} id The Recipe ID
     * @param {RecipeStatusDTO} recipe 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public updateRecipeStatus(id: string, recipe: RecipeStatusDTO, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).updateRecipeStatus(id, recipe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update images for multiple recipes at once
     * @param {UpdateRecipesImagesRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public updateRecipesImages(request: UpdateRecipesImagesRequestDTO, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).updateRecipesImages(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload an image for an Recipe
     * @param {string} id The Recipe ID
     * @param {File} image The image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public uploadRecipeImage(id: string, image: File, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).uploadRecipeImage(id, image, options).then((request) => request(this.axios, this.basePath));
    }
}
