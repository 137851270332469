/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum RecipeCategoryDTO {
    CEREAL = 'CEREAL',
    EGGS = 'EGGS',
    GRAIN_BOWL = 'GRAIN_BOWL',
    PASTA = 'PASTA',
    SALAD = 'SALAD',
    SANDWICH = 'SANDWICH',
    SMOOTHIE = 'SMOOTHIE',
    SOUP = 'SOUP',
    SQUARE_MEAL = 'SQUARE_MEAL',
    STIR_FRY = 'STIR_FRY',
    TACO_OR_LETTUCE_WRAP = 'TACO_OR_LETTUCE_WRAP',
    TOAST = 'TOAST'
};

