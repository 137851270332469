import clsx from 'clsx';

export type Props = {
    curValue: number;
    maxValue?: number;
    height?: string;
    bgColor?: string;
    progressColor?: string;
    containerClassName?: string;
    className?: string;
};

const ProgressBar = ({
    curValue,
    maxValue = 100,
    className,
    height = 'h-2',
    bgColor = 'bg-standard-oat',
    progressColor = 'bg-highBlue-1',
    containerClassName,
}: Props): JSX.Element => {
    const progress = (curValue / maxValue) * 100;
    return (
        <div
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemax={maxValue}
            className={clsx('flex w-full justify-start', height, bgColor, containerClassName)}
        >
            <div
                className={clsx(progress !== 100 && 'rounded-r', className, progressColor)}
                style={{ width: `${progress}%`, transition: 'width 0.5s ease-in-out' }}
            />
        </div>
    );
};

export default ProgressBar;
