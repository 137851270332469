/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SsoAddRequestDTO } from '../models';
// @ts-ignore
import { SsoLoginRequestDTO } from '../models';
// @ts-ignore
import { TokenResponseDTO } from '../models';
/**
 * SsoApi - axios parameter creator
 * @export
 */
export const SsoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Apple SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAppleSso: async (token: SsoAddRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('addAppleSso', 'token', token)
            const localVarPath = `/auth/add-apple-sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Google SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGoogleSso: async (token: SsoAddRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('addGoogleSso', 'token', token)
            const localVarPath = `/auth/add-google-sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log into the application with a Apple ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appleLogin: async (token: SsoLoginRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('appleLogin', 'token', token)
            const localVarPath = `/auth/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log into the application with a Google ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogin: async (token: SsoLoginRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('googleLogin', 'token', token)
            const localVarPath = `/auth/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Apple SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAppleSso: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/remove-apple-sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Google SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGoogleSso: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/remove-google-sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SsoApi - functional programming interface
 * @export
 */
export const SsoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SsoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Apple SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAppleSso(token: SsoAddRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAppleSso(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add Google SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGoogleSso(token: SsoAddRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGoogleSso(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log into the application with a Apple ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appleLogin(token: SsoLoginRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appleLogin(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log into the application with a Google ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleLogin(token: SsoLoginRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleLogin(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Apple SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAppleSso(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAppleSso(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Google SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeGoogleSso(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeGoogleSso(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SsoApi - factory interface
 * @export
 */
export const SsoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SsoApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Apple SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAppleSso(token: SsoAddRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addAppleSso(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Google SSO to account
         * @param {SsoAddRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGoogleSso(token: SsoAddRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addGoogleSso(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log into the application with a Apple ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Apple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appleLogin(token: SsoLoginRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.appleLogin(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log into the application with a Google ID token and gets an app token.
         * @param {SsoLoginRequestDTO} token the ID token from Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogin(token: SsoLoginRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return localVarFp.googleLogin(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Apple SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAppleSso(options?: any): AxiosPromise<void> {
            return localVarFp.removeAppleSso(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Google SSO from account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGoogleSso(options?: any): AxiosPromise<void> {
            return localVarFp.removeGoogleSso(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SsoApi - object-oriented interface
 * @export
 * @class SsoApi
 * @extends {BaseAPI}
 */
export class SsoApi extends BaseAPI {
    /**
     * 
     * @summary Add Apple SSO to account
     * @param {SsoAddRequestDTO} token the ID token from Apple
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public addAppleSso(token: SsoAddRequestDTO, options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).addAppleSso(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Google SSO to account
     * @param {SsoAddRequestDTO} token the ID token from Google
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public addGoogleSso(token: SsoAddRequestDTO, options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).addGoogleSso(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log into the application with a Apple ID token and gets an app token.
     * @param {SsoLoginRequestDTO} token the ID token from Apple
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public appleLogin(token: SsoLoginRequestDTO, options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).appleLogin(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log into the application with a Google ID token and gets an app token.
     * @param {SsoLoginRequestDTO} token the ID token from Google
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public googleLogin(token: SsoLoginRequestDTO, options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).googleLogin(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Apple SSO from account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public removeAppleSso(options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).removeAppleSso(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Google SSO from account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public removeGoogleSso(options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).removeGoogleSso(options).then((request) => request(this.axios, this.basePath));
    }
}
