import type { AddressDTO, DeliveryDetailsDTO } from 'API';
import type { ApplicationState } from 'store';

type DeliveryDetailsSlice = Pick<ApplicationState, 'deliveryDetails'>;

export const selectDeliveryDetails = (state: DeliveryDetailsSlice): DeliveryDetailsDTO | undefined => {
    return state.deliveryDetails?.details;
};

export const selectShippingAddress = (state: DeliveryDetailsSlice): AddressDTO | undefined => {
    return state.deliveryDetails?.details?.shippingAddress;
};
