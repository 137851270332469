/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddInsurancePolicyCoverageCheckRequestDTO } from '../models';
// @ts-ignore
import { ClientInsurancePoliciesResponseDTO } from '../models';
// @ts-ignore
import { ClientInsurancePolicyResponseDTO } from '../models';
// @ts-ignore
import { CreateInsurancePolicyRequestDTO } from '../models';
// @ts-ignore
import { GetInsurancePolicyDocumentResponseDTO } from '../models';
// @ts-ignore
import { InsurancePoliciesResponseDTO } from '../models';
// @ts-ignore
import { InsurancePolicyCoverageEmailResponseDTO } from '../models';
// @ts-ignore
import { InsurancePolicyResponseDTO } from '../models';
// @ts-ignore
import { SearchInsurancePoliciesResponseDTO } from '../models';
// @ts-ignore
import { UpdateInsurancePolicyCoverageCheckRequestDTO } from '../models';
// @ts-ignore
import { UpdateInsurancePolicyIdentifiersRequestDTO } from '../models';
// @ts-ignore
import { UpdateInsurancePolicyReferralRequirementContactRequestDTO } from '../models';
// @ts-ignore
import { VerifyInsurancePolicyRequestDTO } from '../models';
/**
 * InsurancePolicyApi - axios parameter creator
 * @export
 */
export const InsurancePolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Insurance Policy Card
         * @param {string} id The Insurance Policy ID
         * @param {File} insuranceCardFront The front of the insurance card
         * @param {File} insuranceCardBack The back of the insurance card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInsurancePolicyCard: async (id: string, insuranceCardFront: File, insuranceCardBack: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addInsurancePolicyCard', 'id', id)
            // verify required parameter 'insuranceCardFront' is not null or undefined
            assertParamExists('addInsurancePolicyCard', 'insuranceCardFront', insuranceCardFront)
            // verify required parameter 'insuranceCardBack' is not null or undefined
            assertParamExists('addInsurancePolicyCard', 'insuranceCardBack', insuranceCardBack)
            const localVarPath = `/insurance_policies/{id}/add-card`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (insuranceCardFront !== undefined) { 
                localVarFormParams.append('insuranceCardFront', insuranceCardFront as any);
            }
    
            if (insuranceCardBack !== undefined) { 
                localVarFormParams.append('insuranceCardBack', insuranceCardBack as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Insurance Policy Coverage Check
         * @param {string} id The Insurance Policy ID
         * @param {AddInsurancePolicyCoverageCheckRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInsurancePolicyCoverageCheck: async (id: string, request: AddInsurancePolicyCoverageCheckRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addInsurancePolicyCoverageCheck', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('addInsurancePolicyCoverageCheck', 'request', request)
            const localVarPath = `/insurance_policies/{id}/add-coverage-check`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Insurance Policy Document
         * @param {string} id The Insurance Policy ID
         * @param {string} documentType The type of document
         * @param {File} file 
         * @param {string} [icd10Codes] The icd10 codes verified by the document. Separated by new line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInsurancePolicyDocument: async (id: string, documentType: string, file: File, icd10Codes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addInsurancePolicyDocument', 'id', id)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('addInsurancePolicyDocument', 'documentType', documentType)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('addInsurancePolicyDocument', 'file', file)
            const localVarPath = `/insurance_policies/{id}/add-document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (documentType !== undefined) { 
                localVarFormParams.append('documentType', documentType as any);
            }
    
            if (icd10Codes !== undefined) { 
                localVarFormParams.append('icd10Codes', icd10Codes as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add external condition documentation asynchronously
         * @param {string} id The Insurance Policy ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInsurancePolicyDocumentsAsync: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addInsurancePolicyDocumentsAsync', 'id', id)
            const localVarPath = `/insurance_policies/{id}/add-documents-async`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Insurance Policy
         * @param {CreateInsurancePolicyRequestDTO} request The Insurance Policy to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsurancePolicy: async (request: CreateInsurancePolicyRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createInsurancePolicy', 'request', request)
            const localVarPath = `/insurance_policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Insurance Policy as Client
         * @param {CreateInsurancePolicyRequestDTO} request The Insurance Policy to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsurancePolicyAsClient: async (request: CreateInsurancePolicyRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createInsurancePolicyAsClient', 'request', request)
            const localVarPath = `/insurance_policies/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Insurance Policy Document
         * @param {string} id The Insurance Policy ID
         * @param {string} documentId The Document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInsurancePolicyDocument: async (id: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInsurancePolicyDocument', 'id', id)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteInsurancePolicyDocument', 'documentId', documentId)
            const localVarPath = `/insurance_policies/{id}/documents/{documentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Insurance Policies
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurancePolicies: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getInsurancePolicies', 'clientId', clientId)
            const localVarPath = `/insurance_policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Insurance Policies for Client
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurancePoliciesForClient: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getInsurancePoliciesForClient', 'clientId', clientId)
            const localVarPath = `/insurance_policies/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details about an email given an email ID
         * @param {string} id The Insurance Policy ID
         * @param {string} emailId The Email ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurancePolicyCoverageEmailMessage: async (id: string, emailId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInsurancePolicyCoverageEmailMessage', 'id', id)
            // verify required parameter 'emailId' is not null or undefined
            assertParamExists('getInsurancePolicyCoverageEmailMessage', 'emailId', emailId)
            const localVarPath = `/insurance_policies/{id}/coverage-emails/{emailId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"emailId"}}`, encodeURIComponent(String(emailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Insurance Policy Document
         * @param {string} id The Insurance Policy ID
         * @param {string} documentId The Document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurancePolicyDocument: async (id: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInsurancePolicyDocument', 'id', id)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getInsurancePolicyDocument', 'documentId', documentId)
            const localVarPath = `/insurance_policies/{id}/documents/{documentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invalidate Insurance Policy
         * @param {string} id The Insurance Policy ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateInsurancePolicy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invalidateInsurancePolicy', 'id', id)
            const localVarPath = `/insurance_policies/{id}/invalidate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search insurance policies
         * @param {string} [clientId] 
         * @param {Array<'NO_CHECK' | 'NONE' | 'SOME' | 'PENDING_CHECK' | 'CHECK_COMPLETE_PENDING_VERIFICATION' | 'UNLIMITED_FULL'>} [mostRecentCoverageCheckMntCoverage] Empty or null defaults to all values
         * @param {Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>} [currentInsurancePolicyState] Filter by current state; empty or null defaults to all values
         * @param {Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>} [noInsurancePolicyStatusState] Filter out by existence of a status (can be historical or current)
         * @param {boolean} [includeNoAppointment] Null defaults to true
         * @param {Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>} [appointmentType] Value must be provided to return policies for clients with upcoming appointments. Null or empty will result in no policies with upcoming appointments being returned. 
         * @param {Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>} [appointmentPaymentType] Value must be provided to return policies for clients with upcoming appointments. Null or empty will result in no policies with upcoming appointments being returned. 
         * @param {'UPCOMING_APPOINTMENT_DATE_TIME'} [sortKey] Property to sort by, defaults to upcoming appointment date time
         * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
         * @param {number} [limit] Number of records to include, defaults to 50
         * @param {number} [offset] Number of records to skip, defaults to 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInsurancePolicies: async (clientId?: string, mostRecentCoverageCheckMntCoverage?: Array<'NO_CHECK' | 'NONE' | 'SOME' | 'PENDING_CHECK' | 'CHECK_COMPLETE_PENDING_VERIFICATION' | 'UNLIMITED_FULL'>, currentInsurancePolicyState?: Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>, noInsurancePolicyStatusState?: Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>, includeNoAppointment?: boolean, appointmentType?: Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>, appointmentPaymentType?: Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>, sortKey?: 'UPCOMING_APPOINTMENT_DATE_TIME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/insurance_policies/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (mostRecentCoverageCheckMntCoverage) {
                localVarQueryParameter['mostRecentCoverageCheckMntCoverage'] = mostRecentCoverageCheckMntCoverage;
            }

            if (currentInsurancePolicyState) {
                localVarQueryParameter['currentInsurancePolicyState'] = currentInsurancePolicyState;
            }

            if (noInsurancePolicyStatusState) {
                localVarQueryParameter['noInsurancePolicyStatusState'] = noInsurancePolicyStatusState;
            }

            if (includeNoAppointment !== undefined) {
                localVarQueryParameter['includeNoAppointment'] = includeNoAppointment;
            }

            if (appointmentType) {
                localVarQueryParameter['appointmentType'] = appointmentType;
            }

            if (appointmentPaymentType) {
                localVarQueryParameter['appointmentPaymentType'] = appointmentPaymentType;
            }

            if (sortKey !== undefined) {
                localVarQueryParameter['sortKey'] = sortKey;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Insurance Policy Coverage Check
         * @param {string} id The Insurance Policy ID
         * @param {string} coverageCheckId The Coverage Check ID
         * @param {UpdateInsurancePolicyCoverageCheckRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsurancePolicyCoverageCheck: async (id: string, coverageCheckId: string, request: UpdateInsurancePolicyCoverageCheckRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInsurancePolicyCoverageCheck', 'id', id)
            // verify required parameter 'coverageCheckId' is not null or undefined
            assertParamExists('updateInsurancePolicyCoverageCheck', 'coverageCheckId', coverageCheckId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateInsurancePolicyCoverageCheck', 'request', request)
            const localVarPath = `/insurance_policies/{id}/coverage-check/{coverageCheckId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"coverageCheckId"}}`, encodeURIComponent(String(coverageCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update insurance policy
         * @param {string} id Insurance Policy ID
         * @param {UpdateInsurancePolicyIdentifiersRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsurancePolicyIdentifiers: async (id: string, request: UpdateInsurancePolicyIdentifiersRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInsurancePolicyIdentifiers', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateInsurancePolicyIdentifiers', 'request', request)
            const localVarPath = `/insurance_policies/{id}/update-identifiers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Insurance Policy Referral Requirement Contact
         * @param {string} id The Insurance Policy ID
         * @param {UpdateInsurancePolicyReferralRequirementContactRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsurancePolicyReferralRequirementContact: async (id: string, request: UpdateInsurancePolicyReferralRequirementContactRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInsurancePolicyReferralRequirementContact', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateInsurancePolicyReferralRequirementContact', 'request', request)
            const localVarPath = `/insurance_policies/{id}/update-referral-requirement-contact`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify Insurance Policy
         * @param {string} id The Insurance Policy ID
         * @param {VerifyInsurancePolicyRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyInsurancePolicy: async (id: string, request: VerifyInsurancePolicyRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verifyInsurancePolicy', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('verifyInsurancePolicy', 'request', request)
            const localVarPath = `/insurance_policies/{id}/verify`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsurancePolicyApi - functional programming interface
 * @export
 */
export const InsurancePolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsurancePolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Insurance Policy Card
         * @param {string} id The Insurance Policy ID
         * @param {File} insuranceCardFront The front of the insurance card
         * @param {File} insuranceCardBack The back of the insurance card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInsurancePolicyCard(id: string, insuranceCardFront: File, insuranceCardBack: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInsurancePolicyCard(id, insuranceCardFront, insuranceCardBack, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add Insurance Policy Coverage Check
         * @param {string} id The Insurance Policy ID
         * @param {AddInsurancePolicyCoverageCheckRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInsurancePolicyCoverageCheck(id: string, request: AddInsurancePolicyCoverageCheckRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInsurancePolicyCoverageCheck(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add Insurance Policy Document
         * @param {string} id The Insurance Policy ID
         * @param {string} documentType The type of document
         * @param {File} file 
         * @param {string} [icd10Codes] The icd10 codes verified by the document. Separated by new line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInsurancePolicyDocument(id: string, documentType: string, file: File, icd10Codes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInsurancePolicyDocument(id, documentType, file, icd10Codes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add external condition documentation asynchronously
         * @param {string} id The Insurance Policy ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInsurancePolicyDocumentsAsync(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInsurancePolicyDocumentsAsync(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Insurance Policy
         * @param {CreateInsurancePolicyRequestDTO} request The Insurance Policy to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInsurancePolicy(request: CreateInsurancePolicyRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsurancePolicyResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInsurancePolicy(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Insurance Policy as Client
         * @param {CreateInsurancePolicyRequestDTO} request The Insurance Policy to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInsurancePolicyAsClient(request: CreateInsurancePolicyRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientInsurancePolicyResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInsurancePolicyAsClient(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Insurance Policy Document
         * @param {string} id The Insurance Policy ID
         * @param {string} documentId The Document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInsurancePolicyDocument(id: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInsurancePolicyDocument(id, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Insurance Policies
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsurancePolicies(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsurancePoliciesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsurancePolicies(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Insurance Policies for Client
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsurancePoliciesForClient(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientInsurancePoliciesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsurancePoliciesForClient(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details about an email given an email ID
         * @param {string} id The Insurance Policy ID
         * @param {string} emailId The Email ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsurancePolicyCoverageEmailMessage(id: string, emailId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsurancePolicyCoverageEmailResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsurancePolicyCoverageEmailMessage(id, emailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Insurance Policy Document
         * @param {string} id The Insurance Policy ID
         * @param {string} documentId The Document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsurancePolicyDocument(id: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInsurancePolicyDocumentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsurancePolicyDocument(id, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invalidate Insurance Policy
         * @param {string} id The Insurance Policy ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invalidateInsurancePolicy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invalidateInsurancePolicy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search insurance policies
         * @param {string} [clientId] 
         * @param {Array<'NO_CHECK' | 'NONE' | 'SOME' | 'PENDING_CHECK' | 'CHECK_COMPLETE_PENDING_VERIFICATION' | 'UNLIMITED_FULL'>} [mostRecentCoverageCheckMntCoverage] Empty or null defaults to all values
         * @param {Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>} [currentInsurancePolicyState] Filter by current state; empty or null defaults to all values
         * @param {Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>} [noInsurancePolicyStatusState] Filter out by existence of a status (can be historical or current)
         * @param {boolean} [includeNoAppointment] Null defaults to true
         * @param {Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>} [appointmentType] Value must be provided to return policies for clients with upcoming appointments. Null or empty will result in no policies with upcoming appointments being returned. 
         * @param {Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>} [appointmentPaymentType] Value must be provided to return policies for clients with upcoming appointments. Null or empty will result in no policies with upcoming appointments being returned. 
         * @param {'UPCOMING_APPOINTMENT_DATE_TIME'} [sortKey] Property to sort by, defaults to upcoming appointment date time
         * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
         * @param {number} [limit] Number of records to include, defaults to 50
         * @param {number} [offset] Number of records to skip, defaults to 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInsurancePolicies(clientId?: string, mostRecentCoverageCheckMntCoverage?: Array<'NO_CHECK' | 'NONE' | 'SOME' | 'PENDING_CHECK' | 'CHECK_COMPLETE_PENDING_VERIFICATION' | 'UNLIMITED_FULL'>, currentInsurancePolicyState?: Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>, noInsurancePolicyStatusState?: Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>, includeNoAppointment?: boolean, appointmentType?: Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>, appointmentPaymentType?: Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>, sortKey?: 'UPCOMING_APPOINTMENT_DATE_TIME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchInsurancePoliciesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInsurancePolicies(clientId, mostRecentCoverageCheckMntCoverage, currentInsurancePolicyState, noInsurancePolicyStatusState, includeNoAppointment, appointmentType, appointmentPaymentType, sortKey, sortDir, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Insurance Policy Coverage Check
         * @param {string} id The Insurance Policy ID
         * @param {string} coverageCheckId The Coverage Check ID
         * @param {UpdateInsurancePolicyCoverageCheckRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInsurancePolicyCoverageCheck(id: string, coverageCheckId: string, request: UpdateInsurancePolicyCoverageCheckRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInsurancePolicyCoverageCheck(id, coverageCheckId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update insurance policy
         * @param {string} id Insurance Policy ID
         * @param {UpdateInsurancePolicyIdentifiersRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInsurancePolicyIdentifiers(id: string, request: UpdateInsurancePolicyIdentifiersRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInsurancePolicyIdentifiers(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Insurance Policy Referral Requirement Contact
         * @param {string} id The Insurance Policy ID
         * @param {UpdateInsurancePolicyReferralRequirementContactRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInsurancePolicyReferralRequirementContact(id: string, request: UpdateInsurancePolicyReferralRequirementContactRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInsurancePolicyReferralRequirementContact(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify Insurance Policy
         * @param {string} id The Insurance Policy ID
         * @param {VerifyInsurancePolicyRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyInsurancePolicy(id: string, request: VerifyInsurancePolicyRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyInsurancePolicy(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsurancePolicyApi - factory interface
 * @export
 */
export const InsurancePolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsurancePolicyApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Insurance Policy Card
         * @param {string} id The Insurance Policy ID
         * @param {File} insuranceCardFront The front of the insurance card
         * @param {File} insuranceCardBack The back of the insurance card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInsurancePolicyCard(id: string, insuranceCardFront: File, insuranceCardBack: File, options?: any): AxiosPromise<void> {
            return localVarFp.addInsurancePolicyCard(id, insuranceCardFront, insuranceCardBack, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Insurance Policy Coverage Check
         * @param {string} id The Insurance Policy ID
         * @param {AddInsurancePolicyCoverageCheckRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInsurancePolicyCoverageCheck(id: string, request: AddInsurancePolicyCoverageCheckRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addInsurancePolicyCoverageCheck(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Insurance Policy Document
         * @param {string} id The Insurance Policy ID
         * @param {string} documentType The type of document
         * @param {File} file 
         * @param {string} [icd10Codes] The icd10 codes verified by the document. Separated by new line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInsurancePolicyDocument(id: string, documentType: string, file: File, icd10Codes?: string, options?: any): AxiosPromise<void> {
            return localVarFp.addInsurancePolicyDocument(id, documentType, file, icd10Codes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add external condition documentation asynchronously
         * @param {string} id The Insurance Policy ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInsurancePolicyDocumentsAsync(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.addInsurancePolicyDocumentsAsync(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Insurance Policy
         * @param {CreateInsurancePolicyRequestDTO} request The Insurance Policy to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsurancePolicy(request: CreateInsurancePolicyRequestDTO, options?: any): AxiosPromise<InsurancePolicyResponseDTO> {
            return localVarFp.createInsurancePolicy(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Insurance Policy as Client
         * @param {CreateInsurancePolicyRequestDTO} request The Insurance Policy to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsurancePolicyAsClient(request: CreateInsurancePolicyRequestDTO, options?: any): AxiosPromise<ClientInsurancePolicyResponseDTO> {
            return localVarFp.createInsurancePolicyAsClient(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Insurance Policy Document
         * @param {string} id The Insurance Policy ID
         * @param {string} documentId The Document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInsurancePolicyDocument(id: string, documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInsurancePolicyDocument(id, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Insurance Policies
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurancePolicies(clientId: string, options?: any): AxiosPromise<InsurancePoliciesResponseDTO> {
            return localVarFp.getInsurancePolicies(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Insurance Policies for Client
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurancePoliciesForClient(clientId: string, options?: any): AxiosPromise<ClientInsurancePoliciesResponseDTO> {
            return localVarFp.getInsurancePoliciesForClient(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details about an email given an email ID
         * @param {string} id The Insurance Policy ID
         * @param {string} emailId The Email ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurancePolicyCoverageEmailMessage(id: string, emailId: string, options?: any): AxiosPromise<InsurancePolicyCoverageEmailResponseDTO> {
            return localVarFp.getInsurancePolicyCoverageEmailMessage(id, emailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Insurance Policy Document
         * @param {string} id The Insurance Policy ID
         * @param {string} documentId The Document ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsurancePolicyDocument(id: string, documentId: string, options?: any): AxiosPromise<GetInsurancePolicyDocumentResponseDTO> {
            return localVarFp.getInsurancePolicyDocument(id, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invalidate Insurance Policy
         * @param {string} id The Insurance Policy ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateInsurancePolicy(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.invalidateInsurancePolicy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search insurance policies
         * @param {string} [clientId] 
         * @param {Array<'NO_CHECK' | 'NONE' | 'SOME' | 'PENDING_CHECK' | 'CHECK_COMPLETE_PENDING_VERIFICATION' | 'UNLIMITED_FULL'>} [mostRecentCoverageCheckMntCoverage] Empty or null defaults to all values
         * @param {Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>} [currentInsurancePolicyState] Filter by current state; empty or null defaults to all values
         * @param {Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>} [noInsurancePolicyStatusState] Filter out by existence of a status (can be historical or current)
         * @param {boolean} [includeNoAppointment] Null defaults to true
         * @param {Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>} [appointmentType] Value must be provided to return policies for clients with upcoming appointments. Null or empty will result in no policies with upcoming appointments being returned. 
         * @param {Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>} [appointmentPaymentType] Value must be provided to return policies for clients with upcoming appointments. Null or empty will result in no policies with upcoming appointments being returned. 
         * @param {'UPCOMING_APPOINTMENT_DATE_TIME'} [sortKey] Property to sort by, defaults to upcoming appointment date time
         * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
         * @param {number} [limit] Number of records to include, defaults to 50
         * @param {number} [offset] Number of records to skip, defaults to 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInsurancePolicies(clientId?: string, mostRecentCoverageCheckMntCoverage?: Array<'NO_CHECK' | 'NONE' | 'SOME' | 'PENDING_CHECK' | 'CHECK_COMPLETE_PENDING_VERIFICATION' | 'UNLIMITED_FULL'>, currentInsurancePolicyState?: Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>, noInsurancePolicyStatusState?: Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>, includeNoAppointment?: boolean, appointmentType?: Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>, appointmentPaymentType?: Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>, sortKey?: 'UPCOMING_APPOINTMENT_DATE_TIME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: any): AxiosPromise<SearchInsurancePoliciesResponseDTO> {
            return localVarFp.searchInsurancePolicies(clientId, mostRecentCoverageCheckMntCoverage, currentInsurancePolicyState, noInsurancePolicyStatusState, includeNoAppointment, appointmentType, appointmentPaymentType, sortKey, sortDir, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Insurance Policy Coverage Check
         * @param {string} id The Insurance Policy ID
         * @param {string} coverageCheckId The Coverage Check ID
         * @param {UpdateInsurancePolicyCoverageCheckRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsurancePolicyCoverageCheck(id: string, coverageCheckId: string, request: UpdateInsurancePolicyCoverageCheckRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateInsurancePolicyCoverageCheck(id, coverageCheckId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update insurance policy
         * @param {string} id Insurance Policy ID
         * @param {UpdateInsurancePolicyIdentifiersRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsurancePolicyIdentifiers(id: string, request: UpdateInsurancePolicyIdentifiersRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateInsurancePolicyIdentifiers(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Insurance Policy Referral Requirement Contact
         * @param {string} id The Insurance Policy ID
         * @param {UpdateInsurancePolicyReferralRequirementContactRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsurancePolicyReferralRequirementContact(id: string, request: UpdateInsurancePolicyReferralRequirementContactRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateInsurancePolicyReferralRequirementContact(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify Insurance Policy
         * @param {string} id The Insurance Policy ID
         * @param {VerifyInsurancePolicyRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyInsurancePolicy(id: string, request: VerifyInsurancePolicyRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.verifyInsurancePolicy(id, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsurancePolicyApi - object-oriented interface
 * @export
 * @class InsurancePolicyApi
 * @extends {BaseAPI}
 */
export class InsurancePolicyApi extends BaseAPI {
    /**
     * 
     * @summary Add Insurance Policy Card
     * @param {string} id The Insurance Policy ID
     * @param {File} insuranceCardFront The front of the insurance card
     * @param {File} insuranceCardBack The back of the insurance card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public addInsurancePolicyCard(id: string, insuranceCardFront: File, insuranceCardBack: File, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).addInsurancePolicyCard(id, insuranceCardFront, insuranceCardBack, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Insurance Policy Coverage Check
     * @param {string} id The Insurance Policy ID
     * @param {AddInsurancePolicyCoverageCheckRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public addInsurancePolicyCoverageCheck(id: string, request: AddInsurancePolicyCoverageCheckRequestDTO, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).addInsurancePolicyCoverageCheck(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Insurance Policy Document
     * @param {string} id The Insurance Policy ID
     * @param {string} documentType The type of document
     * @param {File} file 
     * @param {string} [icd10Codes] The icd10 codes verified by the document. Separated by new line
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public addInsurancePolicyDocument(id: string, documentType: string, file: File, icd10Codes?: string, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).addInsurancePolicyDocument(id, documentType, file, icd10Codes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add external condition documentation asynchronously
     * @param {string} id The Insurance Policy ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public addInsurancePolicyDocumentsAsync(id: string, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).addInsurancePolicyDocumentsAsync(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Insurance Policy
     * @param {CreateInsurancePolicyRequestDTO} request The Insurance Policy to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public createInsurancePolicy(request: CreateInsurancePolicyRequestDTO, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).createInsurancePolicy(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Insurance Policy as Client
     * @param {CreateInsurancePolicyRequestDTO} request The Insurance Policy to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public createInsurancePolicyAsClient(request: CreateInsurancePolicyRequestDTO, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).createInsurancePolicyAsClient(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Insurance Policy Document
     * @param {string} id The Insurance Policy ID
     * @param {string} documentId The Document ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public deleteInsurancePolicyDocument(id: string, documentId: string, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).deleteInsurancePolicyDocument(id, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Insurance Policies
     * @param {string} clientId Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public getInsurancePolicies(clientId: string, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).getInsurancePolicies(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Insurance Policies for Client
     * @param {string} clientId Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public getInsurancePoliciesForClient(clientId: string, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).getInsurancePoliciesForClient(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details about an email given an email ID
     * @param {string} id The Insurance Policy ID
     * @param {string} emailId The Email ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public getInsurancePolicyCoverageEmailMessage(id: string, emailId: string, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).getInsurancePolicyCoverageEmailMessage(id, emailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Insurance Policy Document
     * @param {string} id The Insurance Policy ID
     * @param {string} documentId The Document ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public getInsurancePolicyDocument(id: string, documentId: string, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).getInsurancePolicyDocument(id, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invalidate Insurance Policy
     * @param {string} id The Insurance Policy ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public invalidateInsurancePolicy(id: string, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).invalidateInsurancePolicy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search insurance policies
     * @param {string} [clientId] 
     * @param {Array<'NO_CHECK' | 'NONE' | 'SOME' | 'PENDING_CHECK' | 'CHECK_COMPLETE_PENDING_VERIFICATION' | 'UNLIMITED_FULL'>} [mostRecentCoverageCheckMntCoverage] Empty or null defaults to all values
     * @param {Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>} [currentInsurancePolicyState] Filter by current state; empty or null defaults to all values
     * @param {Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>} [noInsurancePolicyStatusState] Filter out by existence of a status (can be historical or current)
     * @param {boolean} [includeNoAppointment] Null defaults to true
     * @param {Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>} [appointmentType] Value must be provided to return policies for clients with upcoming appointments. Null or empty will result in no policies with upcoming appointments being returned. 
     * @param {Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>} [appointmentPaymentType] Value must be provided to return policies for clients with upcoming appointments. Null or empty will result in no policies with upcoming appointments being returned. 
     * @param {'UPCOMING_APPOINTMENT_DATE_TIME'} [sortKey] Property to sort by, defaults to upcoming appointment date time
     * @param {'ASC' | 'DESC'} [sortDir] Sort ascending or descending, defaults to ascending
     * @param {number} [limit] Number of records to include, defaults to 50
     * @param {number} [offset] Number of records to skip, defaults to 0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public searchInsurancePolicies(clientId?: string, mostRecentCoverageCheckMntCoverage?: Array<'NO_CHECK' | 'NONE' | 'SOME' | 'PENDING_CHECK' | 'CHECK_COMPLETE_PENDING_VERIFICATION' | 'UNLIMITED_FULL'>, currentInsurancePolicyState?: Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>, noInsurancePolicyStatusState?: Array<'INVALIDATED' | 'PENDING' | 'CONDITION_DOCUMENTATION_FETCHED' | 'VERIFIED'>, includeNoAppointment?: boolean, appointmentType?: Array<'EXTENDED_INITIAL_CONSULTATION' | 'INITIAL_CONSULTATION' | 'FOLLOW_UP'>, appointmentPaymentType?: Array<'COVERED' | 'INSURANCE' | 'SELF_PAY'>, sortKey?: 'UPCOMING_APPOINTMENT_DATE_TIME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).searchInsurancePolicies(clientId, mostRecentCoverageCheckMntCoverage, currentInsurancePolicyState, noInsurancePolicyStatusState, includeNoAppointment, appointmentType, appointmentPaymentType, sortKey, sortDir, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Insurance Policy Coverage Check
     * @param {string} id The Insurance Policy ID
     * @param {string} coverageCheckId The Coverage Check ID
     * @param {UpdateInsurancePolicyCoverageCheckRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public updateInsurancePolicyCoverageCheck(id: string, coverageCheckId: string, request: UpdateInsurancePolicyCoverageCheckRequestDTO, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).updateInsurancePolicyCoverageCheck(id, coverageCheckId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update insurance policy
     * @param {string} id Insurance Policy ID
     * @param {UpdateInsurancePolicyIdentifiersRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public updateInsurancePolicyIdentifiers(id: string, request: UpdateInsurancePolicyIdentifiersRequestDTO, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).updateInsurancePolicyIdentifiers(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Insurance Policy Referral Requirement Contact
     * @param {string} id The Insurance Policy ID
     * @param {UpdateInsurancePolicyReferralRequirementContactRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public updateInsurancePolicyReferralRequirementContact(id: string, request: UpdateInsurancePolicyReferralRequirementContactRequestDTO, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).updateInsurancePolicyReferralRequirementContact(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify Insurance Policy
     * @param {string} id The Insurance Policy ID
     * @param {VerifyInsurancePolicyRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancePolicyApi
     */
    public verifyInsurancePolicy(id: string, request: VerifyInsurancePolicyRequestDTO, options?: AxiosRequestConfig) {
        return InsurancePolicyApiFp(this.configuration).verifyInsurancePolicy(id, request, options).then((request) => request(this.axios, this.basePath));
    }
}
