import { datadogRum } from '@datadog/browser-rum';
import type {
    AddClientIntakeInsuranceResponseDTO,
    ClientSubscriptionDTO,
    DeliveryDetailsDTO,
    InsuranceCategoryDTO,
    LanguageDTO,
    StateDTO,
    SubmitClientIntakeResponseDTO,
} from 'API';
import type { LDClient } from 'launchdarkly-js-client-sdk';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { UserDetails } from 'store/user/types';
import logger from 'utils/logger';
import { isRunningInsideSeasonNativeApp, nativeAppOS, nativeAppVersion } from 'utils/native-app-bridge/utils';
import type { apiObject } from '../../../types/freshpaint';
import { addGlobalTrackEventProperties } from './events';

type TrackUserProps = {
    userDetails: IdentifyProps;
    groupProps?: GroupProps;
};

type IdentifyProps = {
    accountId: string;
    firstName: string;
    lastName: string;
    emailAddress?: string;
    phoneNumber?: string;
    clientId?: string;
    clientIntakeId?: string;
    adminId?: string;
    providerId?: string;
    cohort?: string;
    cohortExternalId?: string;
    state?: StateDTO;
    subscriptionPackageId?: string;
    subscriptionPackageName?: string;
    insuranceCarrierId?: string;
    insuranceCarrierName?: string;
    insuranceCarrierState?: StateDTO;
    insuranceCarrierCarrier?: string;
    insuranceCarrierCategory?: InsuranceCategoryDTO;
    isImpersonation?: boolean;
    preferredLanguage?: LanguageDTO;
};

type GroupProps = {
    groupName: string;
    properties?: Record<string, unknown>;
};

export const getIdentityDetailsFromUserData = (
    userData: UserDetails,
    deliveryDetails?: DeliveryDetailsDTO,
    currentSubscription?: ClientSubscriptionDTO | null,
    primaryEmail?: string
): TrackUserProps | undefined => {
    if (!userData?.accountId) return;

    logger.addGlobalContext('account_id', userData.accountId);
    logger.addGlobalContext('is_impersonation', userData.isImpersonation);
    let groupProps;

    if (userData.admin) {
        groupProps = {
            groupName: 'admin',
            properties: {
                id: userData.admin?.id,
                name: userData.admin?.firstName,
            },
        };
    } else if (userData.client) {
        const { cohortDetails, id } = userData.client;
        if (cohortDetails) {
            groupProps = {
                groupName: cohortDetails.cohort,
                properties: {
                    externalId: cohortDetails?.externalId,
                },
            };
        } else {
            groupProps = {
                groupName: 'client',
                properties: {
                    id,
                },
            };
        }
    } else if (userData.provider) {
        const { id, practice } = userData.provider;
        groupProps = {
            groupName: 'provider',
            properties: {
                id,
                practice: practice?.id,
            },
        };
    }
    return {
        userDetails: {
            accountId: userData.accountId,
            firstName: userData.firstName,
            lastName: userData.lastName,
            emailAddress: primaryEmail,
            phoneNumber: userData.client?.phoneNumbers?.find((p) => p.isPrimary)?.phoneNumber,
            adminId: userData.admin?.id,
            providerId: userData.provider?.id,
            clientId: userData.client?.id,
            cohort: userData.client?.cohortDetails?.cohort,
            cohortExternalId: userData.client?.cohortDetails?.externalId,
            state: deliveryDetails?.shippingAddress?.state,
            subscriptionPackageId: currentSubscription?.subscriptionPackageId,
            isImpersonation: userData.isImpersonation,
            preferredLanguage: userData.client?.preferredLanguage,
        },
        groupProps,
    };
};

export const getIdentityDetailsFromSubmitIntake = (
    submitIntakeResponse: SubmitClientIntakeResponseDTO,
    firstName: string,
    lastName: string,
    emailAddress: string,
    phoneNumber: string,
    preferredLanguage: LanguageDTO
): TrackUserProps | undefined => {
    if (!submitIntakeResponse.pendingAccountId) return;

    const groupProps = submitIntakeResponse.cohort
        ? {
              groupName: submitIntakeResponse.cohort,
          }
        : undefined;

    return {
        userDetails: {
            accountId: submitIntakeResponse.pendingAccountId,
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            phoneNumber: phoneNumber,
            clientIntakeId: submitIntakeResponse.clientIntakeId,
            cohort: submitIntakeResponse.cohort,
            state: submitIntakeResponse.state,
            subscriptionPackageId: submitIntakeResponse.subscriptionPackageId,
            subscriptionPackageName: submitIntakeResponse.subscriptionPackageName,
            isImpersonation: false,
            preferredLanguage,
        },
        groupProps,
    };
};

export const getIdentityDetailsFromInsuranceResponse = (
    addInsuranceResponse: AddClientIntakeInsuranceResponseDTO,
    pendingAccountId: string,
    firstName: string,
    lastName: string,
    emailAddress: string,
    phoneNumber: string,
    clientIntakeId: string,
    preferredLanguage: LanguageDTO,
    state?: StateDTO
): TrackUserProps | undefined => {
    if (!pendingAccountId) return;

    const groupProps = addInsuranceResponse.cohort
        ? {
              groupName: addInsuranceResponse.cohort,
          }
        : undefined;

    return {
        userDetails: {
            accountId: pendingAccountId,
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            phoneNumber: phoneNumber,
            clientIntakeId: clientIntakeId,
            cohort: addInsuranceResponse.cohort,
            state: state,
            subscriptionPackageId: addInsuranceResponse.subscriptionPackageId,
            subscriptionPackageName: addInsuranceResponse.subscriptionPackageName,
            insuranceCarrierId: addInsuranceResponse.insuranceCarrier?.id,
            insuranceCarrierName: addInsuranceResponse.insuranceCarrier?.displayName,
            insuranceCarrierState: addInsuranceResponse.insuranceCarrier?.state,
            insuranceCarrierCarrier: addInsuranceResponse.insuranceCarrier?.carrier,
            insuranceCarrierCategory: addInsuranceResponse.insuranceCarrier?.category,
            isImpersonation: false,
            preferredLanguage,
        },
        groupProps,
    };
};

export const useIdentify = () => {
    try {
        const ldClient = useLDClient();
        const clients = {
            datadogRum: datadogRum,
            ldClient: ldClient,
            freshPaintClient: window.freshpaint,
        };

        logger.debug('useIdentify hook initialized clients', { clients });
        return (props: TrackUserProps) => {
            try {
                identify(props, clients);
            } catch (e) {
                logger.error('Failed to call identity', { identifyDetails: JSON.stringify(props) }, e as Error);
            }
        };
    } catch (e) {
        logger.error('Error in useIdentify hook', {}, e as Error);
    }
};

const identify = (
    props: TrackUserProps,
    clients?: {
        ldClient?: LDClient;
        freshPaintClient?: typeof window.freshpaint;
        datadogRum?: typeof datadogRum;
    }
) => {
    logger.debug('Calling identify', { props, clients });
    const { userDetails, groupProps } = props;
    const userType = userDetails.providerId ? 'PROVIDER' : userDetails.clientId ? 'CLIENT' : 'ADMIN';
    const isSeasonEmployee =
        userDetails.emailAddress &&
        (userDetails.emailAddress.includes('@seasonhealth.com') ||
            userDetails.emailAddress.includes('@helloseason.com'));

    if (clients?.datadogRum) {
        clients?.datadogRum.setUser({
            id: userDetails.accountId,
            name: '' /* don't send pii to datadog */,
            email: '' /* don't send pii to datadog */,
            userType: userType,
            isSeasonEmployee: isSeasonEmployee,
            isImpersonation: userDetails.isImpersonation,
        });
    }

    logger.addGlobalContext('account_id', userDetails.accountId);
    logger.addGlobalContext('is_impersonation', false);
    if (userDetails.emailAddress) addGlobalTrackEventProperties('email', userDetails.emailAddress);
    if (userDetails.phoneNumber) addGlobalTrackEventProperties('phone', userDetails.phoneNumber);

    if (clients?.freshPaintClient) {
        clients?.freshPaintClient.identify(userDetails.accountId, {
            ...{ name: `${userDetails.firstName} ${userDetails.lastName}` },
            // Special property names for Freshpaint->Mixpanel default identity transformation
            // https://documentation.freshpaint.io/integrations/destinations/apps/mixpanel/mixpanel-reference#enable-default-identify-transformations
            email: userDetails.emailAddress,
            phone: userDetails.phoneNumber,
            first_name: userDetails.firstName,
            last_name: userDetails.lastName,
            state: userDetails.state,
            // Additional properties
            accountId: userDetails.accountId,
            clientId: userDetails.clientId,
            cohort: userDetails.cohort,
            subscriptionPackageId: userDetails.subscriptionPackageId,
            subscriptionPackageName: userDetails.subscriptionPackageName,
            // maintaining insurancePlan naming for backwards compatibility
            insurancePlanId: userDetails.insuranceCarrierId,
            insurancePlanName: userDetails.insuranceCarrierName,
            insurancePlanState: userDetails.insuranceCarrierState,
            insurancePlanCarrier: userDetails.insuranceCarrierCarrier,
            insurancePlanCategory: userDetails.insuranceCarrierCategory,
            isImpersonation: userDetails.isImpersonation,
            preferredLanguage: userDetails.preferredLanguage,
        });
        if (groupProps) {
            clients.freshPaintClient.group(groupProps.groupName, groupProps.properties as unknown as apiObject);
        }
    }

    if (clients?.ldClient) {
        logger.debug('Calling identify with LD client');
        clients.ldClient
            .identify({
                kind: 'user',
                key: userDetails.accountId,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                email: userDetails.emailAddress,
                isRunningInsideSeasonNativeApp: isRunningInsideSeasonNativeApp,
                nativeAppOS: nativeAppOS,
                nativeAppVersionMajor: nativeAppVersion.major,
                nativeAppVersionMinor: nativeAppVersion.minor,
                nativeAppVersionPatch: nativeAppVersion.patch,
                clientCohort: userDetails.cohort || '',
                clientCohortExternalId: userDetails.cohortExternalId || '',
                userType: userType,
                isSeasonEmployee: isSeasonEmployee,
                _meta: { privateAttributes: ['firstName', 'lastName'] },
            })
            .catch((error) => {
                logger.error(`Identify call failed for unexpected reason`, undefined, error);
            });
    }
};

export const deidentify = () => {
    const deidentifyClients = {
        datadogRum: datadogRum,
        freshPaintClient: window.freshpaint,
    };

    if (deidentifyClients?.datadogRum) {
        deidentifyClients?.datadogRum.clearGlobalContext();
        deidentifyClients?.datadogRum.clearUser();
    }

    if (deidentifyClients?.freshPaintClient) {
        deidentifyClients?.freshPaintClient.identify('', {
            ...{ name: '' },
        });
    }

    logger.addGlobalContext('account_id', '');
    logger.addGlobalContext('is_impersonation', false);
};
