/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChartNotesResponseDTO } from '../models';
// @ts-ignore
import { CreateChartNoteRequestDTO } from '../models';
// @ts-ignore
import { SignAndLockChartNoteRequestDTO } from '../models';
// @ts-ignore
import { UpdateChartNoteRequestDTO } from '../models';
/**
 * ChartNoteApi - axios parameter creator
 * @export
 */
export const ChartNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create chart note
         * @param {CreateChartNoteRequestDTO} request Details of the chart note to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChartNote: async (request: CreateChartNoteRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createChartNote', 'request', request)
            const localVarPath = `/chart_notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get chart notes for Client
         * @param {string} clientId The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartNotesForClient: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getChartNotesForClient', 'clientId', clientId)
            const localVarPath = `/chart_notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign and lock chart note
         * @param {string} externalId The external id of the chart note
         * @param {SignAndLockChartNoteRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signAndLockChartNote: async (externalId: string, request: SignAndLockChartNoteRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('signAndLockChartNote', 'externalId', externalId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('signAndLockChartNote', 'request', request)
            const localVarPath = `/chart_notes/{externalId}/sign-and-lock`
                .replace(`{${"externalId"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update chart note
         * @param {string} externalId The external id of the chart note to update
         * @param {UpdateChartNoteRequestDTO} request Details of the chart note to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChartNote: async (externalId: string, request: UpdateChartNoteRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('updateChartNote', 'externalId', externalId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateChartNote', 'request', request)
            const localVarPath = `/chart_notes/{externalId}`
                .replace(`{${"externalId"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChartNoteApi - functional programming interface
 * @export
 */
export const ChartNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChartNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create chart note
         * @param {CreateChartNoteRequestDTO} request Details of the chart note to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChartNote(request: CreateChartNoteRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChartNote(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get chart notes for Client
         * @param {string} clientId The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartNotesForClient(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartNotesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartNotesForClient(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign and lock chart note
         * @param {string} externalId The external id of the chart note
         * @param {SignAndLockChartNoteRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signAndLockChartNote(externalId: string, request: SignAndLockChartNoteRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signAndLockChartNote(externalId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update chart note
         * @param {string} externalId The external id of the chart note to update
         * @param {UpdateChartNoteRequestDTO} request Details of the chart note to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChartNote(externalId: string, request: UpdateChartNoteRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChartNote(externalId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChartNoteApi - factory interface
 * @export
 */
export const ChartNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChartNoteApiFp(configuration)
    return {
        /**
         * 
         * @summary Create chart note
         * @param {CreateChartNoteRequestDTO} request Details of the chart note to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChartNote(request: CreateChartNoteRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createChartNote(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get chart notes for Client
         * @param {string} clientId The Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartNotesForClient(clientId: string, options?: any): AxiosPromise<ChartNotesResponseDTO> {
            return localVarFp.getChartNotesForClient(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign and lock chart note
         * @param {string} externalId The external id of the chart note
         * @param {SignAndLockChartNoteRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signAndLockChartNote(externalId: string, request: SignAndLockChartNoteRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.signAndLockChartNote(externalId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update chart note
         * @param {string} externalId The external id of the chart note to update
         * @param {UpdateChartNoteRequestDTO} request Details of the chart note to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChartNote(externalId: string, request: UpdateChartNoteRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateChartNote(externalId, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChartNoteApi - object-oriented interface
 * @export
 * @class ChartNoteApi
 * @extends {BaseAPI}
 */
export class ChartNoteApi extends BaseAPI {
    /**
     * 
     * @summary Create chart note
     * @param {CreateChartNoteRequestDTO} request Details of the chart note to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartNoteApi
     */
    public createChartNote(request: CreateChartNoteRequestDTO, options?: AxiosRequestConfig) {
        return ChartNoteApiFp(this.configuration).createChartNote(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get chart notes for Client
     * @param {string} clientId The Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartNoteApi
     */
    public getChartNotesForClient(clientId: string, options?: AxiosRequestConfig) {
        return ChartNoteApiFp(this.configuration).getChartNotesForClient(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign and lock chart note
     * @param {string} externalId The external id of the chart note
     * @param {SignAndLockChartNoteRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartNoteApi
     */
    public signAndLockChartNote(externalId: string, request: SignAndLockChartNoteRequestDTO, options?: AxiosRequestConfig) {
        return ChartNoteApiFp(this.configuration).signAndLockChartNote(externalId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update chart note
     * @param {string} externalId The external id of the chart note to update
     * @param {UpdateChartNoteRequestDTO} request Details of the chart note to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartNoteApi
     */
    public updateChartNote(externalId: string, request: UpdateChartNoteRequestDTO, options?: AxiosRequestConfig) {
        return ChartNoteApiFp(this.configuration).updateChartNote(externalId, request, options).then((request) => request(this.axios, this.basePath));
    }
}
