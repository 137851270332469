import clsx from 'clsx';
import { Button } from 'galley';

export type Props = {
    label: string;
    isSelected: boolean;
    setSelectedLabel: React.Dispatch<React.SetStateAction<string>>;
};

const Tab = ({ label, isSelected, setSelectedLabel }: Props): JSX.Element => {
    return (
        <Button
            id={`tab-${label}`}
            aria-controls={`tabpanel-${label}`}
            aria-selected={isSelected}
            role="tab"
            tabIndex={isSelected ? 0 : -1}
            onClick={() => {
                setSelectedLabel(label);
            }}
            className={clsx('h-11 w-32 border-b-2 border-white', isSelected && 'border-[#C77955]')}
        >
            <span className={clsx(isSelected ? 'bodySmMedium' : 'bodySmLight', 'm-2')}>{label}</span>
        </Button>
    );
};

export default Tab;
