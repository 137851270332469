import { useState } from 'react';
import Tablist from './Tablist';
import Tabpanel from './Tabpanel';

export type Props = {
    tabs: { label: string; children: React.ReactNode }[];
    initialState?: string;
};

const Tabs = ({ tabs, initialState }: Props) => {
    const [selectedLabel, setSelectedLabel] = useState(
        initialState && tabs.find((i) => i.label === initialState) !== undefined ? initialState : tabs[0].label
    );
    return (
        <div>
            <Tablist
                labels={tabs.map((tab) => tab.label)}
                selectedLabel={selectedLabel}
                setSelectedLabel={setSelectedLabel}
            />
            {tabs.map((tab, i) => {
                return (
                    <Tabpanel key={`tab-panel-${i}`} isSelected={selectedLabel === tab.label} label={tab.label}>
                        {tab.children}
                    </Tabpanel>
                );
            })}
        </div>
    );
};

export default Tabs;
