import clsx from 'clsx';
import { CheckContainedStrokeIcon, InfoStrokeIcon, WarningStrokeIcon, XContainedStrokeIcon } from '../Icons';
import type { AlertVariant } from 'store/alerts/types';

interface Props {
    variant: AlertVariant;
}

const styles = clsx('mdIcon inline flex-none');

export const AlertIcon = ({ variant }: Props): JSX.Element => {
    switch (variant) {
        case 'error':
            return <XContainedStrokeIcon className={styles} role="img" />;
        case 'warning':
            return <WarningStrokeIcon className={styles} role="img" />;
        case 'info':
            return <InfoStrokeIcon className={styles} role="img" />;
        case 'success':
            return <CheckContainedStrokeIcon className={styles} role="img" />;
    }
};
