/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApproveIngredientTagsRequestDTO } from '../models';
// @ts-ignore
import { CreateIngredientRequestDTO } from '../models';
// @ts-ignore
import { CreateIngredientResponseDTO } from '../models';
// @ts-ignore
import { ImageUploadUrlResponseDTO } from '../models';
// @ts-ignore
import { IngredientResponseDTO } from '../models';
// @ts-ignore
import { IngredientsResponseDTO } from '../models';
// @ts-ignore
import { MergeIngredientsRequestDTO } from '../models';
// @ts-ignore
import { UpdateIngredientGroceryProductsRequestDTO } from '../models';
// @ts-ignore
import { UpdateIngredientRequestDTO } from '../models';
// @ts-ignore
import { UpdateIngredientsImagesRequestDTO } from '../models';
// @ts-ignore
import { UpdateIngredientsNutritionRequestDTO } from '../models';
// @ts-ignore
import { UpdateIngredientsPrimaryMeasureRequestDTO } from '../models';
/**
 * IngredientApi - axios parameter creator
 * @export
 */
export const IngredientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Approve an Ingredient\'s tags, deeming it eligible to appear in Client-facing Recipes
         * @param {string} id The Ingredient ID
         * @param {ApproveIngredientTagsRequestDTO} ingredient Updated ingredient tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTags: async (id: string, ingredient: ApproveIngredientTagsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveTags', 'id', id)
            // verify required parameter 'ingredient' is not null or undefined
            assertParamExists('approveTags', 'ingredient', ingredient)
            const localVarPath = `/ingredients/{id}/approve-tags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ingredient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move an Ingredient to AWAITING_MERGE state
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awaitingMerge: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('awaitingMerge', 'id', id)
            const localVarPath = `/ingredients/{id}/awaiting-merge`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new Ingredient
         * @param {CreateIngredientRequestDTO} ingredient Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIngredient: async (ingredient: CreateIngredientRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ingredient' is not null or undefined
            assertParamExists('createIngredient', 'ingredient', ingredient)
            const localVarPath = `/ingredients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ingredient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deprecate ingredient with id provided. Also will deprecate any recipe containing the deprecated ingredient
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deprecate', 'id', id)
            const localVarPath = `/ingredients/{id}/deprecate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an image upload URL for an Ingredient
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateIngredientImageUploadUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateIngredientImageUploadUrl', 'id', id)
            const localVarPath = `/ingredients/{id}/image-upload-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an Ingredient by id
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIngredient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIngredient', 'id', id)
            const localVarPath = `/ingredients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ingredients by certain search parameters (paginated)
         * @param {string} [query] Filter ingredients with a canonical name search query
         * @param {'APPROVED_WITH_AVOIDANCES_AND_DIETS' | 'AWAITING_MERGE' | 'AWAITING_TAG_APPROVAL' | 'DEPRECATED'} [ingredientState] Filter ingredients by state
         * @param {boolean} [hasNutritionalData] Filter ingredients that have or do not have nutritional data
         * @param {boolean} [includedInRecipes] Filter ingredients that are included in recipes
         * @param {boolean} [groceryClinicalApprovalStatus] Omit to include all ingredients, provide true to include ingredients for which all grocery products have clinical approval, or provide false to include ingredients for which any grocery product is unapproved 
         * @param {boolean} [groceryCulinaryApprovalStatus] Omit to include all ingredients, provide true to include ingredients for which all grocery products have culinary approved, or provide false to include ingredients for which any grocery product is unapproved 
         * @param {boolean} [hasGroceryProductData] Omit to include all ingredients, provide true to include ingredients that have at least one grocery product, or provide false to include ingredients that do not have any grocery product data 
         * @param {'CANONICAL_NAME'} [sortBy] Sort results. Defaults to CANONICAL_NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIngredients: async (query?: string, ingredientState?: 'APPROVED_WITH_AVOIDANCES_AND_DIETS' | 'AWAITING_MERGE' | 'AWAITING_TAG_APPROVAL' | 'DEPRECATED', hasNutritionalData?: boolean, includedInRecipes?: boolean, groceryClinicalApprovalStatus?: boolean, groceryCulinaryApprovalStatus?: boolean, hasGroceryProductData?: boolean, sortBy?: 'CANONICAL_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ingredients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (ingredientState !== undefined) {
                localVarQueryParameter['ingredientState'] = ingredientState;
            }

            if (hasNutritionalData !== undefined) {
                localVarQueryParameter['hasNutritionalData'] = hasNutritionalData;
            }

            if (includedInRecipes !== undefined) {
                localVarQueryParameter['includedInRecipes'] = includedInRecipes;
            }

            if (groceryClinicalApprovalStatus !== undefined) {
                localVarQueryParameter['groceryClinicalApprovalStatus'] = groceryClinicalApprovalStatus;
            }

            if (groceryCulinaryApprovalStatus !== undefined) {
                localVarQueryParameter['groceryCulinaryApprovalStatus'] = groceryCulinaryApprovalStatus;
            }

            if (hasGroceryProductData !== undefined) {
                localVarQueryParameter['hasGroceryProductData'] = hasGroceryProductData;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Merge an Ingredient into a target Ingredient. Designed to combine an Ingredient that does not have nutritional information with one that does. Consequently updates all recipes that contained either Ingredient to contain the new merged version. 
         * @param {string} id The Ingredient ID
         * @param {MergeIngredientsRequestDTO} ingredient Details of the Ingredient merge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: async (id: string, ingredient: MergeIngredientsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('merge', 'id', id)
            // verify required parameter 'ingredient' is not null or undefined
            assertParamExists('merge', 'ingredient', ingredient)
            const localVarPath = `/ingredients/{id}/merge`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ingredient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an ingredient\'s preferred and substitute grocery products
         * @param {string} id The Ingredient ID
         * @param {UpdateIngredientGroceryProductsRequestDTO} representativeGroceryProducts Updated representative grocery products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroceryProducts: async (id: string, representativeGroceryProducts: UpdateIngredientGroceryProductsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroceryProducts', 'id', id)
            // verify required parameter 'representativeGroceryProducts' is not null or undefined
            assertParamExists('updateGroceryProducts', 'representativeGroceryProducts', representativeGroceryProducts)
            const localVarPath = `/ingredients/{id}/update-grocery-products`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(representativeGroceryProducts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing ingredient
         * @param {string} id The Ingredient ID
         * @param {UpdateIngredientRequestDTO} updateIngredientRequest The update Ingredient request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIngredient: async (id: string, updateIngredientRequest: UpdateIngredientRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIngredient', 'id', id)
            // verify required parameter 'updateIngredientRequest' is not null or undefined
            assertParamExists('updateIngredient', 'updateIngredientRequest', updateIngredientRequest)
            const localVarPath = `/ingredients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIngredientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update images for multiple Ingredients at once
         * @param {UpdateIngredientsImagesRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIngredientsImages: async (request: UpdateIngredientsImagesRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateIngredientsImages', 'request', request)
            const localVarPath = `/ingredients/update-images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update nutrition values for multiple ingredients
         * @param {UpdateIngredientsNutritionRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIngredientsNutrition: async (request: UpdateIngredientsNutritionRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateIngredientsNutrition', 'request', request)
            const localVarPath = `/ingredients/update-nutrition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the primary measure for multiple ingredients
         * @param {UpdateIngredientsPrimaryMeasureRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIngredientsPrimaryMeasure: async (request: UpdateIngredientsPrimaryMeasureRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateIngredientsPrimaryMeasure', 'request', request)
            const localVarPath = `/ingredients/update-primary-measure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload an image for an Ingredient
         * @param {string} id The Ingredient ID
         * @param {File} image The image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadIngredientImage: async (id: string, image: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadIngredientImage', 'id', id)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('uploadIngredientImage', 'image', image)
            const localVarPath = `/ingredients/{id}/upload-image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IngredientApi - functional programming interface
 * @export
 */
export const IngredientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IngredientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Approve an Ingredient\'s tags, deeming it eligible to appear in Client-facing Recipes
         * @param {string} id The Ingredient ID
         * @param {ApproveIngredientTagsRequestDTO} ingredient Updated ingredient tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveTags(id: string, ingredient: ApproveIngredientTagsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IngredientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveTags(id, ingredient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move an Ingredient to AWAITING_MERGE state
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async awaitingMerge(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.awaitingMerge(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new Ingredient
         * @param {CreateIngredientRequestDTO} ingredient Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIngredient(ingredient: CreateIngredientRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateIngredientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIngredient(ingredient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deprecate ingredient with id provided. Also will deprecate any recipe containing the deprecated ingredient
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deprecate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deprecate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an image upload URL for an Ingredient
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateIngredientImageUploadUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageUploadUrlResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateIngredientImageUploadUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an Ingredient by id
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIngredient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IngredientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIngredient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Ingredients by certain search parameters (paginated)
         * @param {string} [query] Filter ingredients with a canonical name search query
         * @param {'APPROVED_WITH_AVOIDANCES_AND_DIETS' | 'AWAITING_MERGE' | 'AWAITING_TAG_APPROVAL' | 'DEPRECATED'} [ingredientState] Filter ingredients by state
         * @param {boolean} [hasNutritionalData] Filter ingredients that have or do not have nutritional data
         * @param {boolean} [includedInRecipes] Filter ingredients that are included in recipes
         * @param {boolean} [groceryClinicalApprovalStatus] Omit to include all ingredients, provide true to include ingredients for which all grocery products have clinical approval, or provide false to include ingredients for which any grocery product is unapproved 
         * @param {boolean} [groceryCulinaryApprovalStatus] Omit to include all ingredients, provide true to include ingredients for which all grocery products have culinary approved, or provide false to include ingredients for which any grocery product is unapproved 
         * @param {boolean} [hasGroceryProductData] Omit to include all ingredients, provide true to include ingredients that have at least one grocery product, or provide false to include ingredients that do not have any grocery product data 
         * @param {'CANONICAL_NAME'} [sortBy] Sort results. Defaults to CANONICAL_NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIngredients(query?: string, ingredientState?: 'APPROVED_WITH_AVOIDANCES_AND_DIETS' | 'AWAITING_MERGE' | 'AWAITING_TAG_APPROVAL' | 'DEPRECATED', hasNutritionalData?: boolean, includedInRecipes?: boolean, groceryClinicalApprovalStatus?: boolean, groceryCulinaryApprovalStatus?: boolean, hasGroceryProductData?: boolean, sortBy?: 'CANONICAL_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IngredientsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIngredients(query, ingredientState, hasNutritionalData, includedInRecipes, groceryClinicalApprovalStatus, groceryCulinaryApprovalStatus, hasGroceryProductData, sortBy, sortDir, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Merge an Ingredient into a target Ingredient. Designed to combine an Ingredient that does not have nutritional information with one that does. Consequently updates all recipes that contained either Ingredient to contain the new merged version. 
         * @param {string} id The Ingredient ID
         * @param {MergeIngredientsRequestDTO} ingredient Details of the Ingredient merge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merge(id: string, ingredient: MergeIngredientsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merge(id, ingredient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an ingredient\'s preferred and substitute grocery products
         * @param {string} id The Ingredient ID
         * @param {UpdateIngredientGroceryProductsRequestDTO} representativeGroceryProducts Updated representative grocery products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroceryProducts(id: string, representativeGroceryProducts: UpdateIngredientGroceryProductsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IngredientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroceryProducts(id, representativeGroceryProducts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing ingredient
         * @param {string} id The Ingredient ID
         * @param {UpdateIngredientRequestDTO} updateIngredientRequest The update Ingredient request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIngredient(id: string, updateIngredientRequest: UpdateIngredientRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIngredient(id, updateIngredientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update images for multiple Ingredients at once
         * @param {UpdateIngredientsImagesRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIngredientsImages(request: UpdateIngredientsImagesRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIngredientsImages(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update nutrition values for multiple ingredients
         * @param {UpdateIngredientsNutritionRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIngredientsNutrition(request: UpdateIngredientsNutritionRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIngredientsNutrition(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the primary measure for multiple ingredients
         * @param {UpdateIngredientsPrimaryMeasureRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIngredientsPrimaryMeasure(request: UpdateIngredientsPrimaryMeasureRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIngredientsPrimaryMeasure(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload an image for an Ingredient
         * @param {string} id The Ingredient ID
         * @param {File} image The image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadIngredientImage(id: string, image: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadIngredientImage(id, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IngredientApi - factory interface
 * @export
 */
export const IngredientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IngredientApiFp(configuration)
    return {
        /**
         * 
         * @summary Approve an Ingredient\'s tags, deeming it eligible to appear in Client-facing Recipes
         * @param {string} id The Ingredient ID
         * @param {ApproveIngredientTagsRequestDTO} ingredient Updated ingredient tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTags(id: string, ingredient: ApproveIngredientTagsRequestDTO, options?: any): AxiosPromise<IngredientResponseDTO> {
            return localVarFp.approveTags(id, ingredient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move an Ingredient to AWAITING_MERGE state
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awaitingMerge(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.awaitingMerge(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new Ingredient
         * @param {CreateIngredientRequestDTO} ingredient Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIngredient(ingredient: CreateIngredientRequestDTO, options?: any): AxiosPromise<CreateIngredientResponseDTO> {
            return localVarFp.createIngredient(ingredient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deprecate ingredient with id provided. Also will deprecate any recipe containing the deprecated ingredient
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deprecate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an image upload URL for an Ingredient
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateIngredientImageUploadUrl(id: string, options?: any): AxiosPromise<ImageUploadUrlResponseDTO> {
            return localVarFp.generateIngredientImageUploadUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an Ingredient by id
         * @param {string} id The Ingredient ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIngredient(id: string, options?: any): AxiosPromise<IngredientResponseDTO> {
            return localVarFp.getIngredient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ingredients by certain search parameters (paginated)
         * @param {string} [query] Filter ingredients with a canonical name search query
         * @param {'APPROVED_WITH_AVOIDANCES_AND_DIETS' | 'AWAITING_MERGE' | 'AWAITING_TAG_APPROVAL' | 'DEPRECATED'} [ingredientState] Filter ingredients by state
         * @param {boolean} [hasNutritionalData] Filter ingredients that have or do not have nutritional data
         * @param {boolean} [includedInRecipes] Filter ingredients that are included in recipes
         * @param {boolean} [groceryClinicalApprovalStatus] Omit to include all ingredients, provide true to include ingredients for which all grocery products have clinical approval, or provide false to include ingredients for which any grocery product is unapproved 
         * @param {boolean} [groceryCulinaryApprovalStatus] Omit to include all ingredients, provide true to include ingredients for which all grocery products have culinary approved, or provide false to include ingredients for which any grocery product is unapproved 
         * @param {boolean} [hasGroceryProductData] Omit to include all ingredients, provide true to include ingredients that have at least one grocery product, or provide false to include ingredients that do not have any grocery product data 
         * @param {'CANONICAL_NAME'} [sortBy] Sort results. Defaults to CANONICAL_NAME
         * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
         * @param {number} [limit] Limit query for pagination. Defaults to 100
         * @param {number} [offset] Offset query for pagination. Defaults to 0.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIngredients(query?: string, ingredientState?: 'APPROVED_WITH_AVOIDANCES_AND_DIETS' | 'AWAITING_MERGE' | 'AWAITING_TAG_APPROVAL' | 'DEPRECATED', hasNutritionalData?: boolean, includedInRecipes?: boolean, groceryClinicalApprovalStatus?: boolean, groceryCulinaryApprovalStatus?: boolean, hasGroceryProductData?: boolean, sortBy?: 'CANONICAL_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: any): AxiosPromise<IngredientsResponseDTO> {
            return localVarFp.getIngredients(query, ingredientState, hasNutritionalData, includedInRecipes, groceryClinicalApprovalStatus, groceryCulinaryApprovalStatus, hasGroceryProductData, sortBy, sortDir, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Merge an Ingredient into a target Ingredient. Designed to combine an Ingredient that does not have nutritional information with one that does. Consequently updates all recipes that contained either Ingredient to contain the new merged version. 
         * @param {string} id The Ingredient ID
         * @param {MergeIngredientsRequestDTO} ingredient Details of the Ingredient merge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge(id: string, ingredient: MergeIngredientsRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.merge(id, ingredient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an ingredient\'s preferred and substitute grocery products
         * @param {string} id The Ingredient ID
         * @param {UpdateIngredientGroceryProductsRequestDTO} representativeGroceryProducts Updated representative grocery products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroceryProducts(id: string, representativeGroceryProducts: UpdateIngredientGroceryProductsRequestDTO, options?: any): AxiosPromise<IngredientResponseDTO> {
            return localVarFp.updateGroceryProducts(id, representativeGroceryProducts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing ingredient
         * @param {string} id The Ingredient ID
         * @param {UpdateIngredientRequestDTO} updateIngredientRequest The update Ingredient request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIngredient(id: string, updateIngredientRequest: UpdateIngredientRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateIngredient(id, updateIngredientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update images for multiple Ingredients at once
         * @param {UpdateIngredientsImagesRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIngredientsImages(request: UpdateIngredientsImagesRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateIngredientsImages(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update nutrition values for multiple ingredients
         * @param {UpdateIngredientsNutritionRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIngredientsNutrition(request: UpdateIngredientsNutritionRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateIngredientsNutrition(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the primary measure for multiple ingredients
         * @param {UpdateIngredientsPrimaryMeasureRequestDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIngredientsPrimaryMeasure(request: UpdateIngredientsPrimaryMeasureRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateIngredientsPrimaryMeasure(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload an image for an Ingredient
         * @param {string} id The Ingredient ID
         * @param {File} image The image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadIngredientImage(id: string, image: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadIngredientImage(id, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IngredientApi - object-oriented interface
 * @export
 * @class IngredientApi
 * @extends {BaseAPI}
 */
export class IngredientApi extends BaseAPI {
    /**
     * 
     * @summary Approve an Ingredient\'s tags, deeming it eligible to appear in Client-facing Recipes
     * @param {string} id The Ingredient ID
     * @param {ApproveIngredientTagsRequestDTO} ingredient Updated ingredient tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public approveTags(id: string, ingredient: ApproveIngredientTagsRequestDTO, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).approveTags(id, ingredient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move an Ingredient to AWAITING_MERGE state
     * @param {string} id The Ingredient ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public awaitingMerge(id: string, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).awaitingMerge(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new Ingredient
     * @param {CreateIngredientRequestDTO} ingredient Ingredient resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public createIngredient(ingredient: CreateIngredientRequestDTO, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).createIngredient(ingredient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deprecate ingredient with id provided. Also will deprecate any recipe containing the deprecated ingredient
     * @param {string} id The Ingredient ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public deprecate(id: string, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).deprecate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an image upload URL for an Ingredient
     * @param {string} id The Ingredient ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public generateIngredientImageUploadUrl(id: string, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).generateIngredientImageUploadUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an Ingredient by id
     * @param {string} id The Ingredient ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public getIngredient(id: string, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).getIngredient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ingredients by certain search parameters (paginated)
     * @param {string} [query] Filter ingredients with a canonical name search query
     * @param {'APPROVED_WITH_AVOIDANCES_AND_DIETS' | 'AWAITING_MERGE' | 'AWAITING_TAG_APPROVAL' | 'DEPRECATED'} [ingredientState] Filter ingredients by state
     * @param {boolean} [hasNutritionalData] Filter ingredients that have or do not have nutritional data
     * @param {boolean} [includedInRecipes] Filter ingredients that are included in recipes
     * @param {boolean} [groceryClinicalApprovalStatus] Omit to include all ingredients, provide true to include ingredients for which all grocery products have clinical approval, or provide false to include ingredients for which any grocery product is unapproved 
     * @param {boolean} [groceryCulinaryApprovalStatus] Omit to include all ingredients, provide true to include ingredients for which all grocery products have culinary approved, or provide false to include ingredients for which any grocery product is unapproved 
     * @param {boolean} [hasGroceryProductData] Omit to include all ingredients, provide true to include ingredients that have at least one grocery product, or provide false to include ingredients that do not have any grocery product data 
     * @param {'CANONICAL_NAME'} [sortBy] Sort results. Defaults to CANONICAL_NAME
     * @param {'ASC' | 'DESC'} [sortDir] Sort direction. Defaults to ASC
     * @param {number} [limit] Limit query for pagination. Defaults to 100
     * @param {number} [offset] Offset query for pagination. Defaults to 0.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public getIngredients(query?: string, ingredientState?: 'APPROVED_WITH_AVOIDANCES_AND_DIETS' | 'AWAITING_MERGE' | 'AWAITING_TAG_APPROVAL' | 'DEPRECATED', hasNutritionalData?: boolean, includedInRecipes?: boolean, groceryClinicalApprovalStatus?: boolean, groceryCulinaryApprovalStatus?: boolean, hasGroceryProductData?: boolean, sortBy?: 'CANONICAL_NAME', sortDir?: 'ASC' | 'DESC', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).getIngredients(query, ingredientState, hasNutritionalData, includedInRecipes, groceryClinicalApprovalStatus, groceryCulinaryApprovalStatus, hasGroceryProductData, sortBy, sortDir, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Merge an Ingredient into a target Ingredient. Designed to combine an Ingredient that does not have nutritional information with one that does. Consequently updates all recipes that contained either Ingredient to contain the new merged version. 
     * @param {string} id The Ingredient ID
     * @param {MergeIngredientsRequestDTO} ingredient Details of the Ingredient merge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public merge(id: string, ingredient: MergeIngredientsRequestDTO, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).merge(id, ingredient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an ingredient\'s preferred and substitute grocery products
     * @param {string} id The Ingredient ID
     * @param {UpdateIngredientGroceryProductsRequestDTO} representativeGroceryProducts Updated representative grocery products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public updateGroceryProducts(id: string, representativeGroceryProducts: UpdateIngredientGroceryProductsRequestDTO, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).updateGroceryProducts(id, representativeGroceryProducts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing ingredient
     * @param {string} id The Ingredient ID
     * @param {UpdateIngredientRequestDTO} updateIngredientRequest The update Ingredient request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public updateIngredient(id: string, updateIngredientRequest: UpdateIngredientRequestDTO, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).updateIngredient(id, updateIngredientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update images for multiple Ingredients at once
     * @param {UpdateIngredientsImagesRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public updateIngredientsImages(request: UpdateIngredientsImagesRequestDTO, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).updateIngredientsImages(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update nutrition values for multiple ingredients
     * @param {UpdateIngredientsNutritionRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public updateIngredientsNutrition(request: UpdateIngredientsNutritionRequestDTO, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).updateIngredientsNutrition(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the primary measure for multiple ingredients
     * @param {UpdateIngredientsPrimaryMeasureRequestDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public updateIngredientsPrimaryMeasure(request: UpdateIngredientsPrimaryMeasureRequestDTO, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).updateIngredientsPrimaryMeasure(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload an image for an Ingredient
     * @param {string} id The Ingredient ID
     * @param {File} image The image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public uploadIngredientImage(id: string, image: File, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).uploadIngredientImage(id, image, options).then((request) => request(this.axios, this.basePath));
    }
}
