import logger from 'utils/logger';
import type { NativeAppInboundMessageTypes, NativeAppOutboundMessageTypes } from './messageTypes';

const userAgentList = navigator.userAgent.split(' ');
const lastAgentEntry = userAgentList[userAgentList.length - 1];
const isNativeWrapper = lastAgentEntry.startsWith('SeasonNativeApp');
const lastAgentEntryParts = lastAgentEntry.split('/');
export const isRunningInsideSeasonNativeApp = (window.ReactNativeWebView && isNativeWrapper) || false;
export const nativeAppOS =
    isRunningInsideSeasonNativeApp && lastAgentEntryParts.length > 1 ? lastAgentEntryParts[1] : 'unknown';

const versionPartsToMap = ([major, minor, patch]: [number, number, number]) => ({
    major,
    minor,
    patch,
});
/**
 *
 * Helper to parse the version string from user agent, if provided
 *
 * @param version the version string in semver format (major.minor.patch)
 * @returns an object with major, minor, patch versions -- default is {major: -1, minor: -1, patch: -1}
 */
export const getNativeAppVersion = (version: string | undefined) => {
    if (version == null) {
        return versionPartsToMap([-1, -1, -1]);
    }

    try {
        const parts = version.trim().split('.');

        if (parts.length < 3) {
            return versionPartsToMap([-1, -1, -1]);
        }

        return versionPartsToMap(
            parts.slice(0, 3).map((it) => {
                const part = parseInt(it);
                if (isNaN(part)) {
                    throw Error();
                }
                return part;
            }) as [number, number, number]
        );
    } catch {
        return versionPartsToMap([-1, -1, -1]);
    }
};

export type UnsubscribeHandlerFunction = () => void;

export const nativeAppVersion = getNativeAppVersion(lastAgentEntryParts[2]);

/**
 *
 * @param messageType The message being sent by the Native App that you wish to subscribe to
 * @param handler The handler that will run when the message is received
 *
 * @returns The unsubscribe handler you should call when cleaning up event listeners
 */
export function subscribeToNativeAppInboundMessage(
    messageType: NativeAppInboundMessageTypes,
    handler: EventListener
): UnsubscribeHandlerFunction {
    logger.debug('Added event listener to listen for google token from native app');
    let unsubscribeHandler: UnsubscribeHandlerFunction = () => {
        return;
    };

    if (isRunningInsideSeasonNativeApp) {
        window.addEventListener(messageType, handler);

        unsubscribeHandler = () => {
            window.removeEventListener(messageType, handler);
        };
    }

    return unsubscribeHandler;
}

export function postMessageToNativeApp(
    messageType: NativeAppOutboundMessageTypes,
    payload?: Record<string, unknown>
): void {
    if (isRunningInsideSeasonNativeApp) {
        logger.debug(`Posting ${messageType}`, { payload });
        window.ReactNativeWebView?.postMessage(JSON.stringify({ ...payload, name: messageType }));
    }
}
