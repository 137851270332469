import clsx from 'clsx';
import Button from 'galley/Button';
import { XStrokeIcon } from 'galley/Icons';
import type { PropsWithChildren } from 'react';
import type { AlertVariant } from 'store/alerts/types';
import { AlertIcon } from './AlertIcon';

export type Props = PropsWithChildren<{
    variant: AlertVariant;
    message: string | JSX.Element;
    onCloseClick: () => void;
}>;

export const Alert = ({ message, variant, onCloseClick }: Props): JSX.Element => {
    const className = clsx(
        'bodySmLight flex !w-full flex-row items-center justify-center gap-x-2 rounded-lg px-4 py-3 shadow-sm',
        {
            'bg-alert-lightRed text-traffic-red': variant === 'error',
            'bg-alert-lightYellow text-greyscale-offBlack': variant === 'warning',
            'bg-lowBlue-1 text-greyscale-offBlack': variant === 'info',
            'bg-alert-lightGreen text-traffic-green': variant === 'success',
        }
    );

    return (
        <div className={className} role="alert">
            <AlertIcon variant={variant} />
            <p>{message}</p>
            <Button onClick={onCloseClick} className="ml-4">
                <XStrokeIcon className="smIcon text-greyscale-70" />
            </Button>
        </div>
    );
};
