import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDownStrokeIcon } from 'galley/Icons';
import { useState } from 'react';

export type Props = React.PropsWithChildren<{
    header: string | React.ReactNode;
    id?: string;
    className?: {
        root?: string;
        header?: string;
        svg?: string;
        body?: string;
    };
    isExpandedOverride?: boolean;
    onToggle?: (isExpanded: boolean) => void;
    disabled?: boolean;
}>;

export const Accordion = ({
    header,
    id,
    className,
    isExpandedOverride,
    children,
    onToggle,
    disabled,
}: Props): JSX.Element => {
    const [isExpandedDefault, setIsExpandedDefault] = useState(false);

    const isExpanded = isExpandedOverride ?? isExpandedDefault;

    const { open, closed, transition } = {
        open: { height: 'auto', scale: 1 },
        closed: { height: 0, overflow: 'hidden', scale: 0.8 },
        transition: { ease: [0.04, 0.62, 0.23, 0.98], duration: 0.4 },
    };

    const onInteraction = () => {
        if (disabled) {
            return;
        }

        onToggle?.(isExpanded);
        if (isExpandedOverride == null) {
            setIsExpandedDefault((prevState) => !prevState);
        }
    };

    return (
        <div className={clsx(className?.root)}>
            <div
                role="button"
                id={id}
                aria-expanded={isExpanded}
                tabIndex={0}
                onKeyDown={onInteraction}
                onClick={onInteraction}
                className={clsx(
                    'relative flex w-full items-center justify-between gap-4 py-4',
                    disabled && '!bg-greyscale-20 pointer-events-none cursor-default',
                    className?.header
                )}
            >
                {header}
                <ChevronDownStrokeIcon
                    className={clsx(
                        'mdIcon transition-all duration-300',
                        isExpanded ? '-rotate-180' : 'rotate-0',
                        className?.svg
                    )}
                />
            </div>
            <AnimatePresence>
                {isExpanded && (
                    <motion.div animate={open} exit={closed} initial={closed} transition={transition}>
                        <div
                            role="region"
                            aria-labelledby={id}
                            aria-hidden={!isExpanded}
                            className={clsx('pb-4', className?.body)}
                        >
                            {children}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Accordion;
