/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum AgreementTypeDTO {
    FINANCIAL_RESPONSIBILITY = 'FINANCIAL_RESPONSIBILITY',
    HIPAA_PRIVACY_NOTICE = 'HIPAA_PRIVACY_NOTICE',
    PROVIDER_BENEFITS = 'PROVIDER_BENEFITS',
    RELEASE_MEDICAL_INFORMATION = 'RELEASE_MEDICAL_INFORMATION'
};

