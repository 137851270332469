import type { EmailDTO, PhoneNumberDTO } from 'API';
import { CohortDTO, DietDTO } from 'API';
import { parsePhoneNumber } from 'libphonenumber-js';
import { toReadableFraction } from 'readable-fractions';
import logger from 'utils/logger';

export function toTitleCase(str: string): string {
    return str.replace(/\w*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
}

export function toTitleCaseAndSpaces(str: string): string {
    const titleCased = str
        .replace(/[-_]/gi, ' ')
        .replace(/\s+/g, ' ')
        .replace(/\w*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        });
    if (titleCased.toLowerCase().endsWith("'s")) {
        return titleCased.substring(0, titleCased.length - 2) + "'s";
    } else return titleCased;
}

export const dedupeStringArray = (array: string[]): string[] => {
    return array.filter((item, index) => array.indexOf(item) === index && item !== '');
};

export const capitalize = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
/**
 * Get the primary email from an array of Emails objects
 *
 * @param emails array of Emails
 *
 * @returns The primary email address, or the first one if none is marked as primary
 */
export const getPrimaryEmail = (emails: Array<EmailDTO>): string => {
    const primaryEmail = emails.find((email) => email.isPrimary) || emails[0];
    return primaryEmail?.emailAddress || '';
};

/**
 * Get the primary phone number from an array of PhoneNumber objects
 *
 * @param phones array of PhoneNumbers
 *
 * @returns The primary phone number, or the first one if none is marked as primary
 */
export const getPrimaryPhoneNumber = (phones: Array<PhoneNumberDTO>): string | undefined => {
    const primaryPhone = phones.find((phone) => phone.isPrimary) || phones[0];
    return primaryPhone?.phoneNumber;
};

/**
 * Formats any phone number into 10 digit separated with dashes ###-###-####
 *
 * @param phoneNumber phone number in any format
 *
 * @returns Formatted phone number
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
    return parsePhoneNumber(phoneNumber).format('NATIONAL');
};

/**
 * Gets the initials from a user, without any space.
 *
 * @param firstName User's first name
 * @param lastName User's last name
 *
 * @returns User's initials in uppercase
 */
export const getInitials = (firstName: string, lastName: string): string => {
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
    return initials.toUpperCase();
};

/**
 * Returns an string showing the hours and minutes based on a total minutes integer
 *
 * @param mins total minutes
 *
 * @return string showing the hours and minutes
 */
export const formatMinutesToHrsMinsStr = (mins: number): string => {
    if (mins <= 0) {
        return '-';
    }

    const h = Math.floor(mins / 60);
    const m = mins % 60;
    const parts = [];
    if (h > 0) parts.push(`${h} hr`);
    if (m > 0) parts.push(`${m} min`);

    return parts.join(' ');
};

export const displayDietName = (diet: DietDTO): string => {
    if (diet === DietDTO.DASH) return 'DASH 2300';
    if (diet === DietDTO.DASH_1500) return 'DASH 1500';
    else return toTitleCaseAndSpaces(diet);
};

export const UNIT_MEASUREMENTS_TO_EXCLUDE = ['ITEM', 'EACH', 'LARGE'];

export const formatIngredientToReadableFraction = (
    quantity: number,
    maxQuantity: number = quantity,
    unit: string,
    ingredientName: string
): string => {
    const readableQuantity = formatQuantity(quantity, maxQuantity);
    const formattedUnit = UNIT_MEASUREMENTS_TO_EXCLUDE.includes(unit)
        ? ''
        : unit.toLowerCase().replace('_', ' ') + (maxQuantity && (quantity || maxQuantity) <= 1 ? ' ' : 's ');

    return `${readableQuantity} ${formattedUnit}${ingredientName.toLocaleLowerCase()}`.trim();
};

export const formatQuantity = (quantity: number, maxQuantity: number): string => {
    return toReadableFraction(quantity === 0 && maxQuantity ? maxQuantity : quantity, true) as string;
};

export const textWithFractionsToString = (text: string): string => {
    const numberRegex = new RegExp(/(\d+\/\d+)|(\d*\.\d+)|(\d+(\s\d+\/\d+)*)/gm);
    return text.replace(numberRegex, (item: string) => {
        let calcTotal = 0;
        const itemArray = item.split(' ').map((it) => it.replace(/^(0+)/, ''));

        if (itemArray.length > 1) {
            //eslint-disable-next-line no-eval
            calcTotal = eval(itemArray[0]) + eval(itemArray[1]);
        } else {
            //eslint-disable-next-line no-eval
            calcTotal = eval(itemArray[0]);
        }
        const readableFraction = toReadableFraction(calcTotal, true) as string;
        const components = readableFraction.split(' ');

        if (!Number.isInteger(calcTotal)) {
            if (calcTotal > 1) {
                return `${components[0] && components[1] ? `${components[0]} ${components[1]}` : `<${components[0]}`}`;
            } else {
                return `${components[0]}`;
            }
        } else {
            return item;
        }
    });
};

export const dollarsToCents = (dollars: number | string | undefined): number | undefined => {
    if (dollars != null && (Number.isNaN(dollars) || Number.isNaN(Number(dollars)))) {
        logger.error(`Can't convert value ${dollars} of type ${typeof dollars} to cents`);
        return;
    }

    if (typeof dollars === 'number') {
        return Math.round(dollars * 100);
    } else if (typeof dollars === 'string' && dollars.trim() !== '') {
        return Math.round(Number(dollars) * 100);
    }

    return;
};

export const centsToDollars = (cents: number | string | undefined): number | undefined => {
    if (cents != null && (Number.isNaN(cents) || Number.isNaN(Number(cents)))) {
        logger.error(`Can't convert value ${cents} of type ${typeof cents} to dollars`);
        return;
    }

    if (typeof cents === 'number') {
        return cents / 100.0;
    } else if (typeof cents === 'string' && cents.trim() !== '') {
        return Number(cents) / 100.0;
    }

    return;
};

export const toNearestQuarter = (cents: number) => {
    return Math.round((centsToDollars(cents) ?? 0) * 4) / 4;
};

interface CurrencyStringFormatOptions {
    truncateWholeNumbers?: boolean;
}

export const dollarsToCurrencyString = (dollars: number, options: CurrencyStringFormatOptions = {}): string => {
    const shouldTruncateWholeNumbers = options.truncateWholeNumbers && dollars % 1 === 0;
    const numberFormatOptions = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: shouldTruncateWholeNumbers ? 0 : undefined,
        minimumFractionDigits: shouldTruncateWholeNumbers ? 0 : undefined,
    };
    const formatter = new Intl.NumberFormat('en-US', numberFormatOptions);
    return formatter.format(dollars);
};

export const optionalCentsToCurrencyString = (
    cents: string | number | undefined,
    isSubtractingField: boolean
): string => {
    if (cents == null) return '-';
    return isSubtractingField ? `-${centsToCurrencyString(cents as number)}` : centsToCurrencyString(cents as number);
};

export const centsToCurrencyString = (cents: number, options: CurrencyStringFormatOptions = {}): string => {
    return dollarsToCurrencyString(centsToDollars(cents) ?? 0, options);
};

export const centsToEstimatedCurrencyString = (cents: number): string => {
    const centsToNearestQuarter = toNearestQuarter(cents);
    return '$' + centsToNearestQuarter.toFixed(2);
};

export const stringToNumberOrUndefined = (value?: string): number | undefined => {
    return !isNaN(Number(value)) && value && value.length > 0 ? Number(value) : undefined;
};

export const integerToOrdinal = (n: number): string => {
    const suffix = ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
    return n + suffix;
};

export const bufferToBase64String = (arrayBuffer: ArrayBuffer): string => {
    return window.btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
};

export const DISPLAY_COHORT_NAME: Record<CohortDTO, string> = {
    /* eslint-disable lingui/no-unlocalized-strings -- Cohort names */
    [CohortDTO.BCBS_AZ]: 'BCBS AZ',
    [CohortDTO.BCBS_AZ_GROUP_50]: 'BCBS AZ GROUP 50',
    [CohortDTO.COMMON_SPIRIT]: 'Common Spirit',
    [CohortDTO.GEISINGER]: 'Geisinger',
    [CohortDTO.HEALTH_ALIGN]: 'Health Align',
    [CohortDTO.MEDICAL_ASSOCIATES]: 'Medical Associates',
    [CohortDTO.PUBLIC]: 'Public',
    [CohortDTO.SEASON_EMPLOYEE]: 'Season employee',
    [CohortDTO.GA_FOODS]: 'GA Foods',
    [CohortDTO.FROEDTERT]: 'Froedtert',
    [CohortDTO.DEMO]: 'Demo',
    [CohortDTO.UNITED_HEALTHCARE]: 'United Healthcare',
    [CohortDTO.OPTIM]: 'Optim',
    [CohortDTO.EVRY]: 'Evry Health',
    [CohortDTO.CUSTOM_MADE]: 'Custom Made',
    [CohortDTO.STEWARD_FLEX_SERVICES]: 'Steward Flex Services',
    [CohortDTO.STEWARD_HYPERTENSION]: 'Steward Hypertension',
    [CohortDTO.UT_SOUTHWESTERN]: 'UT Southwestern',
    /* eslint-disable lingui/no-unlocalized-strings -- Cohort names */
};
