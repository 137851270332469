/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum InsurancePolicyStateDTO {
    INVALIDATED = 'INVALIDATED',
    PENDING = 'PENDING',
    CONDITION_DOCUMENTATION_FETCHED = 'CONDITION_DOCUMENTATION_FETCHED',
    VERIFIED = 'VERIFIED'
};

