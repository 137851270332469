/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMealRequestDTO } from '../models';
// @ts-ignore
import { DeleteMealsRequestDTO } from '../models';
// @ts-ignore
import { GetClientMealsResponseDTO } from '../models';
// @ts-ignore
import { GetRecipeFeedbackResponseDTO } from '../models';
// @ts-ignore
import { MarkFoodOrderLineItemAsConsumedRequestDTO } from '../models';
// @ts-ignore
import { MarkMealAsPreparedRequestDTO } from '../models';
// @ts-ignore
import { MealResponseDTO } from '../models';
/**
 * MealApi - axios parameter creator
 * @export
 */
export const MealApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create meal for client
         * @param {CreateMealRequestDTO} createMealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeal: async (createMealRequest: CreateMealRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMealRequest' is not null or undefined
            assertParamExists('createMeal', 'createMealRequest', createMealRequest)
            const localVarPath = `/meals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMealRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete meals
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMeal', 'id', id)
            const localVarPath = `/meals/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete meals
         * @param {DeleteMealsRequestDTO} deleteMealsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeals: async (deleteMealsRequest: DeleteMealsRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteMealsRequest' is not null or undefined
            assertParamExists('deleteMeals', 'deleteMealsRequest', deleteMealsRequest)
            const localVarPath = `/meals/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteMealsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get meal recommendations for client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveMeals: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getActiveMeals', 'clientId', clientId)
            const localVarPath = `/meals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get meal
         * @param {string} id Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMeal', 'id', id)
            const localVarPath = `/meals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipe feedback
         * @param {string} clientId Client ID
         * @param {Array<string>} recipeIds Recipe IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeFeedback: async (clientId: string, recipeIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getRecipeFeedback', 'clientId', clientId)
            // verify required parameter 'recipeIds' is not null or undefined
            assertParamExists('getRecipeFeedback', 'recipeIds', recipeIds)
            const localVarPath = `/meals/recipe-feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (recipeIds) {
                localVarQueryParameter['recipeIds'] = recipeIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a food order line item as consumed, with feedback
         * @param {string} id Food Order ID
         * @param {MarkFoodOrderLineItemAsConsumedRequestDTO} markFoodOrderLineItemAsConsumedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFoodOrderLineItemAsConsumed: async (id: string, markFoodOrderLineItemAsConsumedRequest: MarkFoodOrderLineItemAsConsumedRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markFoodOrderLineItemAsConsumed', 'id', id)
            // verify required parameter 'markFoodOrderLineItemAsConsumedRequest' is not null or undefined
            assertParamExists('markFoodOrderLineItemAsConsumed', 'markFoodOrderLineItemAsConsumedRequest', markFoodOrderLineItemAsConsumedRequest)
            const localVarPath = `/food_orders/{id}/mark-line-item-as-consumed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markFoodOrderLineItemAsConsumedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a meal as not prepared
         * @param {string} id Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMealAsNotPrepared: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markMealAsNotPrepared', 'id', id)
            const localVarPath = `/meals/{id}/mark-as-not-prepared`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a meal as prepared, with feedback
         * @param {string} id Meal ID
         * @param {MarkMealAsPreparedRequestDTO} markMealAsPreparedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMealAsPrepared: async (id: string, markMealAsPreparedRequest: MarkMealAsPreparedRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markMealAsPrepared', 'id', id)
            // verify required parameter 'markMealAsPreparedRequest' is not null or undefined
            assertParamExists('markMealAsPrepared', 'markMealAsPreparedRequest', markMealAsPreparedRequest)
            const localVarPath = `/meals/{id}/mark-as-prepared`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markMealAsPreparedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MealApi - functional programming interface
 * @export
 */
export const MealApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MealApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create meal for client
         * @param {CreateMealRequestDTO} createMealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMeal(createMealRequest: CreateMealRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMeal(createMealRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete meals
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete meals
         * @param {DeleteMealsRequestDTO} deleteMealsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeals(deleteMealsRequest: DeleteMealsRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeals(deleteMealsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get meal recommendations for client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveMeals(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientMealsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveMeals(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get meal
         * @param {string} id Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipe feedback
         * @param {string} clientId Client ID
         * @param {Array<string>} recipeIds Recipe IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeFeedback(clientId: string, recipeIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecipeFeedbackResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeFeedback(clientId, recipeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a food order line item as consumed, with feedback
         * @param {string} id Food Order ID
         * @param {MarkFoodOrderLineItemAsConsumedRequestDTO} markFoodOrderLineItemAsConsumedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markFoodOrderLineItemAsConsumed(id: string, markFoodOrderLineItemAsConsumedRequest: MarkFoodOrderLineItemAsConsumedRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markFoodOrderLineItemAsConsumed(id, markFoodOrderLineItemAsConsumedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a meal as not prepared
         * @param {string} id Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMealAsNotPrepared(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMealAsNotPrepared(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a meal as prepared, with feedback
         * @param {string} id Meal ID
         * @param {MarkMealAsPreparedRequestDTO} markMealAsPreparedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMealAsPrepared(id: string, markMealAsPreparedRequest: MarkMealAsPreparedRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMealAsPrepared(id, markMealAsPreparedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MealApi - factory interface
 * @export
 */
export const MealApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MealApiFp(configuration)
    return {
        /**
         * 
         * @summary Create meal for client
         * @param {CreateMealRequestDTO} createMealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeal(createMealRequest: CreateMealRequestDTO, options?: any): AxiosPromise<MealResponseDTO> {
            return localVarFp.createMeal(createMealRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete meals
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeal(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMeal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete meals
         * @param {DeleteMealsRequestDTO} deleteMealsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeals(deleteMealsRequest: DeleteMealsRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMeals(deleteMealsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get meal recommendations for client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveMeals(clientId: string, options?: any): AxiosPromise<GetClientMealsResponseDTO> {
            return localVarFp.getActiveMeals(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get meal
         * @param {string} id Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeal(id: string, options?: any): AxiosPromise<MealResponseDTO> {
            return localVarFp.getMeal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipe feedback
         * @param {string} clientId Client ID
         * @param {Array<string>} recipeIds Recipe IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeFeedback(clientId: string, recipeIds: Array<string>, options?: any): AxiosPromise<GetRecipeFeedbackResponseDTO> {
            return localVarFp.getRecipeFeedback(clientId, recipeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a food order line item as consumed, with feedback
         * @param {string} id Food Order ID
         * @param {MarkFoodOrderLineItemAsConsumedRequestDTO} markFoodOrderLineItemAsConsumedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFoodOrderLineItemAsConsumed(id: string, markFoodOrderLineItemAsConsumedRequest: MarkFoodOrderLineItemAsConsumedRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.markFoodOrderLineItemAsConsumed(id, markFoodOrderLineItemAsConsumedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a meal as not prepared
         * @param {string} id Meal ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMealAsNotPrepared(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.markMealAsNotPrepared(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a meal as prepared, with feedback
         * @param {string} id Meal ID
         * @param {MarkMealAsPreparedRequestDTO} markMealAsPreparedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMealAsPrepared(id: string, markMealAsPreparedRequest: MarkMealAsPreparedRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.markMealAsPrepared(id, markMealAsPreparedRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MealApi - object-oriented interface
 * @export
 * @class MealApi
 * @extends {BaseAPI}
 */
export class MealApi extends BaseAPI {
    /**
     * 
     * @summary Create meal for client
     * @param {CreateMealRequestDTO} createMealRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public createMeal(createMealRequest: CreateMealRequestDTO, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).createMeal(createMealRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete meals
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public deleteMeal(id: string, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).deleteMeal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete meals
     * @param {DeleteMealsRequestDTO} deleteMealsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public deleteMeals(deleteMealsRequest: DeleteMealsRequestDTO, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).deleteMeals(deleteMealsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get meal recommendations for client
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public getActiveMeals(clientId: string, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).getActiveMeals(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get meal
     * @param {string} id Meal ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public getMeal(id: string, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).getMeal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipe feedback
     * @param {string} clientId Client ID
     * @param {Array<string>} recipeIds Recipe IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public getRecipeFeedback(clientId: string, recipeIds: Array<string>, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).getRecipeFeedback(clientId, recipeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a food order line item as consumed, with feedback
     * @param {string} id Food Order ID
     * @param {MarkFoodOrderLineItemAsConsumedRequestDTO} markFoodOrderLineItemAsConsumedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public markFoodOrderLineItemAsConsumed(id: string, markFoodOrderLineItemAsConsumedRequest: MarkFoodOrderLineItemAsConsumedRequestDTO, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).markFoodOrderLineItemAsConsumed(id, markFoodOrderLineItemAsConsumedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a meal as not prepared
     * @param {string} id Meal ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public markMealAsNotPrepared(id: string, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).markMealAsNotPrepared(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a meal as prepared, with feedback
     * @param {string} id Meal ID
     * @param {MarkMealAsPreparedRequestDTO} markMealAsPreparedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public markMealAsPrepared(id: string, markMealAsPreparedRequest: MarkMealAsPreparedRequestDTO, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).markMealAsPrepared(id, markMealAsPreparedRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
