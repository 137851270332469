type Props = {
    label: string;
    element: React.ReactElement;
    className?: string;
};

const StripeField = ({ label, className, element }: Props) => {
    return (
        <div className={className}>
            <label>
                <span className="bodyMdLight block pb-1">{label}</span>
                {element}
            </label>
        </div>
    );
};

export default StripeField;
