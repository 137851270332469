/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ErrorDTO
 */
export interface ErrorDTO {
    /**
     * 
     * @type {number}
     * @memberof ErrorDTO
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ErrorDTO
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorDTO
     */
    'reason'?: ErrorDTOReasonEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ErrorDTOReasonEnum {
    ACCOUNT_INACTIVE = 'ACCOUNT_INACTIVE',
    ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
    ACCOUNT_WITH_EMAIL_ALREADY_EXISTS = 'ACCOUNT_WITH_EMAIL_ALREADY_EXISTS',
    ACTIVE_CLIENT_ALREADY_EXISTS_WITH_COHORT_DETAILS = 'ACTIVE_CLIENT_ALREADY_EXISTS_WITH_COHORT_DETAILS',
    APPOINTMENT_ATTENDANCE_POLICY_VIOLATION_CHARGE_ERROR = 'APPOINTMENT_ATTENDANCE_POLICY_VIOLATION_CHARGE_ERROR',
    APPOINTMENT_DATE_TIME_UNAVAILABLE_ERROR = 'APPOINTMENT_DATE_TIME_UNAVAILABLE_ERROR',
    CLIENT_AWAITING_ACTIVATION_ALREADY_EXISTS_WITH_COHORT_DETAILS = 'CLIENT_AWAITING_ACTIVATION_ALREADY_EXISTS_WITH_COHORT_DETAILS',
    CLIENT_ALREADY_VALIDATED = 'CLIENT_ALREADY_VALIDATED',
    CLIENT_INCORRECT_TOKEN = 'CLIENT_INCORRECT_TOKEN',
    CLIENT_MISSING_VALIDATION_TOKEN = 'CLIENT_MISSING_VALIDATION_TOKEN',
    CLIENT_REQUIRES_VALIDATION = 'CLIENT_REQUIRES_VALIDATION',
    CLIENT_TOKEN_EXPIRED = 'CLIENT_TOKEN_EXPIRED',
    FOOD_ORDER_CART_LINE_ITEMS_NEED_UPDATE = 'FOOD_ORDER_CART_LINE_ITEMS_NEED_UPDATE',
    FOOD_ORDER_CART_ESTIMATED_DELIVERY_OR_COST_DETAILS_NEED_UPDATE = 'FOOD_ORDER_CART_ESTIMATED_DELIVERY_OR_COST_DETAILS_NEED_UPDATE',
    FOOD_ORDER_VIOLATES_ELIGIBLE_MEAL_QUANTITIES_ERROR = 'FOOD_ORDER_VIOLATES_ELIGIBLE_MEAL_QUANTITIES_ERROR',
    INACTIVE_CLIENT_ALREADY_EXISTS_WITH_COHORT_DETAILS = 'INACTIVE_CLIENT_ALREADY_EXISTS_WITH_COHORT_DETAILS',
    INCORRECT_CARD_NUMBER = 'INCORRECT_CARD_NUMBER',
    INVALID_CARD_CVC = 'INVALID_CARD_CVC',
    INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
    PAYMENT_METHOD_MISSING = 'PAYMENT_METHOD_MISSING',
    PAYMENT_DECLINED = 'PAYMENT_DECLINED',
    RECENT_MFA_VERIFICATION_REQUIRED_FOR_ENDPOINT = 'RECENT_MFA_VERIFICATION_REQUIRED_FOR_ENDPOINT',
    CARD_EXPIRED = 'CARD_EXPIRED',
    PASSWORD_RESET_TOKEN_EXPIRED = 'PASSWORD_RESET_TOKEN_EXPIRED',
    PRIMARY_EMAIL_UPDATE_ERROR = 'PRIMARY_EMAIL_UPDATE_ERROR',
    PROVIDER_ALREADY_VERIFIED = 'PROVIDER_ALREADY_VERIFIED',
    SUBSCRIPTION_PACKAGE_INELIGIBLE = 'SUBSCRIPTION_PACKAGE_INELIGIBLE',
    OUTSIDE_US = 'OUTSIDE_US',
    JSON_PARSING_ERROR = 'JSON_PARSING_ERROR'
}

