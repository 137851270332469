/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * The Season Core API
 *
 * The version of the OpenAPI document: 1.18.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddLessonModuleFeedbackRequestDTO } from '../models';
// @ts-ignore
import { AddLessonModuleProgressStatusRequestDTO } from '../models';
// @ts-ignore
import { AddLessonModuleProgressStatusesRequestDTO } from '../models';
// @ts-ignore
import { EducationProgramProgressResponseDTO } from '../models';
/**
 * EducationProgramProgressApi - axios parameter creator
 * @export
 */
export const EducationProgramProgressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Lesson Module Feedback
         * @param {string} educationProgramProgressId 
         * @param {AddLessonModuleFeedbackRequestDTO} addLessonModuleFeedbackRequest Request describing what module to add given feedback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLessonModuleFeedback: async (educationProgramProgressId: string, addLessonModuleFeedbackRequest: AddLessonModuleFeedbackRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationProgramProgressId' is not null or undefined
            assertParamExists('addLessonModuleFeedback', 'educationProgramProgressId', educationProgramProgressId)
            // verify required parameter 'addLessonModuleFeedbackRequest' is not null or undefined
            assertParamExists('addLessonModuleFeedback', 'addLessonModuleFeedbackRequest', addLessonModuleFeedbackRequest)
            const localVarPath = `/education_program_progress/{educationProgramProgressId}/add-lesson-module-feedback`
                .replace(`{${"educationProgramProgressId"}}`, encodeURIComponent(String(educationProgramProgressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLessonModuleFeedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Lesson Module Progress Status
         * @param {string} clientId Client ID of the education program progress resource requested.
         * @param {AddLessonModuleProgressStatusRequestDTO} addLessonModuleProgressStatusRequest Request describing what module to add given state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLessonModuleProgressStatus: async (clientId: string, addLessonModuleProgressStatusRequest: AddLessonModuleProgressStatusRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('addLessonModuleProgressStatus', 'clientId', clientId)
            // verify required parameter 'addLessonModuleProgressStatusRequest' is not null or undefined
            assertParamExists('addLessonModuleProgressStatus', 'addLessonModuleProgressStatusRequest', addLessonModuleProgressStatusRequest)
            const localVarPath = `/education_program_progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLessonModuleProgressStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds lesson module progress statuses
         * @param {string} educationProgramProgressId 
         * @param {AddLessonModuleProgressStatusesRequestDTO} [addLessonModuleProgressStatusesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLessonModuleProgressStatuses: async (educationProgramProgressId: string, addLessonModuleProgressStatusesRequest?: AddLessonModuleProgressStatusesRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationProgramProgressId' is not null or undefined
            assertParamExists('addLessonModuleProgressStatuses', 'educationProgramProgressId', educationProgramProgressId)
            const localVarPath = `/education_program_progress/{educationProgramProgressId}/add-lesson-module-progress-statuses`
                .replace(`{${"educationProgramProgressId"}}`, encodeURIComponent(String(educationProgramProgressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLessonModuleProgressStatusesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivates an active education program
         * @param {string} educationProgramProgressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateEducationProgram: async (educationProgramProgressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationProgramProgressId' is not null or undefined
            assertParamExists('deactivateEducationProgram', 'educationProgramProgressId', educationProgramProgressId)
            const localVarPath = `/education_program_progress/{educationProgramProgressId}/deactivate`
                .replace(`{${"educationProgramProgressId"}}`, encodeURIComponent(String(educationProgramProgressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get education program progress for client. If the education program id is omitted, retrieves the active program progress.
         * @param {string} clientId Client ID of the education program progress resource requested.
         * @param {string} [educationProgramId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEducationProgramProgressForClient: async (clientId: string, educationProgramId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getEducationProgramProgressForClient', 'clientId', clientId)
            const localVarPath = `/education_program_progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (educationProgramId !== undefined) {
                localVarQueryParameter['educationProgramId'] = educationProgramId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EducationProgramProgressApi - functional programming interface
 * @export
 */
export const EducationProgramProgressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EducationProgramProgressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Lesson Module Feedback
         * @param {string} educationProgramProgressId 
         * @param {AddLessonModuleFeedbackRequestDTO} addLessonModuleFeedbackRequest Request describing what module to add given feedback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLessonModuleFeedback(educationProgramProgressId: string, addLessonModuleFeedbackRequest: AddLessonModuleFeedbackRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLessonModuleFeedback(educationProgramProgressId, addLessonModuleFeedbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add Lesson Module Progress Status
         * @param {string} clientId Client ID of the education program progress resource requested.
         * @param {AddLessonModuleProgressStatusRequestDTO} addLessonModuleProgressStatusRequest Request describing what module to add given state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLessonModuleProgressStatus(clientId: string, addLessonModuleProgressStatusRequest: AddLessonModuleProgressStatusRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationProgramProgressResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLessonModuleProgressStatus(clientId, addLessonModuleProgressStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds lesson module progress statuses
         * @param {string} educationProgramProgressId 
         * @param {AddLessonModuleProgressStatusesRequestDTO} [addLessonModuleProgressStatusesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLessonModuleProgressStatuses(educationProgramProgressId: string, addLessonModuleProgressStatusesRequest?: AddLessonModuleProgressStatusesRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLessonModuleProgressStatuses(educationProgramProgressId, addLessonModuleProgressStatusesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivates an active education program
         * @param {string} educationProgramProgressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateEducationProgram(educationProgramProgressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateEducationProgram(educationProgramProgressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get education program progress for client. If the education program id is omitted, retrieves the active program progress.
         * @param {string} clientId Client ID of the education program progress resource requested.
         * @param {string} [educationProgramId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEducationProgramProgressForClient(clientId: string, educationProgramId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationProgramProgressResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEducationProgramProgressForClient(clientId, educationProgramId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EducationProgramProgressApi - factory interface
 * @export
 */
export const EducationProgramProgressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EducationProgramProgressApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Lesson Module Feedback
         * @param {string} educationProgramProgressId 
         * @param {AddLessonModuleFeedbackRequestDTO} addLessonModuleFeedbackRequest Request describing what module to add given feedback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLessonModuleFeedback(educationProgramProgressId: string, addLessonModuleFeedbackRequest: AddLessonModuleFeedbackRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addLessonModuleFeedback(educationProgramProgressId, addLessonModuleFeedbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Lesson Module Progress Status
         * @param {string} clientId Client ID of the education program progress resource requested.
         * @param {AddLessonModuleProgressStatusRequestDTO} addLessonModuleProgressStatusRequest Request describing what module to add given state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLessonModuleProgressStatus(clientId: string, addLessonModuleProgressStatusRequest: AddLessonModuleProgressStatusRequestDTO, options?: any): AxiosPromise<EducationProgramProgressResponseDTO> {
            return localVarFp.addLessonModuleProgressStatus(clientId, addLessonModuleProgressStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds lesson module progress statuses
         * @param {string} educationProgramProgressId 
         * @param {AddLessonModuleProgressStatusesRequestDTO} [addLessonModuleProgressStatusesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLessonModuleProgressStatuses(educationProgramProgressId: string, addLessonModuleProgressStatusesRequest?: AddLessonModuleProgressStatusesRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addLessonModuleProgressStatuses(educationProgramProgressId, addLessonModuleProgressStatusesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivates an active education program
         * @param {string} educationProgramProgressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateEducationProgram(educationProgramProgressId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateEducationProgram(educationProgramProgressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get education program progress for client. If the education program id is omitted, retrieves the active program progress.
         * @param {string} clientId Client ID of the education program progress resource requested.
         * @param {string} [educationProgramId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEducationProgramProgressForClient(clientId: string, educationProgramId?: string, options?: any): AxiosPromise<EducationProgramProgressResponseDTO> {
            return localVarFp.getEducationProgramProgressForClient(clientId, educationProgramId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EducationProgramProgressApi - object-oriented interface
 * @export
 * @class EducationProgramProgressApi
 * @extends {BaseAPI}
 */
export class EducationProgramProgressApi extends BaseAPI {
    /**
     * 
     * @summary Add Lesson Module Feedback
     * @param {string} educationProgramProgressId 
     * @param {AddLessonModuleFeedbackRequestDTO} addLessonModuleFeedbackRequest Request describing what module to add given feedback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationProgramProgressApi
     */
    public addLessonModuleFeedback(educationProgramProgressId: string, addLessonModuleFeedbackRequest: AddLessonModuleFeedbackRequestDTO, options?: AxiosRequestConfig) {
        return EducationProgramProgressApiFp(this.configuration).addLessonModuleFeedback(educationProgramProgressId, addLessonModuleFeedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Lesson Module Progress Status
     * @param {string} clientId Client ID of the education program progress resource requested.
     * @param {AddLessonModuleProgressStatusRequestDTO} addLessonModuleProgressStatusRequest Request describing what module to add given state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationProgramProgressApi
     */
    public addLessonModuleProgressStatus(clientId: string, addLessonModuleProgressStatusRequest: AddLessonModuleProgressStatusRequestDTO, options?: AxiosRequestConfig) {
        return EducationProgramProgressApiFp(this.configuration).addLessonModuleProgressStatus(clientId, addLessonModuleProgressStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds lesson module progress statuses
     * @param {string} educationProgramProgressId 
     * @param {AddLessonModuleProgressStatusesRequestDTO} [addLessonModuleProgressStatusesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationProgramProgressApi
     */
    public addLessonModuleProgressStatuses(educationProgramProgressId: string, addLessonModuleProgressStatusesRequest?: AddLessonModuleProgressStatusesRequestDTO, options?: AxiosRequestConfig) {
        return EducationProgramProgressApiFp(this.configuration).addLessonModuleProgressStatuses(educationProgramProgressId, addLessonModuleProgressStatusesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivates an active education program
     * @param {string} educationProgramProgressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationProgramProgressApi
     */
    public deactivateEducationProgram(educationProgramProgressId: string, options?: AxiosRequestConfig) {
        return EducationProgramProgressApiFp(this.configuration).deactivateEducationProgram(educationProgramProgressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get education program progress for client. If the education program id is omitted, retrieves the active program progress.
     * @param {string} clientId Client ID of the education program progress resource requested.
     * @param {string} [educationProgramId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationProgramProgressApi
     */
    public getEducationProgramProgressForClient(clientId: string, educationProgramId?: string, options?: AxiosRequestConfig) {
        return EducationProgramProgressApiFp(this.configuration).getEducationProgramProgressForClient(clientId, educationProgramId, options).then((request) => request(this.axios, this.basePath));
    }
}
