import { getDateErrors } from 'utils/constants/errorMessages';
import * as Yup from 'yup';
import { isAgeVerified, isDateInPast, isDateStringValidFormat } from '../formValidation';

export const getPastDateValidationSchema = () =>
    Yup.string()
        .required(getDateErrors().REQUIRED)
        .test('formatError', getDateErrors().WRONG_FORMAT, (val) => isDateStringValidFormat(val || ''))
        .test('dateInPast', getDateErrors().FUTURE_DATE, (val) => isDateInPast(val || ''));

export const getDOBValidationSchema = () =>
    getPastDateValidationSchema().test('ageVerified', getDateErrors().UNDERAGE, (val) => isAgeVerified(val || ''));
