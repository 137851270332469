import { createContext } from 'react';

export type Route = {
    to: string;
    from: string | undefined;
};
export type ContextState = {
    route: Route;
    updateRoute: () => void;
};

export const RouterContext = createContext<ContextState>({
    route: { to: '', from: '' },
    updateRoute: () => undefined,
});

export const CONTEXT_NAME = 'RouterContext';
RouterContext.displayName = CONTEXT_NAME;
